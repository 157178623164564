import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

import ApiUrl from "../../helpers/url";
import { generateConfig } from '../helper/axiosHelper';

export const fetchListData = createAsyncThunk(
  'adminInstansi/fetchListData',
  async (_, { rejectWithValue }) => {
    const url = ApiUrl.get_institution.url;
    const method = ApiUrl.get_institution.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDataById = await createAsyncThunk(
  'adminInstansi/fetchDataById',
  async (id, { rejectWithValue }) => {

    const url = `${ApiUrl.get_institution.url}/${id}`;
    const method = ApiUrl.get_institution.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSaveDataModal = createAsyncThunk(
  'adminInstansi/fetchSaveDataModal',
  async (param, { rejectWithValue }) => {
    const {data, instansiId, action} = param;

    const url = action === 'Tambah' ? ApiUrl.create_institution.url : `${ApiUrl.update_institution.url}${instansiId}`;
    const method = action === 'Tambah' ? ApiUrl.create_institution.method : ApiUrl.update_institution.method;
    try {
      const response = await axios.request(generateConfig(url, method, data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeleteById = await createAsyncThunk(
  'adminInstansi/fetchDeleteById',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.delete_institution.url}${id}`;
    const method = ApiUrl.delete_institution.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const adminInstansiSlice = createSlice({
  name: 'instansi',
  initialState: {
    listDataResp: [],
    listDataStatus: 'idle',
    listDataError: null,
    listDataPending: true,

    byIdResp: [],
    byIdStatus: 'idle',
    byIdError: null,
    byIdPending: true,
  
    saveDataModalResp: [],
    saveDataModalStatus: 'idle',
    saveDataModalError: null,
    saveDataModalPending: true,

    deleteByIdResp: [],
    deleteByIdStatus: 'idle',
    deleteByIdError: null,
    deleteByIdPending: true,
  },
  reducers: {
    resetStatus: (state) => {
      state.byIdStatus = 'idle';
    },
    resetStatusList: (state) => {
      state.listDataStatus = 'idle';
      state.listDataPending = true;
      state.deleteByIdPending = true;
    },
    resetDataModalInstansi:  (state) => {
      state.byIdResp = {data:{}}; 
    },
    changeDataModalInstansi: (state, action) => {
      if(state.byIdResp.data === undefined){
        state.byIdResp = {data:{}}; 
      }
      const key = action.payload.key;
      const value = action.payload.value;
      state.byIdResp.data[key] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchListData
      .addCase(fetchListData.pending, (state) => {
        state.listDataStatus = 'loading';
      })
      .addCase(fetchListData.fulfilled, (state, action) => {
        state.listDataStatus = 'succeeded';
        state.listDataResp = action.payload;
        state.listDataPending = false;
      })
      .addCase(fetchListData.rejected, (state, action) => {
        state.listDataStatus = 'failed';
        state.listDataError = action.payload?.message;
      })

      // fetchDataById
      .addCase(fetchDataById.pending, (state) => {
        state.byIdStatus = 'loading';
      })
      .addCase(fetchDataById.fulfilled, (state, action) => {
        state.byIdStatus = 'succeeded';
        state.byIdResp = action.payload;
        state.byIdPending = false;
      })
      .addCase(fetchDataById.rejected, (state, action) => {
        state.byIdStatus = 'failed';
        state.byIdError = action.payload.message;
      })

      // fetchSaveDataModal
      .addCase(fetchSaveDataModal.pending, (state) => {
        state.saveDataModalStatus = 'loading';
      })
      .addCase(fetchSaveDataModal.fulfilled, (state, action) => {
        state.saveDataModalStatus = 'succeeded';
        state.byIdResp = action.payload;
        state.byIdPending = false;
        // state.saveDataModalError = null;
      })
      .addCase(fetchSaveDataModal.rejected, (state, action) => {
        state.saveDataModalStatus = 'failed';
        state.saveDataModalError = action.payload.message;
      })

      // fetchDeleteById
      .addCase(fetchDeleteById.pending, (state) => {
        state.deleteByIdStatus = 'loading';
      })
      .addCase(fetchDeleteById.fulfilled, (state, action) => {
        state.deleteByIdStatus = 'succeeded';
        state.deleteByIdResp = action.payload;
        state.deleteByIdPending = false;
      })
      .addCase(fetchDeleteById.rejected, (state, action) => {
        state.deleteByIdStatus = 'failed';
        state.deleteByIdError = action.payload.message;
      })
  },
})

export const { resetStatus, resetStatusList, resetDataModalInstansi, changeDataModalInstansi } = adminInstansiSlice.actions;
export default adminInstansiSlice.reducer;