import React from "react";

// Dashboard
import Dashboard from "../dashboard/instansi/Dashboard";
import Banner from "../dashboard/instansi/banner/Index";
import Dokter from "../dashboard/instansi/dokter/Index";
import TemplateWA from "../dashboard/instansi/template/Index";

const instansiRoutes = [
  { path: "", element: <Dashboard /> },
  { path: "dokter", element: <Dokter /> },
  { path: "template-wa", element: <TemplateWA /> },
  { path: "banner", element: <Banner /> },
]

export default instansiRoutes;