import React, { useState, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast, Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Helmet from "react-helmet";
import axios from "axios";
import ApiUrl from "../helpers/url";
import { cekSessionLogin } from "../helpers/jwtToken";

export default function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [newLoad, setNewLoad] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setNewLoad(false);
    cekSessionLogin(navigate);

    if (localStorage.getItem("user_id")) {
      if (localStorage.getItem("time_login_start")) {
        const timeLoginStart = new Date(
          localStorage.getItem("time_login_start"),
        );
        const currentTime = new Date();
        const differenceInMinutes =
          (currentTime - timeLoginStart) / (1000 * 60);
        if (differenceInMinutes > 60) {
          return localStorage.clear();
        } else {
          return navigate("/verification");
        }
      }
    }
  }, []);

  const login = async () => {
    let data = JSON.stringify({
      email: email,
      password: password,
      auth_type: "EMAIL",
    });

    let config = {
      method: ApiUrl.login.method,
      maxBodyLength: Infinity,
      url: ApiUrl.login.url,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    setLoading(true);
    await axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          const currentDateTime = new Date();
          const dateTimeString = currentDateTime.toISOString();
          localStorage.setItem("user_id", response.data.user_id);
          localStorage.setItem("email_login", email);
          localStorage.setItem("time_login_start", dateTimeString);
          setEmail("");
          setPassword("");
          toast.success("Login Berhasil!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          navigate("/verification");
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>Auto Health Care | Sign In</title>
      </Helmet>
      <ToastContainer />
      <div className="row g-0">
        <div className="col-lg-6 d-none-media">
          <div className="hero-login"></div>
        </div>
        <div className="col-lg-6">
          <div className="page-sign">
            <Card className="card-sign">
              <Card.Header>
                <Link to="/" className="header-logo mb-2">
                  Masuk
                </Link>
                <Card.Text>
                  Masuk dengan alamat email yang terdaftar pada akun Anda.
                </Card.Text>
              </Card.Header>
              <Card.Body>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    login();
                  }}
                >
                  <div className="mb-2">
                    <Form.Label>Alamat Email</Form.Label>
                    {!newLoad ? (
                      <Form.Control
                        type="text"
                        placeholder="Masukan Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        readOnly={loading ? true : false}
                      />
                    ) : (
                      <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                        <Skeleton count={1} style={{ height: "38px" }} />
                      </SkeletonTheme>
                    )}
                  </div>
                  <div className="mb-3">
                    <Form.Label className="d-flex justify-content-between">
                      Password
                    </Form.Label>
                    {!newLoad ? (
                      <Form.Control
                        type="password"
                        placeholder="Masukan Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        readOnly={loading ? true : false}
                      />
                    ) : (
                      <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                        <Skeleton count={1} style={{ height: "38px" }} />
                      </SkeletonTheme>
                    )}
                  </div>
                  {!newLoad ? (
                    <Button
                      variant="success"
                      type="submit"
                      disabled={loading ? true : false}
                    >
                      {loading ? "Silahkan Tunggu ..." : "Masuk"}
                    </Button>
                  ) : (
                    <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                      <Skeleton count={1} style={{ height: "38px" }} />
                    </SkeletonTheme>
                  )}
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}