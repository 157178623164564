const Sidebar = {
    admin: [
      {
        menu: "Dashboard",
        link: '/admin',
        icon: "ri-home-smile-line",
        key: 11,
        sub: []
      },
      {
        menu: "Instansi",
        link: '/admin/instansi',
        icon: "ri-hospital-line",
        key: 14,
        sub: []
      },
      {
        menu: "Program",
        link: '/admin/program/ruang-kerja',
        icon: "ri-file-list-3-line",
        key: 13,
        sub: [
        ],
      },
      {
        menu: "Users",
        link: '/admin/user',
        icon: "ri-user-line",
        key: 15,
        sub: []
      },
    ],
    instansi: [
      {
        menu: "Dashboard",
        link: '/instansi',
        icon: "ri-home-smile-line",
        key: 21,
        sub: []
      },
      {
        menu: "Dokter/Perawat",
        link: '/instansi/dokter',
        icon: "ri-shield-user-line",
        key: 22,
        sub: []
      },
      {
        menu: "Template WA",
        link: '/instansi/template-wa',
        icon: "ri-whatsapp-line",
        key: 23,
        sub: []
      },
      {
        menu: "Banner",
        link: '/instansi/banner',
        icon: "ri-image-2-fill",
        key: 24,
        sub: []
      },
    ],
    dokter: [
      {
        menu: "Dashboard",
        link: '/dokter',
        icon: "ri-home-smile-line",
        key: 31,
        sub: []
      },
      {
        menu: "Pemantauan Pasien",
        link: '/dokter/laporan',
        icon: "ri-file-list-3-line",
        key: 32,
        sub: [
          {
            title: 'Laporan',
            link: '/dokter/laporan',
            key: 321,
            key_before: 32
          },
          {
            title: 'Formulir',
            link: '/dokter/formulir',
            key: 323,
            key_before: 32
          }
        ],
      },
      {
        menu: "Daftar Pasien",
        link: '/dokter/pasien/aktif',
        icon: "ri-user-heart-line",
        key: 33,
        sub: [
          {
            title: 'Pasien Aktif',
            link: '/dokter/pasien/aktif',
            key: 331,
            key_before: 33
          },
          {
            title: 'Pasien Non Aktif',
            link: '/dokter/pasien/non-aktif',
            key: 332,
            key_before: 33
          },
        ],
      },
      {
        menu: "Penjadwalan",
        link: '/dokter/jadwal',
        icon: "ri-calendar-2-line",
        key: 34,
      },
    ]
  };
  
  export default Sidebar;