export const generateConfig = (url, method, data=null) => {
    const config = {
        method: method,
        maxBodyLength: Infinity,
        url: url,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return config
}

export const generateConfigFormData = (url, method, data=null) => {
    const config = {
        method: method,
        maxBodyLength: Infinity,
        url: url,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data'
        },
        data: data
    };
    return config
}

export const generateConfigCustomHeader = (url, method, headers, data=null) => {
    const config = {
        method: method,
        maxBodyLength: Infinity,
        url: url,
        headers: headers,
        data: data
    };
    return config
}