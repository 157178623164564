import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { Line } from "../../components/chart/index";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchListDashboard,
  fecthListInstitusiDashboard,
} from "../../redux/slices/instansiDashboardSlice";

export default function Dashboard() {
  const [axis, setAxis] = useState([]);
  const [legend, setLegend] = useState(["Dokter/Perawat"]);
  const [data, setData] = useState([
    {
      name: "Dokter/Perawat",
      type: "line",
      stack: "Total",
      data: [],
      color: "#15DB84",
    },
  ]);

  const dispatch = useDispatch();
  const instansiDashboard = useSelector((state) => state.instansiDashboard);

  const dispatchFecthGetDashboard = () => {
    if (instansiDashboard.listDashboardStatus === "idle") {
      dispatch(fetchListDashboard());
    }

    if (instansiDashboard.listDashboardStatus === "failed") {
      toast.error(instansiDashboard.listDashboardError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }

    if (instansiDashboard.listInstitusiDashboardStatus === "idle") {
      dispatch(fecthListInstitusiDashboard());
    }
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const updateData = (jsonData) => {
    const newAxis = jsonData.doctor_and_nurse_monthly_data.map(
      (item) => `${monthNames[item.month - 1]} ${item.year}`,
    );
    const dokterPerawatData = jsonData.doctor_and_nurse_monthly_data.map(
      (item) => item.total,
    );
    setAxis(newAxis);

    setData((prevData) => [
      {
        ...prevData[0],
        data: dokterPerawatData,
      },
    ]);
  };

  useEffect(() => {
    dispatchFecthGetDashboard();
    if (instansiDashboard.listDashboardStatus === "succeeded") {
      updateData(instansiDashboard.listDashboardResp.data);
    }
  }, [instansiDashboard]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Dashboard</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Selamat Datang, Admin!</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-4 mb-3">
            <div className="card card-one">
              <div className="card-body">
                <div className="d-flex align-items-center card-icon-dashboard">
                  <i className="ri-group-line icon first"></i>
                  <div>
                    <label className="card-title fs-sm fw-medium mb-1">
                      Jumlah Perawat/Dokter
                    </label>
                    <h3 className="card-value mb-0">
                      {" "}
                      {instansiDashboard.listDashboardResp?.data
                        ? instansiDashboard.listDashboardResp?.data
                            ?.total_doctor_and_nurse
                        : 0}
                    </h3>
                    <Link className="text-muted" to="/instansi/dokter">
                      <span className="d-inline-flex fs-12">
                        Lihat Selengkapnya{" "}
                        <i className="text-primary ri-arrow-right-circle-line"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-4 mb-3">
            <div className="card card-one">
              <div className="card-body">
                <div className="d-flex align-items-center card-icon-dashboard">
                  <i className="ri-file-text-line icon second"></i>
                  <div>
                    <label className="card-title fs-sm fw-medium mb-1">
                      Jumlah Template Pesan
                    </label>
                    <h3 className="card-value mb-0">
                      {" "}
                      {instansiDashboard.listDashboardResp?.data
                        ? instansiDashboard.listDashboardResp?.data
                            ?.total_template
                        : 0}
                    </h3>
                    <Link className="text-muted" to="/instansi/template-wa">
                      <span className="d-inline-flex fs-12">
                        Lihat Selengkapnya{" "}
                        <i className="text-primary ri-arrow-right-circle-line"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-4 mb-3">
            <div className="card card-one">
              <div className="card-body">
                <div className="d-flex align-items-center card-icon-dashboard">
                  <i className="ri-image-line icon third"></i>
                  <div>
                    <label className="card-title fs-sm fw-medium mb-1">
                      Jumlah Banner
                    </label>
                    <h3 className="card-value mb-0">
                      {" "}
                      {instansiDashboard.listDashboardResp?.data
                        ? instansiDashboard.listDashboardResp?.data
                            ?.total_banner
                        : 0}
                    </h3>
                    <Link className="text-muted" to="/instansi/banner">
                      <span className="d-inline-flex fs-12">
                        Lihat Selengkapnya{" "}
                        <i className="text-primary ri-arrow-right-circle-line"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-8 col-12 mb-2">
            <div className="card card-one">
              <div className="card-header">
                <h6 className="card-title">
                  Grafik Pertumbuhan Dokter/Perawat
                </h6>
              </div>
              <div className="card-body">
                {axis.length > 0 ? (
                  <Line axis={axis} category={legend} data={data} />
                ) : (
                  <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                    <Skeleton count={1} style={{ height: "250px" }} />
                  </SkeletonTheme>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb-2">
            <div className="card card-one">
              <div className="card-header">
                <h6 className="card-title">Daftar Dokter/Perawat Terbaru</h6>
              </div>
              <div className="card-body">
                <ul className="people-group">
                  {instansiDashboard.listInstitusiDashboardStatus ==
                  "succeeded" ? ( instansiDashboard.listInstitusiDashboardResp.data ? 
                    instansiDashboard.listInstitusiDashboardResp.data.map(
                      (item, index) => (
                        <li key={index} className="people-item">
                          <div className="people-body d-flex align-items-center mr-1">
                            <img
                              src={item.image ? item.image : "/favicon.png"}
                              className="w-40px"
                              alt={item.name}
                            />
                            <div>
                              <h6 className="fw-bold text-dark">{item.name}</h6>
                              <span className="d-flex align-items-center">
                                {item.department} | {item.position}
                              </span>
                            </div>
                          </div>
                        </li>
                      ),
                    ) : <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/>
                    <h5>Data Not Found</h5></div>
                  ) : (
                    <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                      <Skeleton
                        count={5}
                        style={{ height: "50px" }}
                        className="mb-1"
                      />
                    </SkeletonTheme>
                  )}
                </ul>
              </div>
              <div className="card-footer d-flex justify-content-center">
                <Link to="/admin/instansi/aktif" className="fs-sm">
                  Lihat Selengkapnya
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}