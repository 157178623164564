import React, { useEffect, useState } from "react";
import { Row, Button, Modal, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import Formulir from "../formulir/Index";
import { Tabs } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchDetailWorkspace,
  fetchDataProgram,
  fetchDetailProgramModal,
  fetchSaveDataModalProgram,
  fetchDeleteProgram,
  resetStatusListProgram,
  resetDataModalProgram,
  changeDataModalProgram,
} from "../../../redux/slices/adminRuangKerjaDetailSlice";

export default function ProgramDetail() {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [mount, setMount] = useState(false);
  const getSegment = (path) => {
    const segments = path.split("/").filter(Boolean);
    return segments[3];
  };
  const Segment = getSegment(location.pathname);

  const dispatch = useDispatch();
  const adminRuangKerjaDetail = useSelector(
    (state) => state.adminRuangKerjaDetail
  );
  const detailWorkspace = useSelector(
    (state) => state.adminRuangKerjaDetail.detailWorkspaceResp.data
  );

  const programModal = useSelector(
    (state) => state.adminRuangKerjaDetail.detailProgramModalResp.data
  );

  const dispatchFetchDetailWorkspace = () => {
    if (adminRuangKerjaDetail.detailWorkspaceStatus === "idle") {
      dispatch(fetchDetailWorkspace(Segment));
    }

    if (adminRuangKerjaDetail.detailWorkspaceStatus === "failed") {
      toast.error(adminRuangKerjaDetail.detailWorkspaceError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
      if(adminRuangKerjaDetail.detailWorkspaceError == 'Workspace not found'){
        return window.history.back();
      }
    }
  };

  const dispatchFetchDataProgram = () => {
    if (adminRuangKerjaDetail.dataProgramStatus === "idle") {
      dispatch(fetchDataProgram(Segment));
    }

    if (adminRuangKerjaDetail.dataProgramStatus === "failed") {
      toast.error(adminRuangKerjaDetail.dataProgramError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const columnsrk = [
    {
      name: "Program",
      selector: (row) => row.name,
    },
    {
      name: "Ruang Kerja",
      selector: (row) => (detailWorkspace?.name ? detailWorkspace.name : "-"),
    },
    {
      name: "Opsi",
      selector: (row) => row.id,
      cell: (row) => (
        <>
          <Link to="#" className="btn btn-primary" onClick={() => edit(row.id)}>
            <i className="ri-edit-2-line"></i>
          </Link>{" "}
          &nbsp;
          <Link
            to="#"
            className="btn btn-danger"
            onClick={() => deleteData(row.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Link>
        </>
      ),
    },
  ];

  const addData = (title) => {
    setShow(true);
    setTitleModal(title);
  };

  const handleClose = () => {
    setShow(false);
    dispatch(resetDataModalProgram());
  };

  const saveData = async () => {
    let data = {
      name: programModal.name,
    };
    if (titleModal === "Tambah") {
      data = {
        names: [programModal.name],
        workspace_id: detailWorkspace.id ? detailWorkspace.id : "",
      };
    }

    const param = {
      data: data,
      id: programModal.id,
      action: titleModal,
    };

    const disp = await dispatch(fetchSaveDataModalProgram(param));
    if (disp.payload?.status !== true) {
      let message = "Fail - Silakan coba lagi";
      if (disp.payload?.message) {
        message = disp.payload.message;
      }
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    } else {
      toast.success(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });

      await dispatch(resetStatusListProgram());
      dispatchFetchDataProgram();
      handleClose();
    }
  };

  const edit = async (id) => {
    setTitleModal("Ubah");
    if (adminRuangKerjaDetail.detailProgramModalStatus === "idle") {
      const disp = await dispatch(fetchDetailProgramModal(id));
      if (disp.payload.status !== true) {
        toast.error(disp.payload.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      }
      setShow(true);
    }
  };

  const deleteData = (id) => {
    swal({
      title: "Apa Data Ingin Dihapus?",
      text: "Jika data dihapus, data tidak dapat dikembalikan!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: "Batal",
        confirm: {
          text: "Hapus",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
    }).then(async (willDelete) => {
      if (willDelete) {
        const disp = await dispatch(fetchDeleteProgram(id));
        if (disp.payload?.status !== true) {
          let message = "Fail - Silakan coba lagi";
          if (disp.payload?.message) {
            message = disp.payload.message;
          }
          toast.error(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
        } else {
          toast.success("Data Berhasil Dihapus!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
        }
        await dispatch(resetStatusListProgram());
        dispatchFetchDataProgram();
      }
    });
  };

  useEffect(() => {
    setMount(true);
    dispatchFetchDetailWorkspace();
    dispatchFetchDataProgram();
  }, [adminRuangKerjaDetail]);

  // unmount
  useEffect(
    () => () => {
      if (mount) {
        dispatch({ type: "RESET_ALL" }); // reset data redux on this page
      }
    },
    [mount]
  );

  const handleChangeModal = (e) => {
    let value = e.target.value;
    let key = e.target.name;
    dispatch(changeDataModalProgram({ key: key, value: value }));
  };

  const items = [
    {
      key: "1",
      label: "Program",
      children: (
        <>
          <Row>
            <h5 className="d-flex align-items-center justify-content-between">
              Daftar Program
              <Button
                variant="primary"
                className="d-flex align-items-center"
                onClick={() => addData("Tambah")}
              >
                <i className="ri-add-line"></i>Tambah Program
              </Button>
            </h5>
            <hr />
            {adminRuangKerjaDetail.dataProgramPending ? (
              <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                <Skeleton count={1} style={{ height: "150px" }} />
              </SkeletonTheme>
            ) : adminRuangKerjaDetail.dataProgramResp.data ? (
              <DataTable
                columns={columnsrk}
                data={adminRuangKerjaDetail.dataProgramResp.data}
                pagination
                progressPending={adminRuangKerjaDetail.dataProgramPending}
                defaultSortFieldId={1}
              />
            ) : (
              <div className="text-center">
                {" "}
                <img src={"/favicon.png"} width="100px" alt={"Icon"} />
                <h5>Data Not Found</h5>
                <p>You can create program to workspace!</p>
              </div>
            )}
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: "Formulir",
      children: (
        <>
          <Formulir />
        </>
      ),
    },
  ];
  const onChange = (key) => {};

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Detail Program</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/admin">Admin</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/program/ruang-kerja">Ruang Kerja</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Detail
              </li>
            </ol>
            <h4 className="main-title mb-0">
              {detailWorkspace?.name ? detailWorkspace.name : "-"}
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-one w-100">
              <div className="card-body">
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            saveData();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{titleModal} Program</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formFileSm" className="mb-3">
              <Form.Label>Program</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukan Program"
                name="name"
                onChange={handleChangeModal}
                value={programModal?.name}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Batalkan
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={adminRuangKerjaDetail.saveDataModalProgramPending}
            >
              {adminRuangKerjaDetail.saveDataModalProgramPending
                ? "Silahkan Tunggu ..."
                : "Simpan"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
}