import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Dropdown, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import DataTable from "react-data-table-component";
import Select from "react-select";
import swal from "sweetalert";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {decodeToken} from "../../helpers/jwtToken";
import { convertToGMT7 } from "../../helpers/timeConvert";
import { useSelector, useDispatch } from "react-redux";
import { fetchDataProgramByWorkspace } from "../../redux/slices/adminInstansiDetailSlice";
import { fetchSendMessage } from "../../redux/slices/globalSlice";
import { 
  fetchReportByWorkspace, fetchReport, fetchComments, fetchDeleteComment,
  fetchSaveComment, fetchMarkDoneUndone, fetchReadNotification, resetReportByWorkspace
} from "../../redux/slices/dokterLaporanSlice";

export default function Report({ selectedWorkspace }) {
  const [dataReportFill, setDataReportFill] = useState([]);
  const [dataProgram, setDataProgram] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [programId, setProgramId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [formComment, setFormComment] = useState("");
  const [formCommentId, setFormCommentId] = useState("");
  const [selectedStatusReport, setSelectedStatusReport] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalPesan, setShowModalPesan] = useState(false);
  const [dataModal, setDataModal] = useState({ name: "", phone: "", id: "", report_id: "" });
  const [dataModalProgram, setDataModalProgram] = useState({ id: "", name: "" });
  const [dataModalSendMsg, setDataModalSendMsg] = useState({});
  const [modalSendMsgValid, setModalSendMsgValid] = useState({
    status: false,
    msg: "",
  });
  const [readComments, setReadComments] = useState({});
  const [dataReportAnswer, setDataReportAnswer] = useState([]);
  const [saveFormLoadComment, setSaveFormLoadComment] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalRedux = useSelector((state) => state.global);
  const adminInstansiDetail = useSelector((state) => state.adminInstansiDetail);
  const dokterLaporan = useSelector((state) => state.dokterLaporan);

  const columns = [
    {
      name: "Pasien",
      selector: (row) => row.patient_program.patient.id,
      cell: (row) => (
        <>
          <div className="flex-direction-column">
            <h6 className="fs-xs mt-2 mb-0">{row.patient_program.patient.mnr}</h6>
            <p className="fs-xs text-secondary d-flex align-items-center">
              {row.patient_program.patient.name}
            </p>
          </div>
        </>
      ),
    },
    {
      name: "Program",
      selector: (row) => row.patient_program.program.name,
    },
    {
      name: "Waktu Pendaftaran",
      selector: (row) => convertToGMT7(row.patient_program.registered_at),
    },
    {
      name: "Waktu Laporan",
      selector: (row) => row.submit_time ? convertToGMT7(row.submit_time) : "-",
      cell: (row) => (
        <>
          <div className="flex-direction-column">
            <span className={`badge text-center bg-${getStatusColor(row.status).color} d-flex align-items-center justify-content-center mb-0`}>
              <i className={`${getStatusColor(row.status).icon}`}></i>{" "} {row.status}
            </span>
            <p className="fs-xs text-secondary d-flex align-items-center mb-0">{row.submit_time ? convertToGMT7(row.submit_time) : "-"}</p>
            </div>
        </>
      )
    },
    {
      name: "Komentar",
      selector: (row) => row.komentar,
      cell: (row) => (
        <>
          <Button variant="light" className="d-flex align-items-center p-relative btn btn-outline-primary" onClick={() => openModal(row)}>
            <i className="ri-chat-3-line"></i> {row?.unread_comments?.length > 0 && !readComments[row.id] ? <small className="tooltip-red"></small> : <></>}
          </Button>{" "}
          &nbsp;
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              <i className="ri-more-2-fill"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                position: "relative !important",
                transform: "none !important",
              }}
            >
              <Dropdown.Item
                href="#"
                onClick={() =>
                  navigate("/dokter/pasien/detail/" + row.patient_program.patient.id + "/profile")
                }
              >
                Profile Pasien
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() => openModalPesan(row)}
                className="text-success"
              >
                Kirim Pesan
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ),
    },
  ];
  
  const statusReport = [{label: 'Belum Selesai', value: 'SUBMITTED'}, {label:'Sudah Selesai', value:'DONE'}]

  const dispatchGetDataProgram = async () => {
    if (adminInstansiDetail.dataProgramStatus === "idle") {
      dispatch(fetchDataProgramByWorkspace(selectedWorkspace));
    }

    if (adminInstansiDetail.dataProgramStatus === "failed") {
      toast.error(adminInstansiDetail.dataProgramError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const getStatusColor = (stat) => {
    if (stat == "PENDING") {
      return { color: "warning", icon: "ri-time-line" };
    } else if (stat == "SUBMITTED") {
      return { color: "info", icon: "ri-record-circle-fill" };
    } else {
      return { color: "success", icon: "ri-shield-check-fill" };
    }
  };

  const selectOptionsstatus = [
    { value: "", label: "Semua Status" },
    { value: "1", label: "Pending" },
    { value: "2", label: "Normal" },
    { value: "3", label: "Terselesaikan" },
  ];

  const openModal = async(row) => {
    setReadComments((prevState) => ({
      ...prevState,
      [row.id]: true,
    }));
    setDataModal({
      name: row.patient_program.patient.name,
      phone: row.patient_program.patient.phone,
      id: row.patient_program.patient.id,
      report_id: row.id
    });
    setDataModalProgram({
      id: row.patient_program.program.id,
      name: row.patient_program.program.name
    })
    setSelectedStatusReport(row.status);
    setShowModal(true);
    await getReportDetail(row.id);
    if(row?.unread_comments?.length > 0 && !readComments[row.id]){
      await readNotification(row.id, row.unread_comments)
    }
  };

  const closeModal = () => {
    dispatch(resetReportByWorkspace());
    dispatchReportByWorkspace(selectedWorkspace, statusId, programId);
    setReadComments([]);
    setSelectedStatusReport('');
    setShowModal(false);
  };

  const openModalPesan = (row, komen = null) => {
    if(komen){
      setDataModal({
        name: row.name,
        phone: row.phone,
        id: row.id,
      });
    }else{
      setDataModal({
        name: row.patient_program.patient.name,
        phone: row.patient_program.patient.phone,
        id: row.patient_program.patient.id,
      });
    }
    setShowModalPesan(true);
  };

  const closeModalPesan = () => {
    setDataModal({ name: "", phone: "", id: "" });
    setDataModalSendMsg({});
    setShowModalPesan(false);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    const filtered = dokterLaporan.reportByWorkspaceResp.data?.filter((item) => {
      return item.patient_program.patient.name
        .toLowerCase()
        .includes(value.toLowerCase());
    });

    setDataReportFill(filtered);
  };

  const dispatchReportByWorkspace = async (workspace, statusFill, programFill) => {
    if (dokterLaporan.reportByWorkspaceStatus === "idle") {
      const param = {
        workspace: workspace,
        programFill: programFill,
        statusFill: statusFill
      }
      await dispatch(fetchReportByWorkspace(param));
    }

    if (dokterLaporan.reportByWorkspaceStatus === "failed") {
      toast.error(dokterLaporan.reportByWorkspaceError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleChangeFilterReport = (workspace, statusFill, programFill)=> {
    dispatch(resetReportByWorkspace());
    dispatchReportByWorkspace(workspace, statusFill, programFill);
    
  }

  const getReportDetail = async(id) => {
    const reportsData = [];
    try {
      const [dispReport, dispComment] = await Promise.all([
        dispatch(fetchReport(id)),dispatch(fetchComments(id))
      ]);
    
      if (dispReport.meta.requestStatus == 'rejected' || dispComment.meta.requestStatus == 'rejected') {
          toast.error('Data Report or Comment Not Found!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
          });
      }

      const reportData = JSON.parse(JSON.stringify(dispReport.payload.data));
      const commentData = JSON.parse(JSON.stringify(dispComment.payload.data));

      reportsData.push({
          id: id,
          data: reportData.data,
          comment: commentData.data
      });
      setDataReportAnswer(reportsData);
    } catch (error) {
      console.log(error)
      toast.error('Data Report or Comment Not Found', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
      });
    }
  }

  const readNotification = async (id, data) => {
    const param = {
      id: id,
      data: {"ids":data}
    }
    const disp = await dispatch(fetchReadNotification(param));
    if (disp.meta.requestStatus == 'fulfilled') {
    } else {
      toast.error(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const validateModalSendMsg = () => {
    let msg = "";
    if (!dataModalSendMsg.body) {
      msg = "Body tidak boleh kosong.";
    }
    if (dataModalSendMsg.textButton && !dataModalSendMsg.urlButton) {
      if (msg) {
        msg = `${msg} <br>`;
      }
      msg = `${msg}Text Button diisi, URL Button tidak boleh kosong`;
    }
    if (!dataModalSendMsg.textButton && dataModalSendMsg.urlButton) {
      if (msg) {
        msg = `${msg} <br>`;
      }
      msg = `${msg}URL Button diisi, URL Button tidak boleh kosong`;
    }

    const status = !msg ? true : false;

    setModalSendMsgValid({ status: status, msg: msg });
  };

  const handleChangeModalSendMsg = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setDataModalSendMsg((dataModalSendMsg) => ({
      ...dataModalSendMsg,
      [key]: value,
    }));
  };

  const handleSendMessage = async () => {
    const data = {
      header: {
        text: dataModalSendMsg.header ? dataModalSendMsg.header : "",
      },
      body: {
        text: dataModalSendMsg.body ? dataModalSendMsg.body : "",
      },
      footer: {
        text: dataModalSendMsg.footer ? dataModalSendMsg.footer : "",
      },
      button: {
        display_text: dataModalSendMsg.textButton
          ? dataModalSendMsg.textButton
          : "",
        url: dataModalSendMsg.urlButton ? dataModalSendMsg.urlButton : "",
      },
    };

    const disp = await dispatch(fetchSendMessage({ data, id: dataModal.id }));
    if (disp?.payload?.data?.status) {
      toast.success(disp.payload.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
      closeModalPesan();
    }else{
      toast.error(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const editComment = (comment = null) => {
    setFormComment(comment?.comment ? comment.comment : '')
    setFormCommentId(comment?.id ? comment.id : '')
  }

  const deleteComment = async(id, comment_id) => {
    swal({
      title: "Peringatan?",
      text: "Apa anda ingin menghapus komentar ini?",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: "Batal",
        confirm: {
          text: "Hapus",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
    }).then(async(willChange) => {
      if (willChange) {
        const param = {
          id: id,
          commentId: comment_id
        }
        const disp = await dispatch(fetchDeleteComment(param));
        if (disp.meta.requestStatus == 'fulfilled') {
          setDataReportAnswer(prevDataReportAnswer => {
            return prevDataReportAnswer.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        comment: item.comment.filter(comment => comment.id !== comment_id)
                    };
                }
                return item;
            });
          });

          toast.success(disp.payload.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
          
        } else {
          toast.error(disp.payload.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
        }
      }
    });
  }

  const saveFormComment = async (id) => {
    if (saveFormLoadComment) {
        return;
    }

    setSaveFormLoadComment(true);

    const param = {
      id: id,
      commentId: formCommentId,
      data: { 'comment': formComment }
    }
    const disp = await dispatch(fetchSaveComment(param));
    if (disp.meta.requestStatus == 'fulfilled') {
      toast.success(disp.payload.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });

      setDataReportAnswer(prevState => {
        const updatedDataReportAnswer = [...prevState];
        const dataIndex = updatedDataReportAnswer.findIndex(item => item.data.id === id);
        if (dataIndex !== -1) {
            if (formCommentId) {
                const commentIndex = updatedDataReportAnswer[dataIndex].comment.findIndex(comment => comment.id === formCommentId);
                if (commentIndex !== -1) {
                    updatedDataReportAnswer[dataIndex].comment[commentIndex].comment = formComment;
                }
            } else {
              if (updatedDataReportAnswer[dataIndex].comment && Array.isArray(updatedDataReportAnswer[dataIndex].comment)) {
                  updatedDataReportAnswer[dataIndex].comment.unshift(disp.payload.data.data);
              } else {
                  updatedDataReportAnswer[dataIndex].comment = [disp.payload.data.data];
              }
            }
        }
        return updatedDataReportAnswer;
      });

      setFormComment('');
      setFormCommentId('');
    } else {
      toast.error(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
    setSaveFormLoadComment(false);
  }

  const markDoneUnDone = async(value, id) =>{
    if(selectedStatusReport !== value){
      swal({
        title: "Peringatan?",
        text: "Apa anda ingin mengubah status report ini?",
        dangerMode: true,
        buttons: {
          cancel: "Batal",
          confirm: {
            text: "Ya, Ubah Status",
            value: true,
            visible: true,
            className: "btn-danger",
            closeModal: true,
          },
        },
      }).then(async(willChange) => {
        if (willChange) {
          const param = {
            id: id,
            value: value
          }
          const disp = await dispatch(fetchMarkDoneUndone(param));
          if (disp.meta.requestStatus == 'fulfilled') {
            toast.success(disp.payload.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
      
            setDataReportAnswer(prevState => {
              return prevState.map(report => {
                  if (report.id === id) {
                      report.data.status = value;
                  }
                  return report;
              });
            });

            dispatch(resetReportByWorkspace());
            dispatchReportByWorkspace(selectedWorkspace, statusId, programId)
            setSelectedStatusReport(value);
          } else {
            toast.error(disp.payload.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
          }
        }
      })
    }
  }

  useEffect(() => {
    dispatchReportByWorkspace(selectedWorkspace, statusId, programId);
    dispatchGetDataProgram();
    setDataReportFill(dokterLaporan.reportByWorkspaceResp.data);

    if (adminInstansiDetail.dataProgramStatus === "succeeded") {
      if(adminInstansiDetail.dataProgramResp.data) {
        var transformedData = [
          { value: "", label: "Semua Program" },
          ...adminInstansiDetail.dataProgramResp?.data?.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        ];
      }else{
        var transformedData = [
          { value: "", label: "Semua Program" }
        ];
      }
      setDataProgram(transformedData);
    }
  }, [selectedWorkspace, adminInstansiDetail, dokterLaporan]);

  useEffect(()=>{
    validateModalSendMsg();
  }, [dataModalSendMsg])

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Report Pasien</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dokter">Dokter</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Pemantauan Pasien
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Laporan
              </li>
            </ol>
            <h4 className="main-title mb-0">Laporan</h4>
          </div>
        </div>
        <Card className="card card-one">
          <Card.Body>
            <Row>
              <Col lg="4">
                <Form.Group controlId="formFileSm" className="mb-3">
                  <Form.Label>Pengguna Klinis</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Cari Nama Pasien"
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchTerm}
                  />
                </Form.Group>
              </Col>
              <Col lg="4">
                <Form.Group controlId="formFileSm" className="mb-3">
                  <Form.Label>Status Pasien</Form.Label>
                  <Select
                    options={selectOptionsstatus}
                    isSearchable={false}
                    onChange={(e) => {
                      setStatusId(e.value);
                      handleChangeFilterReport(
                        selectedWorkspace,
                        e.value,
                        programId
                      );
                    }}
                    value={{
                      value: statusId ? statusId : "",
                      label: statusId
                        ? selectOptionsstatus.find(
                            (option) => option.value === statusId
                          ).label
                        : "Select Status",
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg="4">
                <Form.Group controlId="formFileSm" className="mb-3">
                  <Form.Label>Program</Form.Label>
                  <Select
                    options={dataProgram}
                    isSearchable={false}
                    onChange={(e) => {
                      setProgramId(e.value);
                      handleChangeFilterReport(
                        selectedWorkspace,
                        statusId,
                        e.value
                      );
                    }}
                    value={{
                      value: programId ? programId : "",
                      label: programId
                        ? dataProgram.find(
                            (option) => option.value === programId
                          ).label
                        : "Select Program",
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            {!dokterLaporan.reportByWorkspacePending ? (
              dokterLaporan.reportByWorkspaceResp.data?.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={dataReportFill}
                  pagination
                  progressPending={dokterLaporan.reportByWorkspacePending}
                  defaultSortFieldId={1}
                />
              ) : (
                <div className="text-center">
                  <img src={"/favicon.png"} width="100px" alt={"Icon"} />
                  <h5>Data Not Found</h5>
                  <div>Assign Patient to Program!</div>
                </div>
              )
            ) : (
              <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                <Skeleton count={1} style={{ height: "200px" }} />
              </SkeletonTheme>
            )}
          </Card.Body>
        </Card>
      </div>

      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{dataModal.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div>
              <div className="fs-xs text-secondary d-flex align-items-center mb-0">
                <i className="ri-whatsapp-line text-success"></i> {dataModal.phone}
              </div>
              <div className="d-flex align-items-center mb-0">
                <i className="ri-stethoscope-line text-primary"></i> {dataModalProgram.name}
              </div>
            </div>
            <div>
              <Button variant="light" onClick={()=> navigate("/dokter/pasien/detail/" + dataModal.id + "/profile")} className="btn btn-outline-primary">Profile</Button>&nbsp;
              <Button variant="primary" onClick={()=>{openModalPesan(dataModal, 'komen');closeModal();}}>Kirim Pesan</Button>
            </div>
          </div>
          {selectedStatusReport !== 'PENDING' ? 
          <Select
            options={statusReport}
            className="mb-2"
            onChange={(selectedOption)=> {markDoneUnDone(selectedOption.value, dataModal.report_id)}} value={{ value: selectedStatusReport ? selectedStatusReport : '', label: selectedStatusReport ? statusReport.find(option => option.value === selectedStatusReport).label : 'Select Status Report'}}
          /> : <></>}
          <div className="report-detail">
            <div className="border-grey mb-2">
            {dataReportAnswer.length < 1 ? <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/><h5>Data Not Found</h5></div> 
              : dataReportAnswer?.map((item, index) => (
                  <div key={index}>
                      {item?.data?.status ?
                      <div>
                          <div className="d-flex justify-content-between">
                              <h6 className="d-flex align-items-center mb-0">{item.data.submit_time ? convertToGMT7(item.data.submit_time) : 'Time Not Found '} &nbsp;<span className={`badge text-center bg-${getStatusColor(item.data.status).color} d-flex align-items-center justify-content-center mb-0`}><i className={`${getStatusColor(item.data.status).icon}`}></i>{" "} {item.data.status}</span></h6>
                          </div>
                          <div className="border-primary mt-2">
                              <p className="text-muted d-flex align-items-center"><i className="ri-survey-line text-primary"></i> Laporan</p>  
                              {item.data.answers.length > 0 ? item.data.answers?.map((item2, index2) => (
                                  <div key={index2} className={`d-flex justify-content-between align-items-center ${index2 !== item.data.answers.length - 1 ? 'border-bottom' : ''}`}>
                                      <h6 className="mt-2 fs-xs">{item2.question.value}</h6>
                                      {item2.value.startsWith('data:image/png;base64,') ? (
                                          <img src={item2.value} alt="base64 image" style={{ width: "70px", borderRadius: "10px", marginTop: 5, marginBottom: 5 }}/>
                                      ) : (
                                          <h6 className="fs-xs text-secondary mb-0">{item2.value}</h6>
                                      )}
                                  </div>
                              )) : <div className="text-center"> <img src={"/favicon.png"} width="70px" alt={"Icon"}/><h5>Report Not Found</h5></div>}
                          </div>
                          <p className="text-muted mt-1">Komentar <span className="badge bg-primary badge-pill">{item.comment ? item.comment.length : 0}</span></p>
                          {item.comment && item.comment?.map((itemcomment, indexcomment) => (
                          <div key={indexcomment} className={'border-bottom'}>
                            <div className="post-header mb-2 mt-2">
                                <div className="post-content">
                                    <h6>{itemcomment.creator_name}</h6>
                                </div>
                                <span className="post-date fs-xs text-secondary d-flex align-items-center">{itemcomment.created_time ? convertToGMT7(itemcomment.created_time) : "Time Not Found"} &nbsp;
                                  {decodeToken(`Bearer ${localStorage.getItem('access_token')}`).UNIQUE_ID == itemcomment.creator_id ?
                                  <Dropdown className="btn-sm">
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-sm">
                                    <i className="ri-more-2-fill"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item href="#" onClick={()=>editComment(itemcomment)}>
                                          Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item href="#" onClick={()=>deleteComment(item.data.id, itemcomment.id)}>
                                          Hapus
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown> : <></>}
                                </span>
                            </div>
                            <p className="fs-xs text-secondary">{itemcomment.comment}</p>
                          </div>))}
                          <div className="d-flex align-items-center justify-content-between mb-2 mt-2">
                            <div>Tambah Komentar</div>
                          </div>
                          <Form onSubmit={(e) => { e.preventDefault(); saveFormComment(item.data.id);}}>
                            <Form.Control
                              as="textarea"
                              className="mb-2"
                              rows="3"
                              placeholder="Masukan Komentar ..."
                              onChange={(e)=> setFormComment(e.target.value)}
                              value={formComment}
                              required={true}
                            ></Form.Control>
                            <div className="d-flex align-items-center justify-content-between">
                              <div></div>
                              <div className="d-flex align-items-center">
                              {formCommentId ?
                                <Button variant="danger" type="button" onClick={()=>editComment()}>
                                  Batal Edit
                                </Button> : <></>}
                                &nbsp;<Button variant="primary" type="submit" disabled={saveFormLoadComment}>
                                  {saveFormLoadComment
                                    ? "Silahkan Tunggu ..."
                                    : "Kirim Komentar"}
                                </Button>
                              </div>
                            </div>
                          </Form>
                      </div> : <></>}
                  </div>))}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showModalPesan} onHide={closeModalPesan} centered>
        <Modal.Header closeButton>
          <Modal.Title>Kirim Pesan Ke Pasien</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div>
              <h6 className="mb-1">{dataModal.name}</h6>
              <p className="fs-xs text-secondary d-flex align-items-center mb-0">
                <i className="ri-whatsapp-line text-success"></i>{" "}
                {dataModal.phone}
              </p>
            </div>
          </div>
          <Card className="mb-3">
            <Card.Body>
              <Form.Group controlId="formFileSm" className="mb-2">
                <Form.Label>Header</Form.Label>
                <Form.Control
                  type="text"
                  name="header"
                  onChange={handleChangeModalSendMsg}
                  value={dataModalSendMsg.header}
                  placeholder="Masukan Header Text"
                />
              </Form.Group>
              <Form.Group controlId="formFileSm" className="mb-2">
                <Form.Label>
                  Body <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="body"
                  onChange={handleChangeModalSendMsg}
                  value={dataModalSendMsg.body}
                  type="text"
                  placeholder="Masukan Body Text"
                />
              </Form.Group>
              <Form.Group controlId="formFileSm" className="mb-2">
                <Form.Label>Footer</Form.Label>
                <Form.Control
                  type="text"
                  name="footer"
                  onChange={handleChangeModalSendMsg}
                  value={dataModalSendMsg.footer}
                  placeholder="Masukan Footer Text"
                />
              </Form.Group>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formFileSm" className="mb-2">
                    <Form.Label>Text Button</Form.Label>
                    <Form.Control
                      type="text"
                      name="textButton"
                      onChange={handleChangeModalSendMsg}
                      value={dataModalSendMsg.textButton}
                      placeholder="Masukan Text Button"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formFileSm" className="mb-2">
                    <Form.Label>URL Button</Form.Label>
                    <Form.Control
                      type="text"
                      name="urlButton"
                      onChange={handleChangeModalSendMsg}
                      value={dataModalSendMsg.urlButton}
                      placeholder="URL Button"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <small>* Kosongkan jika tidak menggunakan button</small>
              <div className="mt-2 text-danger"
                 dangerouslySetInnerHTML={{ __html: modalSendMsgValid.msg }}
              />
            </Card.Body>
          </Card>
          <div className="d-flex align-items-center justify-content-end">
            <Button variant="danger" onClick={closeModalPesan}>
              Batalkan
            </Button>
            &nbsp;
            <Button
              variant="primary"
              onClick={handleSendMessage}
              disabled={
                !modalSendMsgValid.status || !globalRedux.sendMessagePending
              }
            >
              {!globalRedux.sendMessagePending
                ? "Silahkan Tunggu ..."
                : "Kirim Pesan"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}