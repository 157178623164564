import React from "react";

// Dashboard
import Dashboard from "../dashboard/admin/Dashboard";
import Instansi from "../dashboard/admin/instansi/Index";
import InstansiDetail from "../dashboard/admin/instansi/Detail";
import Program from "../dashboard/admin/program/WorkSpace";
import ProgramDetail from "../dashboard/admin/program/Detail";
import Formulir from "../dashboard/admin/formulir/Index";
import FormulirAdd from "../dashboard/admin/formulir/Add";
import FormulirEdit from "../dashboard/admin/formulir/Edit";
import User from "../dashboard/admin/User";

const adminRoutes = [
  { path: "", element: <Dashboard /> },
  { path: "instansi", element: <Instansi /> },
  { path: "instansi/:id", element: <InstansiDetail /> },
  { path: "program/ruang-kerja", element: <Program /> },
  { path: "program/ruang-kerja/:id", element: <ProgramDetail /> },
  { path: "program/formulir/:id", element: <Formulir /> },
  { path: "program/formulir/add", element: <FormulirAdd /> },
  { path: "program/formulir/edit/:id", element: <FormulirEdit /> },
  { path: "user", element: <User /> },
]

export default adminRoutes;