import { React, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Card, Button, Dropdown, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import Helmet from "react-helmet";
import Laporan from "./Laporan";
import PengaturanKlinis from "./PengaturanKlinis";
import Pesan from "./Pesan";
import { Tabs } from "antd";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import ApiUrl from "../../../../helpers/url";
import { useSelector, useDispatch } from "react-redux";
import { decodeToken } from "../../../../helpers/jwtToken";
import { fetchSendMessage } from "../../../../redux/slices/globalSlice";
import {
    fetchPatientActive,
    fetchPatientProgram,
    fetchDeletePatientProgram,
    fetchListDokterPerawat,
    fetchListProgram,
    fetchListForm,
    fetchSaveAssignProgram,
    resetModalPatientProgram,
    resetSaveAssignProgram,
  } from "../../../../redux/slices/dokterPasienSlice";

export default function Detail({ selectedWorkspace }) {
  const location = useLocation();
  const getSegment = (path) => {
    const segments = path.split("/").filter(Boolean);
    return segments[3];
  };
  const Segment = getSegment(location.pathname);
  const [load, setLoad] = useState(true);
  const [loadLaporan, setLoadLaporan] = useState(false);
  const [loadPengaturanKlinis, setLoadPengaturanKlinis] = useState(false);
  const [loadPesan, setLoadPesan] = useState(false);
  const [pasien, setPasien] = useState({});
  const [pasienDetail, setPasienDetail] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [showAssignProgram, setShowAssignProgram] = useState(false);
  const [dataModal, setDataModal] = useState({ name: "", phone: "", id: "" });
  const [dataModalAssignProgPass, setDataModalAssignProgPass] = useState({});
  const [dataModalSendMsg, setDataModalSendMsg] = useState({});
  const [modalSendMsgValid, setModalSendMsgValid] = useState({
    status: false,
    msg: "",
  });

  const role =
    decodeToken(`Bearer ${localStorage.getItem("access_token")}`).ROLE_ID === 3
      ? "Perawat"
      : "Dokter";

  const dispatch = useDispatch();
  const globalRedux = useSelector((state) => state.global);
  const dokterPasien = useSelector((state) => state.dokterPasien);
  const dataProgramPasienModal = useSelector(
    (state) => state.dokterPasien.patientProgramResp.data
  );

  const openModal = (row) => {
    setDataModal({ name: row.name, phone: row.phone, id: row.id });
    setShowModal(true);
  };

  const closeModal = () => {
    setDataModal({ name: "", phone: "", id: "" });
    setDataModalSendMsg({});
    setShowModal(false);
  };

  const openAssignProgram = (row) => {
    setDataModal({ name: row.name, phone: row.phone, id: row.id });
    setShowAssignProgram(true);
  };

  const closeAssignProgram = () => {
    setDataModal({ name: "", phone: "", id: "" });
    setDataModalAssignProgPass({});
    setShowAssignProgram(false);
  };

  const getPatient = async () => {
    let config = {
      method: ApiUrl.patient.method,
      maxBodyLength: Infinity,
      url: ApiUrl.patient.url + "/" + Segment,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        if (response.data.status) {
          setPasien(response.data.data);
          getPasienDetail(response.data.data.id);
        } else {
          setPasien([]);
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
          return window.history.back();
        }
      })
      .catch((error) => {
        setPasien([]);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
        return window.history.back();
      });
  };

  const getPasienDetail = async (id) => {
    setLoad(true);
    let config = {
      method: ApiUrl.patient.method,
      maxBodyLength: Infinity,
      url: ApiUrl.patient.url + "/" + id + "/detail",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setLoad(false);
        if (response.data.status) {
          setPasienDetail(response.data.data);
        } else {
          setPasienDetail([]);
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
        }
      })
      .catch((error) => {
        setLoad(false);
        setPasien([]);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const dispatchListDokterPerawat = async () => {
    const roleint = '3,4';
    if (dokterPasien.listDokterPerawatStatus === "idle") {
      dispatch(fetchListDokterPerawat(roleint));
    }

    if (dokterPasien.listDokterPerawatStatus === "failed") {
      toast.error(dokterPasien.listDokterPerawatError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const dispatchListProgram = async () => {
    if (dokterPasien.listProgramStatus === "idle") {
      dispatch(fetchListProgram(selectedWorkspace));
    }

    if (dokterPasien.listProgramStatus === "failed") {
      toast.error(dokterPasien.listProgramError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  useEffect(() => {
    getPatient();
  }, []);

  useEffect(() => {
    dispatchListDokterPerawat();
    dispatchListProgram();
    validateModalSendMsg();
  }, [dataModalSendMsg]);

  const onTabChange = (key) => {
    if (key == 3) {
      setLoadLaporan(true);
    } else if (key == 4) {
      setLoadPengaturanKlinis(true);
    } else if (key == 5) {
      setLoadPesan(true);
    }
  };
  
  const handleChangeModalSendMsg = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setDataModalSendMsg((dataModalSendMsg) => ({
      ...dataModalSendMsg,
      [key]: value,
    }));
  };

  const validateModalSendMsg = () => {
    let msg = "";
    if (!dataModalSendMsg.body) {
      msg = "Body tidak boleh kosong.";
    }
    if (dataModalSendMsg.textButton && !dataModalSendMsg.urlButton) {
      if (msg) {
        msg = `${msg} <br>`;
      }
      msg = `${msg}Text Button diisi, URL Button tidak boleh kosong`;
    }
    if (!dataModalSendMsg.textButton && dataModalSendMsg.urlButton) {
      if (msg) {
        msg = `${msg} <br>`;
      }
      msg = `${msg}URL Button diisi, URL Button tidak boleh kosong`;
    }

    const status = !msg ? true : false;
    setModalSendMsgValid({ status: status, msg: msg });
  };

  const handleSendMessage = async () => {
    const data = {
      header: {
        text: dataModalSendMsg.header ? dataModalSendMsg.header : "",
      },
      body: {
        text: dataModalSendMsg.body ? dataModalSendMsg.body : "",
      },
      footer: {
        text: dataModalSendMsg.footer ? dataModalSendMsg.footer : "",
      },
      button: {
        display_text: dataModalSendMsg.textButton
          ? dataModalSendMsg.textButton
          : "",
        url: dataModalSendMsg.urlButton ? dataModalSendMsg.urlButton : "",
      },
    };

    const disp = await dispatch(fetchSendMessage({ data, id: dataModal.id }));
    if (disp?.payload?.data?.status) {
      toast.success(disp.payload.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
      closeModal();
    }else{
      toast.error(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const dispatchListForm = async (value) => {
    if (value) {
      await dispatch(fetchListForm({ workspaceId: selectedWorkspace, programId: value }));
    }
  
    if (dokterPasien.listFormStatus === "failed") {
      toast.error(dokterPasien.listFormError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleChangeModalAssgnProg = (e, key) => {
    if(key == 'doctorId' || key == 'nurseId'){
      setDataModalAssignProgPass((dataModalAssignProgPass) => ({
        ...dataModalAssignProgPass,
        [key]: e ? e.map(option => option.value) : [],
      }));
    }else{
      setDataModalAssignProgPass((dataModalAssignProgPass) => ({
        ...dataModalAssignProgPass,
        [key]: e.value,
      }));
    }

    if(key == 'programId'){
      dispatchListForm(e.value);
    }
  };

  const dispatchFetchPatientProgram = async (id) => {
    const disp = await dispatch(fetchPatientProgram(id));
    if (!disp.payload.status) {
      toast.error(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const saveAssignProgram = async () => {
    const data = {
      program_id: dataModalAssignProgPass.programId,
      form_ids: [dataModalAssignProgPass.formId],
      nurse_ids: dataModalAssignProgPass.nurseId,
      doctor_ids: dataModalAssignProgPass.doctorId,
    };
    const disp = await dispatch(
      fetchSaveAssignProgram({ data, id: dataModal.id })
    );
    if (disp?.payload?.data?.status) {
      toast.success(disp.payload.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
      dispatch(resetSaveAssignProgram());
      dispatchFetchPatientProgram(Segment);
      closeAssignProgram();
    }else{
      toast.error(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
      dispatch(resetSaveAssignProgram());
    }
  };

  const getMaritalStatus = (stat) => {
    if (stat == "1") {
      return "Kawin";
    } else if (stat == "2") {
      return "Pernah Kawin";
    } else {
      return "Belum Kawin";
    }
  };

  const items = [
    {
      key: "1",
      label: "Tentang",
      children: (
        <>
          {load ? (
            <Row>
              <Col lg={6}>
                <SkeletonTheme>
                  <Skeleton
                    count={1}
                    style={{ height: "200px" }}
                    className="mb-1"
                  />{" "}
                </SkeletonTheme>
              </Col>
              <Col lg={6}>
                <SkeletonTheme>
                  <Skeleton
                    count={1}
                    style={{ height: "200px" }}
                    className="mb-1"
                  />{" "}
                </SkeletonTheme>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col lg={6}>
                  <Card className="card mb-3">
                    <Card.Header className="pb-0">
                      <Card.Title>Informasi Pribadi</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      {pasienDetail.name ? (
                        <>
                          <div
                            className={`d-flex justify-content-between align-items-center border-bottom`}
                          >
                            <h6 className="mt-2 fs-xs">Nama Lengkap</h6>
                            <h6 className="fs-xs text-secondary mb-0">
                              {pasienDetail.name}
                            </h6>
                          </div>
                          <div
                            className={`d-flex justify-content-between align-items-center border-bottom`}
                          >
                            <h6 className="mt-2 fs-xs">Nomo Rekam Medis</h6>
                            <h6 className="fs-xs text-secondary mb-0">
                              {pasienDetail.mnr}
                            </h6>
                          </div>
                          <div
                            className={`d-flex justify-content-between align-items-center border-bottom`}
                          >
                            <h6 className="mt-2 fs-xs">NIK</h6>
                            <h6 className="fs-xs text-secondary mb-0">
                              {pasienDetail.nik}
                            </h6>
                          </div>
                          <div
                            className={`d-flex justify-content-between align-items-center`}
                          >
                            <h6 className="mt-2 fs-xs">Tanggal Lahir</h6>
                            <h6 className="fs-xs text-secondary mb-0">
                              {pasienDetail.birth_date}
                            </h6>
                          </div>
                        </>
                      ) : (
                        <div className="text-center">
                          {" "}
                          <img src={"/favicon.png"} width="95px" alt={"Icon"} />
                          <h5>Information Not Found</h5>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="card mb-3">
                    <Card.Header className="pb-0">
                      <Card.Title>Informasi Lainnya</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      {pasienDetail?.detail?.additional_data ? (
                        <>
                          <div
                            className={`d-flex justify-content-between align-items-center border-bottom`}
                          >
                            <h6 className="mt-2 fs-xs">Nomor BPJS</h6>
                            <h6 className="fs-xs text-secondary mb-0">
                              {pasienDetail.detail.additional_data.bpjs_number}
                            </h6>
                          </div>
                          <div
                            className={`d-flex justify-content-between align-items-center border-bottom`}
                          >
                            <h6 className="mt-2 fs-xs">Jenis Kelamin</h6>
                            <h6 className="fs-xs text-secondary mb-0">
                              {pasienDetail.detail.additional_data.gender == "1"
                                ? "Laki - Laki"
                                : "Perempuan"}
                            </h6>
                          </div>
                          <div
                            className={`d-flex justify-content-between align-items-center border-bottom`}
                          >
                            <h6 className="mt-2 fs-xs">Status Pernikahan</h6>
                            <h6 className="fs-xs text-secondary mb-0">
                              {getMaritalStatus(pasienDetail.detail.additional_data.marital)}
                            </h6>
                          </div>
                          <div
                            className={`d-flex justify-content-between align-items-center`}
                          >
                            <h6 className="mt-2 fs-xs">Bahasa</h6>
                            <h6 className="fs-xs text-secondary mb-0">
                              {pasienDetail.detail.additional_data.language}
                            </h6>
                          </div>
                        </>
                      ) : (
                        <div className="text-center">
                          {" "}
                          <img src={"/favicon.png"} width="95px" alt={"Icon"} />
                          <h5>Information Not Found</h5>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card className="card mb-3">
                    <Card.Header className="pb-0">
                      <Card.Title>Informasi Alamat</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      {pasienDetail?.detail?.address ? (
                        <>
                          <div
                            className={`d-flex justify-content-between align-items-center border-bottom`}
                          >
                            <h6 className="mt-2 fs-xs">Alamat</h6>
                            <h6 className="fs-xs text-secondary mb-0">
                              {pasienDetail.detail.address.street}
                            </h6>
                          </div>
                          <div
                            className={`d-flex justify-content-between align-items-center`}
                          >
                            <h6 className="mt-2 fs-xs">Nomo Rekam Medis</h6>
                            <h6 className="fs-xs text-secondary mb-0">
                              {pasienDetail.detail.address.phone_home}
                            </h6>
                          </div>
                        </>
                      ) : (
                        <div className="text-center">
                          {" "}
                          <img src={"/favicon.png"} width="95px" alt={"Icon"} />
                          <h5>Address Not Found</h5>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </>
      ),
    },
    {
      key: "3",
      label: "Laporan",
      children: (
        <>
          {loadLaporan ? (
            <Laporan
              selectedWorkspace={selectedWorkspace}
              segment={Segment}
              pasienData={pasien}
            />
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      key: "4",
      label: "Pengaturan Formulir",
      children: (
        <>
          {loadPengaturanKlinis ? (
            <PengaturanKlinis
              selectedWorkspace={selectedWorkspace}
              segment={Segment}
              pasienData={pasien}
            />
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      key: "5",
      label: "Pesan",
      children: (
        <>
          {loadPesan ? (
            <Pesan
              selectedWorkspace={selectedWorkspace}
              segment={Segment}
              pasienData={pasien}
            />
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Auto Health Care | Detail Pasien {pasien?.name ? pasien.name : ""}
        </title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/dokter">Dokter</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <Link to="/dokter/pasien/aktif">Pasien</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Detail
            </li>
          </ol>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              {!load ? (
                <>
                  <h5 className=" mb-0">
                    Pasien :{" "}
                    <span className="text-capitalize">
                      {pasien?.name ? pasien.name : ""}
                    </span>
                  </h5>
                  <p className="fs-xs text-secondary d-flex align-items-center mb-0">
                    <i className="ri-whatsapp-line text-success"></i>{" "}
                    {pasien?.phone ? pasien.phone : ""}
                  </p>
                </>
              ) : (
                <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                  <Skeleton
                    count={1}
                    style={{ height: "50px" }}
                    className="mb-1"
                  />
                </SkeletonTheme>
              )}
            </div>
            <div className="d-flex align-items-center">
              <Button variant="light" className="d-flex align-items-center p-relative btn btn-outline-primary" onClick={() => openModal(pasien)}>
                <i className="ri-send-plane-fill"></i>
              </Button>&nbsp;
              <Button variant="primary" className="d-flex align-items-center p-relative" onClick={() => openAssignProgram(pasien)}>
                < i className="ri-add-line"></i> Tambah Program
              </Button>
            </div>
          </div>

          <Card className="card-post mb-3 mt-2 w-100">
            <Card.Body className="pt-1">
              <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />
            </Card.Body>
          </Card>

          <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Kirim Pesan Ke Pasien</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                  <h6 className="mb-1">{dataModal.name}</h6>
                  <p className="fs-xs text-secondary d-flex align-items-center mb-0">
                    <i className="ri-whatsapp-line text-success"></i>{" "}
                    {dataModal.phone}
                  </p>
                </div>
              </div>
              <Card className="mb-3">
                <Card.Body>
                  <Form.Group controlId="formFileSm" className="mb-2">
                    <Form.Label>Header</Form.Label>
                    <Form.Control
                      type="text"
                      name="header"
                      onChange={handleChangeModalSendMsg}
                      value={dataModalSendMsg.header}
                      placeholder="Masukan Header Text"
                    />
                  </Form.Group>
                  <Form.Group controlId="formFileSm" className="mb-2">
                    <Form.Label>
                      Body <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="body"
                      onChange={handleChangeModalSendMsg}
                      value={dataModalSendMsg.body}
                      type="text"
                      placeholder="Masukan Body Text"
                    />
                  </Form.Group>
                  <Form.Group controlId="formFileSm" className="mb-2">
                    <Form.Label>Footer</Form.Label>
                    <Form.Control
                      type="text"
                      name="footer"
                      onChange={handleChangeModalSendMsg}
                      value={dataModalSendMsg.footer}
                      placeholder="Masukan Footer Text"
                    />
                  </Form.Group>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formFileSm" className="mb-2">
                        <Form.Label>Text Button</Form.Label>
                        <Form.Control
                          type="text"
                          name="textButton"
                          onChange={handleChangeModalSendMsg}
                          value={dataModalSendMsg.textButton}
                          placeholder="Masukan Text Button"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formFileSm" className="mb-2">
                        <Form.Label>URL Button</Form.Label>
                        <Form.Control
                          type="text"
                          name="urlButton"
                          onChange={handleChangeModalSendMsg}
                          value={dataModalSendMsg.urlButton}
                          placeholder="URL Button"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <small>* Kosongkan jika tidak menggunakan button</small>
                  <div
                    className="mt-2 text-danger"
                    dangerouslySetInnerHTML={{ __html: modalSendMsgValid.msg }}
                  />
                </Card.Body>
              </Card>
              <div className="d-flex align-items-center justify-content-end">
                <Button variant="danger" onClick={closeModal}>
                  Batalkan
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  onClick={handleSendMessage}
                  disabled={
                    !modalSendMsgValid.status || !globalRedux.sendMessagePending
                  }
                >
                  {!globalRedux.sendMessagePending
                    ? "Silahkan Tunggu ..."
                    : "Kirim Pesan"}
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={showAssignProgram} onHide={closeAssignProgram} centered>
            <Modal.Header closeButton>
              <Modal.Title>Assign Program Pasien</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                  <h6 className="mb-1">{dataModal.name}</h6>
                  <p className="fs-xs text-secondary d-flex align-items-center mb-0">
                    <i className="ri-whatsapp-line text-success"></i>{" "}
                    {dataModal.phone}
                  </p>
                </div>
              </div>
              <Card className="mb-3">
                <Card.Body>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formFileSm" className="mb-3">
                        <Form.Label>
                          {`Pengguna Klinis Penanggung Jawab`} <span className="text-danger"></span>
                        </Form.Label>
                        <Select
                          options={dokterPasien.listPerawatResp}
                          isSearchable={true}
                          isMulti={true}
                          onChange={(e) => {
                            handleChangeModalAssgnProg(e, "nurseId");
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formFileSm" className="mb-3">
                        <Form.Label>
                          {`Pengguna Klinis Penerima Peringatan`} <span className="text-danger"></span>
                        </Form.Label>
                        <Select
                          options={dokterPasien.listDokterResp}
                          isSearchable={true}
                          isMulti={true}
                          onChange={(e) => {
                            handleChangeModalAssgnProg(e, "doctorId");
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId="formFileSm" className="mb-3">
                    <Form.Label>Program</Form.Label>
                    <Select
                      options={dokterPasien.listProgramResp}
                      isSearchable={true}
                      onChange={(e) => {
                        handleChangeModalAssgnProg(e, "programId");
                      }}
                      placeholder="Pilih Program"
                    />
                  </Form.Group>

                  <Form.Group controlId="formFileSm" className="mb-3">
                    <Form.Label>Form</Form.Label>
                    <Select
                      options={
                        dataModalAssignProgPass?.programId
                          ? dokterPasien.listFormResp
                          : []
                      }
                      isSearchable={true}
                      onChange={(e) => {
                        handleChangeModalAssgnProg(e, "formId");
                      }}
                      placeholder="Pilih Form"
                    />
                    <small>* Pilih program terlebih dahulu</small>
                  </Form.Group>
                </Card.Body>
              </Card>
              <div className="d-flex align-items-center justify-content-end">
                <Button variant="danger" onClick={closeAssignProgram}>
                  Batalkan
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  onClick={saveAssignProgram}
                  disabled={!dokterPasien.saveAssignProgramPending}
                >
                  {!dokterPasien.saveAssignProgramPending
                    ? "Silahkan Tunggu ..."
                    : "Assign Program"}
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}