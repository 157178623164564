import {React, useState, useEffect} from "react";
import { Form, Col, Container, Button, Row, Card } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Helmet from 'react-helmet';
import "../../assets/css/react-datepicker.min.css";
import Loader from "../../components/Loader";
import axios from "axios";
import ApiUrl from "../../helpers/url";
import { toast, Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Formulir() {
    const location = useLocation();
    const getSegment = (path, level) => {
        const segments = path.split("/").filter(Boolean);
        return segments[level];
    };
    const Segment = getSegment(location.pathname, 1);
    const SegmentMonitoring = getSegment(location.pathname, 3);
    const navigate = useNavigate();
    const [formId, setFormId] = useState('');
    const [loading, setLoading] = useState(true);
    const [saveFormLoad, setSaveFormLoad] = useState(false);
    const [dataForm, setDataForm] = useState({
        workspace_id : "",
        program_id : "",
        name: "",
        description : "",
        type : "",  
    });
    const [dataSave, setDataSave] = useState({
        patient_id : "",
        form_code : "",
        report_id : "",
        answers: []
    });
    const [dataQuestion, setDataQuestion] = useState([]);

    const getPatientProgram = async() =>{
        let config = {
            method: ApiUrl.patient_program.method,
            maxBodyLength: Infinity,
            url: ApiUrl.patient_program.url+Segment,
            headers: { 
              'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
            }
        };

        await axios.request(config)
        .then((response) => {
            if(response.data.status){
                setFormId(response.data.data.form.id)
                getFormDetail(response.data.data.form.id);
                getQuestionDetail(response.data.data.form.id);
                const newFormState = {
                    patient_id: response.data.data.patient_id,
                    form_code: response.data.data.form_code,
                    report_id: SegmentMonitoring ? SegmentMonitoring : '',
                    answers: []
                };
                setDataSave(newFormState)
            }else{
                return navigate("/report");
            }
        })
        .catch((error) => {
            toast.error('Data Program Not Found!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
            return navigate("/report");
        })
    }
    
    const getFormDetail = async(form_id) =>{
        let config = {
            method: ApiUrl.detail_form.method,
            maxBodyLength: Infinity,
            url: ApiUrl.detail_form.url+form_id,
            headers: { 
              'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
            }
        };
        await axios.request(config)
        .then((response) => {
            if (response.data.status) {
                const { workspace_id, program, name, description, type } = response.data.data;
                setDataForm({
                    workspace_id: workspace_id,
                    program_id: program ? program.id : '',
                    name: name,
                    description: description,
                    type: type
                });
            } else {
                return navigate("/report");
            }
        })
        .catch((error) => {
            toast.error('Data Form Not Found!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
            return navigate("/report");
        })
    }

    const getQuestionDetail = async(form_id) =>{
        let config = {
            method: ApiUrl.detail_form.method,
            maxBodyLength: Infinity,
            url: ApiUrl.detail_form.url+form_id+"/questions",
            headers: { 
              'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
            }
        };
        await axios.request(config)
        .then((response) => {
            setLoading(false)
            if(response.data.status){
              setDataQuestion(response.data.data);
            }
        })
        .catch((error) => {
            setLoading(false)
            toast.error('Data Question Not Found!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
        })
    }

    const handleOnchangeInput = (val, order, id, type, optionId, isChecked) => {
        if (type === 6) {
            const file = val;
            const reader = new FileReader();
            reader.onloadend = () => {
                updateDataSave(reader.result, id, type);
            };
            reader.readAsDataURL(file);
        } else {
            updateDataSave(val, id, type, optionId, isChecked);
        }
    };

    const updateDataSave = (val, id, type, optionId, isChecked) => {
        setDataSave(prevState => {
            const answerIndex = prevState.answers.findIndex(answer => answer.question_id === id);
            let updatedAnswers = [...prevState.answers];
    
            if (answerIndex > -1) {
                if (type === 1 || type === 5 || type === 6) {
                    updatedAnswers[answerIndex].value = val;
                } else if (type === 2) {
                    updatedAnswers[answerIndex].selected_options = [{ id: optionId, value: val }];
                } else if (type === 3) {
                    const existingOptionIndex = updatedAnswers[answerIndex].selected_options.findIndex(option => option.id === optionId);
    
                    if (isChecked) {
                        if (existingOptionIndex === -1) {
                            updatedAnswers[answerIndex].selected_options.push({ id: optionId, value: val });
                        }
                    } else {
                        if (existingOptionIndex > -1) {
                            updatedAnswers[answerIndex].selected_options.splice(existingOptionIndex, 1);
                        }
                    }
                } else if (type === 4) {
                    updatedAnswers[answerIndex].selected_options = [{ id: optionId, value: val }];
                }
            } else {
                if (type === 1 || type === 5 || type === 6) {
                    updatedAnswers.push({
                        question_id: id,
                        value: val
                    });
                } else if (type === 2) {
                    updatedAnswers.push({
                        question_id: id,
                        selected_options: [{ id: optionId, value: val }],
                        value: '-'
                    });
                } else if (type === 3) {
                    if (isChecked) {
                        updatedAnswers.push({
                            question_id: id,
                            selected_options: [{ id: optionId, value: val }],
                            value: '-'
                        });
                    }
                } else if (type === 4) {
                    updatedAnswers.push({
                        question_id: id,
                        selected_options: [{ id: optionId, value: val }],
                        value: '-'
                    });
                }
            }
    
            return { ...prevState, answers: updatedAnswers };
        });
    };

    const saveForm = async() => {
        setSaveFormLoad(true)
        let config = {
            method: ApiUrl.create_form.method,
            maxBodyLength: Infinity,
            url: `${ApiUrl.create_form.url}/${formId}/questions/answers`,
            headers: { 
              'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
            },
            data : dataSave
        };
        
        await axios.request(config)
        .then((response) => {
            setSaveFormLoad(false)
            if(response.data.status){
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    transition: Bounce,
                });
                setTimeout(function() {
                    return navigate('/forms/thanks')
                }, 1000)
            }else{
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    transition: Bounce,
                });
            }
        })
        .catch((error) => {
            setSaveFormLoad(false)
            toast.error('Lengkapi Semua Inputan Terlebih Dahulu!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
        });
    }

    useEffect(() => {
        getPatientProgram();
    },[])

    const renderInput = (quest) => {
        switch (quest.type) {
            case 1:
                return (
                    <input
                        type="text"
                        placeholder="Jawaban Anda"
                        className="form-control"
                        required={quest.is_required === 1}
                        onChange={(e) => handleOnchangeInput(e.target.value, quest.order_index, quest.id, quest.type)}
                    />
                );
            case 2:
                return quest.options.map((option, index) => (
                    <div key={index} className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="radio"
                            name={quest.id}
                            id={option.id}
                            onChange={(e) => handleOnchangeInput(option.value, quest.order_index, quest.id, quest.type, option.id)}
                        />
                        <label className="form-check-label" htmlFor={option.id}>
                            {option.value}
                        </label>
                    </div>
                ));
            case 3:
                return quest.options.map((option, index) => (
                    <div key={index} className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={option.id}
                            onChange={(e) => handleOnchangeInput(option.value, quest.order_index, quest.id, quest.type, option.id, e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor={option.id}>
                            {option.value}
                        </label>
                    </div>
                ));
            case 4:
                return (
                    <select
                        className="form-control"
                        required={quest.is_required === 1}
                        onChange={(e) => {handleOnchangeInput(e.target.value, quest.order_index, quest.id, quest.type, e.target.selectedOptions[0].getAttribute('data-optionid'));}}
                    >
                        <option hidden value="">Silahkan Pilih</option>
                        {quest.options.map((option, index) => (
                            <option key={index} value={option.value} data-optionid={option.id}>
                                {option.value}
                            </option>
                        ))}
                    </select>
                );
            case 5:
                return (
                    <input
                        type="date"
                        className="form-control"
                        required={quest.is_required === 1}
                        onChange={(e) => handleOnchangeInput(e.target.value, quest.order_index, quest.id, quest.type)}
                    />
                );
            case 6:
                return (
                    <input
                        type="file"
                        className="form-control"
                        required={quest.is_required === 1}
                        onChange={(e) => handleOnchangeInput(e.target.files[0], quest.order_index, quest.id, quest.type)}
                        accept="image/*"
                    />
                );
            default:
                return null;
        }
    };

    return (
    <>
        <Helmet>
            <title>Auto Health Care | {dataForm.name}</title>
        </Helmet>
        {loading && <Loader />}
        <ToastContainer />
        <div className="row g-0">
            <div className="content">
                <Form onSubmit={(e) => { e.preventDefault(); saveForm();}}>
                    <Container>
                        <Row className="d-flex justify-content-center mb-4 mt-3">
                            <Col lg={7} md={8} sm={10} xs={12}>
                                <div className="text-center mb-3">
                                    <Link to="/report" className="header-logo"><img src="/favicon.png" width="50px"/>Auto Health Care</Link>
                                </div>
                                <div className="alert alert-solid alert-primary text-center pt-4 pb-4 mb-3" role="alert" >
                                    <h3 className="mb-3">{dataForm.name}</h3>{" "} {dataForm.description}
                                    </div>
                                    {dataQuestion && dataQuestion.map((quest, index) => (
                                    <Card className="card-post mb-3 mt-2 w-100" key={index}>
                                        <Card.Body>
                                            {quest.image ? <img alt={quest.value} src={quest.image} className="mb-2" style={{width: "50%",borderRadius: "10px"}}/> : <></>}
                                            <h5 className={quest.description ? "mb-1" : "mb-2"}>{quest.value} {quest.is_required ? <span className="text-danger">*</span>:<></>}</h5>
                                            {quest.description? <p style={{fontSize:12}} className="mb-1">{quest.description}</p>: <></>}

                                            {renderInput(quest)}
                                        </Card.Body>
                                    </Card>))}
                                    <Row>
                                    <Col lg="12">
                                        <div className="d-flex align-items-center justify-content-end">
                                            <Button
                                            variant="primary"
                                            type="submit"
                                            className="btn-lg"
                                            disabled={saveFormLoad}
                                            >
                                            {saveFormLoad
                                                ? "Silahkan Tunggu ..."
                                                : "Kirim Jawaban"}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
        </div>
    </>
  )
}