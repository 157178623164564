import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Helmet from 'react-helmet';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiUrl from "../../helpers/url";

export default function Jadwal() {
  const [pending, setPending] = React.useState(true);
  const [showModal, setShowModal] = useState(false);
  const [load, setLoad] = useState(true);
  const [loadSave, setLoadSave] = useState(false);
  const [poli, setPoli] = useState([])
  const [dokter, setDokter] = useState([])
  const [pasien, setPasien] = useState([])
  const [data, setData] = useState([])
  const [specialityId, setSpecialityId] = useState('')
  const [doctorId, setDoctorId] = useState('')
  const [patientNik, setPatientNik] = useState('')
  const [date, setDate] = useState('')
  const columns = [
    {
      name: 'Kloter',
      selector: row => row.kloter,
    },
    {
      name: 'Tanggal Periksa',
      selector: row => row.tgl_periksa,
    },
    {
      name: 'Aksi',
      selector: row => row.id,
      cell: (row) => (
        <>
        {row.kloter !== 'TIDAK PRAKTEK' ? 
          <Link
            to="#"
            className="btn btn-success"
            onClick={() => openModal()}
          >
            <i className="ri-menu-add-line"></i> Add Appointment
          </Link> : <></>} 
        </>
      ),
    }
  ];
  
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getCurrentDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1); // Add one day to the current date
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const getpoli = async() =>{
    let config = {
        method: ApiUrl.list_poli.method,
        maxBodyLength: Infinity,
        url: ApiUrl.list_poli.url,
        headers: { 
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    await axios.request(config)
    .then((response) => {
        setPending(false);
        setLoad(false)
        if(response.data.status){
            const transformedData = response.data.data.map(item => ({
              value: item.id,
              label: item.name
            }));
            setPoli(transformedData);
        }else{
          setPoli([]);
        }
    })
    .catch((error) => {
        setPoli([]);
    })
  }

  const getpasien = async() =>{
    let config = {
        method: ApiUrl.patient.method,
        maxBodyLength: Infinity,
        url: ApiUrl.patient.url,
        headers: { 
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    await axios.request(config)
    .then((response) => {
        setPending(false);
        setLoad(false)
        if(response.data.status){
            const transformedData = response.data.data.map(item => ({
              value: item.nik,
              label: item.name
            }));
            setPasien(transformedData);
        }else{
          setPasien([]);
        }
    })
    .catch((error) => {
        setPasien([]);
    })
  }

  const getdokter = async(e) =>{
    let config = {
        method: ApiUrl.list_doctor.method,
        maxBodyLength: Infinity,
        url: ApiUrl.list_doctor.url+"?specialist_id="+e,
        headers: { 
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    await axios.request(config)
    .then((response) => {
        setPending(false);
        setLoad(false)
        if(response.data.status){
            const transformedData = response.data.data.map(item => ({
              value: item.id,
              label: item.name
            }));
            setDokter(transformedData);
        }else{
          setDokter([]);
        }
    })
    .catch((error) => {
        setDokter([]);
    })
  }

  const getData = async(value, typeData) =>{
    let specialist_id = '';
    let doctor_id = '';
    let date_select = '';
    if(typeData === 'poli'){
      setDoctorId('')
      setSpecialityId(value)
      specialist_id = value;
      doctor_id = '';
      date_select = date;
    }else if(typeData === 'dokter'){
      setDoctorId(value)
      specialist_id = specialityId;
      doctor_id = value;
      date_select = date;
    }else{
      setDate(value)
      specialist_id = specialityId;
      doctor_id = doctorId;
      date_select = value;
    }
    if(specialist_id && doctor_id && date_select){
      setPending(true);
      setLoad(true);
      let config = {
          method: ApiUrl.schedule.method,
          maxBodyLength: Infinity,
          url: ApiUrl.schedule.url+"?specialist_id="+specialist_id+"&doctor_id="+doctor_id+"&date="+date_select,
          headers: { 
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
      };
      await axios.request(config)
      .then((response) => {
          setPending(false);
          setLoad(false)
          if(response.data.status){
              setData(response.data.data);
          }else{
              setData([]);
          }
      })
      .catch((error) => {
          setPending(false);
          setLoad(false)
          setData([]);
      })
    }
  }

  const saveData = async() =>{
    let data = JSON.stringify({
        "patient_nik": patientNik,
        "speciality_id": specialityId,
        "doctor_id": doctorId,
        "date": date,
    })

    let config = {
        method: ApiUrl.create_appointment.method,
        maxBodyLength: Infinity,
        url: ApiUrl.create_appointment.url,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`, 
            'Content-Type': 'application/json'
        },
        data : data
    };
    setLoadSave(true)
    await axios.request(config)
    .then((response) => {
        setLoadSave(false)
        if(response.data.status){
            setPatientNik('')
            setShowModal(false);
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
        }else{
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
        }
    })
    .catch((error) => {
        setLoadSave(false)
        toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
        });
    });
  }

  useEffect(() => {
    getpoli();
    getpasien();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Penjadwalan</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/dokter">Dokter</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Penjadwalan</li>
            </ol>
            <h4 className="main-title mb-0">Penjadwalan</h4>
          </div>
        </div>
        <Card className="card card-one">
          <Card.Body>
            <Row>
              <Col lg="4">
                <Form.Group controlId="formFileSm" className="mb-3">
                  <Form.Label>Poli</Form.Label>
                  <Select options={poli} isSearchable={true} onChange={(e)=> {getdokter(e.value); getData(e.value, 'poli');}} value={{ value: specialityId ? specialityId : '', label: poli[0] ? specialityId ? poli.find(option => option.value === specialityId).label : 'Select Poli' : 'Select Poli'}} />
                </Form.Group>
              </Col>
              <Col lg="4">
                <Form.Group controlId="formFileSm" className="mb-3">
                  <Form.Label>Dokter</Form.Label>
                  <Select options={dokter} isSearchable={true} onChange={(e)=> {getData(e.value, 'dokter')}} value={{ value: doctorId ? doctorId : '', label: dokter[0] ? doctorId ? dokter.find(option => option.value === doctorId).label : 'Select Dokter' : 'Select Dokter'}}/>
                </Form.Group>
              </Col>
              <Col lg="4">
                <Form.Group controlId="formFileSm" className="mb-3">
                  <Form.Label>Tanggal</Form.Label>
                  <Form.Control type="date" placeholder="Tanggal" value={date} onChange={(e)=> {getData(e.target.value, 'tanggal')}} min={getCurrentDate()}/>
                </Form.Group>
              </Col>
            </Row>
            {load ? <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
              <Skeleton count={1} style={{ height: "150px" }} />
            </SkeletonTheme> : data.length > 0 ? <DataTable
              columns={columns}
              data={data}
              pagination
              progressPending={pending}
              defaultSortFieldId={1}
            /> : <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/>
            <h5>Data Not Found</h5><p>You can the change filter to find another data!</p></div>}
          </Card.Body>
        </Card>
      </div>

      <Modal show={showModal} onHide={closeModal} centered>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            saveData();
          }}
        >
        <Modal.Header closeButton>
            <Modal.Title>Buat Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFileSm" className="mb-3">
            <Form.Label>Pilih Pasien</Form.Label>
            <Select options={pasien} isSearchable={true} onChange={(e)=> setPatientNik(e.value)} value={{ value: patientNik ? patientNik : '', label: patientNik ? pasien.find(option => option.value === patientNik).label : 'Select Pasien'}} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
                Batalkan
            </Button>
            <Button variant="primary" type="submit" disabled={loadSave}>
                {loadSave ? 'Silahkan Tunggu ...' : 'Simpan'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
