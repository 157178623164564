import React,{useState} from "react";
import { Card, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Helmet from 'react-helmet';
import axios from "axios";
import ApiUrl from "../../helpers/url";
import { toast, Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
 
export default function SearchReport() {
  const navigate = useNavigate();
  const [mnr, setMnr] = useState('');
  const [loadFind, setLoadFind] = useState(false);
  
  const findReport = async() => {
    setLoadFind(true);
    let config = {
      method: ApiUrl.list_report_patient.method,
      maxBodyLength: Infinity,
      url: `${ApiUrl.list_report_patient.url}`,
      headers: { 
        'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
        'Content-Type': 'application/json'
      },
      data : JSON.stringify({mnr: mnr})
    };
    
    await axios.request(config)
    .then((response) => {
        setLoadFind(false)
        if(response.data.data.length > 0){
            return navigate('/report/'+mnr)
        }else{
            toast.error('Data Patient Not Found!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
        }
    })
    .catch((error) => {
        setLoadFind(false)
        toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
        });
    });
  }

  return (
    <>
      <Helmet>
        <title>Auto Health Care | Report Rekam Medis</title>
      </Helmet>
      <ToastContainer/>
      <div className="row g-0">
        <div className="col-lg-6 d-none-media bg-appointment">
          <div className="hero-login"></div>
        </div>
        <div className="col-lg-6">
          <div className="page-sign">
            <Card className="card-sign">
              <Card.Header>
                <Link to="/" className="header-logo mb-2">Data Rekam Medis</Link>
                <Card.Text>Silahkan masukkan nomor rekam medis Anda untuk melihat laporan dan grafik klinis Anda.</Card.Text>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={(e) => { e.preventDefault(); findReport();}}>
                    <div className="mb-4">
                        <label className="form-label">Nomor Rekam Medis</label>
                        <input required type="text" className="form-control text-center" placeholder="Masukan Nomor Rekam Medis" onChange={(e)=>setMnr(e.target.value)} value={mnr}/>
                    </div>

                    <div className="d-flex gap-2">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={loadFind}
                        >
                        {loadFind
                            ? "Silahkan Tunggu ..."
                            : "Cari Report"}
                        </Button>
                    </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}