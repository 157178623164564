import React, { useEffect, useState, useRef } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import { toast, Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import ApiUrl from "../helpers/url";
import { toMinutes } from "../helpers/timeConvert";
import { RoleMapping } from "../helpers/roles";
import { decodeToken, cekSessionLogin } from "../helpers/jwtToken";

export default function Verification() {
  const navigate = useNavigate();
  const [newLoad, setNewLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disabledResendOtp, setDisabledResendOtp] = useState(true);
  const [timedOut, setTimedOut] = useState(3600);
  const [enableResend, setEnableResend] = useState(true);
  const [enableVerify, setEnableVerify] = useState(false);

  const [values, setValues] = useState(["", "", "", "", "", ""]);
  const refs = useRef(Array.from({ length: 6 }, () => React.createRef()));

  useEffect(() => {
    cekSessionLogin(navigate);

    setTimeout(() => {
      setNewLoad(false);
    }, 2000);

    if (localStorage.getItem("user_id")) {
      if (localStorage.getItem("time_login_start")) {
        const timeLoginStart = new Date(
          localStorage.getItem("time_login_start"),
        );
        const currentTime = new Date();
        const differenceInMinutes =
          (currentTime - timeLoginStart) / (1000 * 60);
        const newTimedOut = timedOut - differenceInMinutes * 60;
        if (differenceInMinutes * 60 > 0) {
          setEnableVerify(true);
          startTimer(newTimedOut);
        } else {
          setEnableResend(true);
          setEnableVerify(false);
        }

        if (differenceInMinutes > 60) {
          setEnableResend(true);
          setEnableVerify(false);
        }
      }
    } else {
      localStorage.clear();
      navigate("/sign-in");
      return;
    }

    const handleInput = (index, e) => {
      const { value } = e.target;

      if (value && index < 5) {
        refs.current[index + 1]?.current?.focus();
      } else if (!value && index > 0) {
        refs.current[index - 1]?.current?.focus();
      }
    };

    refs.current.forEach((ref, index) => {
      if (ref.current) {
        ref.current.addEventListener("input", handleInput.bind(null, index));
      }
    });

    const firstInput = refs.current[0].current;
    firstInput.addEventListener("paste", handlePaste);

    return () => {
      firstInput.removeEventListener("paste", handlePaste);
      refs.current.forEach((ref, index) => {
        if (ref.current) {
          ref.current.removeEventListener(
            "input",
            handleInput.bind(null, index),
          );
        }
      });
    };
  }, []);

  const handlePaste = (event) => {
    const paste = (event.clipboardData || window.clipboardData).getData("text");
    if (paste.length === 6) {
      const pasteArray = paste.split("");
      setValues(pasteArray);
      pasteArray.forEach((char, index) => {
        refs.current[index].current.value = char.toUpperCase();
      });
      setDisabled(false);
      event.preventDefault();
    }
  };

  const startTimer = (initialTime) => {
    let timeLeft = initialTime;
    const timerInterval = setInterval(() => {
      timeLeft -= 1;
      if (timeLeft <= 0) {
        clearInterval(timerInterval);
        setEnableResend(true);
        setEnableVerify(false);
        setDisabledResendOtp(false);
      } else {
        setEnableResend(false);
        setEnableVerify(true);
        setDisabledResendOtp(true);
      }
      setTimedOut(timeLeft);
    }, 1000);
  };

  const minutes = toMinutes(timedOut).minutes;
  const seconds = toMinutes(timedOut).seconds;

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = value;
      const allButLastFilled = newValues
        .slice(0, index)
        .every((val) => val !== "");

      if (allButLastFilled) {
        const allFilled = newValues.every((val) => val !== "");
        if (allFilled) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else {
        setDisabled(true);
      }

      return newValues;
    });
  };

  const verifyOtp = async () => {
    let data = JSON.stringify({
      user_id: localStorage.getItem("user_id"),
      otp: values.join("").toUpperCase(),
    });

    let config = {
      method: ApiUrl.verify_otp.method,
      maxBodyLength: Infinity,
      url: ApiUrl.verify_otp.url,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response.data.access_token) {
          localStorage.setItem("access_token", response.data.access_token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          localStorage.removeItem("time_login_start");
          const role = decodeToken(response.data.access_token);
          return navigate(RoleMapping[role.ROLE_ID].url);
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const resendOtp = async () => {
    let data = JSON.stringify({
      user_id: localStorage.getItem("user_id"),
    });

    let config = {
      method: ApiUrl.resend_otp.method,
      maxBodyLength: Infinity,
      url: ApiUrl.resend_otp.url,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          startTimer(3600);
          setTimedOut(3600);
          const currentDateTime = new Date();
          const dateTimeString = currentDateTime.toISOString();
          localStorage.setItem("time_login_start", dateTimeString);
          setEnableResend(false);
          setDisabledResendOtp(true);

          toast.success("OTP Berhasil Dikirim!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>Auto Health Care | Verification</title>
      </Helmet>
      <ToastContainer />
      <div className="row g-0">
        <div className="col-lg-6 d-none-media">
          <div className="hero-login"></div>
        </div>
        <div className="col-lg-6">
          <div className="page-sign">
            <Card className="card-sign">
              <Card.Header>
                <Link to="/" className="header-logo mb-2">
                  Masukan Kode Verifikasi
                </Link>
                <Card.Text>
                  Kode verifikasi telah dikirimkan melalui Email ke alamat{" "}
                  <b>{localStorage.getItem("email_login")}</b>
                </Card.Text>
              </Card.Header>
              <Card.Body>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    verifyOtp();
                  }}
                >
                  <div className="mb-4">
                    <div className="verification-code--inputs">
                      {Array.from({ length: 6 }, (_, index) => (
                        <input
                          key={index}
                          type="text"
                          maxLength="1"
                          ref={refs.current[index]}
                          value={values[index]}
                          autoFocus={index == 0 ? true : false}
                          onChange={(e) => handleInputChange(index, e)}
                          style={{
                            textTransform: "uppercase",
                            border: values[index]
                              ? "2px solid #40C9B6"
                              : "2px solid #ddd",
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="d-flex">
                    {!newLoad ? (
                      <Button
                        variant="success"
                        type="submit"
                        disabled={
                          loading || enableVerify == false || disabled
                            ? true
                            : false
                        }
                      >
                        {loading ? "Silahkan Tunggu ..." : "Verifikasi OTP"}
                      </Button>
                    ) : (
                      <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                        <Skeleton count={1} style={{ height: "38px" }} />
                      </SkeletonTheme>
                    )}
                  </div>
                </Form>
              </Card.Body>
              <Card.Footer>
                {!newLoad ? (
                  enableResend ? (
                    <>
                      Tidak Menerima Kode?{" "}
                      <a
                        href="#"
                        disabled={disabledResendOtp}
                        onClick={(e) => {
                          resendOtp();
                        }}
                      >
                        <br />
                        Kirim Ulang
                      </a>
                    </>
                  ) : (
                    <>
                      Kirim Kode dalam {minutes} menit {seconds} detik
                    </>
                  )
                ) : (
                  <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                    <Skeleton count={1} style={{ height: "45px" }} />
                  </SkeletonTheme>
                )}
              </Card.Footer>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
