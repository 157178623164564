const baseUrl = "https://ahc.brainsia.id/";

const apiEndpoints = {
    url: {url:baseUrl},
    login: {method: 'POST', url: `${baseUrl}v1/auth`},
    verify_otp: {method: 'POST', url: `${baseUrl}v1/auth/otp`},
    resend_otp: {method: 'POST', url: `${baseUrl}v1/auth/resend-otp`},
    refresh_token: {method: 'PUT', url: `${baseUrl}v1/auth/refresh-token`},
    logout: {method: 'POST', url: `${baseUrl}v1/auth/logout`},
    change_password: {method: 'PUT', url: `${baseUrl}v1/auth/change-password`},
    admin_dashboard: {method: 'GET', url: `${baseUrl}v1/dashboard/super-admin`},
    instansi_dashboard: {method: 'GET', url: `${baseUrl}v1/dashboard/admin`},
    dokter_dashboard: {method: 'GET', url: `${baseUrl}v1/dashboard/clinic`},
    get_banner: {method: 'GET', url: `${baseUrl}v1/banners`},
    get_image_banner: {method: 'GET', url: `${baseUrl}v1/storage/images/banners`},
    detail_banner: {method: 'GET', url: `${baseUrl}v1/banners/`},
    create_banner: {method: 'POST', url: `${baseUrl}v1/banners`},
    update_banner: {method: 'PUT', url: `${baseUrl}v1/banners/`},
    delete_banner: {method: 'DELETE', url: `${baseUrl}v1/banners/`},
    get_institution: {method: 'GET', url: `${baseUrl}v1/institutions`},
    detail_institution: {method: 'GET', url: `${baseUrl}v1/institutions/`},
    create_institution: {method: 'POST', url: `${baseUrl}v1/institutions`},
    update_institution: {method: 'PUT', url: `${baseUrl}v1/institutions/`},
    delete_institution: {method: 'DELETE', url: `${baseUrl}v1/institutions/`},
    get_user: {method: 'GET', url: `${baseUrl}v1/users`},
    detail_user: {method: 'GET', url: `${baseUrl}v1/users/`},
    create_user: {method: 'POST', url: `${baseUrl}v1/users`},
    update_user: {method: 'PUT', url: `${baseUrl}v1/users/`},
    delete_user: {method: 'DELETE', url: `${baseUrl}v1/users/`},
    get_workspace: {method: 'GET', url: `${baseUrl}v1/workspaces`},
    detail_workspace: {method: 'GET', url: `${baseUrl}v1/workspaces/`},
    create_workspace: {method: 'POST', url: `${baseUrl}v1/workspaces`},
    update_workspace: {method: 'PUT', url: `${baseUrl}v1/workspaces/`},
    delete_workspace: {method: 'DELETE', url: `${baseUrl}v1/workspaces/`},
    get_program: {method: 'GET', url: `${baseUrl}v1/programs`},
    detail_program: {method: 'GET', url: `${baseUrl}v1/programs/`},
    create_program: {method: 'POST', url: `${baseUrl}v1/programs`},
    update_program: {method: 'PUT', url: `${baseUrl}v1/programs/`},
    delete_program: {method: 'DELETE', url: `${baseUrl}v1/programs/`},
    get_template: {method: 'GET', url: `${baseUrl}v1/templates`},
    get_template_log: {method: 'GET', url: `${baseUrl}v1/templates/logs`},
    detail_template: {method: 'GET', url: `${baseUrl}v1/templates/`},
    create_template: {method: 'POST', url: `${baseUrl}v1/templates`},
    update_template: {method: 'PUT', url: `${baseUrl}v1/templates/`},
    delete_template: {method: 'DELETE', url: `${baseUrl}v1/templates/`},
    get_form: {method: 'GET', url: `${baseUrl}v1/forms`},
    detail_form: {method: 'GET', url: `${baseUrl}v1/forms/`},
    create_form: {method: 'POST', url: `${baseUrl}v1/forms`},
    update_form: {method: 'PUT', url: `${baseUrl}v1/forms/`},
    delete_form: {method: 'DELETE', url: `${baseUrl}v1/forms/`},
    option_template_body_field: {method: 'GET', url: `${baseUrl}v1/options/template-body-fields`},
    option_form_type: {method: 'GET', url: `${baseUrl}v1/options/form-types`},
    option_remainder_option: {method: 'GET', url: `${baseUrl}v1/options/reminder-options`},
    option_onetime_remainder_condition: {method: 'GET', url: `${baseUrl}v1/options/onetime-reminder-conditions`},
    option_schedule_remainder_condition: {method: 'GET', url: `${baseUrl}v1/options/scheduled-reminder-conditions`},
    option_remainder_condition_trigger_event: {method: 'GET', url: `${baseUrl}v1/options/reminder-condition-trigger-events`},
    option_remainder_duration: {method: 'GET', url: `${baseUrl}v1/options/reminder-duration-options`},
    schedule: {method: 'GET', url: `${baseUrl}v1/hospitals/schedule`},
    appointment: {method: 'GET', url: `${baseUrl}v1/hospitals/appointments`},
    list_poli: {method: 'GET', url: `${baseUrl}v1/hospitals/specialist`},
    list_doctor: {method: 'GET', url: `${baseUrl}v1/hospitals/doctors`},
    create_appointment: {method: 'POST', url: `${baseUrl}v1/hospitals/appointments`},
    patient: {method: 'GET', url: `${baseUrl}v1/patients`},
    patient_add: {method: 'POST', url: `${baseUrl}v1/patients`},
    patient_delete: {method: 'DELETE', url: `${baseUrl}v1/patients`},
    patient_program: {method: 'GET', url: `${baseUrl}v1/patient-programs/`},
    sync_patient: {method: 'POST', url: `${baseUrl}v1/patients/sync`},
    list_report: {method: 'GET', url: `${baseUrl}v1/reports`},
    update_list_report: {method: 'PUT', url: `${baseUrl}v1/reports`},
    list_report_patient: {method: 'POST', url: `${baseUrl}v1/reports/patients`},
};

export default apiEndpoints;