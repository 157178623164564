import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import Helmet from 'react-helmet';
import Select from "react-select";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ApiUrl from "../../helpers/url";
import {decodeToken} from "../../helpers/jwtToken";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPatientActive,
} from "../../redux/slices/dokterPasienSlice";

export default function Formulir({selectedWorkspace}) {
  const [load, setLoad] = useState(true);
  const [form, setForm] = useState([]);
  const [patient, setPatient] = useState([]);
  const [formPasien, setFormPasien] = useState([]);
  const [programId, setProgramId] = useState('');
  const [formId, setFormId] = useState('');
  const [patientId, setPatientId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loadSave, setLoadSave] = useState(false);
  const [listDokter, setListDokter] = useState([]);
  const [listPerawat, setListPerawat] = useState([]);
  const [dokter, setDokter] = useState([]);
  const [perawat, setPerawat] = useState([]);

  const dispatch = useDispatch();
  const dokterPasien = useSelector((state) => state.dokterPasien);

  const openModal = (program_id, id) => {
    getFormPasien(id);
    setPatientId('')
    setDokter([])
    setPerawat([])
    setProgramId(program_id)
    setFormId(id)
    setShowModal(true);
  };

  const closeModal = () => {
    setFormPasien([])
    setPatientId('')
    setProgramId('')
    setFormId('')
    setDokter([])
    setPerawat([])
    setShowModal(false);
  };

  const dispatchFecthPasienAktif = () => {
    if (dokterPasien.patientActiveStatus === "idle") {
      dispatch(fetchPatientActive('aktif'));
    }

    if (dokterPasien.patientActiveStatus === "failed") {
      toast.error(dokterPasien.listInstitusiError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  }

  const dispatchFecthPasienAktifDifferent = () => {
    dispatch(fetchPatientActive('aktif'));
  };

  const getFormByWorkspace = async(workspace) => {
    setLoad(true);
    let config = {
        method: ApiUrl.get_form.method,
        maxBodyLength: Infinity,
        url: ApiUrl.get_form.url+"?workspace_id="+workspace,
        headers: { 
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };

    await axios.request(config)
    .then((response) => {
        setLoad(false)
        if(response.data.status){
            setForm(response.data.data);
        }else{
            setForm([]);
            toast.error(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
          });
        }
    })
    .catch((error) => {
        setLoad(false)
        setForm([]);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
      });
    })
  }

  const getListUser = async() => {
    let config = {
        method: ApiUrl.get_user.method,
        maxBodyLength: Infinity,
        url: ApiUrl.get_user.url+"?role=3,4",
        headers: { 
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };

    await axios.request(config)
    .then((response) => {
        if(response.data.status){
            const transformedDataDokter = response.data?.data?.filter(item => item.role === 3).map(item => ({
              value: item.id,
              label: item.name
            }));
            setListDokter(transformedDataDokter);

            const transformedDataPerawat = response.data?.data?.filter(item => item.role === 4).map(item => ({
              value: item.id,
              label: item.name
            }));
            setListPerawat(transformedDataPerawat);
        }else{
          setListDokter([]);
          setListPerawat([]);
          toast.error(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
          });
        }
    })
    .catch((error) => {
        setListDokter([]);
        setListPerawat([]);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
      });
    })
  }

  const getFormPasien = async(form_id) => {
    let config = {
        method: ApiUrl.get_form.method,
        maxBodyLength: Infinity,
        url: ApiUrl.get_form.url+"/"+form_id+"/patients",
        headers: { 
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };

    await axios.request(config)
    .then((response) => {
        if(response.data.status){
            setFormPasien(response.data.data);
        }else{
            setFormPasien([]);
        }
    })
    .catch((error) => {
        setFormPasien([]);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
      });
    })
  }

  const saveData = async() =>{
    let data = JSON.stringify({
      "program_id": programId,
      "form_ids": [
        formId
      ],
      "nurse_ids": [
       perawat[0]
      ],
      "doctor_ids": [
        dokter[0]
      ]
    });
    
    if(!patientId){
      return toast.error('Pilih Pasien Terlebih Dahulu!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
      });
    }

    let config = {
        method: ApiUrl.patient_add.method,
        maxBodyLength: Infinity,
        url: ApiUrl.patient_add.url+'/'+patientId+'/programs',
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`, 
            'Content-Type': 'application/json'
        },
        data : data
    };
    setLoadSave(true)
    await axios.request(config)
    .then((response) => {
        setLoadSave(false)
        if(response.data.status){
            getFormPasien(formId);
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
        }else{
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
        }
    })
    .catch((error) => {
        setLoadSave(false)
        toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
        });
    });
  }

  const unassignProgram = async(pasien_id) => {
    swal({
      title: "Peringatan!",
      text: "Apa program ini akan dihapus dari pasien?",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: "Batal",
        confirm: {
          text: "Hapus",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
    }).then(async(willDelete) => {
      if (willDelete) {
        let config = {
            method: ApiUrl.patient_delete.method,
            maxBodyLength: Infinity,
            url: ApiUrl.delete_form.url+formId+"/patients/"+pasien_id,
            headers: { 
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
        };
    
        await axios.request(config)
        .then((response) => {
            if(response.data.status){
              getFormPasien(formId);
              toast.success(response.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "light",
                  transition: Bounce,
              });
            }else{
              toast.error(response.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "light",
                  transition: Bounce,
              });
            }
        })
        .catch((error) => {
            toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
          });
        })
      }
    })
  }

  useEffect(() => {
    getListUser();
    getFormByWorkspace(selectedWorkspace);
    dispatchFecthPasienAktif();
    if(dokterPasien.patientActiveDeletedStatus !== 'aktif'){
      dispatchFecthPasienAktifDifferent();
    }
    if(dokterPasien.patientActiveStatus === "succeeded"){
      const transformedData = dokterPasien.patientActiveResp.data.map(item => ({
        value: item.id,
        label: `${item.mnr} - ${item.name}`
      }));
      setPatient(transformedData);
    }
  }, [selectedWorkspace, dokterPasien]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Formulir</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/dokter">Dokter</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Formulir</li>
            </ol>
            <h4 className="main-title mb-0">Formulir</h4>
          </div>
        </div>
        <Row>
          {!load ? form?.length > 0 ? form.map((val, index) => (
          <Col lg="4" className="mb-3" key={index}>
            <div className="card card-one">
              <div className="card-body">
                <div className="card-icon-dashboard-dokter">
                  <span className="badge bg-primary mb-2">{val.program.name}</span>
                  <div className="d-flex align-items-center">
                    <label className="card-title fw-medium text-dark mb-1">{val.type}</label>
                  </div>
                  <p className="fs-xs text-secondary mb-4 lh-4">{val.name}</p>
                  <Link className="text-primary" to="#"  onClick={()=> openModal(val.program.id, val.id)}><span className="d-inline-flex d-flex align-items-center fs-12 text-primary">Assign Pasien <i className="ri-arrow-right-circle-line text-primary"></i></span></Link>
                </div>
              </div>
            </div>
          </Col>)) : <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/><h5>Data Not Found</h5></div> 
          : <Col lg="12" className="mb-3"><SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2"><Skeleton count={4} style={{ height: "70px" }}/></SkeletonTheme></Col>}
        </Row>
      </div>

      <Modal show={showModal} onHide={closeModal} centered>
        <Form onSubmit={(e) => { e.preventDefault(); saveData();}}>
          <Modal.Header closeButton>
              <Modal.Title>Medical Remainder Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formFileSm" className="mb-3">
                  <Form.Label>{`Pengguna Klinis Penanggung Jawab`} <span className="text-danger"></span></Form.Label>
                  <Select isMulti={true} options={listPerawat} isSearchable={true} onChange={(selectedOption)=> {setPerawat(selectedOption ? selectedOption.map(option => option.value) : [])}} />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formFileSm" className="mb-3">
                  <Form.Label>{`Pengguna Klinis Penerima Peringatan`} <span className="text-danger"></span></Form.Label>
                  <Select isMulti={true} options={listDokter} isSearchable={true} onChange={(selectedOption)=> {setDokter(selectedOption ? selectedOption.map(option => option.value) : [])}} />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group controlId="formFileSm" className="mb-3">
                  <Form.Label>Pasien <span className="text-danger"></span></Form.Label>
                  <Select options={patient} isSearchable={true} onChange={(selectedOption)=> {setPatientId(selectedOption.value)}} value={{ value: patientId ? patientId : '', label: patientId ? patient.find(option => option.value === patientId).label : 'Pilih Pasien'}} />
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex align-items-center justify-content-end">
              <Button variant="primary" type="submit" disabled={loadSave}>
                  {loadSave ? 'Silahkan Tunggu ...' : 'Simpan'}
              </Button>
            </div>
            <div className="card card-one">
              <div className="card-header">
                <h6 className="card-title">Pasien Pada Program</h6>
              </div> 
              <div className="card-body">
                <div style={{height: "35vh", overflowY: "scroll"}}>  
                  { formPasien?.length > 0 ? formPasien.map((val, index) => (
                  <div className="d-flex align-items-center justify-content-between border-bottom-1px mb-2" key={index}> 
                    <div>
                      <h6>{val.mnr} - {val.name}</h6>
                      <p className="fs-xs text-secondary d-flex align-items-center mb-2"><i className="ri-whatsapp-line text-success"></i> {val.phone}</p>
                    </div>
                    <Button variant="danger" onClick={()=>unassignProgram(val.id)}><i className="ri-delete-bin-6-line"></i></Button>
                  </div>)) : <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/><h5>Data Not Found</h5></div> }
                </div>
              </div>
            </div>
             
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
                Tutup
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
