import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import DataTable from "react-data-table-component";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Select from "react-select";
import FormData from "form-data";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchListBanner,
  fetchBannerById,
  fetchSaveDataModal,
  fetchDeleteById,
  resetDataModal,
  resetStatusListBanner,
  changeDataModalBanner,
} from "../../../redux/slices/instansiBannerSlice";

export default function Instansi() {
  const [modalAdd, setModalAdd] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [image, setImage] = useState(null);

  const dispatch = useDispatch();
  const instansiBanner = useSelector((state) => state.instansiBanner);
  const dataModalBanner = useSelector(
    (state) => state.instansiBanner.bannerByIdResp.data,
  );

  const columns = [
    {
      name: "Banner",
      cell: (row) => (
        <>
          <div className="d-flex align-items-center gap-2">
            <div className="avatar w-100" style={{borderRadius: "0px"}}>
              <img
                src={
                  row.path
                    ? "https://ahc.brainsia.id/" + row.path
                    : "/favicon.png"
                }
                alt={row.title}
              />
            </div>
          </div>
        </>
      ),
    },{
      name: "Judul",
      selector: (row) => row.title,
    },
    {
      name: "Order",
      selector: (row) => row.order_display,
    },
    {
      name: "Aksi",
      selector: (row) => row.title,
      cell: (row) => (
        <>
          <Link
            to="#"
            className="btn btn-primary"
            onClick={() => dispatchFecthBannerById(row.id)}
          >
            <i className="ri-edit-2-line"></i>
          </Link>{" "}
          &nbsp;
          <Link
            to="#"
            className="btn btn-danger"
            onClick={() => deleteData(row.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Link>
        </>
      ),
    },
  ];

  const dispatchFecthListBanner = () => {
    if (instansiBanner.listBannerStatus === "idle") {
      dispatch(fetchListBanner());
    }

    if (instansiBanner.listBannerStatus === "failed") {
      toast.error(instansiBanner.listBannerError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  useEffect(() => {
    dispatchFecthListBanner();
  }, [instansiBanner, dataModalBanner]);

  const dispatchFecthBannerById = async (id) => {
    setTitleModal("Ubah");
    if (instansiBanner.bannerByIdStatus === "idle") {
      await dispatch(fetchBannerById(id));
      if (instansiBanner.bannerByIdError) {
        toast.error(instansiBanner.bannerByIdError, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      }
    }
    setModalAdd(true);
  };

  const deleteData = (id) => {
    swal({
      title: "Apa Data Ingin Dihapus?",
      text: "Jika data dihapus, data tidak dapat dikembalikan!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: "Batal",
        confirm: {
          text: "Hapus",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
    })
      .then(async (willDelete) => {
        if (willDelete) {
          const disp = await dispatch(fetchDeleteById(id));
          if (disp.payload?.status !== true) {
            let message = "Fail - Silakan coba lagi";
            if (disp.payload?.message) {
              message = disp.payload.message;
            }
            toast.error(message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
          } else {
            toast.success("Data Berhasil Dihapus!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
          }
          await dispatch(resetStatusListBanner());
          dispatchFecthListBanner();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const saveData = async () => {
    let data = new FormData();
    data.append("title", dataModalBanner.title);

    const parsedOrderDisplay = parseInt(dataModalBanner.order_display);
    if (!isNaN(parsedOrderDisplay)) {
      data.append("order_display", parsedOrderDisplay);
    } else {
      toast.error("Order Display is not a valid integer!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    if (image) {
      data.append("image", image);
    }

    const param = {
      data: data,
      id: dataModalBanner.id,
      action: titleModal,
    };

    const disp = await dispatch(fetchSaveDataModal(param));
    if (disp.payload?.status !== true) {
      let message = "Fail - Silakan coba lagi";
      if (disp.payload?.message) {
        message = disp.payload.message;
      }
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    } else {
      toast.success(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });

      await dispatch(resetStatusListBanner());
      dispatchFecthListBanner();
      setModalAdd(false);
    }
  };

  const addData = (title) => {
    dispatch(resetDataModal());
    setModalAdd(true);
    setTitleModal(title);
  };

  const closeAddData = () => {
    setModalAdd(false);
    dispatch(resetDataModal());
  };

  const handleChangeModal = (e) => {
    let value = e.target.value;
    let key = e.target.name;
    dispatch(changeDataModalBanner({ key: key, value: value }));
  };

  const handleChangeModalOption = (e, key) => {
    dispatch(changeDataModalBanner({ key: key, value: e.value }));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Banner Instansi</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/instansi">Instansi</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Banner
              </li>
            </ol>
            <h4 className="main-title mb-0">Banner</h4>
          </div>
          <Link
            to="#"
            className="btn btn-primary d-flex align-items-center"
            onClick={() => addData("Tambah")}
          >
            <i className="ri-add-line"></i>Tambah Banner
          </Link>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-one w-100">
              <div className="card-body">
                { instansiBanner.listBannerResp.data ? <DataTable
                  columns={columns}
                  data={instansiBanner.listBannerResp.data}
                  pagination
                  progressPending={instansiBanner.listBannerPending}
                  defaultSortFieldId={1}
                /> : <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/>
                <h5>Data Not Found</h5><p>You can create new banner!</p></div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={modalAdd} onHide={closeAddData} centered>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            saveData();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{titleModal} Banner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Judul <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukan Judul"
                    name="title"
                    onChange={handleChangeModal}
                    required
                    value={dataModalBanner?.title}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Order Display <small className="text-danger">*</small>
                  </Form.Label>
                  <Select
                    options={titleModal == 'Tambah' ? instansiBanner.listBannerActiveOption : instansiBanner.listBannerUpdateOption}
                    isSearchable={false}
                    onChange={(e) =>
                      handleChangeModalOption(e, "order_display")
                    }
                    value={titleModal == 'Tambah' ?  instansiBanner.listBannerActiveOption.find(
                      (option) =>
                        option.value === dataModalBanner?.order_display,
                    ) : instansiBanner.listBannerUpdateOption.find(
                      (option) =>
                        option.value === dataModalBanner?.order_display,
                    )}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Gambar{" "}
                    {titleModal == "Tambah" ? (
                      <small className="text-danger">*</small>
                    ) : (
                      <></>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                    required={titleModal === "Tambah"}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeAddData}>
              Tutup
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={instansiBanner.saveDataModalPending}
            >
              {instansiBanner.saveDataModalPending
                ? "Silahkan Tunggu ..."
                : "Simpan"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
}