import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="main-footer p-3 p-lg-4">
      <span>&copy; 2024. RSKS. All Rights Reserved.</span>
      <span>Created by: <Link to="#" target="_blank">AHC</Link></span>
    </div>
  )
}