import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import userAvatar from "../assets/img/img1.jpg";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ApiUrl from "../helpers/url";
import {decodeToken} from "../helpers/jwtToken";
import {RoleMapping} from "../helpers/roles";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchListWorkspace
} from "../redux/slices/adminRuangKerjaSlice";
import { 
  resetReportByWorkspace
} from "../redux/slices/dokterLaporanSlice";
const formatDate = (date) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  return date.toLocaleDateString("id-ID", options);
};

export default function Header(props) {
  const [showEditInstansi, setShowEditInstansi] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [workSpace, setWorkSpace] = useState([]);
  const adminRuangKerja = useSelector((state) => state.adminRuangKerja);
  const dispatch = useDispatch();
  const dispatchFecthGetWorkspace = () => {
    if (adminRuangKerja.listWorkspaceStatus === "idle") {
      dispatch(fetchListWorkspace());
    }
  }
  const location = useLocation();

  const getFirstSegment = (path) => {
    const segments = path.split("/").filter(Boolean);
    return segments[0];
  };

  const firstSegment = getFirstSegment(location.pathname);

  const today = new Date();
  const formattedDate = formatDate(today);

  const handleChange = (selectedOption) => {
    props.setSelectedWorkspace(selectedOption);
    dispatch(resetReportByWorkspace());
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  const editInstansi = () => {
    setShowEditInstansi(true);
  };

  const closeEditInstansi = () => {
    setShowEditInstansi(false);
  };

  const editPassword = () => {
    setShowEditPassword(true);
  };

  const closeEditPassword = () => {
    setShowEditPassword(false);
  };

  const logout = () => {
    props.logout();
  };

  const syncPatient = () => {
    props.syncPatient();
  };

  const changePassword = async () => {
    if (password && newPassword && confirmationPassword) {
      if (newPassword === confirmationPassword) {
        setLoading(true);
        let data = JSON.stringify({
          old_password: password,
          new_password: newPassword,
          retype_password: confirmationPassword,
        });

        let config = {
          method: ApiUrl.change_password.method,
          maxBodyLength: Infinity,
          url: ApiUrl.change_password.url,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          data: data,
        };

        await axios
          .request(config)
          .then((response) => {
            setLoading(false);
            if (response.data.status) {
              setPassword("");
              setNewPassword("");
              setConfirmationPassword("");
              setShowEditPassword(false);
              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
            } else {
              toast.error(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          });
      } else {
        toast.error("Password Baru & Konfirmasi Password Tidak Sama!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } else {
      toast.error("Inputan Tidak Boleh Kosong!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  useEffect(() => {
    if(firstSegment === 'dokter'){
      dispatchFecthGetWorkspace();
      if(adminRuangKerja.listWorkspaceStatus === "succeeded"){
        const transformedData = adminRuangKerja.listWorkspaceResp.data.map(item => ({
          value: item.id,
          label: item.name
        }));
        setWorkSpace(transformedData);
        if(!props.selectedWorkspace){
          props.setSelectedWorkspace(adminRuangKerja.listWorkspaceResp.data[0] ? props.selectedWorkspace ? props.selectedWorkspace : adminRuangKerja.listWorkspaceResp.data[0].id : "Select Workspace")
        }
      }
    }
  }, [adminRuangKerja, firstSegment]);

  return (
    <>
      <div className="header-main px-3 px-lg-4">
        <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
          <i className="ri-menu-2-fill"></i>
        </Link>

        <div className="me-auto d-flex align-items-center">
          {firstSegment === "dokter" ? (
            <Select
              className="mr-1"
              options={workSpace}
              isSearchable={false}
              value={{ label: workSpace[0] ? props.selectedWorkspace ? workSpace.find(option => option.value === props.selectedWorkspace).label : workSpace[0].label : "Select Workspace", value: workSpace[0] ? props.selectedWorkspace ? props.selectedWorkspace : workSpace[0].value : "Select Workspace" }}
              onChange={(e)=> handleChange(e.value)}
            />
          ) : (
            ""
          )}&nbsp;
          <Button variant="light" className="d-flex align-items-center btn-outline-primary">
            <i className="ri-calendar-event-line"></i>
            {` Hari ini, ${formattedDate}`}
          </Button>
        </div>

        <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
          <Dropdown.Toggle as={CustomToggle}>
            <div className="avatar online d-flex align-items-center ">
              <img src={userAvatar} alt="" className="mr-2" />
              <div className="d-flex align-items-center jusitfy-content-center">
                <h6 className="text-dark mb-0 text-capitalize">{RoleMapping[decodeToken(`Bearer ${localStorage.getItem('access_token')}`).ROLE_ID].name}</h6>
                <i className="ri-arrow-down-s-line"></i>
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="mt-10-f">
            <div className="dropdown-menu-body">

              {firstSegment === "admin" ? (
                <>
                  <nav className="nav">
                    <Link to="#" onClick={() => syncPatient()}>
                      < i className="ri-restart-line"></i> Sync Pasien
                    </Link>
                  </nav>
                  <hr />
                </>
              ) : (
                ""
              )}
              <nav className="nav">
                <Link to="#" onClick={() => editPassword()}>
                  <i className="ri-user-settings-line"></i> Ganti Password
                </Link>
                <Link
                  to="#"
                  onClick={() => {
                    logout();
                  }}
                >
                  <i className="ri-logout-box-r-line"></i> Keluar
                </Link>
              </nav>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Modal show={showEditPassword} onHide={closeEditPassword} centered>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            changePassword();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Ubah Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg="12">
                <Form.Group controlId="formFileSm" className="mb-3">
                  <Form.Label>
                    Password Lama <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    required="required"
                    placeholder="Masukan Password Lama"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" className="mb-3">
                <Form.Group controlId="formFileSm">
                  <Form.Label>
                    Password Baru <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    required="required"
                    placeholder="Masukan Password Baru"
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newPassword}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" className="mb-3">
                <Form.Group controlId="formFileSm">
                  <Form.Label>
                    Konfirmasi Password Baru{" "}
                    <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    required="required"
                    placeholder="Konfirmasi Password Baru"
                    onChange={(e) => setConfirmationPassword(e.target.value)}
                    value={confirmationPassword}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeEditPassword}>
              Tutup
            </Button>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? "Silahkan Tunggu ..." : "Ganti Password"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}