import { jwtDecode } from "jwt-decode";
import ApiUrl from "./url";
import { RoleMapping } from "./roles";
import axios from "axios";

const decodeToken = (token) => {
  return jwtDecode(token);
};

const refreshToken = async(navigate) => {
  if (localStorage.getItem("access_token")) {
    const role = decodeToken(localStorage.getItem("access_token"));
    var currentTime = Date.now();
    var timeutc = currentTime + (24 * 60 * 60 * 1000)
    var expiredTime = role.EXPIRED * 1000;
    if (timeutc > expiredTime) {
      let config = {
        method: ApiUrl.refresh_token.method,
        maxBodyLength: Infinity,
        url: ApiUrl.refresh_token.url,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          if (response?.data?.access_token) {
            localStorage.setItem("access_token", response?.data?.access_token);
            localStorage.setItem(
              "refresh_token",
              response?.data?.refresh_token,
            );
          }
        })
        .catch((error) => {
          localStorage.clear();
          return navigate('/sign-in')
        });
    }
  }
};

const cekSessionLogin = (navigate) => {
  if (localStorage.getItem("access_token")) {
    const role = localStorage.getItem('access_token') ? decodeToken(localStorage.getItem("access_token")) : 0;
    if (role.ROLE_ID) {
      refreshToken(navigate);
      return navigate(RoleMapping[role.ROLE_ID].url);
    } else {
      localStorage.clear();
      return navigate("/sign-in");
    }
  }
};

const backToRole = (navigate) => {
  if (localStorage.getItem("access_token")) {
    const role = decodeToken(localStorage.getItem("access_token"));
    var pathname = window.location.pathname;
    var segments = pathname.split("/");
    var segment1 = segments[1];
    if (`/${segment1}` !== RoleMapping[role.ROLE_ID].url) {
      return navigate(RoleMapping[role.ROLE_ID].url);
    }
  } else {
    localStorage.clear();
    return navigate("/sign-in");
  }
};

export { decodeToken, refreshToken, cekSessionLogin, backToRole };