import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

import ApiUrl from "../../helpers/url";
import { generateConfig } from '../helper/axiosHelper';

export const fetchListDashboard = createAsyncThunk(
  'adminDashboard/fetchListDashboard',
  async (_, { rejectWithValue }) => {
    const url = ApiUrl.admin_dashboard.url;
    const method = ApiUrl.admin_dashboard.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fecthListInstitusiDashboard = createAsyncThunk(
  'adminDashboard/fetchListInstitusiDashboard',
  async (_, { rejectWithValue }) => {
    const url = ApiUrl.get_institution.url+"?limit=5";
    const method = ApiUrl.get_institution.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const adminDashboardSlice = createSlice({
  name: 'adminDashboard',
  initialState: {
    listDashboardResp: [],
    listDashboardStatus: 'idle',
    listDashboardError: null,
    listDashboardPending: true,

    listInstitusiDashboardResp: [],
    listInstitusiDashboardStatus: 'idle',
    listInstitusiDashboardError: null,
    listInstitusiDashboardPending: true,
  },
  reducers: {
    resetStatusList: (state) => {
        state.listDashboardStatus = 'idle';
        state.listInstitusiDashboardStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchListDashboard
      .addCase(fetchListDashboard.pending, (state) => {
        state.listDashboardStatus = 'loading';
      })
      .addCase(fetchListDashboard.fulfilled, (state, action) => {
        state.listDashboardStatus = 'succeeded';
        state.listDashboardResp = action.payload;
        state.listDashboardPending = false;
      })
      .addCase(fetchListDashboard.rejected, (state, action) => {
        state.listDashboardStatus = 'failed';
        state.listDashboardError = action.payload.message;
      })

      // fecthListInstitusi
      .addCase(fecthListInstitusiDashboard.pending, (state) => {
        state.listInstitusiDashboardStatus = 'loading';
      })
      .addCase(fecthListInstitusiDashboard.fulfilled, (state, action) => {
        state.listInstitusiDashboardStatus = 'succeeded';
        state.listInstitusiDashboardResp = action.payload;
        state.listInstitusiDashboardPending = false;
      })
      .addCase(fecthListInstitusiDashboard.rejected, (state, action) => {
        state.listInstitusiDashboardStatus = 'failed';
        state.listInstitusiDashboardError = action.payload.message;
      })
  },
})

export const { resetStatusList } = adminDashboardSlice.actions;
export default adminDashboardSlice.reducer;