import React, {useState, useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './layouts/Main';
import Landing from "./pages/Landing";
import NotFound from "./pages/NotFound";

import publicRoutes from "./routes/PublicRoutes";
import adminRoutes from "./routes/AdminRoutes";
import instansiRoutes from "./routes/InstansiRoutes";
import dokterRoutes from "./routes/DokterRoutes";

import "./assets/css/remixicon.css";
import "./scss/style.scss";

window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />}>
            
          </Route>
          <Route path="/admin" element={<Main setSelectedWorkspace={setSelectedWorkspace} selectedWorkspace={selectedWorkspace}/>}>
            {adminRoutes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  element={route.element}
                  key={index}
                />
              )
            })}
          </Route>

          <Route path="/dokter" element={<Main setSelectedWorkspace={setSelectedWorkspace} selectedWorkspace={selectedWorkspace} />}> 
            {dokterRoutes.map((route, index) => (
              <Route
                key={index} 
                path={route.path}
                element={React.cloneElement(route.element, { selectedWorkspace })}
              />
            ))}
          </Route>

          <Route path="/instansi" element={<Main setSelectedWorkspace={setSelectedWorkspace} selectedWorkspace={selectedWorkspace}/>}>  
            {instansiRoutes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  element={route.element}
                  key={index}
                />
              )
            })}
          </Route>

          {publicRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={route.element}
                key={index}
              />
            )
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}