import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import dataSidebar from "../data/Sidebar";
import { Button } from "react-bootstrap";

export default function Sidebar(props) {
    const [urlpath, setUrlpath] = useState('');
    const [firstSegment, setFirstSegment] = useState('');

    useEffect(() => {
        const pathname = window.location.pathname;
        const segments = pathname.split('/');
        const firstSegment = segments[1];
        const secondSegment = segments[2];
        setUrlpath(pathname);
        setFirstSegment(firstSegment);
    }, []);

    const toggleFooterMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest(".sidebar");
        parent.classList.toggle("footer-menu-show");
    }

    const logout = () => {
        props.logout();
      }

    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <Link to='/dashboard' className="sidebar-logo"><img src="/favicon.png" alt="logo" width="50px"/></Link>
            </div>
            <PerfectScrollbar className="sidebar-body">
                <SidebarMenu firstSegment={firstSegment} urlpath={urlpath} />
            </PerfectScrollbar>
            <div className="sidebar-footer">
                <Button onClick={()=>{logout()}} variant="danger"><i className="ri-logout-box-line"></i></Button>
            </div>
        </div>
    );
}

function findKeyFromLink(link, datasidebar) {
    let foundKey = null;
    let foundSub = false;

    datasidebar.forEach(item => {
        if (item.link === link) {
            foundKey = item.key;
            const subItem = item?.sub?.find(sub => sub.link === link);
            if (subItem) {
                foundSub = true;
            }
        } else if (item.sub && item.sub.length > 0) {
            const subItem = item?.sub?.find(sub => sub.link === link);
            if (subItem) {
                foundKey = subItem.key_before;
            }
            if (subItem) {
                foundSub = true;
            }
        }
    });

    return {foundKey, foundSub};
}

function SidebarMenu({ firstSegment, urlpath }) {
    const populateMenu = () => {
        let datasidebar;

        if(firstSegment === 'admin'){
            datasidebar = dataSidebar.admin;
        } else if(firstSegment === 'instansi'){
            datasidebar = dataSidebar.instansi;
        } else if(firstSegment === 'dokter'){
            datasidebar = dataSidebar.dokter;
        }
        
        if (!datasidebar) return null;
        let datakeyashow = findKeyFromLink(urlpath, datasidebar);
        return datasidebar.map((m, key) => {
            return (
                <li key={key} className="nav-item">
                    <Link to={m.link} data-key={m.key} className={m.link == urlpath || m.key == datakeyashow.foundKey ? "nav-link nav-remove-active active" : "nav-link nav-remove-active"} data-bs-toggle="tooltip" data-bs-placement="top" title={m.menu}  onClick={() => changeActive(m.key, m.link, datasidebar)}><i className={m.icon}></i></Link>
                </li>
            );
        });
    }

    const changeActive = (e, link, datasidebar) =>{
        const pathname = window.location.pathname;
        let datakeyashow = findKeyFromLink(link, datasidebar);
        
        if(datakeyashow.foundSub){
            const elements = document.querySelectorAll('.menu-left');
            elements.forEach(element => {
                element.classList.remove('d-none');
            });
        }else{
            const elements = document.querySelectorAll('.menu-left');
            elements.forEach(element => {
                element.classList.add('d-none');
            });
        }

        const elements = document.querySelectorAll('.nav-remove-active');
        elements.forEach(element => {
            element.classList.remove('active');
        });

        const elementssub = document.querySelectorAll('.nav-sub-remove-active');
        elementssub.forEach(element => {
            element.classList.remove('active');
        });

        const elementssubchange = document.querySelectorAll('.menu-left-body div');
        elementssubchange.forEach(element => {
            element.classList.add('d-none');
        });
        
        const element = document.querySelector(`[data-key="${e}"]`);
        if (element) {
            element.classList.add("active");
        }

        const elementsub = document.querySelector(`.nav-sub-remove-active[href="${link}"]`);
        if (elementsub) {
            elementsub.classList.add("active");
        }

        const elementsubchange = document.querySelector(`[data-subkey="${e}"]`);
        if (elementsubchange) {
            elementsubchange.classList.remove("d-none");
            elementsubchange.classList.add("show-left");
        }
    }

    const toggleMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-group');
        parent.classList.toggle('show');
    }

    const toggleSubMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-item');
        let node = parent.parentNode.firstChild;

        while (node) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling;
        }

        parent.classList.toggle('show');
    }

    return (
        <React.Fragment>
            <div className="nav-group show">
                <ul className="nav nav-sidebar">
                    {populateMenu()}
                </ul>
            </div>
        </React.Fragment>
    );
}
