import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function NotFound() {
  
  document.body.classList.remove("sidebar-show");

  return (
    <div className="page-error">
      <div className="header">
        <Container>
          <Link to="/" className="header-logo"><img src="/favicon.png" width="50px"/>Auto Health Care</Link>
        </Container>
      </div>

      <div className="content">
        <Container>
          <Row className="d-flex align-items-center justify-content-center">
            <Col lg="6" className="d-flex flex-column align-items-center">
              <h1 className="error-number">404</h1>
              <h2 className="error-title">Page Not Found</h2>
              <p className="error-text">Oopps. The page you were looking for doesn't exist. You may have mistyped the address or the page may have moved.</p>
              <Link to="/" className="btn btn-success btn-error">Back to Dashboard</Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}