import React from "react";

// Dashboard
import Dashboard from "../dashboard/dokter/Dashboard";
import Report from "../dashboard/dokter/Report";
import Jadwal from "../dashboard/dokter/Jadwal";
import Pasien from "../dashboard/dokter/pasien/List";
import Formulir from "../dashboard/dokter/Formulir";
import PasienProfile from "../dashboard/dokter/pasien/profile/Detail";
import PasienReport from "../dashboard/dokter/pasien/profile/Laporan";
import PengaturanKlinis from "../dashboard/dokter/pasien/profile/PengaturanKlinis";

const dokterRoutes = [
  { path: "", element: <Dashboard /> },
  { path: "laporan", element: <Report /> },
  { path: "jadwal", element: <Jadwal /> },
  { path: "pasien/aktif", element: <Pasien /> },
  { path: "pasien/non-aktif", element: <Pasien /> },
  { path: "pasien/detail/:id/profile", element: <PasienProfile /> },
  { path: "pasien/detail/:id/report", element: <PasienReport /> },
  { path: "pasien/detail/:id/pengaturan-klinis", element: <PengaturanKlinis /> },
  { path: "formulir", element: <Formulir /> },
]

export default dokterRoutes;