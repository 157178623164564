import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import ApiUrl from "../../helpers/url";
import { generateConfig } from '../helper/axiosHelper';

export const fetchPatientActive = createAsyncThunk(
  'dokterPasien/fetchPatientActive',
  async (status, { rejectWithValue }) => {
    const deleted = status !== 'aktif' ? '?deleted=true' : '';
    const url = ApiUrl.patient.url + deleted;
    const method = ApiUrl.patient.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return { data: response.data, status };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchSendMessage = createAsyncThunk(
  'dokterPasien/fetchSendMessage',
  async (param, { rejectWithValue }) => {
    const {data, id} = param;
    const url = `${ApiUrl.patient.url}/${id}/messages`;
    const method = 'POST';
    try {
      const response = await axios.request(generateConfig(url, method, data));
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPatientProgram = createAsyncThunk(
  'dokterPasien/fetchPatientProgram',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.patient.url}/${id}/programs`;
    const method = ApiUrl.patient.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return { data: response.data};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeletePatientProgram = createAsyncThunk(
  'dokterPasien/fetchDeletePatientProgram',
  async (param, { rejectWithValue }) => {
    const {patientId, detailId} = param;
    const url = `${ApiUrl.patient.url}/${patientId}/programs/${detailId}`;
    const method = 'DELETE';
    try {
      const response = await axios.request(generateConfig(url, method));
      return { data: response.data};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchListDokterPerawat = createAsyncThunk(
  'dokterPasien/fetchListDokterPerawat',
  async (roleint, { rejectWithValue }) => {
    const url = ApiUrl.get_user.url+"?role="+roleint;
    const method = ApiUrl.get_user.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return { data: response.data};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchListProgram = createAsyncThunk(
  'dokterPasien/fetchListProgram',
  async (workspaceId, { rejectWithValue }) => {
    const url = ApiUrl.get_program.url+"?workspace_id="+workspaceId;
    const method = ApiUrl.get_program.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return { data: response.data};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchListForm = createAsyncThunk(
  'dokterPasien/fetchListForm',
  async ({ workspaceId, programId }, { rejectWithValue }) => {
    const program = programId ? `&program_id=${programId}` : '';
    const url = `${ApiUrl.get_form.url}?workspace_id=${workspaceId}${program}`;
    const method = ApiUrl.get_form.method;
    
    try {
      const response = await axios.request(generateConfig(url, method));
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSaveAssignProgram = createAsyncThunk(
  'dokterPasien/fetchSaveAssignProgram',
  async (param, { rejectWithValue }) => {
    const {data, id} = param;
    const url = `${ApiUrl.patient.url}/${id}/programs`;
    const method = 'POST';
    try {
      const response = await axios.request(generateConfig(url, method, data));
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUpdateAssignProgram = createAsyncThunk(
  'dokterPasien/fetchUpdateAssignProgram',
  async (param, { rejectWithValue }) => {
    const {data, id, program_id} = param;
    const url = `${ApiUrl.patient.url}/${id}/programs/${program_id}`;
    const method = 'PUT';
    try {
      const response = await axios.request(generateConfig(url, method, data));
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSetStatusPatient= createAsyncThunk(
  'dokterPasien/fetchSetStatusPatient',
  async (param, { rejectWithValue }) => {
    const {patientId, data} = param;
    const url = `${ApiUrl.patient.url}/${patientId}/status`;
    const method = 'PUT';
    try {
      const response = await axios.request(generateConfig(url, method, data));
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  patientActiveResp: [],
  patientActiveStatus: 'idle',
  patientActiveError: null,
  patientActivePending: true,
  patientActiveDeletedStatus: '',

  sendMessageResp: [],
  sendMessageStatus: 'idle',
  sendMessageError: null,
  sendMessagePending: true,

  patientProgramResp: [],
  patientProgramStatus: 'idle',
  patientProgramError: null,
  patientProgramPending: true,

  deletePatientProgramResp: [],
  deletePatientProgramStatus: 'idle',
  deletePatientProgramError: null,
  deletePatientProgramPending: true,

  listPerawatResp: [],
  listDokterResp: [],
  listDokterPerawatStatus: 'idle',
  listDokterPerawatError: null,
  listDokterPerawatPending: true,

  listProgramResp: [],
  listProgramStatus: 'idle',
  listProgramError: null,
  listProgramPending: true,

  listFormResp: [],
  listFormStatus: 'idle',
  listFormError: null,
  listFormPending: true,

  saveAssignProgramResp: [],
  saveAssignProgramStatus: 'idle',
  saveAssignProgramError: null,
  saveAssignProgramPending: true,

  updateAssignProgramResp: [],
  updateAssignProgramStatus: 'idle',
  updateAssignProgramError: null,
  updateAssignProgramPending: true,

  setStatusPatientResp: [],
  setStatusPatientStatus: 'idle',
  setStatusPatientError: null,
  setStatusPatientPending: true,
};

const dokterPasien = createSlice({
  name: 'dokterPasien',
  initialState: initialState,
  reducers: {
    resetModalPatientProgram: (state) => {
      state.patientProgramResp = [];
      state.patientProgramStatus = 'idle';
      state.patientProgramError = null;
      state.patientProgramPending = true;
    },
    resetSaveAssignProgram: (state) => {
      state.saveAssignProgramResp = [];
      state.saveAssignProgramStatus = 'idle';
      state.saveAssignProgramError = null;
      state.saveAssignProgramPending = true;
    },
    resetUpdateAssignProgram: (state) => {
      state.updateAssignProgramResp = [];
      state.updateAssignProgramStatus = 'idle';
      state.updateAssignProgramError = null;
      state.updateAssignProgramPending = true;
    },
    resetListPatient: (state) => {
      state.patientActiveResp = [];
      state.patientActiveStatus = 'idle';
      state.patientActiveError = null;
      state.patientActivePending = true;
      state.patientActiveDeletedStatus = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatientActive.pending, (state, action) => {
        state.patientActiveStatus = 'loading';
        state.patientActivePending = true;
        state.patientActiveDeletedStatus = action.meta.arg;
      })
      .addCase(fetchPatientActive.fulfilled, (state, action) => {
        state.patientActiveStatus = 'succeeded';
        state.patientActiveResp = action.payload.data;
        state.patientActivePending = false;
        state.patientActiveDeletedStatus = action.payload.status;
      })
      .addCase(fetchPatientActive.rejected, (state, action) => {
        state.patientActiveStatus = 'failed';
        state.patientActiveError = action.payload.message;
        state.patientActivePending = false;
        state.patientActiveDeletedStatus = action.meta.arg;
      })

      // fetchSendMessage
      .addCase(fetchSendMessage.pending, (state, action) => {
        state.sendMessageStatus = 'loading';
        state.sendMessagePending = false;
      })
      .addCase(fetchSendMessage.fulfilled, (state, action) => {
        state.sendMessageStatus = 'succeeded';
        state.sendMessageResp = action.payload.data;
        state.sendMessagePending = true;
      })
      .addCase(fetchSendMessage.rejected, (state, action) => {
        state.sendMessageStatus = 'failed';
        state.sendMessageError = action.payload.message;
        state.sendMessagePending = true;
      })

      // fetchPatientProgram
      .addCase(fetchPatientProgram.pending, (state, action) => {
        state.patientProgramStatus = 'loading';
        state.patientProgramPending = true;
      })
      .addCase(fetchPatientProgram.fulfilled, (state, action) => {
        state.patientProgramStatus = 'succeeded';
        state.patientProgramResp = action.payload.data;
        state.patientProgramPending = false;
      })
      .addCase(fetchPatientProgram.rejected, (state, action) => {
        state.patientProgramStatus = 'failed';
        state.patientProgramError = action.payload.message;
        state.patientProgramPending = false;
      })

      // fetchDeletePatientProgram
      .addCase(fetchDeletePatientProgram.pending, (state, action) => {
        state.deletePatientProgramStatus = 'loading';
        state.deletePatientProgramPending = true;
      })
      .addCase(fetchDeletePatientProgram.fulfilled, (state, action) => {
        state.deletePatientProgramStatus = 'succeeded';
        state.deletePatientProgramResp = action.payload.data;
        state.deletePatientProgramPending = false;
      })
      .addCase(fetchDeletePatientProgram.rejected, (state, action) => {
        state.deletePatientProgramStatus = 'failed';
        state.deletePatientProgramError = action.payload.message;
        state.deletePatientProgramPending = false;
      })

      // fetchListDokterPerawat
      .addCase(fetchListDokterPerawat.pending, (state, action) => {
        state.listDokterPerawatStatus = 'loading';
        state.listDokterPerawatPending = true;
      })
      .addCase(fetchListDokterPerawat.fulfilled, (state, action) => {
        state.listDokterPerawatStatus = 'succeeded';
        const resp = action.payload.data
        if(resp.data){
          state.listDokterResp = resp.data.filter(item => item.role === 3).map(item => ({
            value: item.id,
            label: item.name
          }));

          state.listPerawatResp = resp.data.filter(item => item.role === 4).map(item => ({
            value: item.id,
            label: item.name
          }));
        }else{
          state.listDokterResp.data = []
          state.listPerawatResp.data = []
        }
        state.listDokterPerawatPending = false;
      })
      .addCase(fetchListDokterPerawat.rejected, (state, action) => {
        state.listDokterPerawatStatus = 'failed';
        state.listDokterPerawatError = action.payload.message;
        state.listDokterPerawatPending = false;
      })

      // fetchListProgram
      .addCase(fetchListProgram.pending, (state, action) => {
        state.listProgramStatus = 'loading';
        state.listProgramPending = true;
      })
      .addCase(fetchListProgram.fulfilled, (state, action) => {
        state.listProgramStatus = 'succeeded';
        const resp = action.payload.data
        if(resp.data){
          state.listProgramResp = resp.data.map(item => ({
            value: item.id,
            label: item.name
          }));
        }else{
          state.listProgramResp.data = []
        }
        state.listProgramPending = false;
      })
      .addCase(fetchListProgram.rejected, (state, action) => {
        state.listProgramStatus = 'failed';
        state.listProgramError = action.payload.message;
        state.listProgramPending = false;
      })

      // fetchListForm
      .addCase(fetchListForm.pending, (state, action) => {
        state.listFormStatus = 'loading';
        state.listFormPending = true;
      })
      .addCase(fetchListForm.fulfilled, (state, action) => {
        state.listFormStatus = 'succeeded';
        const resp = action.payload.data
        if(resp.data){
          state.listFormResp = resp.data.map(item => ({
            value: item.id,
            label: item.name
          }));
        }else{
          state.listFormResp = []
        }
        state.listFormPending = false;
      })
      .addCase(fetchListForm.rejected, (state, action) => {
        state.listFormStatus = 'failed';
        state.listFormError = action.payload.message;
        state.listFormPending = false;
      })

      // fetchSaveAssignProgram
      .addCase(fetchSaveAssignProgram.pending, (state, action) => {
        state.saveAssignProgramStatus = 'loading';
        state.saveAssignProgramPending = false;
      })
      .addCase(fetchSaveAssignProgram.fulfilled, (state, action) => {
        state.saveAssignProgramStatus = 'succeeded';
        state.saveAssignProgramResp = action.payload.data;
        state.saveAssignProgramPending = true;
      })
      .addCase(fetchSaveAssignProgram.rejected, (state, action) => {
        state.saveAssignProgramStatus = 'failed';
        state.saveAssignProgramError = action.payload.message;
        state.saveAssignProgramPending = true;
      })

      //fetchUpdateAssign
      .addCase(fetchUpdateAssignProgram.pending, (state, action) => {
        state.updateAssignProgramStatus = 'loading';
        state.updateAssignProgramPending = false;
      })
      .addCase(fetchUpdateAssignProgram.fulfilled, (state, action) => {
        state.updateAssignProgramStatus = 'succeeded';
        state.updateAssignProgramResp = action.payload.data;
        state.updateAssignProgramPending = true;
      })
      .addCase(fetchUpdateAssignProgram.rejected, (state, action) => {
        state.updateAssignProgramStatus = 'failed';
        state.updateAssignProgramError = action.payload.message;
        state.updateAssignProgramPending = true;
      })

      // fetchSetStatusPatient
      .addCase(fetchSetStatusPatient.pending, (state, action) => {
        state.setStatusPatientStatus = 'loading';
        state.setStatusPatientPending = false;
      })
      .addCase(fetchSetStatusPatient.fulfilled, (state, action) => {
        state.setStatusPatientStatus = 'succeeded';
        state.setStatusPatientResp = action.payload.data;
        state.setStatusPatientPending = true;
      })
      .addCase(fetchSetStatusPatient.rejected, (state, action) => {
        state.setStatusPatientStatus = 'failed';
        state.setStatusPatientError = action.payload.message;
        state.setStatusPatientPending = true;
      })
      
      ;
  },
})

export const { resetModalPatientProgram, resetSaveAssignProgram, resetListPatient, resetUpdateAssignProgram } = dokterPasien.actions;
export default dokterPasien.reducer;