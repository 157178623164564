export const toMinutes = (second) => {
  let m = Math.floor(second / 60)
  let s = Math.floor(second % 60)
  if (s < 10) {
    s = `0${s}`
  }
  if (m < 10) {
    m = `0${m}`
  }

  return {
    minutes: m,
    seconds: s,
  }
}

export const convertToGMT7 = (utcDateStr) => {
  const utcDate = new Date(utcDateStr);
  
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'Asia/Bangkok',
    hour12: false
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = formatter.format(utcDate);

  return formattedDate;
};