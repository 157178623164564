import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import DataTable from "react-data-table-component";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Select from "react-select";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchListWorkspace,
  fecthListInstitusi,
  fetchWorkspaceById,
  fetchSaveDataModal,
  fetchDeleteById,
  resetStatus,
  resetStatusList,
  resetDataModalWorkspace,
  changeDataModalWorkspace,
} from "../../../redux/slices/adminRuangKerjaSlice";

export default function WorkSpace() {
  const [modalAdd, setModalAdd] = useState(false);
  const [titleModal, setTitleModal] = useState("");

  const dispatch = useDispatch();
  const adminRuangKerja = useSelector((state) => state.adminRuangKerja);
  const dataModalWorkspace = useSelector(
    (state) => state.adminRuangKerja.workspaceByIdResp.data,
  );

  const columns = [
    {
      name: "Ruang Kerja",
      selector: (row) => row.name,
    },
    {
      name: "Institusi",
      cell: (row) => (
        <>
          <div className="d-flex align-items-center gap-2">
            <div>
              <h6 className="mb-0">{row.institution.name}</h6>
              <span className="fs-xs text-secondary">
                {row.institution.email}
              </span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Aksi",
      selector: (row) => row.title,
      cell: (row) => (
        <>
          <Link
            to={"/admin/program/ruang-kerja/"+row.id}
            className="btn btn-success"
          >
            <i className="ri-external-link-line"></i>
          </Link>
          &nbsp;
          <Link
            to="#"
            className="btn btn-primary"
            onClick={() => dispatchFecthWorkspaceById(row.id)}
          >
            <i className="ri-edit-2-line"></i>
          </Link>{" "}
          &nbsp;
          <Link
            to="#"
            className="btn btn-danger"
            onClick={() => deleteData(row.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Link>
        </>
      ),
    },
  ];

  const dispatchFecthGetWorkspace = () => {
    if (adminRuangKerja.listWorkspaceStatus === "idle") {
      dispatch(fetchListWorkspace());
    }

    if (adminRuangKerja.listWorkspaceStatus === "failed") {
      toast.error(adminRuangKerja.listUserError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const dispatchFecthGetInstitusi = () => {
    if (adminRuangKerja.listInstitusiStatus === "idle") {
      dispatch(fecthListInstitusi());
    }

    if (adminRuangKerja.listInstitusiStatus === "failed") {
      toast.error(adminRuangKerja.listInstitusiError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  useEffect(() => {
    dispatchFecthGetWorkspace();
    dispatchFecthGetInstitusi();
  }, [adminRuangKerja]);

  const dispatchFecthWorkspaceById = async (id) => {
    setTitleModal("Ubah");
    if (adminRuangKerja.workspaceByIdStatus === "idle") {
      const disp = await dispatch(fetchWorkspaceById(id));
      if (adminRuangKerja.workspaceByIdError) {
        toast.error(adminRuangKerja.workspaceByIdError, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      }
      setModalAdd(true);
      dispatch(resetStatus());
    }
  };

  const deleteData = (id) => {
    swal({
      title: "Apa Data Ingin Dihapus?",
      text: "Jika data dihapus, data tidak dapat dikembalikan!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: "Batal",
        confirm: {
          text: "Hapus",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
    })
      .then(async (willDelete) => {
        if (willDelete) {
          await dispatch(fetchDeleteById(id));
          if (adminRuangKerja.deleteByIdError) {
            toast.error(adminRuangKerja.deleteByIdError, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
          } else {
            toast.success("Data Berhasil Dihapus!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
          }
          await dispatch(resetStatusList());
          dispatchFecthGetWorkspace();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const saveData = async () => {
    const data = JSON.stringify({
      name: dataModalWorkspace.name,
      description: dataModalWorkspace.description,
      institution_id: dataModalWorkspace.institution_id,
    });

    const param = {
      data: data,
      dataId: dataModalWorkspace.id,
      action: titleModal,
    };
    const disp = await dispatch(fetchSaveDataModal(param));
    if (disp.payload.status !== true) {
      toast.error(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    } else {
      toast.success(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });

      await dispatch(resetStatusList());
      dispatchFecthGetWorkspace();
      setModalAdd(false);
    }
  };

  const addData = (title) => {
    setModalAdd(true);
    if (titleModal !== title) {
      dispatch(resetDataModalWorkspace());
    }
    setTitleModal(title);
  };

  const closeAddData = () => {
    setModalAdd(false);
  };

  const handleChangeModal = (e) => {
    let value = e.target.value;
    let key = e.target.name;
    dispatch(changeDataModalWorkspace({ key: key, value: value }));
  };

  const handleChangeModalOption = (e, key) => {
    dispatch(changeDataModalWorkspace({ key: key, value: e.value }));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Ruang Kerja</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/admin">Admin</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Program
              </li>
            </ol>
            <h4 className="main-title mb-0">Ruang Kerja</h4>
          </div>
          <Link
            to="#"
            className="btn btn-primary d-flex align-items-center"
            onClick={() => addData("Tambah")}
          >
            <i className="ri-add-line"></i>Tambah Ruang Kerja
          </Link>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-one w-100">
              <div className="card-body">
                { adminRuangKerja.listWorkspaceResp.data ? <DataTable
                  columns={columns}
                  data={adminRuangKerja.listWorkspaceResp.data}
                  pagination
                  progressPending={adminRuangKerja.listWorkspacePending}
                  defaultSortFieldId={1}
                /> : <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/>
                <h5>Data Not Found</h5><p>You can create new workspace!</p></div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={modalAdd} onHide={closeAddData} centered>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            saveData();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{titleModal} Ruang Kerja</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Nama Ruang Kerja<small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukan Nama Lengkap"
                    name="name"
                    onChange={handleChangeModal}
                    required
                    value={dataModalWorkspace?.name}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Deskripsi <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="description"
                    onChange={handleChangeModal}
                    placeholder="Masukan Deskripsi"
                    value={dataModalWorkspace?.description}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Institusi <small className="text-danger">*</small>
                  </Form.Label>
                  <Select
                    options={adminRuangKerja.listInstitusiResp}
                    isSearchable={true}
                    onChange={(e) =>
                      handleChangeModalOption(e, "institution_id")
                    }
                    value={adminRuangKerja.listInstitusiResp.find(
                      (option) =>
                        option.value === dataModalWorkspace?.institution_id,
                    )}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeAddData}>
              Tutup
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!adminRuangKerja.saveDataModalPending}
            >
              {!adminRuangKerja.saveDataModalPending
                ? "Silahkan Tunggu ..."
                : "Simpan"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
}