import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

import ApiUrl from "../../helpers/url";
import { generateConfig } from '../helper/axiosHelper';

export const fetchListUser = createAsyncThunk(
  'instansiDokter/fetchListUser',
  async (_, { rejectWithValue }) => {
    const url = ApiUrl.get_user.url;
    const method = ApiUrl.get_user.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserById = createAsyncThunk(
  'instansiDokter/fetchUserById',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.detail_user.url}${id}`;
    const method = ApiUrl.detail_user.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSaveDataModal = createAsyncThunk(
  'instansiDokter/fetchSaveDataModal',
  async (param, { rejectWithValue }) => {
    const {data, userId, action} = param;

    const url = action === 'Tambah' ? ApiUrl.create_user.url : `${ApiUrl.update_user.url}${userId}`;
    const method = action === 'Tambah' ? ApiUrl.create_user.method : ApiUrl.update_user.method;
    try {
      const response = await axios.request(generateConfig(url, method, data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeleteById = await createAsyncThunk(
  'instansiDokter/fetchDeleteById',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.delete_user.url}${id}`;
    const method = ApiUrl.delete_user.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const instansiDokterSlice = createSlice({
  name: 'instansiDokter',
  initialState: {
    listUserResp: [],
    listUserStatus: 'idle',
    listUserError: null,
    listUserPending: true,

    userByIdResp: [],
    userByIdStatus: 'idle',
    userByIdError: null,
    userByIdPending: true,

    saveDataModalResp: [],
    saveDataModalStatus: 'idle',
    saveDataModalError: null,
    saveDataModalPending: true,

    deleteByIdResp: [],
    deleteByIdStatus: 'idle',
    deleteByIdError: null,
    deleteByIdPending: true,
  },
  reducers: {
    resetStatus: (state) => {
      state.userByIdStatus = 'idle';
    },
    changeDataModalUser: (state, action) => {
      if(state.userByIdResp.data === undefined){
        state.userByIdResp = {data:{}}; 
      }
      const key = action.payload.key;
      const value = action.payload.value;
      state.userByIdResp.data[key] = value;
    },
    resetDataModalUser:  (state) => {
      state.userByIdResp = {data:{}}; 
    },
    resetStatusList: (state) => {
      state.listUserStatus = 'idle';
      state.listUserPending = true;
      state.deleteByIdPending = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchListUser
      .addCase(fetchListUser.pending, (state) => {
        state.listUserStatus = 'loading';
      })
      .addCase(fetchListUser.fulfilled, (state, action) => {
        state.listUserStatus = 'succeeded';
        state.listUserResp = action.payload;
        state.listUserPending = false;
      })
      .addCase(fetchListUser.rejected, (state, action) => {
        state.listUserStatus = 'failed';
        state.listUserError = action.payload.message;
      })

      // fetchUserById
      .addCase(fetchUserById.pending, (state) => {
        state.userByIdStatus = 'loading';
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.userByIdStatus = 'succeeded';
        state.userByIdResp = action.payload;
        state.userByIdPending = false;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.userByIdStatus = 'failed';
        state.userByIdError = action.payload.message;
      })

      // fetchSaveDataModal
      .addCase(fetchSaveDataModal.pending, (state) => {
        state.saveDataModalStatus = 'loading';
      })
      .addCase(fetchSaveDataModal.fulfilled, (state, action) => {
        state.saveDataModalStatus = 'succeeded';
        state.byIdResp = action.payload;
        state.byIdPending = false;
      })
      .addCase(fetchSaveDataModal.rejected, (state, action) => {
        state.saveDataModalStatus = 'failed';
        state.saveDataModalError = action.payload.message;
      })

      // fetchDeleteById
      .addCase(fetchDeleteById.pending, (state) => {
        state.deleteByIdStatus = 'loading';
      })
      .addCase(fetchDeleteById.fulfilled, (state, action) => {
        state.deleteByIdStatus = 'succeeded';
        state.deleteByIdResp = action.payload;
        state.deleteByIdPending = false;
      })
      .addCase(fetchDeleteById.rejected, (state, action) => {
        state.deleteByIdStatus = 'failed';
        state.deleteByIdError = action.payload.message;
      })
  },
})

export const { resetStatus, resetStatusList, changeDataModalUser, resetDataModalUser } = instansiDokterSlice.actions;
export default instansiDokterSlice.reducer;