import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Helmet from 'react-helmet';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import swal from 'sweetalert';
import { toast, Bounce } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchListTemplate, fetchListTemplateLog, fetchListTemplateBodyWa,
    fetchDeleteTemplateWa, resetStatusTemplateDetail, resetStatusListTemplate,
    fetchTemplateDetail, fetchSaveDataTemplate
} from "../../../redux/slices/instansiTemplateWaSlice"

const defaultStateTemplate = {
    name: '',
    category: '',
    lang: '',
    header: '',
    body: {
      text: '',
      bindings: '',
    },
    footer: '',
    actions: [
      {
        text: "",
        url: ""
      },
      {
        text: "",
        url: ""
      }
    ]
  }

export default function Banner() {
  const [showAdd, setShowAdd] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [nextStepAdd, setNextStepAdd] = useState(false);
  const [titleModal, setTitleModal] = useState('Tambah');
  const [templateId, setTemplateId] = useState('');
  const [dataSave, setDataSave] = useState(defaultStateTemplate);

  const dispatch = useDispatch();
  const instansiTemplateWa = useSelector((state) => state.instansiTemplateWa);
  const templateDetail = useSelector((state) => state.instansiTemplateWa.templateDetailResp);

  const Item = ({ entity: { name, value } }) => <div style={{fontSize:11}}>{`${name}`}</div>;
  const Loading = ({ data }) => <div style={{fontSize:11}}>Loading</div>;

  const selectOptionskategori = [
    { value: 'UTILITY', label: 'Utility' },
    { value: 'MARKETING', label: 'Marketing' },
    { value: 'AUTHENTICATION', label: 'Authentication' },
  ];

  const selectOptionslanguage = [
    { value: 'id', label: 'Bahasa Indonesia' },
    { value: 'en_US', label: 'English' },
  ];

  const columns = [
    {
      name: 'Nama Template',
      selector: row => row.name,
    },
    {
      name: 'Kategori',
      selector: row => row.category,
    },
    {
      name: 'Status',
      selector: row => row.status,
    },
    {
      name: 'Opsi',
      selector: row => row.id,
      cell: row => <><Button variant="primary" onClick={()=>dispatchFecthTemplateDetail(row.id)}><i className="ri-external-link-line"></i></Button>&nbsp;<Button variant="danger" onClick={()=> deleteData(row.id)}><i className="ri-delete-bin-5-line"></i></Button></>
    },
  ];

  const columnsLog = [
    {
      name: 'Template Name',
      selector: row => row.template_name,
    },
    {
      name: 'Status',
      selector: row => row.status,
    },
    {
      name: 'Quality Score',
      selector: row => row.quality_score,
    },
    {
      name: 'Rejection Reason',
      selector: row => row.rejection_reason,
    }
  ];

  const dispatchFecthListTemplate = () => {
    if (instansiTemplateWa.listTemplateStatus === 'idle') {
		dispatch(fetchListTemplate());
    }

    if (instansiTemplateWa.listTemplateStatus === 'failed') {
      toast.error(instansiTemplateWa.listTemplateError, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
      });
    }
  }

  const dispatchFecthListTemplateLog = () => {
    if (instansiTemplateWa.listTemplateLogStatus === 'idle') {
		dispatch(fetchListTemplateLog());
    }

    if (instansiTemplateWa.listTemplateLogStatus === 'failed') {
      toast.error(instansiTemplateWa.listTemplateLogError, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
      });
    }
  }


  const dispatchFecthListTemplateBodyWa = () => {
    if (instansiTemplateWa.listTemplateBodyWaStatus === 'idle') {
		dispatch(fetchListTemplateBodyWa());
    }

    if (instansiTemplateWa.listTemplateBodyWaStatus === 'failed') {
      toast.error(instansiTemplateWa.listTemplateBodyWaError, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
      });
    }
  }
  
  const dispatchFecthTemplateDetail = async (id) => {
    if (instansiTemplateWa.templateDetailStatus === 'idle') {
      await dispatch(fetchTemplateDetail(id));
      if (instansiTemplateWa.templateDetailError) {
        toast.error(instansiTemplateWa.templateDetailError, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
        });
      }
    }
    setShowDetail(true);
  };

  const addData = (title) => {
    setShowAdd(true);
    if(titleModal !== title){
      setDataSave(defaultStateTemplate)
      setTemplateId('')
      setNextStepAdd(false);
    }
    setTitleModal(title)
  };

  const backadd = () => {
    setNextStepAdd(false);
  };
  
  const closeAddData = () => {
      setShowAdd(false);
  };

  const closeDetailData = () => {
    dispatch(resetStatusTemplateDetail());
    setShowDetail(false);
  };

  const logData = () => {
    setShowLog(true);
  };

  const closeLogData = () => {
      setShowLog(false);
  };

  const deleteData = (id) => {
    swal({
      title: "Apa Data Ingin Dihapus?",
      text: "Jika data dihapus, data tidak dapat dikembalikan!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: "Batal",
        confirm: {
          text: "Hapus",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true
        }
      }
    })
    .then(async(willDelete) => {
        if (willDelete) {
            const disp = await dispatch(fetchDeleteTemplateWa(id));
            if(disp.payload?.status !== true){
                let message = "Fail - Silakan coba lagi";
                if (disp.payload?.message) {
                    message = disp.payload.message
                }
                toast.error(message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    transition: Bounce,
                });
            } else {
                toast.success('Data Berhasil Dihapus!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    transition: Bounce,
                });
            }
            await dispatch(resetStatusListTemplate());
            dispatchFecthListTemplate();
        }
    })
    .catch((error) => {
        toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
        });
    });
  };

  const nextadd = () => {
    if (dataSave.category) {
      setNextStepAdd(true);
    } else {
      toast.error("Lengkapi Data Terlebih Dahulu!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };
  
  const saveData = async () => {
    const regex = /{{(.*?)}}/g;
    const matches = [];
    let match;
    while ((match = regex.exec(dataSave.body.text)) !== null) {
      matches.push(match[1]);
    }

    let action1 = {};
    if(dataSave.actions[0].url && dataSave.actions[0].text){
        action1 = {
          text: dataSave.actions[0].text,
          url: dataSave.actions[0].url,
        }
    }else if (dataSave.actions[0].text || dataSave.actions[0].url) {
      return toast.error("Lengkapi Data Button 1 Dahulu!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }

    let action2 = {};
    if(dataSave.actions[1].url && dataSave.actions[1].text){
        action2 = {
          text: dataSave.actions[1].text,
          url: dataSave.actions[1].url,
        }
    }else if (dataSave.actions[1].text || dataSave.actions[1].url) {
      return toast.error("Lengkapi Data Button 2 Dahulu!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
    
    let counter = 1;
    let data = JSON.stringify({
      "name": dataSave.name.replace(/\s+/g, '_').toLowerCase(),
      "category": dataSave.category,
      "lang": dataSave.lang,
      "header": dataSave.header,
      "body": {
        "text": dataSave.body.text.replace(/{{\w+}}/g, (match, index) => `{{${counter++}}}`),
        "bindings": matches
      },
      "footer": dataSave.footer,
      "actions": [action1.text ? action1 : null, action2.text ? action2 : null].filter(action => action !== null)
    })

    const param = {
        data: data,
        id: '',
        action: titleModal
    }

    const disp = await dispatch(fetchSaveDataTemplate(param));
    if (disp.payload?.status !== true) {
		let message = "Fail - Silakan coba lagi";
		if (disp.payload?.message) {
			message = disp.payload.message
		}
        toast.error(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
        });
    } else {
        toast.success(disp.payload.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
        });

        await dispatch(resetStatusListTemplate());
        dispatchFecthListTemplate();
        setShowAdd(false);
        setNextStepAdd(false);
        setDataSave(defaultStateTemplate)
    }

  }

  useEffect(() => {
    dispatchFecthListTemplate();
    dispatchFecthListTemplateLog();
    dispatchFecthListTemplateBodyWa();
  }, [instansiTemplateWa]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Template Whatsapp</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/instansi">Instansi</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Template Whatsapp</li>
            </ol>
            <h4 className="main-title mb-0">Daftar Template Whatsapp</h4>
          </div>
          <div className="d-flex align-items-center">
            <Button variant="secondary" className="d-flex align-items-center" onClick={()=>logData()}><i className="ri-time-line"></i>Logs</Button>&nbsp;
            <Button variant="primary" className="d-flex align-items-center" onClick={()=>addData('Tambah')}><i className="ri-add-line"></i>Tambah Template</Button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-one w-100">
              <div className="card-body">
                { instansiTemplateWa.listTemplateResp.data ? <DataTable
                  columns={columns}
                  data={instansiTemplateWa.listTemplateResp.data}
                  pagination
                  progressPending={instansiTemplateWa.listTemplatePending}
                  defaultSortFieldId={1}
                /> : <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/>
                <h5>Data Not Found</h5><p>You can create new template!</p></div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showAdd} onHide={closeAddData} centered size="xl">
        <Modal.Header closeButton>
            <Modal.Title>{titleModal} Template</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => { e.preventDefault(); saveData();}}>
          <Modal.Body>
            <div className="wizard mb-3">
                <div className="steps clearfix">
                    <ul role="tablist" className="justify-content-center">
                        <li role="tab" className="first current" aria-disabled="false" aria-selected="true">
                            <a id="wizard2-t-0" href="#wizard2-h-0" aria-controls="wizard2-p-0">
                                <span className="current-info audible">current step: </span>
                                <span className="number">1</span> 
                                <span className="title">Data Template</span>
                            </a>
                        </li>
                        <li role="tab" className={!nextStepAdd ? "disabled" : "current"} aria-disabled="true">
                            <a id="wizard2-t-1" href="#wizard2-h-1" aria-controls="wizard2-p-1">
                                <span className="number">2</span>
                                <span className="title">Pesan Template</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <Row className={!nextStepAdd ? "" : "d-none"}>
              <Col lg={6}>
                  <Form.Group controlId="formFileSm" className="mb-3">
                      <Form.Label>Kategori</Form.Label>
                      <Select options={selectOptionskategori} isSearchable={false} onChange={(selectedOption)=>setDataSave(prevState => ({...prevState,category: selectedOption ? selectedOption.value : ''}))} value={{ value: dataSave.category ? dataSave.category : '', label: dataSave.category ? selectOptionskategori.find(option => option.value === dataSave.category).label : 'Select Category'}}/>
                  </Form.Group>
              </Col>
              <Col lg={6}>
                  <Form.Group controlId="formFileSm" className="mb-3">
                      <Form.Label>Bahasa</Form.Label>
                      <Select options={selectOptionslanguage} isSearchable={false} onChange={(selectedOption)=>setDataSave(prevState => ({...prevState,lang: selectedOption ? selectedOption.value : ''}))} value={{ value: dataSave.lang ? dataSave.lang : '', label: dataSave.lang ? selectOptionslanguage.find(option => option.value === dataSave.lang).label : 'Select Language'}}/>
                  </Form.Group>
              </Col>
            </Row>

            <Row className={!nextStepAdd ? "d-none" : ""}>
              <Col lg="4">
                <h6>Preview Isi Pesan</h6>
                <div className="card card-task chat-whatsapp-bg" style={{ height: `calc(100vh - 325px)` }}>
                  <div className="card-body p-3 pb-3">
                    <div className="right-wa">
                      <div className="d-flex align-items-center mb-3">
                        <h6 className="card-title">{dataSave.header}</h6>
                      </div>
                      <p className="fs-sm">{dataSave.body.text}</p>
                      <small><i>{dataSave.footer}</i></small>
                    </div>
                    {dataSave.actions[0].text ? <Button className="btn btn-light btn-sm w-100 mt-1" variant="light">{dataSave.actions[0].text ? dataSave.actions[0].text : 'Button1'}</Button> : ""}
                    {dataSave.actions[1].text ? <Button className="btn btn-light btn-sm w-100 mt-1" variant="light">{dataSave.actions[1].text ? dataSave.actions[1].text : 'Button2'}</Button> : ""}
                  </div>
                </div>
              </Col>
              <Col lg="8">
                <h6>Pesan Template</h6>
                <Row style={{ height: `calc(100vh - 325px)`, overflowY: 'scroll' }}>
                  <Col lg={6}>
                      <Form.Group controlId="formFileSm" className="mb-3">
                          <Form.Label>Nama Template</Form.Label>
                          <Form.Control type="text" placeholder="Masukan Nama Template" onChange={(e)=>setDataSave(prevState => ({...prevState,name: e ? e.target.value : ''}))} value={dataSave.name}/>
                      </Form.Group>
                  </Col>
                  <Col lg={6}>
                      <Form.Group controlId="formFileSm" className="mb-3">
                          <Form.Label>Header</Form.Label>
                          <Form.Control type="text" placeholder="Masukan Header Text" onChange={(e)=>setDataSave(prevState => ({...prevState,header: e ? e.target.value : ''}))} value={dataSave.header} />
                      </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group controlId="formFileSm" className="mb-3">
                        <Form.Label>Isi Pesan</Form.Label>
                        <ReactTextareaAutocomplete
                          className="my-textarea"
                          onChange={(e)=>setDataSave(prevState => ({
                            ...prevState,
                            body: {
                              ...prevState.body,
                              text: e.target.value
                            }
                          }))} 
                          value={dataSave.body.text}
                          loadingComponent={Loading}
                          style={{
                            fontSize: "18px",
                            lineHeight: "20px",
                            padding: 5
                          }}
                          ref={rta => {
                            rta = rta;
                          }}
                          innerRef={textarea => {
                            textarea = textarea;
                          }}
                          containerStyle={{
                            marginTop: 20,
                            width: 400,
                            height: 100,
                            margin: "20px auto"
                          }}
                          minChar={0}
                          trigger={{
                            "{{": {
                              dataProvider: token => {
                                return instansiTemplateWa.listTemplateBodyWaResp.data.map(({ name, value }) => ({ name, value }));
                              },
                              component: Item,
                              output: (item, trigger) => "{{"+item.value+"}}"
                            }
                          }}
                        />
                        <small>* Dapat menggunakan lebih dari 1 parameter, gunakan &#123;&#123; (kurung kurawal 2 kali) untuk memunculkan parameter pada fitur blasting</small><br/>
                        <small>* Dapat menggunakan fungsi text pada Whatsapp seperti (**) untuk bold, (_ _) untuk italic, dll. </small>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group controlId="formFileSm" className="mb-3">
                        <Form.Label>Footer</Form.Label>
                        <Form.Control type="text" placeholder="Masukan Footer" onChange={(e)=>setDataSave(prevState => ({...prevState,footer: e ? e.target.value : ''}))} 
                          value={dataSave.body.footer} />
                    </Form.Group>
                  </Col>
                  <hr className="mt-2"/>
                  <h6 className="card-title">Button 1</h6>
                  <Col lg={12}>
                    <Row>
                      <Col lg={6}>
                        <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Text Button</Form.Label>
                            <Form.Control type="text" placeholder="Masukan Text Button" value={dataSave.actions[0].text} onChange={(e)=> setDataSave(prevState => ({
                              ...prevState,
                              actions: prevState.actions.map((action, index) => 
                                index === 0 ? { ...action, text: e.target.value } : action
                              )
                            }))}/>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>URL Button</Form.Label>
                            <Form.Control type="text" placeholder="URL Button" value={dataSave.actions[0].url} onChange={(e)=> setDataSave(prevState => ({
                              ...prevState,
                              actions: prevState.actions.map((action, index) => 
                                index === 0 ? { ...action, url: e.target.value } : action
                              )
                            }))}/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <small>* Kosongkan jika tidak menggunakan button</small>
                  </Col>

                  <hr className="mt-2"/>
                  <h6 className="card-title">Button 2</h6>
                  <Col lg={12}>
                    <Row>
                      <Col lg={6}>
                        <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Text Button</Form.Label>
                            <Form.Control type="text" placeholder="Masukan Text Button" value={dataSave.actions[1].text} onChange={(e)=> setDataSave(prevState => ({
                              ...prevState,
                              actions: prevState.actions.map((action, index) => 
                                index === 1 ? { ...action, text: e.target.value } : action
                              )
                            }))}/>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>URL Button</Form.Label>
                            <Form.Control type="text" placeholder="URL Button" value={dataSave.actions[1].url} onChange={(e)=> setDataSave(prevState => ({
                              ...prevState,
                              actions: prevState.actions.map((action, index) => 
                                index === 1 ? { ...action, url: e.target.value } : action
                              )
                            }))}/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <small>* Kosongkan jika tidak menggunakan button</small>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className={!nextStepAdd ? "d-block" : "d-none"}>
              <Button variant="secondary" onClick={closeAddData}>
                  Tutup
              </Button>&nbsp;
              <Button variant="primary" onClick={()=>nextadd()}>
                  Selanjutnya
              </Button>
            </div>

            <div className={!nextStepAdd ? "d-none" : "d-block"}>
              <Button variant="secondary" onClick={closeAddData}>
                  Tutup
              </Button>&nbsp;
              <Button variant="danger" onClick={backadd}>
                  Sebelumnya
              </Button>&nbsp;
              <Button variant="primary" type="submit" disabled={instansiTemplateWa.saveDataTemplatePending}>
                  {instansiTemplateWa.saveDataTemplatePending ? 'Silahkan Tunggu ...' : 'Simpan'}
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showDetail} onHide={closeDetailData} centered size="md">
        <Modal.Header closeButton>
            <Modal.Title>Detail Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card card-task chat-whatsapp-bg" style={{ height: `calc(100vh - 325px)` }}>
            <div className="card-body p-3 pb-3">
              <div className="right-wa">
                <div className="d-flex align-items-center mb-3">
                  <h6 className="card-title">{templateDetail.header ? templateDetail.header : ''}</h6>
                </div>
                <p className="fs-sm">{templateDetail.body ? templateDetail.body.text : ''}</p>
                <small><i>{templateDetail.footer ? templateDetail.footer : ''}</i></small>
              </div>
              {templateDetail.actions?.map( (action, i) => (
                <Button className="btn btn-light btn-sm w-100 mt-1" variant="light">
                    {action.text ? action.text : `Button ${i}`}
                </Button>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeDetailData}>
                Tutup
            </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLog} onHide={closeLogData} centered size="xl">
        <Modal.Header closeButton>
            <Modal.Title>Log Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={columnsLog}
            data={instansiTemplateWa.listTemplateLogResp.data}
            pagination
            progressPending={!instansiTemplateWa.listTemplateLogPending}
            defaultSortFieldId={1}
          />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeLogData}>
                Tutup
            </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
