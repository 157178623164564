import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

import ApiUrl from "../../helpers/url";
import { generateConfig } from '../helper/axiosHelper';

export const fetchListWorkspace = createAsyncThunk(
  'adminRuangKerja/fetchListWorkspace',
  async (_, { rejectWithValue }) => {
    const url = ApiUrl.get_workspace.url;
    const method = ApiUrl.get_workspace.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fecthListInstitusi = createAsyncThunk(
  'adminRuangKerja/fetchListInstitusi',
  async (_, { rejectWithValue }) => {
    const url = ApiUrl.get_institution.url;
    const method = ApiUrl.get_institution.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchWorkspaceById = createAsyncThunk(
  'adminRuangKerja/fetchWorkspaceById',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.detail_workspace.url}${id}`;
    const method = ApiUrl.detail_workspace.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSaveDataModal = createAsyncThunk(
  'adminRuangKerja/fetchSaveDataModal',
  async (param, { rejectWithValue }) => {
    const {data, dataId, action} = param;

    const url = action === 'Tambah' ? ApiUrl.create_workspace.url : `${ApiUrl.update_workspace.url}${dataId}`;
    const method = action === 'Tambah' ? ApiUrl.create_workspace.method : ApiUrl.update_workspace.method;
    try {
      const response = await axios.request(generateConfig(url, method, data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeleteById = await createAsyncThunk(
  'adminRuangKerja/fetchDeleteById',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.delete_workspace.url}${id}`;
    const method = ApiUrl.delete_workspace.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const adminRuangKerjaSlice = createSlice({
  name: 'adminRuangKerja',
  initialState: {
    listWorkspaceResp: [],
    listWorkspaceStatus: 'idle',
    listWorkspaceError: null,
    listWorkspacePending: true,

    listInstitusiResp: [],
    listInstitusiStatus: 'idle',
    listInstitusiError: null,
    listInstitusiPending: true,

    workspaceByIdResp: [],
    workspaceByIdStatus: 'idle',
    workspaceByIdError: null,
    workspaceByIdPending: true,

    saveDataModalResp: [],
    saveDataModalStatus: 'idle',
    saveDataModalError: null,
    saveDataModalPending: true,

    deleteByIdResp: [],
    deleteByIdStatus: 'idle',
    deleteByIdError: null,
    deleteByIdPending: true,
  },
  reducers: {
    resetStatus: (state) => {
      state.workspaceByIdStatus = 'idle';
    },
    changeDataModalWorkspace: (state, action) => {
      if(state.workspaceByIdResp.data === undefined){
        state.workspaceByIdResp = {data:{}}; 
      }
      const key = action.payload.key;
      const value = action.payload.value;
      state.workspaceByIdResp.data[key] = value;
    },
    resetDataModalWorkspace:  (state) => {
      state.workspaceByIdResp = {data:{}}; 
    },
    resetStatusList: (state) => {
      state.listWorkspaceStatus = 'idle';
      state.listWorkspacePending = true;
      state.deleteByIdPending = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchListWorkspace
      .addCase(fetchListWorkspace.pending, (state) => {
        state.listWorkspaceStatus = 'loading';
      })
      .addCase(fetchListWorkspace.fulfilled, (state, action) => {
        state.listWorkspaceStatus = 'succeeded';
        state.listWorkspaceResp = action.payload;
        state.listWorkspacePending = false;
      })
      .addCase(fetchListWorkspace.rejected, (state, action) => {
        state.listWorkspaceStatus = 'failed';
        state.listWorkspaceError = action.payload.message;
      })

      // fecthListInstitusi
      .addCase(fecthListInstitusi.pending, (state) => {
        state.listInstitusiStatus = 'loading';
      })
      .addCase(fecthListInstitusi.fulfilled, (state, action) => {
        state.listInstitusiStatus = 'succeeded';
        const formattedData = action.payload.data.map(val => ({
            label: val.name,
            value: val.id
        }));
        state.listInstitusiResp = formattedData;
        state.listInstitusiPending = false;
      })
      .addCase(fecthListInstitusi.rejected, (state, action) => {
        state.listInstitusiStatus = 'failed';
        state.listInstitusiError = action.payload?.message;
      })

      // fetchWorkspaceById
      .addCase(fetchWorkspaceById.pending, (state) => {
        state.workspaceByIdStatus = 'loading';
      })
      .addCase(fetchWorkspaceById.fulfilled, (state, action) => {
        state.workspaceByIdStatus = 'succeeded';
        state.workspaceByIdResp = action.payload;
        state.workspaceByIdResp.data['institution_id'] = action.payload.data.institution.id;
        state.workspaceByIdPending = false;
      })
      .addCase(fetchWorkspaceById.rejected, (state, action) => {
        state.workspaceByIdStatus = 'failed';
        state.workspaceByIdError = action.payload.message;
      })

      // fetchSaveDataModal
      .addCase(fetchSaveDataModal.pending, (state) => {
        state.saveDataModalStatus = 'loading';
      })
      .addCase(fetchSaveDataModal.fulfilled, (state, action) => {
        state.saveDataModalStatus = 'succeeded';
        state.byIdResp = action.payload;
        state.byIdPending = false;
      })
      .addCase(fetchSaveDataModal.rejected, (state, action) => {
        state.saveDataModalStatus = 'failed';
        state.saveDataModalError = action.payload.message;
      })

      // fetchDeleteById
      .addCase(fetchDeleteById.pending, (state) => {
        state.deleteByIdStatus = 'loading';
      })
      .addCase(fetchDeleteById.fulfilled, (state, action) => {
        state.deleteByIdStatus = 'succeeded';
        state.deleteByIdResp = action.payload;
        state.deleteByIdPending = false;
      })
      .addCase(fetchDeleteById.rejected, (state, action) => {
        state.deleteByIdStatus = 'failed';
        state.deleteByIdError = action.payload.message;
      })
  },
})

export const { resetStatus, resetStatusList, changeDataModalWorkspace, resetDataModalWorkspace } = adminRuangKerjaSlice.actions;
export default adminRuangKerjaSlice.reducer;