import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Dropdown, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import DataTable from "react-data-table-component";
import Select from "react-select";
import axios from "axios";
import swal from "sweetalert";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ApiUrl from "../../../../helpers/url";
import {decodeToken} from "../../../../helpers/jwtToken";
import { convertToGMT7 } from "../../../../helpers/timeConvert";
import { useSelector, useDispatch } from "react-redux";
import { fetchDataProgramByWorkspace } from "../../../../redux/slices/adminInstansiDetailSlice";

export default function Laporan({ selectedWorkspace, segment, pasienData }) {
  const [load, setLoad] = useState(true);
  const [dataReport, setDataReport] = useState([]);
  const [dataReportFill, setDataReportFill] = useState([]);
  const [dataProgram, setDataProgram] = useState([]);
  const [programId, setProgramId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [formComment, setFormComment] = useState("");
  const [formCommentId, setFormCommentId] = useState("");
  const [selectedStatusReport, setSelectedStatusReport] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [dataModal, setDataModal] = useState({ name: "", phone: "", id: "", report_id: "" });
  const [dataModalProgram, setDataModalProgram] = useState({ id: "", name: "" });
  const [readComments, setReadComments] = useState({});
  const [dataReportAnswer, setDataReportAnswer] = useState([]);
  const [saveFormLoadComment, setSaveFormLoadComment] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminInstansiDetail = useSelector((state) => state.adminInstansiDetail);
  const columns = [
    {
      name: "Pasien",
      selector: (row) => row.patient_program.patient.id,
      cell: (row) => (
        <>
          <div className="flex-direction-column">
            <h6 className="fs-xs mt-2 mb-0">{row.patient_program.patient.mnr}</h6>
            <p className="fs-xs text-secondary d-flex align-items-center">
              {row.patient_program.patient.name}
            </p>
          </div>
        </>
      ),
    },
    {
      name: "Program",
      selector: (row) => row.patient_program.program.name,
    },
    {
      name: "Waktu Pendaftaran",
      selector: (row) => convertToGMT7(row.patient_program.registered_at),
    },
    {
      name: "Waktu Laporan",
      selector: (row) => row.submit_time ? convertToGMT7(row.submit_time) : "-",
      cell: (row) => (
        <>
          <div className="flex-direction-column">
            <span className={`badge text-center bg-${getStatusColor(row.status).color} d-flex align-items-center justify-content-center mb-0`}>
              <i className={`${getStatusColor(row.status).icon}`}></i>{" "} {row.status}
            </span>
            <p className="fs-xs text-secondary d-flex align-items-center mb-0">{row.submit_time ? convertToGMT7(row.submit_time) : "-"}</p>
            </div>
        </>
      )
    },
    {
      name: "Komentar",
      selector: (row) => row.komentar,
      cell: (row) => (
        <>
          <Button variant="primary" className="d-flex align-items-center p-relative" onClick={() => openModal(row)}>
            <i className="ri-chat-3-line"></i> {row?.unread_comments?.length > 0 && !readComments[row.id] ? <small className="tooltip-red"></small> : <></>}
          </Button>
        </>
      ),
    },
  ];
  
  const statusReport = [{label: 'Belum Selesai', value: 'SUBMITTED'}, {label:'Sudah Selesai', value:'DONE'}]

  const dispatchGetDataProgram = async () => {
    if (adminInstansiDetail.dataProgramStatus === "idle") {
      dispatch(fetchDataProgramByWorkspace(selectedWorkspace));
    }

    if (adminInstansiDetail.dataProgramStatus === "failed") {
      toast.error(adminInstansiDetail.dataProgramError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const getStatusColor = (stat) => {
    if (stat == "PENDING") {
      return { color: "warning", icon: "ri-time-line" };
    } else if (stat == "SUBMITTED") {
      return { color: "info", icon: "ri-record-circle-fill" };
    } else {
      return { color: "success", icon: "ri-shield-check-fill" };
    }
  };

  const selectOptionsstatus = [
    { value: "", label: "Semua Status" },
    { value: "1", label: "Pending" },
    { value: "2", label: "Normal" },
    { value: "3", label: "Terselesaikan" },
  ];

  const openModal = async(row) => {
    setReadComments((prevState) => ({
      ...prevState,
      [row.id]: true,
    }));
    setDataModal({
      name: row.patient_program.patient.name,
      phone: row.patient_program.patient.phone,
      id: row.patient_program.patient.id,
      report_id: row.id
    });
    setDataModalProgram({
      id: row.patient_program.program.id,
      name: row.patient_program.program.name
    })
    setSelectedStatusReport(row.status);
    setShowModal(true);
    await getReportDetail(row.id);
    if(row?.unread_comments?.length > 0 && !readComments[row.id]){
      await readNotification(row.id, row.unread_comments)
    }
  };

  const closeModal = () => {
    setReadComments([]);
    setSelectedStatusReport('');
    setShowModal(false);
  };

  const getReportByWorkspace = async (workspace, statusFill, programFill) => {
    setLoad(true);
    const program = programFill ? "&program_id=" + programFill : "";
    const status = statusFill ? "&status=" + statusFill : "";
    const pasien = pasienData ? "&patient_id=" + pasienData.id : "";
    let config = {
      method: ApiUrl.list_report.method,
      maxBodyLength: Infinity,
      url:ApiUrl.list_report.url +"?workspace_id=" +workspace +program +status+pasien,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setLoad(false);
        if (response.data.status) {
          setDataReport(response.data.data);
          setDataReportFill(response.data.data);
        } else {
          setDataReport([]);
          setDataReportFill([]);
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
        }
      })
      .catch((error) => {
        setLoad(false);
        setDataReport([]);
        setDataReportFill([]);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const getReportDetail = async(id) => {
    const reportsData = [];
    try {
      const [fetchReportResponse, fetchCommentResponse] = await Promise.all([
          fetch(`${ApiUrl.list_report.url}/${id}`, {
              headers: {
                  'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
                  'Content-Type': 'application/json'
              }
          }),
          fetch(`${ApiUrl.list_report.url}/${id}/comments`, {
              headers: {
                  'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
                  'Content-Type': 'application/json'
              }
          })
      ]);

      if (!fetchReportResponse.ok || !fetchCommentResponse.ok) {
          toast.error('Data Report or Comment Not Found!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
          });
      }

      const reportData = await fetchReportResponse.json();
      const commentData = await fetchCommentResponse.json();

      reportsData.push({
          id: id,
          data: reportData.data,
          comment: commentData.data
      });
      setDataReportAnswer(reportsData);
  } catch (error) {
      toast.error('Data Report or Comment Not Found', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
      });
    }
  }

  const readNotification = async (id, data) => {
    let config = {
      method: ApiUrl.update_list_report.method,
      maxBodyLength: Infinity,
      url:ApiUrl.update_list_report.url +"/"+id+"/notifications",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      data: {"ids":data}
    };

    await axios
      .request(config)
      .then((response) => {
        if (response.data.status) {
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const editComment = (comment = null) => {
    setFormComment(comment?.comment ? comment.comment : '')
    setFormCommentId(comment?.id ? comment.id : '')
  }

  const deleteComment = async(id, comment_id) => {
    swal({
      title: "Peringatan?",
      text: "Apa anda ingin menghapus komentar ini?",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: "Batal",
        confirm: {
          text: "Hapus",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
    }).then(async(willChange) => {
      if (willChange) {
        let config = {
            method: ApiUrl.patient_delete.method,
            maxBodyLength: Infinity,
            url: ApiUrl.list_report.url+"/"+id+"/comments/"+comment_id,
            headers: { 
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
        };
    
        await axios.request(config)
        .then((response) => {
            if(response.data.status){
              setDataReportAnswer(prevDataReportAnswer => {
                return prevDataReportAnswer.map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            comment: item.comment.filter(comment => comment.id !== comment_id)
                        };
                    }
                    return item;
                });
              });

              toast.success(response.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "light",
                  transition: Bounce,
              });
            }else{
              toast.error(response.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "light",
                  transition: Bounce,
              });
            }
        })
        .catch((error) => {
            toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
          });
        })
      }
    });
  }

  const saveFormComment = async (id) => {
    if (saveFormLoadComment) {
        return;
    }

    setSaveFormLoadComment(true);

    const method = formCommentId ? 'PUT' : 'POST';
    const url = formCommentId ? `${ApiUrl.list_report.url}/${id}/comments/${formCommentId}` : `${ApiUrl.list_report.url}/${id}/comments/`
    let config = {
        method: method,
        maxBodyLength: Infinity,
        url: url,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
        data: { 'comment': formComment }
    };

    try {
        const response = await axios.request(config);
        if (response.data.status) {
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });

            setDataReportAnswer(prevState => {
                const updatedDataReportAnswer = [...prevState];
                const dataIndex = updatedDataReportAnswer.findIndex(item => item.data.id === id);
                if (dataIndex !== -1) {
                    if (formCommentId) {
                        const commentIndex = updatedDataReportAnswer[dataIndex].comment.findIndex(comment => comment.id === formCommentId);
                        if (commentIndex !== -1) {
                            updatedDataReportAnswer[dataIndex].comment[commentIndex].comment = formComment;
                        }
                    } else {
                      if (updatedDataReportAnswer[dataIndex].comment && Array.isArray(updatedDataReportAnswer[dataIndex].comment)) {
                          updatedDataReportAnswer[dataIndex].comment.unshift(response.data.data);
                      } else {
                          updatedDataReportAnswer[dataIndex].comment = [response.data.data];
                      }
                    }
                }
                return updatedDataReportAnswer;
            });

            setFormComment('');
            setFormCommentId('');
        } else {
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
        }
    } catch (error) {
        toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
        });
    } finally {
        setSaveFormLoadComment(false)
    }
  }

  const markDoneUnDone = async(value, id) =>{
    if(selectedStatusReport !== value){
      swal({
        title: "Peringatan?",
        text: "Apa anda ingin mengubah status report ini?",
        dangerMode: true,
        buttons: {
          cancel: "Batal",
          confirm: {
            text: "Ya, Ubah Status",
            value: true,
            visible: true,
            className: "btn-danger",
            closeModal: true,
          },
        },
      }).then(async(willChange) => {
        if (willChange) {
          const method = 'PUT';
          const url = value == 'DONE' ? `${ApiUrl.list_report.url}/${id}/done` : `${ApiUrl.list_report.url}/${id}/undone`
          let config = {
              method: method,
              maxBodyLength: Infinity,
              url: url,
              headers: { 
                  'Authorization': `Bearer ${localStorage.getItem('access_token')}`
              },
          };

          try {
              const response = await axios.request(config);
              if (response.data.status) {
                  toast.success(response.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      theme: "light",
                      transition: Bounce,
                  });
                  setDataReportAnswer(prevState => {
                    return prevState.map(report => {
                        if (report.id === id) {
                            report.data.status = value;
                        }
                        return report;
                    });
                  });

                  getReportByWorkspace(selectedWorkspace, statusId, programId)
                  setSelectedStatusReport(value);
              } else {
                  toast.error(response.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      theme: "light",
                      transition: Bounce,
                  });
              }
          } catch (error) {
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
          }
        }
      })
    }
  }

  useEffect(() => {
    getReportByWorkspace(selectedWorkspace, statusId, programId);
    dispatchGetDataProgram();

    if (adminInstansiDetail.dataProgramStatus === "succeeded") {
      if(adminInstansiDetail.dataProgramResp.data) {
        var transformedData = [
          { value: "", label: "Semua Program" },
          ...adminInstansiDetail.dataProgramResp?.data?.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        ];
      }else{
        var transformedData = [
          { value: "", label: "Semua Program" }
        ];
      }
      setDataProgram(transformedData);
    }
  }, [selectedWorkspace, adminInstansiDetail]);

  return (
    <React.Fragment>
        <Row>
            <Col lg="6">
            <Form.Group controlId="formFileSm" className="mb-3">
                <Form.Label>Status Pasien</Form.Label>
                <Select
                options={selectOptionsstatus}
                isSearchable={false}
                onChange={(e) => {
                    setStatusId(e.value);
                    getReportByWorkspace(
                    selectedWorkspace,
                    e.value,
                    programId
                    );
                }}
                value={{
                    value: statusId ? statusId : "",
                    label: statusId
                    ? selectOptionsstatus.find(
                        (option) => option.value === statusId
                        ).label
                    : "Select Status",
                }}
                />
            </Form.Group>
            </Col>
            <Col lg="6">
            <Form.Group controlId="formFileSm" className="mb-3">
                <Form.Label>Program</Form.Label>
                <Select
                options={dataProgram}
                isSearchable={false}
                onChange={(e) => {
                    setProgramId(e.value);
                    getReportByWorkspace(
                    selectedWorkspace,
                    statusId,
                    e.value
                    );
                }}
                value={{
                    value: programId ? programId : "",
                    label: programId
                    ? dataProgram.find(
                        (option) => option.value === programId
                        ).label
                    : "Select Program",
                }}
                />
            </Form.Group>
            </Col>
        </Row>
        {!load ? (
            dataReport.length > 0 ? (
            <DataTable
                columns={columns}
                data={dataReportFill}
                pagination
                progressPending={false}
                defaultSortFieldId={1}
            />
            ) : (
            <div className="text-center">
                <img src={"/favicon.png"} width="100px" alt={"Icon"} />
                <h5>Data Not Found</h5>
                <div>Assign Patient to Program!</div>
            </div>
            )
        ) : (
            <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
            <Skeleton count={1} style={{ height: "200px" }} />
            </SkeletonTheme>
        )}

      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{dataModal.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div>
              <div className="fs-xs text-secondary d-flex align-items-center mb-0">
                <i className="ri-whatsapp-line text-success"></i> {dataModal.phone}
              </div>
              <div className="d-flex align-items-center mb-0">
                <i className="ri-stethoscope-line text-primary"></i> {dataModalProgram.name}
              </div>
            </div>
          </div>
          {selectedStatusReport !== 'PENDING' ? 
          <Select
            options={statusReport}
            className="mb-2"
            onChange={(selectedOption)=> {markDoneUnDone(selectedOption.value, dataModal.report_id)}} value={{ value: selectedStatusReport ? selectedStatusReport : '', label: selectedStatusReport ? statusReport.find(option => option.value === selectedStatusReport).label : 'Select Status Report'}}
          /> : <></>}
          <div className="report-detail">
            <div className="border-grey mb-2">
            {dataReportAnswer.length < 1 ? <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/><h5>Data Not Found</h5></div> 
              : dataReportAnswer.map((item, index) => (
                  <div key={index}>
                      {item?.data?.status ?
                      <div>
                          <div className="d-flex justify-content-between">
                              <h6 className="d-flex align-items-center mb-0">{item.data.submit_time ? convertToGMT7(item.data.submit_time) : 'Time Not Found '} &nbsp;<span className={`badge text-center bg-${getStatusColor(item.data.status).color} d-flex align-items-center justify-content-center mb-0`}><i className={`${getStatusColor(item.data.status).icon}`}></i>{" "} {item.data.status}</span></h6>
                          </div>
                          <div className="border-primary mt-2">
                              <p className="text-muted d-flex align-items-center"><i className="ri-survey-line text-primary"></i> Laporan</p>  
                              {item.data.answers.length > 0 ? item.data.answers.map((item2, index2) => (
                                  <div key={index2} className={`d-flex justify-content-between align-items-center ${index2 !== item.data.answers.length - 1 ? 'border-bottom' : ''}`}>
                                      <h6 className="mt-2 fs-xs">{item2.question.value}</h6>
                                      {item2.value.startsWith('data:image/png;base64,') ? (
                                          <img src={item2.value} alt="base64 image" style={{ width: "70px", borderRadius: "10px", marginTop: 5, marginBottom: 5 }}/>
                                      ) : (
                                          <h6 className="fs-xs text-secondary mb-0">{item2.value}</h6>
                                      )}
                                  </div>
                              )) : <div className="text-center"> <img src={"/favicon.png"} width="70px" alt={"Icon"}/><h5>Report Not Found</h5></div>}
                          </div>
                          <p className="text-muted mt-1">Komentar <span className="badge bg-primary badge-pill">{item.comment ? item.comment.length : 0}</span></p>
                          {item.comment && item.comment.map((itemcomment, indexcomment) => (
                          <div key={indexcomment} className={'border-bottom'}>
                            <div className="post-header mb-2 mt-2">
                                <div className="post-content">
                                    <h6>{itemcomment.creator_name}</h6>
                                </div>
                                <span className="post-date fs-xs text-secondary d-flex align-items-center">{itemcomment.created_time ? convertToGMT7(itemcomment.created_time) : "Time Not Found"} &nbsp;
                                  {decodeToken(`Bearer ${localStorage.getItem('access_token')}`).UNIQUE_ID == itemcomment.creator_id ?
                                  <Dropdown className="btn-sm">
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-sm">
                                    <i className="ri-more-2-fill"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item href="#" onClick={()=>editComment(itemcomment)}>
                                          Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item href="#" onClick={()=>deleteComment(item.data.id, itemcomment.id)}>
                                          Hapus
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown> : <></>}
                                </span>
                            </div>
                            <p className="fs-xs text-secondary">{itemcomment.comment}</p>
                          </div>))}
                          <div className="d-flex align-items-center justify-content-between mb-2 mt-2">
                            <div>Tambah Komentar</div>
                          </div>
                          <Form onSubmit={(e) => { e.preventDefault(); saveFormComment(item.data.id);}}>
                            <Form.Control
                              as="textarea"
                              className="mb-2"
                              rows="3"
                              placeholder="Masukan Komentar ..."
                              onChange={(e)=> setFormComment(e.target.value)}
                              value={formComment}
                              required={true}
                            ></Form.Control>
                            <div className="d-flex align-items-center justify-content-between">
                              <div></div>
                              <div className="d-flex align-items-center">
                              {formCommentId ?
                                <Button variant="danger" type="button" onClick={()=>editComment()}>
                                  Batal Edit
                                </Button> : <></>}
                                &nbsp;<Button variant="primary" type="submit" disabled={saveFormLoadComment}>
                                  {saveFormLoadComment
                                    ? "Silahkan Tunggu ..."
                                    : "Kirim Komentar"}
                                </Button>
                              </div>
                            </div>
                          </Form>
                      </div> : <></>}
                  </div>))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}