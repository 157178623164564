import React, {useState} from 'react';
import ReactECharts from 'echarts-for-react';

const Line = (props) => {
    const [axis, setAxis] = useState(props.axis);
    const [category, setCategory] = useState(props.category);
    const [data, setData] = useState(props.data);
    const option = {
        legend: {
            data: category,
            bottom: 0
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
        },
        xAxis: {
            type: 'category',
            data: axis
        },
        yAxis: {
            type: 'value'
        },
        grid: {
            top: '10%',
            bottom:'15%',
            left: '5%',
            right: '2%'
        },
        series: data
    }; 

    return (
        <ReactECharts option={option} />
    )
} 
export default Line;