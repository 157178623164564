import React, { useEffect, useState } from "react";
import axios from "axios";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Helmet from 'react-helmet';
import Prism from "prismjs";
import {Pie} from "../../components/chart/index";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchListBanner,
} from "../../redux/slices/instansiBannerSlice";
import {
  fetchListDashboard,
} from "../../redux/slices/dokterDashboardSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ApiUrl from "../../helpers/url.js";
import { convertToGMT7 } from "../../helpers/timeConvert";

export default function Dashboard({selectedWorkspace}) {
  const [category] = useState(['Pasien Patuh', 'Pasien Tidak Patuh']);
  const [radius] = useState(['40%', '70%']);
  const [color] = useState(['#FF1F62', '#009EB3']);
  const [reportWorkspace, setReportWorkspace] = useState([]);
  const [loadReport, setLoadReport] = useState(true);

  const dispatch = useDispatch();
  const instansiBanner = useSelector((state) => state.instansiBanner);
  const dokterDashboard = useSelector((state) => state.dokterDashboard);
  const [data] = useState([
    { value: dokterDashboard.listDashboardResp?.data ? dokterDashboard.listDashboardResp?.data?.patient_not_report : 0, name: 'Pasien Tidak Patuh' },
    { value: dokterDashboard.listDashboardResp?.data ? dokterDashboard.listDashboardResp?.data?.patient_report : 0, name: 'Pasien Patuh' },
  ]);

  const dispatchFecthList = () => {
    if (instansiBanner.listBannerStatus === "idle") {
      dispatch(fetchListBanner());
    }

    if (dokterDashboard.listDashboardStatus === "idle") {
      dispatch(fetchListDashboard());
    }

    if (instansiBanner.listBannerStatus === "failed") {
      toast.error(instansiBanner.listBannerError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }

    if (dokterDashboard.listBannerStatus === "failed") {
      toast.error(dokterDashboard.listBannerError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const getReportWorkspace = async(workspace) => {
    setLoadReport(true);
    let config = {
        method: ApiUrl.list_report.method,
        maxBodyLength: Infinity,
        url: ApiUrl.list_report.url+"?workspace_id="+workspace+"&status=1&limit=5",
        headers: { 
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    await axios.request(config)
    .then((response) => {
        setLoadReport(false)
        if(response.data.status){
            setReportWorkspace(response.data.data);
        }else{
            setReportWorkspace([]);
        }
    })
    .catch((error) => {
        setLoadReport(false)
        setReportWorkspace([]);
    })
  }

  useEffect(() => {
    dispatchFecthList();
    Prism.highlightAll();
  }, [instansiBanner, dokterDashboard]);

  useEffect(() => {
    getReportWorkspace(selectedWorkspace);
  }, [selectedWorkspace]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Dashboard</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Selamat Datang, Dokter!</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-sm-12 col-12 mb-3">
            <Carousel>
              { instansiBanner.listBannerStatus === "succeeded" ? (
                instansiBanner.listBannerResp ? instansiBanner.listBannerResp.data.map(
                  (item, index) => (
                    <Carousel.Item className="h-265px">
                      <img
                        className="d-block w-100 border-radius-10 h-265px"
                        src={item.path ? "https://ahc.brainsia.id/" + item.path : "https://placehold.co/750x300.jpg"}
                        alt={item.title}
                      />
                    </Carousel.Item>  ),
                    ) : <img
                      className="d-block w-100 border-radius-10 h-300px"
                      src={"https://placehold.co/750x300.jpg"}
                      alt="Slider"
                    />
                  ) : (
                    <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                      <Skeleton
                        count={1}
                        style={{ height: "300px" }}
                      />
                    </SkeletonTheme>
                  )}
            </Carousel>
          </div>
          <div className="col-lg-4 col-sm-12 col-12">
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-12 mb-3">
                <div className="card card-one">
                  <div className="card-body">
                    <div className="card-icon-dashboard-dokter">
                      <div className="d-flex align-items-center justify-content-center">
                        <i className="ri-user-heart-line icon fourth"></i>
                        <h3 className="card-value mb-0"> {dokterDashboard.listDashboardResp?.data
                        ? dokterDashboard.listDashboardResp?.data
                            ?.total_patient : 0}</h3>
                      </div>
                      <Link className="text-muted text-center w-100 d-block" to="/dokter/pasien/aktif"><span className="d-inline-flex fs-12">Pasien Aktif <i className="ri-arrow-right-circle-line text-primary"></i></span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-12 mb-3">
                <div className="card card-one">
                  <div className="card-body">
                    <div className="card-icon-dashboard-dokter">
                      <div className="d-flex align-items-center">
                        <i className="ri-wechat-line icon third"></i>
                        <h3 className="card-value mb-0"> {dokterDashboard.listDashboardResp?.data
                        ? dokterDashboard.listDashboardResp?.data
                            ?.unread_comment : 0}</h3>
                      </div>
                      <Link className="text-muted" to="/dokter/laporan"><span className="d-inline-flex fs-12">Komentar Belum Dibaca <i className="ri-arrow-right-circle-line text-primary"></i></span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-12 mb-3">
                <div className="card card-one">
                  <div className="card-body">
                    <div className="card-icon-dashboard-dokter">
                      <div className="d-flex align-items-center">
                        <i className="ri-emotion-sad-line icon second"></i>
                        <h3 className="card-value mb-0"> {dokterDashboard.listDashboardResp?.data
                        ? dokterDashboard.listDashboardResp?.data
                            ?.patient_not_reported : 0}</h3>
                      </div>
                      <Link className="text-muted" to="/dokter/laporan"><span className="d-inline-flex fs-12">Pasien Tidak Patuh <i className="ri-arrow-right-circle-line text-primary"></i></span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-8 col-12 mb-2">
            <div className="card card-one">
              <div className="card-header">
                <h6 className="card-title d-flex align-items-center"><i className="ri-emotion-sad-line text-warning"></i>&nbsp; Daftar Pasien Tidak Patuh</h6>
              </div>
              <div className={reportWorkspace[0] ? "card-body" : "card-body d-flex align-items-center justify-content-center"}>
                <ul className="people-group">
                  {!loadReport ? reportWorkspace.length > 0 ? reportWorkspace.map(
                    (item, index) => (
                      <li className="people-item">
                        <div className="people-body">
                          <h6 className="fw-bold">{item.patient_program.patient.name}</h6>
                          <span className="fs-xs text-secondary d-flex align-items-center"><i className="ri-whatsapp-line text-success"></i> {item.patient_program.patient.phone} - {item.patient_program.program.name}</span>
                        </div>
                        <div className="text-end">
                          <div className="fs-sm">{convertToGMT7(item.patient_program.registered_at)}</div>
                        </div>
                      </li>
                      ),
                    ) : <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/>
                    <h5>Data Not Found</h5></div>
                    : (
                    <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                      <Skeleton
                        count={5}
                        style={{ height: "50px" }}
                        className="mb-1"
                      />
                    </SkeletonTheme>
                  )}
                </ul>
              </div>
              <div className="card-footer d-flex justify-content-center">
                <Link to="/dokter/laporan" className="fs-sm">Lihat Selengkapnya</Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb-2">
            <div className="card card-one">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h6 className="card-title d-flex align-items-center"><i className="ri-pie-chart-line text-primary"></i>&nbsp; Grafik Klinis</h6>
              </div>
              <div className="card-body">
                <Pie radius={radius} data={data} category={category} color={color}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
