import {React} from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Helmet from 'react-helmet';
import "../../assets/css/react-datepicker.min.css";

export default function Thanks() {
    return (
    <>
        <Helmet>
            <title>Auto Health Care | Thank You</title>
        </Helmet>
        <div className="row g-0">
            <div className="header">
                <Container>
                <Link to="/report" className="header-logo"><img src="/favicon.png" width="50px"/>Auto Health Care</Link>
                </Container>
            </div>
            <div className="content center-of-screen">
                <Container>
                    <Row className="d-flex justify-content-center mb-4 d-flex">
                        <Col lg={7} md={8} sm={10} xs={12}>
                            <Card className="card-post mb-3 mt-2 w-100">
                                <Card.Body className="text-center">
                                    <img src="/favicon.png" width="150px"/>
                                    <h2 className="error-title text-center">Thank You</h2>
                                    <p className="error-text">Terima kasih telah mengisi formulir ini. Kami berharap dapat terus memberikan layanan terbaik bagi Anda.</p>
                                    <Link to="/report" className="btn btn-primary">
                                    < i className="ri-search-2-line"></i> &nbsp;Find Your Report</Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>
  )
}