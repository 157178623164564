import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

import ApiUrl from "../../helpers/url";
import { generateConfig } from '../helper/axiosHelper';


export const fetchDetailWorkspace = createAsyncThunk(
  'adminRuangKerjaDetail/fetchDetailWorkspace',
  async (id, { rejectWithValue }) => {
    const url = ApiUrl.detail_workspace.url+id;
    const method = ApiUrl.detail_workspace.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDataProgram = createAsyncThunk(
  'adminRuangKerjaDetail/fetchDataProgram',
  async (id, { rejectWithValue }) => {
    const url = ApiUrl.get_program.url+'?workspace_id='+id;
    const method = ApiUrl.get_program.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchDetailProgramModal = createAsyncThunk(
  'adminRuangKerjaDetail/fetchDetailProgramModal',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.detail_program.url}${id}`;
    const method = ApiUrl.detail_program.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSaveDataModalProgram = createAsyncThunk(
  'adminRuangKerjaDetail/fetchSaveDataModalProgram',
  async (param, { rejectWithValue }) => {
    const {data, id, action} = param;

    const url = action === 'Tambah' ? ApiUrl.create_program.url : `${ApiUrl.update_program.url}${id}`;
    const method = action === 'Tambah' ? ApiUrl.create_program.method : ApiUrl.update_program.method;
    try {
      const response = await axios.request(generateConfig(url, method, data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeleteProgram = await createAsyncThunk(
  'adminRuangKerjaDetail/fetchDeleteProgram',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.delete_program.url}${id}`;
    const method = ApiUrl.delete_program.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  detailWorkspaceResp: [],
  detailWorkspaceStatus: 'idle',
  detailWorkspaceError: null,
  detailWorkspacePending: true,

  dataProgramResp: [],
  dataProgramStatus: 'idle',
  dataProgramError: null,
  dataProgramPending: true,

  detailProgramModalResp: [],
  detailProgramModalStatus: 'idle',
  detailProgramModalError: null,
  detailProgramModalPending: true,

  saveDataModalProgramResp: [],
  saveDataModalProgramStatus: 'idle',
  saveDataModalProgramError: null,
  saveDataModalProgramPending: false,

  deleteProgramResp: [],
  deleteProgramStatus: 'idle',
  deleteProgramError: null,
  deleteProgramPending: true,
};

const adminRuangKerjaDetailSlice = createSlice({
  name: 'adminRuangKerja',
  initialState: initialState,
  reducers: {
    changeDataModalProgram: (state, action) => {
      if(state.detailProgramModalResp.data === undefined){
        state.detailProgramModalResp = {data:{}}; 
      }
      const key = action.payload.key;
      const value = action.payload.value;
      state.detailProgramModalResp.data[key] = value;
    },
    resetDataModalProgram:  (state) => {
      state.detailProgramModalResp = {data:{}};
      state.detailProgramModalStatus = 'idle';
      state.detailProgramModalError = null;
      state.detailProgramModalPending = true;
    },
    resetStatusListProgram: (state) => {
      state.dataProgramResp = [];
      state.dataProgramStatus = 'idle';
      state.dataProgramError = null;
      state.dataProgramPending = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('RESET_ALL', () => initialState)

      // fetchDetailWorkspace
      .addCase(fetchDetailWorkspace.pending, (state) => {
        state.detailWorkspaceStatus = 'loading';
      })
      .addCase(fetchDetailWorkspace.fulfilled, (state, action) => {
        state.detailWorkspaceStatus = 'succeeded';
        state.detailWorkspaceResp = action.payload;
        state.detailWorkspacePending = false;
      })
      .addCase(fetchDetailWorkspace.rejected, (state, action) => {
        state.detailWorkspaceStatus = 'failed';
        state.detailWorkspaceError = action.payload.message;
      })

      // fetchDataProgram
      .addCase(fetchDataProgram.pending, (state) => {
        state.dataProgramStatus = 'loading';
      })
      .addCase(fetchDataProgram.fulfilled, (state, action) => {
        state.dataProgramStatus = 'succeeded';
        state.dataProgramResp = action.payload;
        state.dataProgramPending = false;
      })
      .addCase(fetchDataProgram.rejected, (state, action) => {
        state.dataProgramStatus = 'failed';
        state.dataProgramError = action.payload.message;
      })

      // fetchDetailProgramModal
      .addCase(fetchDetailProgramModal.pending, (state) => {
        state.detailProgramModalStatus = 'loading';
      })
      .addCase(fetchDetailProgramModal.fulfilled, (state, action) => {
        state.detailProgramModalStatus = 'succeeded';
        state.detailProgramModalResp = action.payload;
        state.detailProgramModalPending = false;
      })
      .addCase(fetchDetailProgramModal.rejected, (state, action) => {
        state.detailProgramModalStatus = 'failed';
        state.detailProgramModalError = action.payload.message;
      })

      // fetchSaveDataModalProgram
      .addCase(fetchSaveDataModalProgram.pending, (state) => {
        state.saveDataModalProgramStatus = 'loading';
        state.saveDataModalProgramPending = true;
      })
      .addCase(fetchSaveDataModalProgram.fulfilled, (state, action) => {
        state.saveDataModalProgramStatus = 'succeeded';
        state.saveDataModalProgramResp = action.payload;
        state.saveDataModalProgramPending = false;
      })
      .addCase(fetchSaveDataModalProgram.rejected, (state, action) => {
        state.saveDataModalProgramStatus = 'failed';
        state.saveDataModalProgramError = action.payload.message;
        state.saveDataModalProgramPending = false;
      })

      // fetchDeleteProgram
      .addCase(fetchDeleteProgram.pending, (state) => {
        state.deleteProgramStatus = 'loading';
      })
      .addCase(fetchDeleteProgram.fulfilled, (state, action) => {
        state.deleteProgramStatus = 'succeeded';
        state.deleteProgramResp = action.payload;
        state.deleteProgramPending = false;
      })
      .addCase(fetchDeleteProgram.rejected, (state, action) => {
        state.deleteProgramStatus = 'failed';
        state.deleteProgramError = action.payload.message;
      })
  },
})

export const { resetStatusListProgram, changeDataModalProgram, resetDataModalProgram } = adminRuangKerjaDetailSlice.actions;
export default adminRuangKerjaDetailSlice.reducer;