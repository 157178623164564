import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { Line } from "../../components/chart/index";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchListDashboard,
  fecthListInstitusiDashboard,
} from "../../redux/slices/adminDashboardSlice";

export default function Dashboard() {
  const [axis, setAxis] = useState([]);
  const [legend, setLegend] = useState([
    "Instansi Aktif",
    "Instansi Non Aktif",
  ]);
  const [data, setData] = useState([
    {
      name: "Instansi Aktif",
      type: "line",
      stack: "Total",
      data: [],
      color: "#15DB84",
    },
    {
      name: "Instansi Non Aktif",
      type: "line",
      stack: "Total",
      data: [],
      color: "#FF1F62",
    },
  ]);

  const dispatch = useDispatch();
  const adminDashboard = useSelector((state) => state.adminDashboard);

  const dispatchFecthGetDashboard = () => {
    if (adminDashboard.listDashboardStatus === "idle") {
      dispatch(fetchListDashboard());
    }

    if (adminDashboard.listDashboardStatus === "failed") {
      toast.error(adminDashboard.listDashboardError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }

    if (adminDashboard.listInstitusiDashboardStatus === "idle") {
      dispatch(fecthListInstitusiDashboard());
    }
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const updateData = (jsonData) => {
    const newAxis = jsonData.monthly_data.map(
      (item) => `${monthNames[item.month - 1]} ${item.year}`,
    );
    const activeInstitutionsData = jsonData.monthly_data.map(
      (item) => item.active_institutions,
    );
    const nonActiveInstitutionsData = jsonData.monthly_data.map(
      (item) => item.non_active_institutions,
    );
    setAxis(newAxis);

    setData((prevData) => [
      {
        ...prevData[0],
        data: activeInstitutionsData,
      },
      {
        ...prevData[1],
        data: nonActiveInstitutionsData,
      },
    ]);
  };

  useEffect(() => {
    dispatchFecthGetDashboard();
    if (adminDashboard.listDashboardStatus === "succeeded") {
      updateData(adminDashboard.listDashboardResp.data);
    }
  }, [adminDashboard]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Dashboard</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Selamat Datang, Super Admin!</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-4 mb-3">
            <div className="card card-one">
              <div className="card-body">
                <div className="d-flex align-items-center card-icon-dashboard">
                  <i className="ri-hospital-line icon first"></i>
                  <div>
                    <label className="card-title fs-sm fw-medium mb-1">
                      Jumlah Instansi
                    </label>
                    <h3 className="card-value mb-0">
                      {" "}
                      {adminDashboard.listDashboardResp?.data
                        ? adminDashboard.listDashboardResp?.data
                            ?.total_institutions
                        : 0}
                    </h3>
                    <Link className="text-muted" to="/admin/instansi">
                      <span className="d-inline-flex fs-12">
                        Lihat Selengkapnya{" "}
                        <i className="text-primary ri-arrow-right-circle-line"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-4 mb-3">
            <div className="card card-one">
              <div className="card-body">
                <div className="d-flex align-items-center card-icon-dashboard">
                  <i className="ri-stethoscope-line icon second"></i>
                  <div>
                    <label className="card-title fs-sm fw-medium mb-1">
                      Jumlah Program
                    </label>
                    <h3 className="card-value mb-0">
                      {" "}
                      {adminDashboard.listDashboardResp?.data
                        ? adminDashboard.listDashboardResp?.data?.total_programs
                        : 0}
                    </h3>
                    <Link
                      className="text-muted"
                      to="/admin/program/ruang-kerja"
                    >
                      <span className="d-inline-flex fs-12">
                        Lihat Selengkapnya{" "}
                        <i className="text-primary ri-arrow-right-circle-line"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-4 mb-3">
            <div className="card card-one">
              <div className="card-body">
                <div className="d-flex align-items-center card-icon-dashboard">
                  <i className="ri-newspaper-line icon third"></i>
                  <div>
                    <label className="card-title fs-sm fw-medium mb-1">
                      Jumlah Formulir
                    </label>
                    <h3 className="card-value mb-0">
                      {" "}
                      {adminDashboard.listDashboardResp?.data
                        ? adminDashboard.listDashboardResp?.data?.total_forms
                        : 0}
                    </h3>
                    <Link className="text-muted" to="/admin/program/formulir">
                      <span className="d-inline-flex fs-12">
                        Lihat Selengkapnya{" "}
                        <i className="text-primary ri-arrow-right-circle-line"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-8 col-12 mb-2">
            <div className="card card-one">
              <div className="card-header">
                <h6 className="card-title">Grafik Instansi 2024</h6>
              </div>
              <div className="card-body">
                {axis.length > 0 ? (
                  <Line axis={axis} category={legend} data={data} />
                ) : (
                  <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                    <Skeleton count={1} style={{ height: "250px" }} />
                  </SkeletonTheme>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb-2">
            <div className="card card-one">
              <div className="card-header">
                <h6 className="card-title">Daftar Instansi Terbaru</h6>
              </div>
              <div className="card-body">
                <ul className="people-group">
                  {adminDashboard.listInstitusiDashboardStatus ==
                  "succeeded" ? (
                    adminDashboard.listInstitusiDashboardResp ? 
                    adminDashboard.listInstitusiDashboardResp.data.map(
                      (item, index) => (
                        <li key={index} className="people-item">
                          <div className="people-body d-flex align-items-center mr-1">
                            <img
                              src={item.image ? item.image : "/favicon.png"}
                              className="w-40px"
                              alt={item.name}
                            />
                            <Link to={"/admin/instansi/" + item.id}>
                              <h6 className="fw-bold text-dark">{item.name}</h6>
                              <span className="fs-xs text-secondary d-flex align-items-center">
                                <i className="ri-whatsapp-line text-success"></i>{" "}
                                {item.phone} |{" "}
                                <i className="ri-mail-line text-danger"></i>{" "}
                                {item.email}
                              </span>
                            </Link>
                          </div>
                        </li>
                      ),
                    ) : <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/>
                    <h5>Data Not Found</h5></div>
                  ) : (
                    <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                      <Skeleton
                        count={5}
                        style={{ height: "50px" }}
                        className="mb-1"
                      />
                    </SkeletonTheme>
                  )}
                </ul>
              </div>
              <div className="card-footer d-flex justify-content-center">
                <Link to="/admin/instansi" className="fs-sm">
                  Lihat Selengkapnya
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
