import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

import ApiUrl from "../../helpers/url";
import { generateConfig } from '../helper/axiosHelper';

export const fetchRemainderOption = createAsyncThunk(
  'dokterDashboard/fetchRemainderOption',
  async (_, { rejectWithValue }) => {
    const url = ApiUrl.option_remainder_option.url;
    const method = ApiUrl.option_remainder_option.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOnetimeRemainderCondition = createAsyncThunk(
    'dokterDashboard/fetchOnetimeRemainderCondition',
    async (_, { rejectWithValue }) => {
      const url = ApiUrl.option_onetime_remainder_condition.url;
      const method = ApiUrl.option_onetime_remainder_condition.method;
      try {
        const response = await axios.request(generateConfig(url, method));
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

export const fetchScheduleRemainderCondition = createAsyncThunk(
    'dokterDashboard/fetchScheduleRemainderCondition',
    async (_, { rejectWithValue }) => {
      const url = ApiUrl.option_schedule_remainder_condition.url;
      const method = ApiUrl.option_schedule_remainder_condition.method;
      try {
        const response = await axios.request(generateConfig(url, method));
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

export const fetchRemainderConditionTriggerEvent = createAsyncThunk(
    'dokterDashboard/fetchRemainderConditionTriggerEvent',
    async (_, { rejectWithValue }) => {
      const url = ApiUrl.option_remainder_condition_trigger_event.url;
      const method = ApiUrl.option_remainder_condition_trigger_event.method;
      try {
        const response = await axios.request(generateConfig(url, method));
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

export const fetchDurationOption = createAsyncThunk(
    'dokterDashboard/fetchDurationOption',
    async (_, { rejectWithValue }) => {
      const url = ApiUrl.option_remainder_duration.url;
      const method = ApiUrl.option_remainder_duration.method;
      try {
        const response = await axios.request(generateConfig(url, method));
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

const optionFormSlice = createSlice({
  name: 'optionForm',
  initialState: {
    listRemainderOptionResp: [],
    listRemainderOptionStatus: 'idle',
    listRemainderOptionError: null,
    listRemainderOptionPending: true,

    listOnetimeRemainderConditionResp: [],
    listOnetimeRemainderConditionStatus: 'idle',
    listOnetimeRemainderConditionError: null,
    listOnetimeRemainderConditionPending: true,

    listScheduleRemainderConditionResp: [],
    listScheduleRemainderConditionStatus: 'idle',
    listScheduleRemainderConditionError: null,
    listScheduleRemainderConditionPending: true,

    listRemainderConditionTriggerEventResp: [],
    listRemainderConditionTriggerEventStatus: 'idle',
    listRemainderConditionTriggerEventError: null,
    listRemainderConditionTriggerEventPending: true,

    listDurationOptionResp: [],
    listDurationOptionStatus: 'idle',
    listDurationOptionError: null,
    listDurationOptionPending: true,
  },
  reducers: {
    resetStatusList: (state) => {
        state.listDashboardStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRemainderOption.pending, (state) => {
        state.listRemainderOptionStatus = 'loading';
      })
      .addCase(fetchRemainderOption.fulfilled, (state, action) => {
        state.listRemainderOptionStatus = 'succeeded';
        state.listRemainderOptionResp = action.payload;
        state.listRemainderOptionPending = false;
      })
      .addCase(fetchRemainderOption.rejected, (state, action) => {
        state.listRemainderOptionStatus = 'failed';
        state.listRemainderOptionError = action.payload.message;
      })

      .addCase(fetchOnetimeRemainderCondition.pending, (state) => {
        state.listOnetimeRemainderConditionStatus = 'loading';
      })
      .addCase(fetchOnetimeRemainderCondition.fulfilled, (state, action) => {
        state.listOnetimeRemainderConditionStatus = 'succeeded';
        state.listOnetimeRemainderConditionResp = action.payload;
        state.listOnetimeRemainderConditionPending = false;
      })
      .addCase(fetchOnetimeRemainderCondition.rejected, (state, action) => {
        state.listOnetimeRemainderConditionStatus = 'failed';
        state.listOnetimeRemainderConditionError = action.payload.message;
      })

      .addCase(fetchScheduleRemainderCondition.pending, (state) => {
        state.listScheduleRemainderConditionStatus = 'loading';
      })
      .addCase(fetchScheduleRemainderCondition.fulfilled, (state, action) => {
        state.listScheduleRemainderConditionStatus = 'succeeded';
        state.listScheduleRemainderConditionResp = action.payload;
        state.listScheduleRemainderConditionPending = false;
      })
      .addCase(fetchScheduleRemainderCondition.rejected, (state, action) => {
        state.listScheduleRemainderConditionStatus = 'failed';
        state.listScheduleRemainderConditionError = action.payload.message;
      })

      .addCase(fetchRemainderConditionTriggerEvent.pending, (state) => {
        state.listRemainderConditionTriggerEventStatus = 'loading';
      })
      .addCase(fetchRemainderConditionTriggerEvent.fulfilled, (state, action) => {
        state.listRemainderConditionTriggerEventStatus = 'succeeded';
        state.listRemainderConditionTriggerEventResp = action.payload;
        state.listRemainderConditionTriggerEventPending = false;
      })
      .addCase(fetchRemainderConditionTriggerEvent.rejected, (state, action) => {
        state.listRemainderConditionTriggerEventStatus = 'failed';
        state.listRemainderConditionTriggerEventError = action.payload.message;
      })

      .addCase(fetchDurationOption.pending, (state) => {
        state.listDurationOptionStatus = 'loading';
      })
      .addCase(fetchDurationOption.fulfilled, (state, action) => {
        state.listDurationOptionStatus = 'succeeded';
        state.listDurationOptionResp = action.payload;
        state.listDurationOptionPending = false;
      })
      .addCase(fetchDurationOption.rejected, (state, action) => {
        state.listDurationOptionStatus = 'failed';
        state.listDurationOptionError = action.payload.message;
      })
  },
})

export const { resetStatusList } = optionFormSlice.actions;
export default optionFormSlice.reducer;