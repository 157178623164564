import {React, useState, useEffect} from "react";
import { Col, Container, Card } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Helmet from 'react-helmet';
import Avatar from "../../components/Avatar";
import { Tabs } from 'antd';
import "../../assets/css/react-datepicker.min.css";
import axios from "axios";
import ApiUrl from "../../helpers/url";
import {convertToGMT7} from "../../helpers/timeConvert";
import { toast, Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {Line} from "../../components/chart/index"

export default function DetailReport() {
    const navigate = useNavigate();
    const location = useLocation(); 
    const getSegment = (path) => {
        const segments = path.split("/").filter(Boolean);
        return segments[1];
    };
    const Segment = getSegment(location.pathname);
    const [loadPatient, setLoadPatient] = useState(true);
    const [loadReport, setLoadReport] = useState(true);
    const [dataReport, setDataReport] = useState([]);
    const [dataReportAnswer, setDataReportAnswer] = useState([]);
    const [dataReportComment, setDataReportComment] = useState([]);
    
    // const [axis, setAxis] = useState(['19 Mar', '20 Mar', '21 Mar', '22 Mar', '23 Mar', '24 Mar', '25 Mar']);
    // const [legend, setLegend] = useState(['Systolic mmHg', 'Diastolic mmHg']);
    // const [data, setData] = useState([{
    //     name: 'Systolic mmHg',
    //     type: 'line',
    //     stack: 'Total',
    //     data: [120, 132, 101, 134, 90, 230, 210],
    //     color: '#FF1F62'
    //   },
    //   {
    //     name: 'Diastolic mmHg',
    //     type: 'line',
    //     stack: 'Total',
    //     data: [220, 182, 191, 234, 290, 330, 310],
    //     color: '#15DB84'
    //   },]);

    const getDetailReport = async () => {
        let config = {
            method: `${ApiUrl.list_report_patient.method}`,
            maxBodyLength: Infinity,
            url: `${ApiUrl.list_report_patient.url}`,
            headers: {
                'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ mnr: Segment })
        };
    
        try {
            const response = await axios.request(config);
            if (response.data.data.length > 0) {
                setLoadPatient(false);
                setDataReport(response.data.data);
                const reportsData = [];
    
                for (const item of response.data.data) {
                    try {
                        const [fetchReportResponse, fetchCommentResponse] = await Promise.all([
                            fetch(`${ApiUrl.list_report.url}/${item.id}`, {
                                headers: {
                                    'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
                                    'Content-Type': 'application/json'
                                }
                            }),
                            fetch(`${ApiUrl.list_report.url}/${item.id}/comments`, {
                                headers: {
                                    'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
                                    'Content-Type': 'application/json'
                                }
                            })
                        ]);
    
                        if (!fetchReportResponse.ok || !fetchCommentResponse.ok) {
                            toast.error('Data Report or Comment Not Found!', {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: "light",
                                transition: Bounce,
                            });
                            continue;
                        }
    
                        const reportData = await fetchReportResponse.json();
                        const commentData = await fetchCommentResponse.json();
    
                        reportsData.push({
                            id: item.id,
                            data: reportData.data,
                            comment: commentData.data
                        });
                    } catch (error) {
                        toast.error('Data Report or Comment Not Found', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            theme: "light",
                            transition: Bounce,
                        });
                    }
                }
    
                setLoadReport(false);
                setDataReportAnswer(reportsData);
            } else {
                toast.error('Data Report Not Found!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    transition: Bounce,
                });
                navigate('/report');
            }
        } catch (error) {
            toast.error('Data Report Not Found!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
            navigate('/report');
        }
    };

    const getStatusColor = (stat) => {
        if (stat == "PENDING") {
          return { color: "warning", icon: "ri-time-line" };
        } else if (stat == "SUBMITTED") {
          return { color: "info", icon: "ri-record-circle-fill" };
        } else {
          return { color: "success", icon: "ri-shield-check-fill" };
        }
    };

    useEffect(() => {
        getDetailReport();
    },[])

    const items = [
        {
          key: '1',
          label: 'Laporan',
          children: (
            <> 
                <div className="report-detail">
                {!loadReport ? 
                    <>{dataReportAnswer.length < 1 ? <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/><h5>Data Not Found</h5></div> 
                    : dataReportAnswer.map((item, index) => (
                        <div key={index}>
                            {item?.data?.status ?
                            <div className="border-grey mb-2">
                                <div className="d-flex justify-content-between">
                                    <h6 className="d-flex align-items-center mb-0">{item.data.submit_time ? convertToGMT7(item.data.submit_time) : 'Time Not Found '}&nbsp;<span className={`badge text-center bg-${getStatusColor(item.data.status).color} d-flex align-items-center justify-content-center mb-0`}><i className={`${getStatusColor(item.data.status).icon}`}></i>{" "} {item.data.status}</span> </h6>
                                    <span className="badge bg-success badge-pill">Program : {item.data.patient_program.program.name}</span>
                                </div>
                                <div className="border-primary mt-2">
                                    <p className="text-muted d-flex align-items-center"><i className="ri-survey-line text-primary"></i> Laporan</p>  
                                    {item.data.answers.length > 0 ? item.data.answers.map((item2, index2) => (
                                        <div key={index2} className={`d-flex justify-content-between align-items-center ${index2 !== item.data.answers.length - 1 ? 'border-bottom' : ''}`}>
                                            <h6 className="mt-2 fs-xs">{item2.question.value}</h6>
                                            {item2.value.startsWith('data:image/png;base64,') ? (
                                                <img src={item2.value} alt="base64 image" style={{ width: "70px", borderRadius: "10px", marginTop: 5, marginBottom: 5 }}/>
                                            ) : (
                                                <h6 className="fs-xs text-secondary mb-0">{item2.value}</h6>
                                            )}
                                        </div>
                                    )) : <div className="text-center"> <img src={"/favicon.png"} width="70px" alt={"Icon"}/><h5>Report Not Found</h5></div>}
                                </div>
                                <p className="text-muted mt-2 mb-0">Komentar <span className="badge bg-primary badge-pill">{item.comment ? item.comment.length : 0}</span></p>
                                {item.comment && item.comment.map((itemcomment, indexcomment) => (
                                <div key={indexcomment} className={`${indexcomment !== item.comment.length - 1 ? 'border-bottom' : ''}`}>
                                    <div className="post-header mb-1 mt-2">
                                        <div className="post-content">
                                            <h6 className="mb-0">{itemcomment.creator_name}</h6>
                                        </div>
                                        <span className="post-date fs-xs text-secondary">{itemcomment.created_time ?convertToGMT7(itemcomment.created_time) : "Time Not Found"}</span>
                                    </div>
                                    <p className="fs-xs text-secondary">{itemcomment.comment}</p>
                                </div>))}
                            </div> : <></>}
                        </div>))}</>
                    : <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2"> <Skeleton count={5} style={{ height: "130px" }} className="mb-2" /></SkeletonTheme>}
                </div>
            </>
          )
        },
        // {
        //   key: '2',
        //   label: 'Grafik Klinis',
        //   children: (
        //     <>
        //         <div className="d-flex justify-content-between align-items-center mb-2">
        //             <div className="row">
        //                 <div className="col-lg-6 col-md-6 col-sm-6 col-6">
        //                     <ReactDatePicker
        //                         dateFormat="MMMM dd, yyyy"
        //                         selected={startDate1}
        //                         onChange={(date) => setStartDate1(date)}
        //                         className="form-control"
        //                     />
        //                 </div>
        //                 <div className="col-lg-6 col-md-6 col-sm-6 col-6">
        //                     <ReactDatePicker
        //                         dateFormat="MMMM dd, yyyy"
        //                         selected={startDate2}
        //                         onChange={(date) => setStartDate1(date)}
        //                         className="form-control"
        //                     />
        //                 </div>
        //             </div>
                    
        //             <div className="d-flex align-items-center">
        //                 <select name="" id="" className="form-select form-control">
        //                     <option value="" hidden>Silahkan Pilih</option>
        //                     <option value="3">3 Hari Terakhir</option>
        //                     <option value="7">7 Hari Terakhir</option>
        //                     <option value="14">14 Hari Terakhir</option>
        //                 </select>&nbsp;
        //                 <button className="btn btn-outline-secondary white-space-nowrap">UnduhData <i className="ri-download-line"></i></button>
        //             </div>
        //         </div>
        //         <div className="d-flex justify-content-between align-items-center mb-2">
        //             <select name="" id="" className="form-select form-control w-100px">
        //                 <option value="BP">BP</option>
        //                 <option value="HR">HR</option>
        //             </select>
        //             <select name="" id="" className="form-select form-control w-100px">
        //                 <option value="Grafik">Grafik</option>
        //                 <option value="Data">Data</option>
        //             </select>
        //         </div>
        //         <h6 className="fw-bold mt-3 mb-3">BP</h6>
        //         <div className="row">
        //             <div className="col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
        //                 <div className="border-grey px-3 py-2">
        //                     <h6 className="fw-bold mb-1">128/81 md/dL</h6>
        //                     <p className="text-muted mb-0">Rata rata keseluruhan</p>
        //                 </div>
        //             </div>
        //             <div className="col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
        //                 <div className="border-grey px-3 py-2">
        //                     <h6 className="fw-bold mb-1">128/81 md/dL</h6>
        //                     <p className="text-muted mb-0">Minggu lalu</p>
        //                 </div>
        //             </div>
        //             <div className="col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
        //                 <div className="border-grey px-3 py-2">
        //                     <h6 className="fw-bold mb-1">128/81 md/dL</h6>
        //                     <p className="text-muted mb-0">2 Minggu yang lalu</p>
        //                 </div>
        //             </div>
        //             <div className="col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
        //                 <div className="border-grey px-3 py-2">
        //                     <h6 className="fw-bold mb-1">128/81 md/dL</h6>
        //                     <p className="text-muted mb-0">1 Bulan terakhir</p>
        //                 </div>
        //             </div>
        //         </div>
        //         <Line axis={axis} category={legend} data={data}/>
        //     </>
        //   )
        // },
    ];
    return (
        <>
        <Helmet>
            <title>Auto Health Care | Report Rekam Medis</title>
        </Helmet>
        <ToastContainer/>
        <div className="row g-0">
            <div className="header">
                <Container>
                <Link to="/" className="header-logo"><img src="/favicon.png" width="50px"/>Auto Health Care</Link>
                <Link to="/report" className="btn btn-sm btn-danger d-flex align-items-center"><i className="ri-arrow-left-line"></i> Kembali</Link>
                </Container>
            </div>
                <div className="content">
                    <Container>
                    <div className="post-header mb-3 mt-2 w-100">
                    <Link to=""><Avatar initial={!loadPatient ? dataReport[0].patient_program.patient.name.charAt(0).toUpperCase() : '?'} /></Link>
                    <div className="post-content">
                        <h6>{!loadPatient ? dataReport[0].patient_program.patient.name : <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2"> <Skeleton count={1} style={{ height: "18.50px", width:"100px" }} /></SkeletonTheme>}
                        </h6>
                        <div className="d-flex">
                            <span className="fs-xs text-secondary d-flex align-items-center">{!loadPatient ? <><i className="ri-whatsapp-line text-success"></i> {dataReport[0].patient_program.patient.phone}</> : <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2"> <Skeleton count={1} style={{ height: "21px", width:"100px" }} /></SkeletonTheme>}</span>
                        </div>
                    </div>
                    </div>
                    </Container>
                    <Container>
                        <Card className="card-post mb-3 mt-2 w-100">
                            <Card.Body>
                                <Tabs defaultActiveKey="1" items={items} />
                            </Card.Body>
                        </Card>
                    </Container>
                </div>
            </div>
        </>
    )
}