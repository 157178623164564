import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import DataTable from "react-data-table-component";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import { RoleMapping, CreateRoleInstitusi } from "../../../helpers/roles";
import { decodeToken } from "../../../helpers/jwtToken";
import Select from "react-select";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchListUser,
  fetchUserById,
  fetchSaveDataModal,
  fetchDeleteById,
  resetStatus,
  resetStatusList,
  resetDataModalUser,
  changeDataModalUser,
} from "../../../redux/slices/instansiDokterSlice";

export default function User(props) {
  const [modalAdd, setModalAdd] = useState(false);
  const [titleModal, setTitleModal] = useState("");

  const dispatch = useDispatch();
  const instansiDokter = useSelector((state) => state.instansiDokter);
  const dataModalUser = useSelector(
    (state) => state.instansiDokter.userByIdResp.data,
  );

  const selectOptionsGender = [
    { value: "Male", label: "Laki - Laki" },
    { value: "Female", label: "Perempuan" },
  ];
  const selectOptionsRole = CreateRoleInstitusi;

  const columns = [
    {
      name: "Identitas",
      selector: (row) => row.title,
      cell: (row) => (
        <>
          <div className="d-flex align-items-center gap-2">
            <div>
              <h6 className="mb-0">{row.name}</h6>
              <span className="fs-xs text-secondary">{row.email}</span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Institusi",
      cell: (row) => (
        <>
          <div className="d-flex align-items-center gap-2">
            <div>
              <h6 className="mb-0">{row.institution.name}</h6>
              <span className="fs-xs text-secondary">
                {row.institution.email}
              </span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Role",
      selector: (row) => RoleMapping[row.role].role,
    },
    {
      name: "Department",
      selector: (row) => (row.department ? row.department : "-"),
    },
    {
      name: "Aksi",
      selector: (row) => row.title,
      cell: (row) => (
        <>
          <Link
            to="#"
            className="btn btn-primary"
            onClick={() => dispatchFecthUserById(row.id)}
          >
            <i className="ri-edit-2-line"></i>
          </Link>{" "}
          &nbsp;
          <Link
            to="#"
            className="btn btn-danger"
            onClick={() => deleteData(row.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Link>
        </>
      ),
    },
  ];

  const dispatchFecthGetUser = () => {
    if (instansiDokter.listUserStatus === "idle") {
      dispatch(fetchListUser());
    }

    if (instansiDokter.listUserStatus === "failed") {
      toast.error(instansiDokter.listUserError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  useEffect(() => {
    dispatchFecthGetUser();
  }, [instansiDokter]);

  const dispatchFecthUserById = async (id) => {
    setTitleModal("Ubah");
    if (instansiDokter.userByIdStatus === "idle") {
      await dispatch(fetchUserById(id));
      if (instansiDokter.byIdError) {
        toast.error(instansiDokter.byIdError, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      }
    }
    setModalAdd(true);
    dispatch(resetStatus());
  };

  const deleteData = (id) => {
    swal({
      title: "Apa Data Ingin Dihapus?",
      text: "Jika data dihapus, data tidak dapat dikembalikan!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: "Batal",
        confirm: {
          text: "Hapus",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
    })
      .then(async (willDelete) => {
        if (willDelete) {
          await dispatch(fetchDeleteById(id));
          if (instansiDokter.deleteByIdError) {
            toast.error(instansiDokter.deleteByIdError, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
          } else {
            toast.success("Data Berhasil Dihapus!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
          }
          await dispatch(resetStatusList());
          dispatchFecthGetUser();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const addData = (title) => {
    setModalAdd(true);
    if (titleModal !== title) {
      dispatch(resetDataModalUser());
    }
    setTitleModal(title);
  };

  const closeAddData = () => {
    setModalAdd(false);
  };

  const saveData = async () => {
    const jwttoken = decodeToken(localStorage.getItem("access_token"));
    const data =
      titleModal === "Tambah"
        ? {
            nik: dataModalUser.nik,
            name: dataModalUser.name,
            email: dataModalUser.email,
            password: dataModalUser.password,
            gender: dataModalUser.gender === "Male" ? 1 : 2,
            birth_date: dataModalUser.birth_date,
            phone: dataModalUser.phone,
            address: dataModalUser.address,
            department: dataModalUser.department,
            position: dataModalUser.position,
            role: dataModalUser.role,
            institution_id: jwttoken.INSTITUTION_ID,
          }
        : {
            name: dataModalUser.name,
            email: dataModalUser.email,
            gender: dataModalUser.gender === "Male" ? 1 : 2,
            birth_date: dataModalUser.birth_date,
            phone: dataModalUser.phone,
            address: dataModalUser.address,
            department: dataModalUser.department,
            position: dataModalUser.position,
            role: dataModalUser.role,
            institution_id: jwttoken.INSTITUTION_ID,
          };

    const param = {
      data: data,
      userId: dataModalUser.id,
      action: titleModal,
    };
    await dispatch(fetchSaveDataModal(param));
    if (instansiDokter.saveDataModalError) {
      toast.error(instansiDokter.saveDataModalError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }else{
      toast.success('Save Success!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
    await dispatch(resetStatusList());
    dispatchFecthGetUser();
    setModalAdd(false);
  };

  const handleChangeModal = (e) => {
    let value = e.target.value;
    let key = e.target.name;
    dispatch(changeDataModalUser({ key: key, value: value }));
  };

  const handleChangeModalOption = (e, key) => {
    dispatch(changeDataModalUser({ key: key, value: e.value }));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Dokter & Perawat</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/instansi">Instansi</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Dokter & Perawat
              </li>
            </ol>
            <h4 className="main-title mb-0">Dokter & Perawat</h4>
          </div>
          <Link
            to="#"
            className="btn btn-primary d-flex align-items-center"
            onClick={() => addData("Tambah")}
          >
            <i className="ri-add-line"></i>Tambah User
          </Link>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-one w-100">
              <div className="card-body">
                { instansiDokter.listUserResp.data ? <DataTable
                  columns={columns}
                  data={instansiDokter.listUserResp.data}
                  pagination
                  progressPending={instansiDokter.listUserPending}
                  defaultSortFieldId={1}
                /> : <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/>
                <h5>Data Not Found</h5><p>You can create new user!</p></div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={modalAdd} onHide={closeAddData} centered>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            saveData();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{titleModal} Dokter / Perawat</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {titleModal == "Tambah" ? (
                <Col lg={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>
                      NIK <small className="text-danger">*</small>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Masukan NIK"
                      name="nik"
                      onChange={handleChangeModal}
                      required
                    />
                  </Form.Group>
                </Col>
              ) : (
                <></>
              )}
              <Col lg={titleModal == "Tambah" ? 6 : 12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Nama <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukan Nama Lengkap"
                    name="name"
                    onChange={handleChangeModal}
                    required
                    value={dataModalUser?.name}
                  />
                </Form.Group>
              </Col>
              <Col lg={titleModal == "Tambah" ? 6 : 12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Email <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="example@mail.com"
                    name="email"
                    onChange={handleChangeModal}
                    required
                    value={dataModalUser?.email}
                  />
                </Form.Group>
              </Col>
              {titleModal == "Tambah" ? (
                <Col lg={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>
                      Password <small className="text-danger">*</small>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="**********"
                      name="password"
                      onChange={handleChangeModal}
                      required
                      value={dataModalUser?.password}
                    />
                  </Form.Group>
                </Col>
              ) : (
                <></>
              )}
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    No Telpon <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="08xxxxxxxxxx"
                    name="phone"
                    onChange={handleChangeModal}
                    required
                    value={dataModalUser?.phone}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Jenis Kelamin <small className="text-danger">*</small>
                  </Form.Label>
                  <Select
                    options={selectOptionsGender}
                    isSearchable={false}
                    required
                    name="gender"
                    onChange={(e) => handleChangeModalOption(e, "gender")}
                    value={selectOptionsGender.find(
                      (option) => option.value === dataModalUser?.gender,
                    )}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Tanggal Lahir <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder=""
                    name="birth_date"
                    onChange={handleChangeModal}
                    required
                    value={dataModalUser?.birth_date}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Department <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="department"
                    onChange={handleChangeModal}
                    placeholder="Masukan Department"
                    value={dataModalUser?.department}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Jabatan <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="position"
                    onChange={handleChangeModal}
                    placeholder="Masukan Jabatan"
                    value={dataModalUser?.position}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Alamat <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="address"
                    onChange={handleChangeModal}
                    placeholder="Masukan Alamat Lengkap"
                    value={dataModalUser?.address}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Role <small className="text-danger">*</small>
                  </Form.Label>
                  <Select
                    options={selectOptionsRole}
                    isSearchable={true}
                    name="role"
                    onChange={(e) => handleChangeModalOption(e, "role")}
                    value={selectOptionsRole.find(
                      (option) => option.value === dataModalUser?.role,
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeAddData}>
              Tutup
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!instansiDokter.deleteByIdPending}
            >
              {!instansiDokter.deleteByIdPending
                ? "Silahkan Tunggu ..."
                : "Simpan"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
}