import React from "react";
import Forbidden from "../pages/Forbidden";
import InternalServerError from "../pages/InternalServerError";
import NotFound from "../pages/NotFound";
import ServiceUnavailable from "../pages/ServiceUnavailable";
import Landing from "../pages/Landing";
import Signin from "../pages/Signin";
import Verification from "../pages/Verification";

// APPOINTMENT
// import DaftarAppointment from "../pages/appointment/Index";
// import DetailAppointment from "../pages/appointment/Detail";

// REPORT
import SearchReport from "../pages/report/Search";
import DetailReport from "../pages/report/Detail";

// FORM
import Formulir from "../pages/form/Index";
import Thanks from "../pages/form/Thanks";

const publicRoutes = [
  { path: "", element: <Landing /> },
  { path: "sign-in", element: <Signin /> },
  { path: "verification", element: <Verification /> },
  { path: "error-404", element: <NotFound /> },
  { path: "error-500", element: <InternalServerError /> },
  { path: "error-503", element: <ServiceUnavailable /> },
  { path: "error-505", element: <Forbidden /> },
  // { path: "appointment", element: <DaftarAppointment /> },
  // { path: "appointment/:id", element: <DetailAppointment /> },
  { path: "report", element: <SearchReport /> },
  { path: "report/:id", element: <DetailReport /> },
  { path: "forms/thanks", element: <Thanks /> },
  { path: "forms/:form_code", element: <Formulir /> },
  { path: "forms/:form_code/monitoring/:report_id", element: <Formulir /> }
];

export default publicRoutes;