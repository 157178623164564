import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import ApiUrl from "../../helpers/url";
import { generateConfig, generateConfigCustomHeader } from '../helper/axiosHelper';


export const fetchReportByWorkspace = createAsyncThunk(
  'dokterPasien/fetchReportByWorkspace',
  async (param, { rejectWithValue }) => {
    const {workspace, programFill, statusFill} = param;
    const program = programFill ? "&program_id=" + programFill : "";
    const status = statusFill ? "&status=" + statusFill : "";

    const url = ApiUrl.list_report.url +"?workspace_id=" +workspace +program +status;
    const method = ApiUrl.list_report.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return { data: response.data, status };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchReport = createAsyncThunk(
  'dokterPasien/fetchReport',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.list_report.url}/${id}`;
    const method = ApiUrl.list_report.method;
    const headers = {
      'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
      'Content-Type': 'application/json'
    }
    try {
      const response = await axios.request(generateConfigCustomHeader(url, method, headers));
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchComments = createAsyncThunk(
  'dokterPasien/fetchComments',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.list_report.url}/${id}/comments`;
    const method = ApiUrl.list_report.method;
    const headers = {
      'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
      'Content-Type': 'application/json'
    }
    try {
      const response = await axios.request(generateConfigCustomHeader(url, method, headers));
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeleteComment = createAsyncThunk(
  'dokterPasien/fetchDeleteComment',
  async (param, { rejectWithValue }) => {
    const {id, commentId} = param;
    const url = `${ApiUrl.list_report.url}/${id}/comments/${commentId}`;
    const method = ApiUrl.patient_delete.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return { data: response.data};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchSaveComment = createAsyncThunk(
  'dokterPasien/fetchSaveComment',
  async (param, { rejectWithValue }) => {
    const {id, commentId, data} = param;
    const url =  commentId ? `${ApiUrl.list_report.url}/${id}/comments/${commentId}` : `${ApiUrl.list_report.url}/${id}/comments/`;
    const method = commentId ? 'PUT' : 'POST';
    try {
      const response = await axios.request(generateConfig(url, method, data));
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchMarkDoneUndone = createAsyncThunk(
  'dokterPasien/fetchMarkDoneUndone',
  async (param, { rejectWithValue }) => {
    const {id, value} = param;
    const url =  value == 'DONE' ? `${ApiUrl.list_report.url}/${id}/done` : `${ApiUrl.list_report.url}/${id}/undone`;
    const method = 'PUT';
    try {
      const response = await axios.request(generateConfig(url, method));
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchReadNotification = createAsyncThunk(
  'dokterPasien/fetchReadNotification',
  async (param, { rejectWithValue }) => {
    const {id, data} = param;
    const url =  ApiUrl.update_list_report.url +"/"+id+"/notifications";
    const method = ApiUrl.update_list_report.method;
    try {
      const response = await axios.request(generateConfig(url, method, data));
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const initialState = {
  reportByWorkspaceResp: [],
  reportByWorkspaceStatus: 'idle',
  reportByWorkspaceError: null,
  reportByWorkspacePending: true,

  reportResp: [],
  reportStatus: 'idle',
  reportError: null,
  reportPending: true,

  commentsResp: [],
  commentsStatus: 'idle',
  commentsError: null,
  commentsPending: true,

  deleteCommentResp: [],
  deleteCommentStatus: 'idle',
  deleteCommentError: null,
  deleteCommentPending: true,

  saveCommentResp: [],
  saveCommentStatus: 'idle',
  saveCommentError: null,
  saveCommentPending: true,

  markDoneUndoneResp: [],
  markDoneUndoneStatus: 'idle',
  markDoneUndoneError: null,
  markDoneUndonePending: true,

  readNotifResp: [],
  readNotifStatus: 'idle',
  readNotifError: null,
  readNotifPending: true,
};

const dokterPasien = createSlice({
  name: 'dokterPasien',
  initialState: initialState,
  reducers: {
    resetReportByWorkspace: (state) => {
      state.reportByWorkspaceResp = [];
      state.reportByWorkspaceStatus = 'idle';
      state.reportByWorkspaceError = null;
      state.reportByWorkspacePending = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchReportByWorkspace
      .addCase(fetchReportByWorkspace.pending, (state, action) => {
        state.reportByWorkspaceStatus = 'loading';
        state.reportByWorkspacePending = true;
      })
      .addCase(fetchReportByWorkspace.fulfilled, (state, action) => {
        state.reportByWorkspaceStatus = 'succeeded';
        state.reportByWorkspaceResp = action.payload.data;
        state.reportByWorkspacePending = false;
      })
      .addCase(fetchReportByWorkspace.rejected, (state, action) => {
        state.reportByWorkspaceStatus = 'failed';
        state.reportByWorkspaceError = action.payload.message;
        state.reportByWorkspacePending = false;
      })

      // fetchReport
      .addCase(fetchReport.pending, (state, action) => {
        state.reportStatus = 'loading';
        state.reportPending = true;
      })
      .addCase(fetchReport.fulfilled, (state, action) => {
        state.reportStatus = 'succeeded';
        state.reportResp = action.payload.data;
        state.reportPending = false;
      })
      .addCase(fetchReport.rejected, (state, action) => {
        state.reportStatus = 'failed';
        state.reportError = action.payload.message;
        state.reportPending = false;
      })

      // fetchComments
      .addCase(fetchComments.pending, (state, action) => {
        state.commentsStatus = 'loading';
        state.commentsPending = true;
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.commentsStatus = 'succeeded';
        state.commentsResp = action.payload.data;
        state.commentsPending = false;
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.commentsStatus = 'failed';
        state.commentsError = action.payload.message;
        state.commentsPending = false;
      })


      // fetchDeleteComment
      .addCase(fetchDeleteComment.pending, (state, action) => {
        state.deleteCommentStatus = 'loading';
        state.deleteCommentPending = true;
      })
      .addCase(fetchDeleteComment.fulfilled, (state, action) => {
        state.deleteCommentStatus = 'succeeded';
        state.deleteCommentResp = action.payload.data;
        state.deleteCommentPending = false;
      })
      .addCase(fetchDeleteComment.rejected, (state, action) => {
        state.deleteCommentStatus = 'failed';
        state.deleteCommentError = action.payload.message;
        state.deleteCommentPending = false;
      })

      // fetchSaveComment
      .addCase(fetchSaveComment.pending, (state, action) => {
        state.saveCommentStatus = 'loading';
        state.saveCommentPending = false;
      })
      .addCase(fetchSaveComment.fulfilled, (state, action) => {
        state.saveCommentStatus = 'succeeded';
        state.saveCommentResp = action.payload.data;
        state.saveCommentPending = true;
      })
      .addCase(fetchSaveComment.rejected, (state, action) => {
        state.saveCommentStatus = 'failed';
        state.saveCommentError = action.payload.message;
        state.saveCommentPending = true;
      })

      // fetchMarkDoneUndone
      .addCase(fetchMarkDoneUndone.pending, (state, action) => {
        state.markDoneUndoneStatus = 'loading';
        state.markDoneUndonePending = false;
      })
      .addCase(fetchMarkDoneUndone.fulfilled, (state, action) => {
        state.markDoneUndoneStatus = 'succeeded';
        state.markDoneUndoneResp = action.payload.data;
        state.markDoneUndonePending = true;
      })
      .addCase(fetchMarkDoneUndone.rejected, (state, action) => {
        state.markDoneUndoneStatus = 'failed';
        state.markDoneUndoneError = action.payload.message;
        state.markDoneUndonePending = true;
      })

      // fetchReadNotification
      .addCase(fetchReadNotification.pending, (state, action) => {
        state.readNotifStatus = 'loading';
        state.readNotifPending = false;
      })
      .addCase(fetchReadNotification.fulfilled, (state, action) => {
        state.readNotifStatus = 'succeeded';
        state.readNotifResp = action.payload.data;
        state.readNotifPending = true;
      })
      .addCase(fetchReadNotification.rejected, (state, action) => {
        state.readNotifStatus = 'failed';
        state.readNotifError = action.payload.message;
        state.readNotifPending = true;
      });
  },
})

export const { resetReportByWorkspace } = dokterPasien.actions;
export default dokterPasien.reducer;