import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import MenuLeft from "./MenuLeft";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "../components/Loader";
import { backToRole, refreshToken } from "../helpers/jwtToken";
import swal from "sweetalert";
import { toast, Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ApiUrl from "../helpers/url";

export default function Main({ setSelectedWorkspace, selectedWorkspace }) {
  const [menuVisible, setMenuVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    refreshToken(navigate);
    backToRole(navigate);
    const checkMenuVisibility = () => {
      setTimeout(() => {
        const menuLeftElements = document.querySelectorAll(".menu-left");
        const firstMenuLeftElement = menuLeftElements[0];
        const isMenuVisible =
          firstMenuLeftElement?.classList.contains("d-none");
        typeof isMenuVisible == "undefined" || isMenuVisible
          ? setMenuVisible(true)
          : setMenuVisible(false);
      }, 500);
    };

    checkMenuVisibility();

    const unlisten = () => {
      checkMenuVisibility();
    };

    const timeout = setTimeout(() => {
      setLoading(false);
    }, 600);

    return () => {
      clearTimeout(timeout);
      unlisten();
    };
  }, [navigate]);

  const logout = () => {
    swal({
      title: "Apa Anda Ingin Keluar?",
      text: "Jika anda keluar, anda harus login kembali untuk mengakses halaman ini",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: "Batal",
        confirm: {
          text: "Keluar",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
    }).then((willLogout) => {
      if (willLogout) {
        let config = {
          method: ApiUrl.logout.method,
          maxBodyLength: Infinity,
          url: ApiUrl.logout.url,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        };

        axios
          .request(config)
          .then((response) => {
            if (response.data.status) {
              localStorage.clear();
              navigate("/sign-in");
              return;
            } else {
              toast.error(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          });
      }
    });
  };

  const syncPatient = async() => {
    setLoading(true);
    let config = {
      method: ApiUrl.sync_patient.method,
      maxBodyLength: Infinity,
      url: ApiUrl.sync_patient.url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        if (response.data.status) {
          setLoading(false);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Sidebar logout={logout} />
      <Header logout={logout} selectedWorkspace={selectedWorkspace} setSelectedWorkspace={setSelectedWorkspace} syncPatient={syncPatient}/>
      <div className="main main-app d-flex">
        {loading && <Loader />}
        <MenuLeft />
        <div className={!menuVisible ? "w-body-dashboard" : "w-100"}>
          {!loading && <Outlet />}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
}