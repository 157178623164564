import { configureStore } from '@reduxjs/toolkit';
import adminDashboardReducer from "./slices/adminDashboardSlice";
import adminInstansiReducer from "./slices/adminInstansiSlice";
import adminInstansiDetailReducer from "./slices/adminInstansiDetailSlice";
import adminRuangKerjaReducer from "./slices/adminRuangKerjaSlice";
import adminUserReducer from "./slices/adminUserSlice";
import adminRuangKerjaDetailReducer from "./slices/adminRuangKerjaDetailSlice";

import instansiDokterReducer from "./slices/instansiDokterSlice";
import instansiBannerReducer from "./slices/instansiBannerSlice";
import instansiDashboardReducer from "./slices/instansiDashboardSlice";
import instansiTemplateWaReducer from "./slices/instansiTemplateWaSlice";

import dokterDashboardReducer from "./slices/dokterDashboardSlice";
import dokterPasienReducer from "./slices/dokterPasienSlice";
import dokterLaporanReducer from "./slices/dokterLaporanSlice";

import optionFormReducer from "./slices/optionFormSlice";

import globalReducer from "./slices/globalSlice";

const store = configureStore({
  reducer: {
    adminInstansi: adminInstansiReducer,
    adminInstansiDetail: adminInstansiDetailReducer,
    adminRuangKerja: adminRuangKerjaReducer,
    adminUser: adminUserReducer,
    adminDashboard: adminDashboardReducer,
    adminRuangKerjaDetail: adminRuangKerjaDetailReducer,
    instansiDokter: instansiDokterReducer,
    instansiBanner: instansiBannerReducer,
    instansiDashboard: instansiDashboardReducer,
    instansiTemplateWa: instansiTemplateWaReducer,
    dokterDashboard: dokterDashboardReducer,
    dokterPasien: dokterPasienReducer,
    dokterLaporan: dokterLaporanReducer,
    optionForm: optionFormReducer,
    global: globalReducer,
  },
});

store.subscribe(()=>{
    console.log("STORE CHANGE : ", store.getState());
})

export default store;