import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';


import ApiUrl from "../../helpers/url";
import { generateConfig } from '../helper/axiosHelper';


export const fetchDetailInstansi = createAsyncThunk(
  'adminInstansiDetail/fetchDetailInstansi',
  async (id, { rejectWithValue }) => {
    const url = ApiUrl.detail_institution.url+id;
    const method = ApiUrl.detail_institution.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchDataDokter = createAsyncThunk(
  'adminInstansiDetail/fetchDataDokter',
  async (id, { rejectWithValue }) => {
    const url = ApiUrl.get_user.url+"?institution_id="+id;
    const method = ApiUrl.get_user.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchDataWorkspace = createAsyncThunk(
  'adminInstansiDetail/fetchDataWorkspace',
  async (id, { rejectWithValue }) => {
    const url = ApiUrl.get_workspace.url+"?institution_id="+id;
    const method = ApiUrl.get_workspace.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDataProgram = createAsyncThunk(
  'adminInstansiDetail/fetchDataProgram',
  async (id, { rejectWithValue }) => {
    const url = ApiUrl.get_program.url+"?institution_id="+id;
    const method = ApiUrl.get_program.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDataProgramByWorkspace = createAsyncThunk(
  'adminInstansiDetail/fetchDataProgramByWorkspace',
  async (id, { rejectWithValue }) => {
    const url = ApiUrl.get_program.url+"?workspace_id="+id;
    const method = ApiUrl.get_program.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDataProgramById = await createAsyncThunk(
  'adminInstansiDetail/fetchDataProgramById',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.detail_program.url}${id}`;
    const method = ApiUrl.detail_program.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchSaveDataModal = createAsyncThunk(
  'adminInstansiDetail/fetchSaveDataModal',
  async (param, { rejectWithValue }) => {
    const {data, instansiId, action} = param;
    const url = action === 'Tambah' ? ApiUrl.create_program.url : `${ApiUrl.update_program.url}${instansiId}`;
    const method = action === 'Tambah' ? ApiUrl.create_program.method : ApiUrl.update_program.method;
    try {
      const response = await axios.request(generateConfig(url, method, data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeleteProgramById = await createAsyncThunk(
  'adminInstansiDetail/fetchDeleteProgramById',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.delete_program.url}${id}`;
    const method = ApiUrl.delete_program.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const adminInstansiDetailSlice = createSlice({
  name: 'adminInstansiDetail',
  initialState: {
    detailInstansiResp: [],
    detailInstansiStatus: 'idle',
    detailInstansiError: null,
    detailInstansiPending: true,

    dataDokterResp: [],
    dataDokterStatus: 'idle',
    dataDokterError: null,
    dataDokterPending: true,

    dataWorkspaceResp: [],
    dataWorkspaceStatus: 'idle',
    dataWorkspaceError: null,
    dataWorkspacePending: true,

    dataProgramResp: [],
    dataProgramStatus: 'idle',
    dataProgramError: null,
    dataProgramPending: true,

    dataProgramByIdResp: [],
    dataProgramByIdStatus: 'idle',
    dataProgramByIdError: null,
    dataProgramByIdPending: true,
  
    saveDataModalResp: [],
    saveDataModalStatus: 'idle',
    saveDataModalError: null,
    saveDataModalPending: false,

    deleteProgramByIdResp: [],
    deleteProgramByIdStatus: 'idle',
    deleteProgramByIdError: null,
    deleteProgramByIdPending: true,
  },
  reducers: {
    resetDataDetail: (state) => {
      state.detailInstansiResp = [];
      state.detailInstansiStatus = 'idle';
      state.detailInstansiError = null;
      state.detailInstansiPending = true;

      state.dataDokterResp = [];
      state.dataDokterStatus = 'idle';
      state.dataDokterError = null;
      state.dataDokterPending = true;

      state.dataWorkspaceResp = [];
      state.dataWorkspaceStatus = 'idle';
      state.dataWorkspaceError = null;
      state.dataWorkspacePending = true;

      state.dataProgramResp = [];
      state.dataProgramStatus = 'idle';
      state.dataProgramError = null;
      state.dataProgramPending = true;

      state.dataProgramByIdResp = [];
      state.dataProgramByIdStatus = 'idle';
      state.dataProgramByIdError = null;
      state.dataProgramByIdPending = true;
    },
    resetStatusDataProgram: (state) => {
      state.dataProgramResp = [];
      state.dataProgramStatus = 'idle';
      state.dataProgramError = null;
      state.dataProgramPending = true;
    },
    resetDataProgramById: (state) => {
      state.dataProgramByIdResp = [];
      state.dataProgramByIdStatus = 'idle';
      state.dataProgramByIdError = null;
      state.dataProgramByIdPending = true;
    },
    changeDataProgramById: (state, action) => {
      if(state.dataProgramByIdResp.data === undefined){
        state.dataProgramByIdResp = {data:{}}; 
      }
      const key = action.payload.key;
      const value = action.payload.value;
      state.dataProgramByIdResp.data[key] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchDetailInstansi
      .addCase(fetchDetailInstansi.pending, (state) => {
        state.detailInstansiStatus = 'loading';
      })
      .addCase(fetchDetailInstansi.fulfilled, (state, action) => {
        state.detailInstansiStatus = 'succeeded';
        state.detailInstansiResp = action.payload;
        state.detailInstansiPending = false;
      })
      .addCase(fetchDetailInstansi.rejected, (state, action) => {
        state.detailInstansiStatus = 'failed';
        state.detailInstansiError = action.payload?.message;
      })


      // fetchDataDokter
      .addCase(fetchDataDokter.pending, (state) => {
        state.dataDokterStatus = 'loading';
      })
      .addCase(fetchDataDokter.fulfilled, (state, action) => {
        state.dataDokterStatus = 'succeeded';
        state.dataDokterResp = action.payload;
        state.dataDokterPending = false;
      })
      .addCase(fetchDataDokter.rejected, (state, action) => {
        state.dataDokterStatus = 'failed';
        state.dataDokterError = action.payload?.message;
      })


      // fetchDataWorkspace
      .addCase(fetchDataWorkspace.pending, (state) => {
        state.dataWorkspaceStatus = 'loading';
      })
      .addCase(fetchDataWorkspace.fulfilled, (state, action) => {
        state.dataWorkspaceStatus = 'succeeded';
        state.dataWorkspaceResp = action.payload;
        if(action.payload.data){
          state.dataWorkspaceResp.data = action.payload.data.map(val => ({
            label: val.name,
            value: val.id
          }));
        }else{
          state.dataWorkspaceResp.data = []
        }
        
        state.dataWorkspacePending = false;
      })
      .addCase(fetchDataWorkspace.rejected, (state, action) => {
        state.dataWorkspaceStatus = 'failed';
        state.dataWorkspaceError = action.payload?.message;
      })

      // fetchDataProgram
      .addCase(fetchDataProgram.pending, (state) => {
        state.dataProgramStatus = 'loading';
      })
      .addCase(fetchDataProgram.fulfilled, (state, action) => {
        state.dataProgramStatus = 'succeeded';
        state.dataProgramResp = action.payload;
        state.dataProgramPending = false;
      })
      .addCase(fetchDataProgram.rejected, (state, action) => {
        state.dataProgramStatus = 'failed';
        state.dataProgramError = action.payload?.message;
      })

      // fetchDataProgramByWorkspace
      .addCase(fetchDataProgramByWorkspace.pending, (state) => {
        state.dataProgramStatus = 'loading';
      })
      .addCase(fetchDataProgramByWorkspace.fulfilled, (state, action) => {
        state.dataProgramStatus = 'succeeded';
        state.dataProgramResp = action.payload;
        state.dataProgramPending = false;
      })
      .addCase(fetchDataProgramByWorkspace.rejected, (state, action) => {
        state.dataProgramStatus = 'failed';
        state.dataProgramError = action.payload?.message;
      })

      // fetchDataProgramById
      .addCase(fetchDataProgramById.pending, (state) => {
        state.dataProgramByIdStatus = 'loading';
      })
      .addCase(fetchDataProgramById.fulfilled, (state, action) => {
        state.dataProgramByIdStatus = 'succeeded';
        state.dataProgramByIdResp = action.payload;
        state.dataProgramByIdPending = false;
      })
      .addCase(fetchDataProgramById.rejected, (state, action) => {
        state.dataProgramByIdStatus = 'failed';
        state.dataProgramByIdError = action.payload?.message;
      })

      // fetchSaveDataModal
      .addCase(fetchSaveDataModal.pending, (state) => {
        state.saveDataModalStatus = 'loading';
        state.saveDataModalPending = true;
      })
      .addCase(fetchSaveDataModal.fulfilled, (state, action) => {
        state.saveDataModalStatus = 'succeeded';
        state.saveDataModalResp = action.payload;
        state.saveDataModalPending = false;
      })
      .addCase(fetchSaveDataModal.rejected, (state, action) => {
        state.saveDataModalStatus = 'failed';
        state.saveDataModalError = action.payload.message;
      })

      // fetchDeleteById
      .addCase(fetchDeleteProgramById.pending, (state) => {
        state.deleteProgramByIdStatus = 'loading';
      })
      .addCase(fetchDeleteProgramById.fulfilled, (state, action) => {
        state.deleteProgramByIdStatus = 'succeeded';
        state.deleteProgramByIdResp = action.payload;
        state.deleteProgramByIdPending = false;
      })
      .addCase(fetchDeleteProgramById.rejected, (state, action) => {
        state.deleteProgramByIdStatus = 'failed';
        state.deleteProgramByIdError = action.payload.message;
      })
  },
})

export const {resetStatusDataProgram, resetDataDetail, resetDataProgramById, changeDataProgramById } = adminInstansiDetailSlice.actions;
export default adminInstansiDetailSlice.reducer;