import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import DataTable from "react-data-table-component";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Select from "react-select";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchDataById,
  fetchListData,
  fetchSaveDataModal,
  fetchDeleteById,
  resetStatus,
  resetStatusList,
  resetDataModalInstansi,
  changeDataModalInstansi,
} from "../../../redux/slices/adminInstansiSlice";

export default function Instansi() {
  const [modalAdd, setModalAdd] = useState(false);
  const [titleModal, setTitleModal] = useState("");

  const dispatch = useDispatch();
  const adminInstansi = useSelector((state) => state.adminInstansi);
  const dataModalInstansi = useSelector(
    (state) => state.adminInstansi.byIdResp.data,
  );

  const isActiveOption = [
    { value: 1, label: "Aktif" },
    { value: 0, label: "Tidak Aktif" },
  ];

  const columns = [
    {
      name: "Institusi",
      cell: (row) => (
        <>
          <div className="d-flex align-items-center gap-2">
            <div className="avatar">
              <img
                src={row.image ? row.image : "/favicon.png"}
                alt={row.title}
              />
            </div>
            <div>
              <h6 className="mb-0">{row.name}</h6>
              <span className="fs-xs text-secondary">{row.email}</span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Nomor Telpon",
      selector: (row) => row.phone,
    },
    {
      name: "Status",
      selector: (row) => (row.is_active ? "Aktif" : "Tidak Aktif"),
    },
    {
      name: "Aksi",
      selector: (row) => row.title,
      cell: (row) => (
        <>
          <Link to={`/admin/instansi/${row.id}`} className="btn btn-success">
            <i className="ri-external-link-line"></i>
          </Link>
          &nbsp;
          <Link
            to="#"
            className="btn btn-primary"
            onClick={() => dispatchFecthInstansiById(row.id)}
          >
            <i className="ri-edit-2-line"></i>
          </Link>{" "}
          &nbsp;
          <Link
            to="#"
            className="btn btn-danger"
            onClick={() => deleteData(row.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Link>
        </>
      ),
    },
  ];

  const dispatchFecthInstansi = () => {
    if (adminInstansi.listDataStatus === "idle") {
      dispatch(fetchListData());
    }

    if (adminInstansi.listDataStatus === "failed") {
      toast.error(adminInstansi.listDataError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const dispatchFecthInstansiById = async (id) => {
    setTitleModal("Ubah");
    if (adminInstansi.byIdStatus === "idle") {
      await dispatch(fetchDataById(id));
      if (adminInstansi.byIdError) {
        toast.error(adminInstansi.byIdError, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      }
    }
    setModalAdd(true);
    dispatch(resetStatus());
  };

  const handleChangeModal = (e) => {
    let value = e.target.value;
    let key = e.target.name;
    dispatch(changeDataModalInstansi({ key: key, value: value }));
  };

  const handleChangeModalOption = (e) => {
    dispatch(changeDataModalInstansi({ key: "is_active", value: e.value }));
  };

  useEffect(() => {
    dispatchFecthInstansi();
  }, [adminInstansi]);

  const deleteData = (id) => {
    swal({
      title: "Apa Data Ingin Dihapus?",
      text: "Jika data dihapus, data tidak dapat dikembalikan!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: "Batal",
        confirm: {
          text: "Hapus",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
    })
      .then(async (willDelete) => {
        if (willDelete) {
          await dispatch(fetchDeleteById(id));
          if (adminInstansi.deleteByIdStatus === "succeeded") {
            toast.success("Data Berhasil Dihapus!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
          } else {
            toast.error(adminInstansi.deleteByIdError, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
          }
          dispatch(resetStatusList());
          dispatchFecthInstansi();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const saveData = async () => {
    let isActive = 0;
    if (dataModalInstansi.is_active) {
      isActive = 1;
    }
    const param = {
      data: JSON.stringify({
        name: dataModalInstansi.name,
        pic: dataModalInstansi.pic,
        location: dataModalInstansi.location,
        email: dataModalInstansi.email,
        phone: dataModalInstansi.phone,
        address: dataModalInstansi.address,
        is_active: isActive,
        image: dataModalInstansi.image ? dataModalInstansi.image : "",
      }),
      instansiId: dataModalInstansi.id,
      action: titleModal,
    };
    const disp = await dispatch(fetchSaveDataModal(param));
    if (disp.payload.status !== true) {
      toast.error(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }else{
      toast.success(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
    await dispatch(resetStatusList());
    dispatchFecthInstansi();
    setModalAdd(false);
  };

  const addData = (title) => {
    setModalAdd(true);
    if (titleModal !== title) {
      dispatch(resetDataModalInstansi());
    }
    setTitleModal(title);
  };

  const closeAddData = () => {
    setModalAdd(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          changeDataModalInstansi({ key: "image", value: reader.result }),
        );
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Instansi</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/admin">Admin</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Instansi
              </li>
            </ol>
            <h4 className="main-title mb-0">Instansi</h4>
          </div>
          <Link
            to="#"
            className="btn btn-primary d-flex align-items-center"
            onClick={() => addData("Tambah")}
          >
            <i className="ri-add-line"></i>Tambah Instansi
          </Link>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-one w-100">
              <div className="card-body">
                {adminInstansi.listDataResp.data ? 
                <DataTable
                  columns={columns}
                  data={adminInstansi.listDataResp.data}
                  pagination
                  progressPending={adminInstansi.listDataPending}
                  defaultSortFieldId={1}
                /> : <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/>
                <h5>Data Not Found</h5><p>You can create new instansi!</p></div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={modalAdd} onHide={closeAddData} centered>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            saveData();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{titleModal} Instansi</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Nama Instansi <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukan Nama Instansi"
                    name="name"
                    onChange={handleChangeModal}
                    required
                    value={dataModalInstansi?.name}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Pic <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukan Pic"
                    name="pic"
                    onChange={handleChangeModal}
                    required
                    value={dataModalInstansi?.pic}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Email <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="example@mail.com"
                    name="email"
                    onChange={handleChangeModal}
                    required
                    value={dataModalInstansi?.email}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Phone Number <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Phone Number"
                    name="phone"
                    onChange={handleChangeModal}
                    required
                    value={dataModalInstansi?.phone}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Lokasi <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukan Lokasi"
                    name="location"
                    onChange={handleChangeModal}
                    required
                    value={dataModalInstansi?.location}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Alamat <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="address"
                    onChange={handleChangeModal}
                    placeholder="Masukan Alamat"
                    value={dataModalInstansi?.address}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Status <small className="text-danger">*</small>
                  </Form.Label>
                  <Select
                    options={isActiveOption}
                    isSearchable={true}
                    onChange={handleChangeModalOption}
                    value={isActiveOption.find(
                      (option) =>
                        option.value === (dataModalInstansi?.is_active ? 1 : 0),
                    )}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Logo{" "}
                    {titleModal == "Tambah" ? (
                      <small className="text-danger">*</small>
                    ) : (
                      <></>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleImageChange}
                    required={titleModal == "Tambah" ? true : false}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeAddData}>
              Tutup
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!adminInstansi.deleteByIdPending}
            >
              {!adminInstansi.deleteByIdPending
                ? "Silahkan Tunggu ..."
                : "Simpan"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
}