import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import ApiUrl from "../../helpers/url";
import { generateConfig } from '../helper/axiosHelper';

export const fetchSendMessage = createAsyncThunk(
  'global/fetchSendMessage',
  async (param, { rejectWithValue }) => {
    const {data, id} = param;
    const url = `${ApiUrl.patient.url}/${id}/messages`;
    const method = 'POST';
    try {
      const response = await axios.request(generateConfig(url, method, data));
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  sendMessageResp: [],
  sendMessageStatus: 'idle',
  sendMessageError: null,
  sendMessagePending: true,
};

const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      // fetchSendMessage
      .addCase(fetchSendMessage.pending, (state, action) => {
        state.sendMessageStatus = 'loading';
        state.sendMessagePending = false;
      })
      .addCase(fetchSendMessage.fulfilled, (state, action) => {
        state.sendMessageStatus = 'succeeded';
        state.sendMessageResp = action.payload.data;
        state.sendMessagePending = true;
      })
      .addCase(fetchSendMessage.rejected, (state, action) => {
        state.sendMessageStatus = 'failed';
        state.sendMessageError = action.payload.message;
        state.sendMessagePending = true;
      })
  },
})

export default globalSlice.reducer;