import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

import ApiUrl from "../../helpers/url";
import { generateConfig } from '../helper/axiosHelper';

export const fetchListDashboard = createAsyncThunk(
  'dokterDashboard/fetchListDashboard',
  async (_, { rejectWithValue }) => {
    const url = ApiUrl.dokter_dashboard.url;
    const method = ApiUrl.dokter_dashboard.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchListPasienTidakPatuh = createAsyncThunk(
    'dokterDashboard/fetchListPasienTidakPatuh',
    async (_, { rejectWithValue }) => {
      const url = ApiUrl.dokter_dashboard.url;
      const method = ApiUrl.dokter_dashboard.method;
      try {
        const response = await axios.request(generateConfig(url, method));
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

const dokterDashboardSlice = createSlice({
  name: 'dokterDashboard',
  initialState: {
    listDashboardResp: [],
    listDashboardStatus: 'idle',
    listDashboardError: null,
    listDashboardPending: true,

    listPasienTidakPatuhResp: [],
    listPasienTidakPatuhStatus: 'idle',
    listPasienTidakPatuhError: null,
    listPasienTidakPatuhPending: true,
  },
  reducers: {
    resetStatusList: (state) => {
        state.listDashboardStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchListDashboard
      .addCase(fetchListDashboard.pending, (state) => {
        state.listDashboardStatus = 'loading';
      })
      .addCase(fetchListDashboard.fulfilled, (state, action) => {
        state.listDashboardStatus = 'succeeded';
        state.listDashboardResp = action.payload;
        state.listDashboardPending = false;
      })
      .addCase(fetchListDashboard.rejected, (state, action) => {
        state.listDashboardStatus = 'failed';
        state.listDashboardError = action.payload.message;
      })

      .addCase(fetchListPasienTidakPatuh.pending, (state) => {
        state.listPasienTidakPatuhStatus = 'loading';
      })
      .addCase(fetchListPasienTidakPatuh.fulfilled, (state, action) => {
        state.listPasienTidakPatuhStatus = 'succeeded';
        state.listPasienTidakPatuhResp = action.payload;
        state.listPasienTidakPatuhPending = false;
      })
      .addCase(fetchListPasienTidakPatuh.rejected, (state, action) => {
        state.listPasienTidakPatuhStatus = 'failed';
        state.listPasienTidakPatuhError = action.payload.message;
      })
  },
})

export const { resetStatusList } = dokterDashboardSlice.actions;
export default dokterDashboardSlice.reducer;