import React, {useState} from 'react';
import ReactECharts from 'echarts-for-react';

function Pie(props) {  
    const [category, setCategory] = useState(props.category);
    const [data, setData] = useState(props.data);
    const [radius, setRadius] = useState(props.radius);
    const [color, setColor] = useState(props.color);
    const option = {
        type: 'pie',
        tooltip: {
            trigger: 'item'
        },
        grid: {
            top: '0%',
            bottom:'10%',
            left: '5%',
            right: '2%'
        },
        xAxis: {
            type: 'category',
            data: category
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: data,
                color: color,
                type: 'pie',
                radius:radius,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                      show: false,
                      fontSize: 40,
                      fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
            }
        ],
    }; 

    return (
        <ReactECharts option={option} />
    )
} 
export default Pie;