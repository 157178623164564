import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

import ApiUrl from "../../helpers/url";
import { generateConfig, generateConfigFormData } from '../helper/axiosHelper';

export const fetchListBanner = createAsyncThunk(
  'instansiBanner/fetchListData',
  async (_, { rejectWithValue }) => {
    const url = ApiUrl.get_banner.url;
    const method = ApiUrl.get_banner.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBannerById = await createAsyncThunk(
  'instansiBanner/fetchBannerById',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.detail_banner.url}${id}`;
    const method = ApiUrl.detail_banner.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSaveDataModal = createAsyncThunk(
  'instansiBanner/fetchSaveDataModal',
  async (param, { rejectWithValue }) => {
    const {data, id, action} = param;
    const url = action === 'Tambah' ? ApiUrl.create_banner.url : `${ApiUrl.update_banner.url}${id}`;
    const method = action === 'Tambah' ? ApiUrl.create_banner.method : ApiUrl.update_banner.method;
    try {
      const response = await axios.request(generateConfigFormData(url, method, data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeleteById = await createAsyncThunk(
  'instansiBanner/fetchDeleteById',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.delete_banner.url}${id}`;
    const method = ApiUrl.delete_banner.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const instansiBannerSlice = createSlice({
  name: 'instansiBanner',
  initialState: {
    listBannerResp: [],
    listBannerStatus: 'idle',
    listBannerError: null,
    listBannerPending: true,
    listBannerActiveOption: [{label: 1, value: 1}],
    listBannerUpdateOption: [{label: 1, value: 1}],

    bannerByIdResp: [],
    bannerByIdStatus: 'idle',
    bannerByIdError: null,
    bannerByIdPending: true,
  
    saveDataModalResp: [],
    saveDataModalStatus: 'idle',
    saveDataModalError: null,
    saveDataModalPending: false,

    deleteByIdResp: [],
    deleteByIdStatus: 'idle',
    deleteByIdError: null,
    deleteByIdPending: true,
  },
  reducers: {
    resetDataModal: (state) => {
      state.bannerByIdResp = [];
      state.bannerByIdStatus = 'idle';
      state.bannerByIdError = null;
      state.bannerByIdPending = true;
    },
    resetStatusListBanner: (state) => {
      state.listBannerStatus = 'idle';
      state.listBannerPending = true;
      state.listBannerResp = [];
    },
    resetDataModalBanner:  (state) => {
      state.bannerByIdResp = {data:{}}; 
    },
    changeDataModalBanner: (state, action) => {
      if(state.bannerByIdResp.data === undefined){
        state.bannerByIdResp = {data:{}}; 
      }
      const key = action.payload.key;
      const value = action.payload.value;
      state.bannerByIdResp.data[key] = value;
    },
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
  }),
  extraReducers: (builder) => {
    builder
      // fetchListBanner
      .addCase(fetchListBanner.pending, (state) => {
        state.listBannerStatus = 'loading';
      })
      .addCase(fetchListBanner.fulfilled, (state, action) => {
        state.listBannerStatus = 'succeeded';
        state.listBannerResp = action.payload;
        state.listBannerPending = false;
        state.listBannerActiveOption = action.payload?.data.concat({}).map((val, index) => ({
          label: index + 1,
          value: index + 1
        }));
        state.listBannerUpdateOption = action.payload?.data?.map((val, index) => ({
          label: index+1,
          value: index+1
        }));
        
      })
      .addCase(fetchListBanner.rejected, (state, action) => {
        state.listBannerStatus = 'failed';
        state.listBannerError = action.payload?.message;
      })

      // fetchBannerById
      .addCase(fetchBannerById.pending, (state) => {
        state.bannerByIdStatus = 'loading';
      })
      .addCase(fetchBannerById.fulfilled, (state, action) => {
        state.bannerByIdStatus = 'succeeded';
        state.bannerByIdResp = action.payload;
        state.bannerByIdPending = false;
      })
      .addCase(fetchBannerById.rejected, (state, action) => {
        state.bannerByIdStatus = 'failed';
        state.bannerByIdError = action.payload.message;
      })

      // fetchSaveDataModal
      .addCase(fetchSaveDataModal.pending, (state) => {
        state.saveDataModalStatus = 'loading';
        state.saveDataModalPending = true;
      })
      .addCase(fetchSaveDataModal.fulfilled, (state, action) => {
        state.saveDataModalStatus = 'succeeded';
        state.saveDataModalResp = action.payload;
        state.saveDataModalPending = false;
      })
      .addCase(fetchSaveDataModal.rejected, (state, action) => {
        state.saveDataModalStatus = 'failed';
        state.saveDataModalPending = false;
        if(action.payload?.message){
          state.saveDataModalError = action.payload.message;
        } else {
          state.saveDataModalError = "Fail - Silakan coba lagi"
        }
      })

      // fetchDeleteById
      .addCase(fetchDeleteById.pending, (state) => {
        state.deleteByIdStatus = 'loading';
      })
      .addCase(fetchDeleteById.fulfilled, (state, action) => {
        state.deleteByIdStatus = 'succeeded';
        state.deleteByIdResp = action.payload;
        state.deleteByIdPending = false;
      })
      .addCase(fetchDeleteById.rejected, (state, action) => {
        state.deleteByIdStatus = 'failed';
        state.deleteByIdError = action.payload.message;
      })
  },
})

export const { resetDataModal, resetStatusListBanner, resetDataModalBanner, changeDataModalBanner } = instansiBannerSlice.actions;
export default instansiBannerSlice.reducer;