import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import Select from "react-select";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import ApiUrl from "../../../helpers/url";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchListWorkspace
} from "../../../redux/slices/adminRuangKerjaSlice";
import {
  fetchRemainderOption,
  fetchOnetimeRemainderCondition,
  fetchScheduleRemainderCondition,
  fetchRemainderConditionTriggerEvent,
  fetchDurationOption,
} from "../../../redux/slices/optionFormSlice";
import {
  fetchListTemplate
} from "../../../redux/slices/instansiTemplateWaSlice"
import { RolesForm } from "../../../helpers/roles";

export default function FormulirEdit() {
  const location = useLocation();
  const getSegment = (path) => {
    const segments = path.split("/").filter(Boolean);
    return segments[4];
  };
  const Segment = getSegment(location.pathname);
  const [nextStepStatus, setNextStepStatus] = useState(false);
  const [question, setQuestion] = useState(false);
  const [indexQuestion, setIndexQuestion] = useState(false);
  const [indexOption, setIndexOption] = useState(false);
  const [showSetFrekuensi, setShowSetFrekuensi] = useState(false);
  const [saveFormLoad, setSaveFormLoad] = useState(false);

  const [workSpace, setWorkSpace] = useState([]);
  const [program, setProgram] = useState([]);
  const [typeForm, setTypeForm] = useState(0);
  const defaultRemainder = {
    name: "",
    template_id: "",
    type: 0,
    onetime: {
        reminder_condition: "",
        reminder_condition_trigger_event: "",
        value: "0"
    },
    scheduled: {
        reminder_condition: "",
    },
    durations: {
        start_trigger: {
            reminder_condition: "",
            reminder_condition_trigger_event: "",
            value: "0"
        },
        end_trigger: {
            reminder_condition: "",
            reminder_condition_trigger_event: "",
            value: "0"
        }
    }
  }

  const resetOnetime = {
    reminder_condition: "",
    reminder_condition_trigger_event: "",
    value: "0"
  }

  const resetSchedule = {
    reminder_condition: "",
  };
  const [remainder, setRemainder] = useState(defaultRemainder);

  const [templateId, setTemplateId] = useState([]);
  const [remainderOption, setRemainderOption] = useState([]);
  const [oneTimeRemainderCondition, setOneTimeRemainderCondition] = useState([]);
  const [scheduleRemainderCondition, setScheduleRemainderCondition] = useState([]);
  const [remainderDurationOption, setRemainderDurationOption] = useState([]);
  const [remainderConditionTriggerEvent, setRemainderConditionTriggerEvent] = useState([]);

  const dispatch = useDispatch();
  const adminRuangKerja = useSelector((state) => state.adminRuangKerja);
  const optionForm = useSelector((state) => state.optionForm);
  const instansiTemplateWa = useSelector((state) => state.instansiTemplateWa);

  const dispatchFecthGetWorkspace = () => {
    if (adminRuangKerja.listWorkspaceStatus === "idle") {
      dispatch(fetchListWorkspace());
    }
  }

  const dispatchFecthGetOptionForm = () => {
    if (optionForm.listRemainderOptionStatus === "idle") {
      dispatch(fetchRemainderOption());
    }

    if (optionForm.listOnetimeRemainderConditionStatus === "idle") {
      dispatch(fetchOnetimeRemainderCondition());
    }

    if (optionForm.listScheduleRemainderConditionStatus === "idle") {
      dispatch(fetchScheduleRemainderCondition());
    }

    if (optionForm.listRemainderConditionTriggerEventStatus === "idle") {
      dispatch(fetchRemainderConditionTriggerEvent());
    }

    if (optionForm.listDurationOptionStatus === "idle") {
      dispatch(fetchDurationOption());
    }
  }

  const dispatchFecthListTemplate = () => {
    if (instansiTemplateWa.listTemplateStatus === 'idle') {
		  dispatch(fetchListTemplate());
    }
  }

  const [dataForm, setDataForm] = useState({
    workspace_id : "",
    program_id : "",
    name: "",
    description : "",
    type : "",  
  });

  const [dataQuestion, setDataQuestion] = useState([
    {
      order_index: 1,
      value: '',
      type: '',
      description: '',
      image: '',
      is_required: '',
      options: []
    }
  ]);

  const selectOptionstype = [
    { value: 1, label: "Jawaban Text" },
    { value: 2, label: "Pilihan Ganda" },
    { value: 3, label: "Checkbox" },
    { value: 4, label: "Dropdown" },
    { value: 5, label: "Tanggal" },
    { value: 6, label: "Upload File" },
  ];

  const selectOptionsRequired = [
    { value: 1, label: "Ya" },
    { value: 0, label: "Tidak" },
  ];

  const selectOptionsdays = [
    { value: 1, label: "Senin" },
    { value: 2, label: "Selasa" },
    { value: 3, label: "Rabu" },
    { value: 4, label: "Kamis" },
    { value: 5, label: "Jumat" },
    { value: 6, label: "Sabtu" },
    { value: 7, label: "Minggu" },
  ];

  const selectOptionsdates = [];
  for (let i = 1; i <= 31; i++) {
    selectOptionsdates.push({ value: i, label: i.toString() });
  }

  const selectOptionsmonths = [
    { value: 1, label: "Januari" },
    { value: 2, label: "Februari" },
    { value: 3, label: "Maret" },
    { value: 4, label: "April" },
    { value: 5, label: "Mei" },
    { value: 6, label: "Juni" },
    { value: 7, label: "Juli" },
    { value: 8, label: "Agustus" },
    { value: 9, label: "September" },
    { value: 10, label: "Oktober" },
    { value: 11, label: "November" },
    { value: 12, label: "Desember" },
  ];

  const handleAddQuestion = () => {
    setDataQuestion([
      ...dataQuestion,
      {
        order_index: dataQuestion.length + 1,
        value: '',
        type: '',
        description: '',
        image: '',
        is_required: '',
        options: []
      }
    ]);
  };

  const deleteQuestion = (index) => {
    setDataQuestion(prevData => {
      const newData = prevData.filter(item => item.order_index !== index);
      return newData.map((item, idx) => ({
        ...item,
        order_index: idx + 1
      }));
    });
  }

  const handleInputChange = (id, field, value) => {
    setDataQuestion(dataQuestion.map(card => {
      if (card.order_index === id) {
        let updatedCard = { ...card, [field]: value };
        if (field === 'type') {
          if (value === '2' || value === '3' || value === '4') {
            updatedCard.options = [{ value: '', order_index: 0 }];
          } else {
            updatedCard.options = [];
          }
        }
        return updatedCard;
      }
      return card;
    }));
  };

  const handleFileChange = (id, field, file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDataQuestion(dataQuestion.map(card => card.order_index === id ? { ...card, [field]: reader.result } : card));
      };
      reader.readAsDataURL(file);
    }
  };

  const addOption = (id) => {
    setDataQuestion(dataQuestion.map(card => {
      if (card.order_index === id) {
        const lastOptionIndex = card.options.length > 0 ? card.options[card.options.length - 1].order_index : -1;
        const newOption = { value: '', order_index: lastOptionIndex + 1 };
        const updatedOptions = [...card.options, newOption];
        return { ...card, options: updatedOptions };
      }
      return card;
    }));
  };

  const deleteOption = (index_question, index_option) => {
    setDataQuestion(prevData => {
      return prevData.map(item => {
        if (item.order_index === index_question) {
          const updatedOptions = item.options.filter((opt, index) => index !== index_option);
          const updatedOptionsWithOrder = updatedOptions.map((opt, idx) => ({
            ...opt,
            order_index: idx
          }));
          return { ...item, options: updatedOptionsWithOrder };
        }
        return item;
      });
    });
  };

  const resetRemainder = (index_question, index_option) => {
    const newDataQuestion = [...dataQuestion];
    const dataItem = newDataQuestion.find(item => item.order_index === index_question);
    if (dataItem) {
        const optionItem = dataItem.options.find(option => option.order_index === index_option);
        if (optionItem) {
            delete optionItem.reminders;
        }
    }
    setDataQuestion(newDataQuestion);
  };

  const handleRemainderChange = (field, value, thirdField = null, fourField = null, index = null) => {
    if (thirdField == null && fourField == null) {
      setRemainder({
        ...remainder,
        [field]: value
      });
    } else if (thirdField !== null && fourField == null) {
      if(thirdField == 'scheduled' && field == 'reminder_condition'){
        let newScheduled = {
          ...remainder.scheduled,
          reminder_condition: value,
        };
      
        if (value === 'day') {
          newScheduled.daily_reminder = {
            times: [],
            frequently: 0
          };
          delete newScheduled.weekly_reminder;
          delete newScheduled.monthly_reminder;
          delete newScheduled.yearly_reminder;
        } else if (value === 'week') {
          newScheduled.weekly_reminder = {
            days: [],
            times: [],
            frequently: 0
          };
          delete newScheduled.daily_reminder;
          delete newScheduled.monthly_reminder;
          delete newScheduled.yearly_reminder;
        } else if (value === 'month') {
          newScheduled.monthly_reminder = {
            dates: [],
            times: [],
            frequently: 0
          };
          delete newScheduled.daily_reminder;
          delete newScheduled.weekly_reminder;
          delete newScheduled.yearly_reminder;
        } else if (value === 'year') {
          newScheduled.yearly_reminder = {
            dates: [],
            months: [],
            times: [],
            frequently: 0
          };
          delete newScheduled.daily_reminder;
          delete newScheduled.weekly_reminder;
          delete newScheduled.monthly_reminder;
        } else {
          delete newScheduled.daily_reminder;
          delete newScheduled.weekly_reminder;
          delete newScheduled.monthly_reminder;
          delete newScheduled.yearly_reminder;
        }
        setRemainder({
          ...remainder,
          scheduled: newScheduled
        });

        // setRemainder({
        //   ...remainder,
        //   scheduled: {
        //     ...remainder.scheduled,
        //     reminder_condition: value,
        //     daily_reminder: {
        //       times: [],
        //       frequently: 0
        //     },
        //     monthly_reminder: {
        //       dates: [],
        //       times: [],
        //       frequently: 0
        //     },
        //     weekly_reminder: {
        //       days: [],
        //       times: [],
        //       frequently: 0
        //     },
        //     yearly_reminder: {
        //       dates: [],
        //       months: [],
        //       times: [],
        //       frequently: 0
        //     }
        //   }
        // });
      }else{
        setRemainder({
          ...remainder,
          [thirdField]: {
            ...remainder[thirdField],
            [field]: value
          }
        });
      }

      // else if(thirdField == 'onetime'){
      //   if(field == 'reminder_condition'){
      //     if(value === 'after_patient_enrollment' || value === 'after_patient_discharge'){
      //       setRemainder({
      //         ...remainder,
      //         [thirdField]: {
      //           ...remainder[thirdField],
      //           'reminder_condition': value,
      //           'reminder_condition_trigger_event': "",
      //           'value': "",
      //         }
      //       });
      //     }else{
      //       setRemainder({
      //         ...remainder,
      //         [thirdField]: {
      //           ...remainder[thirdField],
      //           [field]: value
      //         }
      //       }); 
      //     }
      //   }else{
      //     setRemainder({
      //       ...remainder,
      //       [thirdField]: {
      //         ...remainder[thirdField],
      //         [field]: value
      //       }
      //     });
      //   }
      // }
    } else if (thirdField !== null && fourField !== null) {
      if(index !== null){
        setRemainder({
          ...remainder,
          [thirdField]: {
            ...remainder[thirdField],
            [fourField]: {
              ...remainder[thirdField][fourField],
              [field]: remainder[thirdField][fourField][field].map((time, i) => 
                i === index ? value : time
              )
            }
          }
        });
      }else{
        setRemainder({
          ...remainder,
          [thirdField]: {
            ...remainder[thirdField],
            [fourField]: {
              ...remainder[thirdField][fourField],
              [field]: value
            }
          }
        });
      }
    }
  };

  const getLabelByValue = (array, value) => {
    const option = array.find(option => option.value === value);
    return option ? option.label : 'Not Found!';
  };

  const getProgramFromWorkspace = async(e) =>{
    let config = {
        method: ApiUrl.get_program.method,
        maxBodyLength: Infinity,
        url: ApiUrl.get_program.url+"?workspace_id="+e,
        headers: { 
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    await axios.request(config)
    .then((response) => {
        if(response.data.status){
            const transformedData = response.data.data.map(item => ({
              value: item.id,
              label: item.name
            }));
            setProgram(transformedData);
        }else{
          setProgram([]);
        }
    })
    .catch((error) => {
        setProgram([]);
    })
  }

  const getFormDetail = async() =>{
    let config = {
        method: ApiUrl.detail_form.method,
        maxBodyLength: Infinity,
        url: ApiUrl.detail_form.url+Segment,
        headers: { 
          'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    await axios.request(config)
    .then((response) => {
      if (response.data.status) {
          const { workspace_id, program, name, description, type } = response.data.data;
          getProgramFromWorkspace(workspace_id);
          setDataForm({
              workspace_id: workspace_id,
              program_id: program ? program.id : '',
              name: name,
              description: description,
              type: type
          });
      } else {
          return window.history.back();
      }
    })
    .catch((error) => {
        setTypeForm([]);
    })
  }

  const getQuestionDetail = async() =>{
    let config = {
        method: ApiUrl.detail_form.method,
        maxBodyLength: Infinity,
        url: ApiUrl.detail_form.url+Segment+"/questions",
        headers: { 
          'PARTNER-TOKEN': 'TXMKh?j9RTJutU5',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    await axios.request(config)
    .then((response) => {
        if(response.data.status){
          setDataQuestion(response.data.data);
        }
    })
    .catch((error) => {
      toast.error('Data Question Not Found!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    })
  }

  const getTypeForm = async() =>{
    let config = {
        method: ApiUrl.option_form_type.method,
        maxBodyLength: Infinity,
        url: ApiUrl.option_form_type.url,
        headers: { 
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    await axios.request(config)
    .then((response) => {
        if(response.data.status){
            const transformedData = response.data.data.map(item => ({
              value: item.value,
              label: item.name
            }));
            setTypeForm(transformedData);
        }else{
          setTypeForm([]);
        }
    })
    .catch((error) => {
        setTypeForm([]);
    })
  }

  useEffect(()=>{
    getFormDetail();
    getQuestionDetail();
    getTypeForm()
  }, [])

  useEffect(() => {
    dispatchFecthGetWorkspace();
    dispatchFecthGetOptionForm();
    dispatchFecthListTemplate();

    if(instansiTemplateWa.listTemplateStatus === "succeeded"){
      const transformedData = instansiTemplateWa.listTemplateResp.data.map(item => ({
        value: item.id,
        label: item.name
      }));
      setTemplateId(transformedData);
    }

    if(adminRuangKerja.listWorkspaceStatus === "succeeded"){
      const transformedData = adminRuangKerja.listWorkspaceResp.data.map(item => ({
        value: item.id,
        label: item.name
      }));
      setWorkSpace(transformedData);
    }

    if(optionForm.listDurationOptionStatus === "succeeded"){
      const transformedData = optionForm.listDurationOptionResp.data.map(item => ({
        value: item.value,
        label: item.name
      }));
      setRemainderDurationOption(transformedData);
    }

    if(optionForm.listOnetimeRemainderConditionStatus === "succeeded"){
      const transformedData = optionForm.listOnetimeRemainderConditionResp.data.map(item => ({
        value: item.value,
        label: item.name
      }));
      setOneTimeRemainderCondition(transformedData);
    }

    if(optionForm.listRemainderConditionTriggerEventStatus === "succeeded"){
      const transformedData = optionForm.listRemainderConditionTriggerEventResp.data.map(item => ({
        value: item.value,
        label: item.name
      }));
      setRemainderConditionTriggerEvent(transformedData);
    }

    if(optionForm.listRemainderOptionStatus === "succeeded"){
      const transformedData = optionForm.listRemainderOptionResp.data.map(item => ({
        value: item.value,
        label: item.name
      }));
      setRemainderOption(transformedData);
    }

    if(optionForm.listScheduleRemainderConditionStatus === "succeeded"){
      const transformedData = optionForm.listScheduleRemainderConditionResp.data.map(item => ({
        value: item.value,
        label: item.name
      }));
      setScheduleRemainderCondition(transformedData);
    }
  }, [adminRuangKerja, optionForm, remainder]);

  const nextStep = () => {
    if(dataForm.program_id && dataForm.workspace_id){
      setNextStepStatus(true);
    }else{
      toast.error('Lengkapi Form Tersebut!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const backStep = () => {
    setNextStepStatus(false);
  };

  const createQuestion = () => {
    if(dataForm.name && dataForm.description && dataForm.type){
      setQuestion(true);
    }else{
      toast.error('Lengkapi Form Tersebut!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const backQuestion = () => {
    setQuestion(false);
  };

  const setFrekuensi = (indexquest, indexopt) => {
    setIndexQuestion(indexquest);
    setIndexOption(indexopt);
    const newDataQuestion = [...dataQuestion];
    const dataItem = newDataQuestion.find(item => item.order_index === indexquest);
    if (dataItem) {
        const optionItem = dataItem.options.find(option => option.order_index === indexopt);

        if(optionItem.reminders){
          setRemainder(optionItem.reminders)
        }else{
          setRemainder(defaultRemainder)
        }
        setShowSetFrekuensi(true);
      }
  };

  const closeSetFrekuensi = () => {
    swal({
      title: "Apa Anda Menutup Modal Ini?",
      text: "Jika anda tutup, maka data pengingat akan ter reset!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: "Batal",
        confirm: {
          text: "Ya Tutup",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
    }).then((willClose) => {
      if (willClose) {
        setIndexQuestion(0);
        setIndexOption(0);
        setRemainder(defaultRemainder);
        setShowSetFrekuensi(false);
        setRemainder({
          ...remainder,
          scheduled: {
            ...remainder.scheduled,
            reminder_condition: '',
          }
        })
      }
    });
  };

  const addRemainderSchedule = () =>{
    var arr = RolesForm[remainder.scheduled.reminder_condition].label

    setRemainder(prevRemainder => {
      const updatedTimes = [
        ...prevRemainder.scheduled[arr].times,
        '00:00'
      ];
  
      return {
        ...prevRemainder,
        scheduled: {
          ...prevRemainder.scheduled,
          [arr]: {
            ...prevRemainder.scheduled[arr],
            times: updatedTimes
          }
        }
      };
    });
  }

  const deleteRemainderSchedule = (indexToRemove) => {
    var arr = '';
    if(remainder.scheduled.reminder_condition == 'day'){
      arr = "daily_reminder"
    }else if(remainder.scheduled.reminder_condition == 'week'){
      arr = "weekly_reminder"
    }else if(remainder.scheduled.reminder_condition == 'month'){
      arr = "monthly_reminder"
    }else if(remainder.scheduled.reminder_condition == 'year'){
      arr = "yearly_reminder"
    }
    
    setRemainder(prevRemainder => {
      const updatedTimes = prevRemainder.scheduled[arr].times.filter((time, index) => index !== indexToRemove);
  
      return {
        ...prevRemainder,
        scheduled: {
          ...prevRemainder.scheduled,
          [arr]: {
            ...prevRemainder.scheduled[arr],
            times: updatedTimes
          }
        }
      };
    });
  };

  const errorRemainder = () => {
    toast.error('Lengkapi Form Reminder Terlebih Dahulu!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });
  }

  const saveRemainder = async () => {
    try {
      
      if (!remainder.name || !remainder.template_id || !remainder.type) {
        return errorRemainder();
      }
  
      if (remainder.type === 1) {
        if (!remainder.onetime.reminder_condition) {
          return errorRemainder();
        } else {
          if (
            remainder.onetime.reminder_condition === 'after_patient_enrollment' ||
            remainder.onetime.reminder_condition === 'after_patient_discharge'
          ) {
            if (!remainder.onetime.reminder_condition_trigger_event) {
              return errorRemainder();
            }
          }
        }
      } else if (remainder.type === 2) {
        if (remainder.scheduled.reminder_condition) {
          if (remainder.scheduled.reminder_condition === 'day') {
            if (!remainder.scheduled.daily_reminder.frequently || remainder.scheduled.daily_reminder.times.length < 1) {
              return errorRemainder();
            }
          } else if (remainder.scheduled.reminder_condition === 'week') {
            if (!remainder.scheduled.weekly_reminder.frequently || remainder.scheduled.weekly_reminder.times.length < 1 || remainder.scheduled.weekly_reminder.days.length < 1) {
              return errorRemainder();
            }
          } else if (remainder.scheduled.reminder_condition === 'month') {
            if (!remainder.scheduled.monthly_reminder.frequently || remainder.scheduled.monthly_reminder.times.length < 1 || remainder.scheduled.monthly_reminder.dates.length < 1) {
              return errorRemainder();
            }
          } else if (remainder.scheduled.reminder_condition === 'year') {
            if (!remainder.scheduled.yearly_reminder.frequently || remainder.scheduled.yearly_reminder.times.length < 1 || remainder.scheduled.yearly_reminder.dates.length < 1 || remainder.scheduled.yearly_reminder.months.length < 1) {
              return errorRemainder();
            }
          }
        } else {
          return errorRemainder();
        }
      }
      
      if (remainder.durations.start_trigger.reminder_condition) {
        if (
          remainder.durations.start_trigger.reminder_condition === 'after_patient_enrollment' ||
          remainder.durations.start_trigger.reminder_condition === 'after_patient_discharge'
        ) {
          if (!remainder.durations.start_trigger.reminder_condition_trigger_event) {
            return errorRemainder();
          }
        }
      } else {
        return errorRemainder();
      }
  
      if (remainder.durations.end_trigger.reminder_condition) {
        if (
          remainder.durations.end_trigger.reminder_condition === 'after_patient_enrollment' ||
          remainder.durations.end_trigger.reminder_condition === 'after_patient_discharge'
        ) {
          if (!remainder.durations.end_trigger.reminder_condition_trigger_event) {
            return errorRemainder();
          }
        }
      } else {
        return errorRemainder();
      }
  
      const newDataQuestion = [...dataQuestion];
      const dataItem = newDataQuestion.find(item => item.order_index === indexQuestion);
      if (dataItem) {
        const optionItem = dataItem.options.find(option => option.order_index === indexOption);
        if (optionItem) {
          if (remainder.type === 1) {
              const { scheduled, ...updatedRemainder } = remainder;
              optionItem.reminders = updatedRemainder;
          } else if (remainder.type === 2) {
              const { onetime, ...updatedRemainder } = remainder;
              optionItem.reminders = updatedRemainder;
          }
        }
      }
      
      setDataQuestion(newDataQuestion);
      setIndexQuestion(0);
      setIndexOption(0);
      setShowSetFrekuensi(false);
      setRemainder(defaultRemainder);
    } catch (error) {
      return errorRemainder();
    }
  };

  const saveForm = async() => {
    setSaveFormLoad(true);
    let config = {
      method: ApiUrl.update_form.method,
      maxBodyLength: Infinity,
      url: ApiUrl.update_form.url+Segment,
      headers: { 
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`, 
          'Content-Type': 'application/json'
      },
      data : dataForm
    };
    await axios.request(config)
    .then(async(response) => {
        if(response.data.status){
            let config2 = {
              method: ApiUrl.update_form.method,
              maxBodyLength: Infinity,
              url: ApiUrl.update_form.url+Segment+"/questions",
              headers: { 
                  'Authorization': `Bearer ${localStorage.getItem('access_token')}`, 
                  'Content-Type': 'application/json'
              },
              data : JSON.stringify({questions: dataQuestion})
            };

            await axios.request(config2)
            .then(async(response2) => {
                if(response2.data.status){
                    toast.success(response.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      theme: "light",
                      transition: Bounce,
                    });
                    return window.history.back();
                }else{
                  setSaveFormLoad(false)
                  toast.error(response2.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      theme: "light",
                      transition: Bounce,
                  });
                }
            })
            .catch((error) => {
              setSaveFormLoad(false)
              toast.error(error.response.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "light",
                  transition: Bounce,
              });
          });
        }else{
            setSaveFormLoad(false)
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
        }
    })
    .catch((error) => {
        setSaveFormLoad(false)
        toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
        });
    });
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Detail Formulir</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/admin">Admin</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="#" onClick={() => {window.history.back()}}>Formulir</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Detail
              </li>
            </ol>
            <h4 className="main-title mb-0">Detail Formulir</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Form onSubmit={(e) => { e.preventDefault(); saveForm();}}>
              <div className={question ? "d-none" : "card card-one w-100"}>
                <div className="card-body">
                  <div className="wizard mb-3">
                    <div className="steps clearfix">
                      <ul role="tablist" className="justify-content-center">
                        <li
                          role="tab"
                          className="first current"
                          aria-disabled="false"
                          aria-selected="true"
                        >
                          <a
                            id="wizard2-t-0"
                            href="#wizard2-h-0"
                            aria-controls="wizard2-p-0"
                          >
                            <span className="current-info audible">
                              current step:{" "}
                            </span>
                            <span className="number">1</span>
                            <span className="title">Ruang Kerja / Program</span>
                          </a>
                        </li>
                        <li
                          role="tab"
                          className={!nextStepStatus ? "disabled" : "current"}
                          aria-disabled="true"
                        >
                          <a
                            id="wizard2-t-1"
                            href="#wizard2-h-1"
                            aria-controls="wizard2-p-1"
                          >
                            <span className="number">2</span>
                            <span className="title">Formulir</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={!nextStepStatus ? "d-block" : "d-none"}>
                    <Row>
                      <Col lg={6}>
                        <Form.Group controlId="formFileSm" className="mb-3">
                          <Form.Label>Ruang Kerja</Form.Label>
                          <Select
                            options={workSpace}
                            isSearchable={true} 
                            required
                            onChange={(selectedOption)=> {getProgramFromWorkspace(selectedOption.value); setDataForm(prevState => ({...prevState,workspace_id: selectedOption ? selectedOption.value : ''}))}} value={{ value: dataForm.workspace_id ? dataForm.workspace_id : '', label: dataForm.workspace_id ? workSpace.find(option => option.value === dataForm.workspace_id)?.label : 'Select Workspace'}}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group controlId="formFileSm" className="mb-3">
                          <Form.Label>Program</Form.Label>
                          <Select options={program} isSearchable={true} onChange={(selectedOption)=>setDataForm(prevState => ({...prevState,program_id: selectedOption ? selectedOption.value : ''}))} value={{ value: dataForm.program_id ? dataForm.program_id : '', label: program[0] ? dataForm.program_id ? program.find(option => option.value === dataForm.program_id).label : 'Select Program' : 'Select Program'}} required/>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className={!nextStepStatus ? "d-none" : "d-block"}>
                    <div
                      className="alert alert-secondary alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>Formulir</strong>
                      <br /> {getLabelByValue(workSpace, dataForm.workspace_id)} - {getLabelByValue(program, dataForm.program_id)}
                    </div>
                    <Row>
                      <Col lg={6}>
                        <Form.Group controlId="formFileSm" className="mb-3">
                          <Form.Label>Judul Formulir</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Masukan Judul Formulir"
                            onChange={(e)=>setDataForm(prevState => ({...prevState,name: e ? e.target.value : ''}))} 
                            value={dataForm.name}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group controlId="formFileSm" className="mb-3">
                          <Form.Label>Deskripsi Formulir</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Masukan Deskripsi Formulir"
                            onChange={(e)=>setDataForm(prevState => ({...prevState,description: e ? e.target.value : ''}))} 
                            value={dataForm.description}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group controlId="formFileSm" className="mb-3">
                          <Form.Label>Tipe Form</Form.Label>
                          <Select options={typeForm} required isSearchable={true} onChange={(selectedOption)=>{setDataForm(prevState => ({...prevState,type: selectedOption ? selectedOption.value : ''}))}} value={{ value: dataForm.type ? dataForm.type : '', label: typeForm[0] ? dataForm.type ? typeForm.find(option => option.value === dataForm.type).label : 'Select Type Form' : 'Select Type Form'}} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="card-footer">
                  <div className={!nextStepStatus ? "d-block" : "d-none"}>
                    <div className="d-flex justify-content-end align-items-center">
                      <Button
                        variant="secondary"
                        className="btn btn-outline-secondary"
                        onClick={()=> window.history.back()}
                      >
                        Batalkan
                      </Button>
                      &nbsp;
                      <Button variant="primary" onClick={() => nextStep()}>
                        Selanjutnya
                      </Button>
                    </div>
                  </div>

                  <div className={!nextStepStatus ? "d-none" : "d-block"}>
                    <div className="d-flex justify-content-end align-items-center">
                      <Button
                        variant="secondary"
                        className="btn btn-outline-secondary"
                        onClick={() => backStep()}
                      >
                        Kembali
                      </Button>
                      &nbsp;
                      <Button variant="primary" onClick={() => createQuestion()}>
                        Buat Pertanyaan
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className={question ? "d-block" : "d-none"}>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm mb-2"
                  onClick={() => backQuestion()}
                >
                  <i className="ri-arrow-left-line"></i> Kembali
                </button>
                <div
                  className="alert alert-solid alert-primary text-center pt-4 pb-4 mb-3"
                  role="alert"
                >
                  <h4 className="mb-1">{dataForm.name}</h4>{" "} {dataForm.description}
                </div>

                {dataQuestion.map((question) => (
                  <div className="card card-one mb-3" key={question.order_index}>
                    <div className="card-header justify-content-between">
                      <h6 className="card-title">Pertanyaan {question.order_index}</h6>
                      <div>
                        {question.order_index !== 1 ? 
                        <Button variant="danger" onClick={()=> deleteQuestion(question.order_index)}>
                          <i className="ri-delete-bin-5-line"></i>
                        </Button> : <></>}
                      </div>
                    </div>
                    <div className="card-body">
                      <Row as="fieldset" className="mb-3">
                        <Col lg={3}>
                          <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Tipe Jawaban <span className="text-danger">*</span></Form.Label>
                            <Select options={selectOptionstype} isSearchable={true} required onChange={(option) => handleInputChange(question.order_index, 'type', option.value)} value={{ value: question.type ? question.type : '', label: question.type ? selectOptionstype.find(option => option.value === question.type).label : 'Select Type'}}/>
                          </Form.Group>
                        </Col>
                        <Col lg={7}>
                          <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Pertanyaan <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Masukan Pertanyaan"
                              required
                              onChange={(e) => handleInputChange(question.order_index, 'value', e.target.value)} 
                              value={question.value ? question.value : ''}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={2}>
                          <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Gambar</Form.Label>
                            <Form.Control
                              type="file"
                              accept="image/*"
                              onChange={(e)=> handleFileChange(question.order_index, 'image', e.target.files[0])}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={8}>
                          <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Deskripsi</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Masukan Deskripsi Pertanyaan"
                              onChange={(e) => handleInputChange(question.order_index, 'description', e.target.value)} 
                              value={question.description ? question.description : ''}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Wajib Diisi? <span className="text-danger">*</span></Form.Label>
                            <Select
                              options={selectOptionsRequired}
                              isSearchable={true}
                              required
                              onChange={(option) => handleInputChange(question.order_index, 'is_required', option.value)} 
                              value={{ value: question.is_required ? question.is_required : '', label: question.is_required !== '' ? selectOptionsRequired.find(option => option.value === question.is_required).label : 'Silahkan Pilih'}}
                            />
                          </Form.Group>
                        </Col>
                        {question.options && question.options.map((option, index) => (
                          <React.Fragment key={index}>
                            <Row>
                              <Col lg="4">
                                <Form.Group controlId="formFileSm" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Masukan Jawaban"
                                    required
                                    value={option.value || ''}
                                    onChange={(e) => {
                                      const newOptions = question.options.map((opt, idx) =>
                                        idx === index ? { ...opt, value: e.target.value } : opt
                                      );
                                      handleInputChange(question.order_index, 'options', newOptions);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg="3" className="d-flex mb-3">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary btn-sm d-flex align-items-center"
                                  onClick={() => setFrekuensi(question.order_index, option.order_index)}
                                >
                                  <i className="ri-timer-line"></i> Reminder/Frekuensi
                                </button> {option.reminders ?
                                <>&nbsp;<Button variant="warning" onClick={()=>resetRemainder(question.order_index, option.order_index)}>
                                  <i className="ri-restart-line"></i>
                                </Button></> : <></>}&nbsp;{option.order_index !== 0 ?
                                <Button variant="danger" onClick={()=>deleteOption(question.order_index, option.order_index)}>
                                  <i className="ri-close-fill"></i>
                                </Button> : <></>}
                              </Col>
                            </Row>
                          </React.Fragment>
                        ))}
                        {question.type == 2 || question.type == 3 || question.type == 4 ?
                        <Row>
                          <Col lg="12">
                            <button className="text-primary btn" type="button" onClick={()=> addOption(question.order_index)}>
                              <i className="ri-add-line"></i> Tambah Opsi Lainnya
                            </button>
                          </Col>
                        </Row> : <></>}
                      </Row>
                    </div>
                  </div>
                ))}
                
                <Button variant="success" className="w-100 mb-3" onClick={handleAddQuestion}>
                  <i className="ri-add-line"></i> Tambah Pertanyaan
                </Button>

                <Modal
                  show={showSetFrekuensi}
                  onHide={closeSetFrekuensi}
                  centered
                  size="lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Tambah Frekuensi/Reminder</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {showSetFrekuensi ?
                    <>
                      <>
                        <h6 className="card-title mb-1">Frekuensi</h6>
                        <span>
                          Pastikan Anda sudah mengatur Frekuensi atau Frekuensi akan diatur secara default.
                        </span>
                        <hr />
                        <Row>
                          <Col lg={6}>
                            <Form.Group controlId="formFileSm" className="mb-3">
                              <Form.Label>Pesan Template</Form.Label>
                              <Select options={templateId} isSearchable={true} onChange={(option) => handleRemainderChange('template_id', option.value)} value={{ value: remainder.template_id ? remainder.template_id : '', label: remainder.template_id ? templateId.find(option => option.value === remainder.template_id).label : 'Select Template'}}/>
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group controlId="formFileSm" className="mb-3">
                              <Form.Label>Pilih Pengingat</Form.Label>
                              <Select options={remainderOption} isSearchable={true} onChange={(option) => handleRemainderChange('name', option.value)} value={{ value: remainder.name ? remainder.name : '', label: remainder.name ? remainderOption.find(option => option.value === remainder.name).label : 'Select Reminder'}}/>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Form.Group controlId="formFileSm" className="mb-3">
                          <Form.Label>Jenis Pengingat</Form.Label>
                          <Row>
                            <Col lg="6">
                              <div className={remainder.type == 1 ? "card card-task mb-3 border-success" : "card card-task mb-3"} onClick={() => {setRemainder({...remainder,type: 1,onetime: resetOnetime, scheduled: resetSchedule})}}>
                                <div className="card-body p-3 pb-1">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <span className="card-title">Satu Kali</span>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mt-2 mb-1">
                                    <h6 className="card-date">
                                      Kirim pengingat ke pasien berdasarkan peristiwa pemicu seperti pendaftaran atau pemulangan pasien
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className={remainder.type == 2 ? "card card-task mb-3 border-success" : "card card-task mb-3"} onClick={() => {setRemainder({...remainder, type: 2, onetime: resetOnetime, scheduled: resetSchedule})}}>
                                <div className="card-body p-3 pb-1">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <span className="card-title">Sedang Berlangsung</span>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mt-2 mb-1">
                                    <h6 className="card-date">
                                      Kirim pengingat ke pasien berdasarkan jadwal yang telah ditentukan
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form.Group>
                        {remainder.type == 1 ?
                        <>
                          <h6 className="text-title">Buat Pengingat 1x</h6>
                          <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Kondisi Yang Dipilih</Form.Label>
                            <Select options={oneTimeRemainderCondition} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition', option.value, 'onetime')} value={{ value: remainder.onetime.reminder_condition ? remainder.onetime.reminder_condition : '', label: remainder.onetime.reminder_condition ? oneTimeRemainderCondition.find(option => option.value === remainder.onetime.reminder_condition).label : 'Select Reminder'}} />
                          </Form.Group>
                          {remainder.onetime.reminder_condition == 'after_patient_enrollment' || remainder.onetime.reminder_condition == 'after_patient_discharge' ? <></> : <></>}
                          <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Kirim Pengingat</Form.Label>
                            <Row>
                              <Col lg="3">
                                <Form.Control type="number" placeholder="Harus Lebih Dari 0" onChange={(e) => handleRemainderChange('value', e.target.value ? e.target.value.toString() : "0", 'onetime')} value={remainder.onetime.value ? parseInt(remainder.onetime.value) : 0} />
                              </Col>
                              <Col lg="9">
                                <Select options={remainderConditionTriggerEvent} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition_trigger_event', option.value, 'onetime')} value={{ value: remainder.onetime.reminder_condition_trigger_event ? remainder.onetime.reminder_condition_trigger_event : '', label: remainder.onetime.reminder_condition_trigger_event ? remainderConditionTriggerEvent.find(option => option.value === remainder.onetime.reminder_condition_trigger_event).label : 'Select Trigger Event'}}/>
                              </Col>
                            </Row>
                          </Form.Group>
                        </> : <></>}
                        {remainder.type == 2 ? <>
                          <h6 className="text-title">Buat Pengingat Sedang Berlangsung</h6>
                          <Row>
                            <Col lg={remainder.scheduled.reminder_condition ? 6 : 12 }>
                              <Form.Group controlId="formFileSm" className="mb-3">
                                <Form.Label>Trigger Pengingat</Form.Label>
                                <Select options={scheduleRemainderCondition} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition', option.value, 'scheduled')} value={{ value: remainder.scheduled.reminder_condition ? remainder.scheduled.reminder_condition : '', label: remainder.scheduled.reminder_condition ? scheduleRemainderCondition.find(option => option.value === remainder.scheduled.reminder_condition).label : 'Select Remainder Condition'}}/>
                              </Form.Group>
                            </Col>
                            <Col lg={remainder.scheduled.reminder_condition ? 6 : 0} className={remainder.scheduled.reminder_condition ? '' : 'd-none'}>
                              <Form.Group controlId="formFileSm" className="mb-3">
                                <Form.Label>Frekuensi</Form.Label>
                                <Form.Control type="number" placeholder="Harus Lebih Dari 0" onChange={(e) => handleRemainderChange('frequently', e.target.value ? parseInt(e.target.value) : 0, 'scheduled', RolesForm[remainder.scheduled.reminder_condition]?.label)} value={remainder.scheduled[RolesForm[remainder.scheduled?.reminder_condition]?.label]?.frequently ? parseInt(remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label]?.frequently) : 0} />
                              </Form.Group>
                            </Col>
                          </Row>
                          {remainder.scheduled.reminder_condition ? 
                          <> 
                            <Row>
                              {remainder.scheduled.reminder_condition === 'year' ? 
                              <Col>
                                <div className={"card card-task mb-3"}>
                                  <div className="card-body p-3 pb-1">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <span className="card-title mb-2">Bulan</span>
                                    </div>
                                    <div className="">
                                      <Row>
                                        <Col lg={12}>
                                          <Form.Group controlId="formFileSm" className="mb-2">
                                          <Select
                                            options={selectOptionsmonths}
                                            isSearchable={true} 
                                            isMulti={true}
                                            onChange={(selectedOptions) => {
                                              const newRemainderMonths = selectedOptions.map(option => option.value);
                                              handleRemainderChange('months', newRemainderMonths ? newRemainderMonths : [], 'scheduled', 'yearly_reminder')
                                            }}
                                            value={remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label] ? remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label]?.months.map(month => {
                                              const option = selectOptionsmonths.find(option => option.value === month);
                                              return { value: month, label: option ? option.label : month };
                                            }) : { value: '', label: 'Pilih Bulan' }}
                                          />
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </Col>:<></>}
                              {remainder.scheduled.reminder_condition === 'month' || remainder.scheduled.reminder_condition === 'year' ? 
                              <Col>
                                <div className={"card card-task mb-3"}>
                                  <div className="card-body p-3 pb-1">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <span className="card-title mb-2">Tanggal</span>
                                    </div>
                                    <div className="">
                                      <Row>
                                        <Col lg={12}>
                                          <Form.Group controlId="formFileSm" className="mb-2">
                                          <Select
                                            options={selectOptionsdates}
                                            isSearchable={true} 
                                            isMulti={true}
                                            onChange={(selectedOptions) => {
                                              const newRemainderDates = selectedOptions.map(option => option.value);
                                              if(remainder.scheduled.reminder_condition === 'month'){
                                                handleRemainderChange('dates', newRemainderDates ? newRemainderDates : [], 'scheduled', 'monthly_reminder')
                                              }else{
                                                handleRemainderChange('dates', newRemainderDates ? newRemainderDates : [], 'scheduled', 'yearly_reminder')
                                              }
                                            }}
                                            value={remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label] ? remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label]?.dates.map(date => {
                                              const option = selectOptionsdates.find(option => option.value === date);
                                              return { value: date, label: option ? option.label : date };
                                            }) : { value: '', label: 'Pilih Tanggal' }}
                                          />
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </Col>:<></>}
                              {remainder.scheduled.reminder_condition === 'week' ? 
                                <Col>
                                  <div className={"card card-task mb-3"}>
                                    <div className="card-body p-3 pb-1">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <span className="card-title mb-2">Hari</span>
                                      </div>
                                      <div className="">
                                        <Row>
                                          <Col lg={12}>
                                            <Form.Group controlId="formFileSm" className="mb-2">
                                            <Select
                                              options={selectOptionsdays}
                                              isSearchable={true} 
                                              isMulti={true}
                                              onChange={(selectedOptions) => {
                                                const newRemainderDays = selectedOptions.map(option => option.value);
                                                handleRemainderChange('days', newRemainderDays ? newRemainderDays : [], 'scheduled', 'weekly_reminder')
                                              }}
                                              value={remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label] ? remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label]?.days.map(day => {
                                                const option = selectOptionsdays.find(option => option.value === day);
                                                return { value: day, label: option ? option.label : day };
                                              }) : { value: '', label: 'Pilih Hari' }}
                                            />
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              :<></>}
                              <Col>
                                <div className={"card card-task mb-3"}>
                                  <div className="card-body p-3 pb-1">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <span className="card-title mb-2">Jam</span>
                                    </div>
                                    <div className="">
                                      {remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition].label]?.times && remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition].label]?.times.map((val, index) => (
                                        <React.Fragment key={index}>
                                          <Row>
                                            <Col lg={8}>
                                              <Form.Group controlId="formFileSm" className="mb-2">
                                                <Form.Control
                                                  type="time"
                                                  placeholder="Masukan Waktu"
                                                  value={val}
                                                  onChange={(e) => {
                                                    if(remainder.scheduled.reminder_condition === 'month'){
                                                      handleRemainderChange('times', e.target.value ? e.target.value : '00:00', 'scheduled', 'monthly_reminder', index)
                                                    }else if(remainder.scheduled.reminder_condition === 'year'){
                                                      handleRemainderChange('times', e.target.value ? e.target.value : '00:00', 'scheduled', 'yearly_reminder', index)
                                                    }else if(remainder.scheduled.reminder_condition === 'week'){
                                                      handleRemainderChange('times', e.target.value ? e.target.value : '00:00', 'scheduled', 'weekly_reminder', index)
                                                    }else if(remainder.scheduled.reminder_condition === 'day'){
                                                      handleRemainderChange('times', e.target.value ? e.target.value : '00:00', 'scheduled', 'daily_reminder', index)
                                                    }
                                                  }}
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col lg={4}>
                                              {index !== 0 ?
                                              <Button variant="danger" onClick={()=>deleteRemainderSchedule(index)}>
                                                <i className="ri-close-fill"></i>
                                              </Button> : <></>}
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      ))}
                                      <button className="text-primary btn" type="button" onClick={()=> addRemainderSchedule()}>
                                        <i className="ri-add-line"></i> Tambah Opsi Lainnya
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </> : <></>}
                        </> : <></> }
                      </>
                      <>
                        <h6 className="card-title mb-1">Durasi</h6>
                        <span>
                          Pastikan Anda sudah mengatur durasi atau durasi akan diatur secara default.
                        </span>
                        <hr />
                        <Row>
                          <Col lg={12} className="mb-3">
                            <Form.Group controlId="formFileSm" className="mb-3">
                              <Form.Label>Pengingat Mulai</Form.Label>
                              <Select options={remainderDurationOption} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition', option.value, 'durations', 'start_trigger')} value={{ value: remainder.durations.start_trigger.reminder_condition ? remainder.durations.start_trigger.reminder_condition : '', label: remainder.durations.start_trigger.reminder_condition ? remainderDurationOption.find(option => option.value === remainder.durations.start_trigger.reminder_condition).label : 'Select Reminder'}} />
                            </Form.Group>
                            {remainder.durations.start_trigger.reminder_condition == 'after_patient_enrollment' || remainder.durations.start_trigger.reminder_condition == 'after_patient_discharge' ? <></> : <></>}
                            <Form.Group controlId="formFileSm" className="mb-3">
                              <Row>
                                <Col lg="3">
                                  <Form.Control type="number" placeholder="Harus Lebih Dari 0" onChange={(e) => handleRemainderChange('value', e.target.value ? e.target.value.toString() : "0", 'durations', 'start_trigger')} value={remainder.durations.start_trigger.value ? parseInt(remainder.durations.start_trigger.value) : 0} />
                                </Col>
                                <Col lg="9">
                                  <Select options={remainderConditionTriggerEvent} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition_trigger_event', option.value, 'durations', 'start_trigger')} value={{ value: remainder.durations.start_trigger.reminder_condition_trigger_event ? remainder.durations.start_trigger.reminder_condition_trigger_event : '', label: remainder.durations.start_trigger.reminder_condition_trigger_event ? remainderConditionTriggerEvent.find(option => option.value === remainder.durations.start_trigger.reminder_condition_trigger_event).label : 'Select Trigger Event'}}/>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Col>
                        
                          <Col lg={12} className="mb-3">
                            <Form.Group controlId="formFileSm" className="mb-3">
                              <Form.Label>Pengingat Selesai</Form.Label>
                              <Select options={remainderDurationOption} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition', option.value, 'durations', 'end_trigger')} value={{ value: remainder.durations.end_trigger.reminder_condition ? remainder.durations.end_trigger.reminder_condition : '', label: remainder.durations.end_trigger.reminder_condition ? remainderDurationOption.find(option => option.value === remainder.durations.end_trigger.reminder_condition).label : 'Select Reminder'}} />
                            </Form.Group>
                            {remainder.durations.end_trigger.reminder_condition == 'after_patient_enrollment' || remainder.durations.end_trigger.reminder_condition == 'after_patient_discharge' ? <></> : <></>}
                            <Form.Group controlId="formFileSm" className="mb-3">
                              <Row>
                                <Col lg="3">
                                  <Form.Control type="number" placeholder="Harus Lebih Dari 0" onChange={(e) => handleRemainderChange('value', e.target.value ? e.target.value.toString() : "0", 'durations', 'end_trigger')} value={remainder.durations.end_trigger.value ? parseInt(remainder.durations.end_trigger.value) : 0} />
                                </Col>
                                <Col lg="9">
                                  <Select options={remainderConditionTriggerEvent} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition_trigger_event', option.value, 'durations', 'end_trigger')} value={{ value: remainder.durations.end_trigger.reminder_condition_trigger_event ? remainder.durations.end_trigger.reminder_condition_trigger_event : '', label: remainder.durations.end_trigger.reminder_condition_trigger_event ? remainderConditionTriggerEvent.find(option => option.value === remainder.durations.end_trigger.reminder_condition_trigger_event).label : 'Select Trigger Event'}}/>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    </> : <></> }
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={closeSetFrekuensi}>
                      Tutup
                    </Button>
                    <Button variant="primary" onClick={()=>saveRemainder()}>
                      Simpan & Buat Pertanyaan
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Row>
                  <Col lg="12">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="mb-0 card-title">{dataQuestion.length} Pertanyaan Dibuat</h6>
                      <div>
                        <Button
                          variant="secondary"
                          className="btn btn-outline-secondary"
                          onClick={() => backQuestion()}
                        >
                          Kembali
                        </Button>
                        &nbsp;
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={saveFormLoad}
                        >
                          {saveFormLoad
                            ? "Silahkan Tunggu ..."
                            : "Simpan Form"}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}