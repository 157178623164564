import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import dataSidebar from "../data/Sidebar";

export default function MenuLeft() {
    const [pathname, setPathname] = useState('');
    const [firstSegment, setFirstSegment] = useState('');
    const [secondSegment, setSecondSegment] = useState('');
    const location = useLocation();

    useEffect(() => {
        const pathname = window.location.pathname;
        const segments = pathname.split('/');
        const firstSegment = segments[1];
        const secondSegment = segments[2];
        setPathname(pathname);
        setFirstSegment(firstSegment);
        setSecondSegment(secondSegment);
    }, [location]);

    return (
        <LeftMenu firstSegment={firstSegment} pathname={pathname} secondSegment={secondSegment} />
    );
}

function findKeyFromLink(link, datasidebar) {
    let foundKey = null;
    let foundSub = false;

    datasidebar.forEach(item => {
        if (item.link === link) {
            foundKey = item.key;
            const subItem = item?.sub?.find(sub => sub.link === link);
            if (subItem) {
                foundSub = true;
            }
        } else if (item.sub && item.sub.length > 0) {
            const subItem = item?.sub?.find(sub => sub.link === link);
            if (subItem) {
                foundKey = subItem.key_before;
            }
            if (subItem) {
                foundSub = true;
            }
        }
    });

    return {foundKey, foundSub};
}

function LeftMenu({ firstSegment, pathname }) {
    let datasidebar;
    if(firstSegment === 'admin'){
        datasidebar = dataSidebar.admin;
    } else if(firstSegment === 'klinik'){
        datasidebar = dataSidebar.klinik;
    } else if(firstSegment === 'dokter'){
        datasidebar = dataSidebar.dokter;
    }

    if (!datasidebar) return null;
    let datakeyashow = findKeyFromLink(pathname, datasidebar);
    const changeActiveSub = (link) =>{
        const elementssub = document.querySelectorAll('.nav-sub-remove-active');
        elementssub.forEach(element => {
            element.classList.remove('active');
        });
        
        const elementsub = document.querySelector(`.nav-sub-remove-active[href="${link}"]`);
        if (elementsub) {
            elementsub.classList.add("active");
        }
    }

    return (
        <>
            {datasidebar.filter(m => m.sub && m.sub.length > 0).length > 0 && (
                <div key="sidebar" className={datakeyashow.foundSub ? "menu-left" : "menu-left d-none"}>
                    <div className="menu-left-header" key="header"></div>
                    <div className="menu-left-body">
                        {datasidebar.map((md, keyd) => (
                            md.sub && md.sub.length > 0 && (
                                <div key={`menu-${keyd}`} data-subkey={md.key} className={md.key === datakeyashow.foundKey ? "show-left" : "d-none"}>
                                    <h6 className="fw-semibold fs-6">{md.menu}</h6>
                                    <ul className="nav nav-sub d-block">
                                        {md.sub.map((m, subKey) => (
                                            <li key={`sub-${keyd}-${subKey}`} className="nav-item item2">
                                                <Link key={`link-${keyd}-${subKey}`} to={m.link} className={m.link === pathname ? "nav-sub-link nav-sub-remove-active active" : "nav-sub-link nav-sub-remove-active"} onClick={() => changeActiveSub(m.link)}>
                                                    {m.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            )}
        </>
    );

   
}