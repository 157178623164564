import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Dropdown, Accordion, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchPatientProgram,
    fetchDeletePatientProgram,
    fetchUpdateAssignProgram,
    resetUpdateAssignProgram
} from "../../../../redux/slices/dokterPasienSlice";
import {
    fetchRemainderOption,
    fetchOnetimeRemainderCondition,
    fetchScheduleRemainderCondition,
    fetchRemainderConditionTriggerEvent,
    fetchDurationOption,
} from "../../../../redux/slices/optionFormSlice";
import {
    fetchListTemplate
} from "../../../../redux/slices/instansiTemplateWaSlice"
import {
  resetStatusTemplateDetail, fetchTemplateDetail
} from "../../../../redux/slices/instansiTemplateWaSlice"
import { RolesForm } from "../../../../helpers/roles";
import ApiUrl from "../../../../helpers/url";
import { convertToGMT7 } from "../../../../helpers/timeConvert";
export default function PengaturanKlinis({selectedWorkspace, segment, pasienData}) {
    const [loadForm, setLoadForm] = useState(true);
    const dispatch = useDispatch();
    const dataProgramPasienModal = useSelector(
        (state) => state.dokterPasien.patientProgramResp.data
    );
    const templateDetail = useSelector((state) => state.instansiTemplateWa.templateDetailResp);
    const [dataModalAssignProgPass, setDataModalAssignProgPass] = useState({});
    const [showAssignProgram, setShowAssignProgram] = useState(false);
    const [showSetFrekuensi, setShowSetFrekuensi] = useState(false);
    const [saveFormLoad, setSaveFormLoad] = useState(false);
    const [oldFrequency, setOldFrequency] = useState('');
    const [newFrequency, setNewFrequency] = useState('');
    const [programId, setProgramId] = useState('');
    const [reminderId, setReminderId] = useState('');
    const [showDetail, setShowDetail] = useState(false);
    const defaultRemainder = {
        name: "",
        template_id: "",
        type: 0,
        onetime: {
            reminder_condition: "",
            reminder_condition_trigger_event: "",
            value: "0"
        },
        scheduled: {
            reminder_condition: "",
        },
        durations: {
            start_trigger: {
                reminder_condition: "",
                reminder_condition_trigger_event: "",
                value: "0"
            },
            end_trigger: {
                reminder_condition: "",
                reminder_condition_trigger_event: "",
                value: "0"
            }
        }
    }

    const resetOnetime = {
        reminder_condition: "",
        reminder_condition_trigger_event: "",
        value: "0"
    }

    const selectOptionsdays = [
        { value: 1, label: "Senin" },
        { value: 2, label: "Selasa" },
        { value: 3, label: "Rabu" },
        { value: 4, label: "Kamis" },
        { value: 5, label: "Jumat" },
        { value: 6, label: "Sabtu" },
        { value: 7, label: "Minggu" },
    ];
    
    const selectOptionsdates = [];
    for (let i = 1; i <= 31; i++) {
        selectOptionsdates.push({ value: i, label: i.toString() });
    }
    
    const selectOptionsmonths = [
        { value: 1, label: "Januari" },
        { value: 2, label: "Februari" },
        { value: 3, label: "Maret" },
        { value: 4, label: "April" },
        { value: 5, label: "Mei" },
        { value: 6, label: "Juni" },
        { value: 7, label: "Juli" },
        { value: 8, label: "Agustus" },
        { value: 9, label: "September" },
        { value: 10, label: "Oktober" },
        { value: 11, label: "November" },
        { value: 12, label: "Desember" },
    ];

    const resetSchedule = {
        reminder_condition: "",
    };
    const [remainder, setRemainder] = useState(defaultRemainder);
    const dokterPasien = useSelector((state) => state.dokterPasien);
    const [templateId, setTemplateId] = useState([]);
    const [remainderOption, setRemainderOption] = useState([]);
    const [oneTimeRemainderCondition, setOneTimeRemainderCondition] = useState([]);
    const [scheduleRemainderCondition, setScheduleRemainderCondition] = useState([]);
    const [remainderDurationOption, setRemainderDurationOption] = useState([]);
    const [remainderConditionTriggerEvent, setRemainderConditionTriggerEvent] = useState([]);
    const [dataClickReminder, setDataClickReminder] = useState([]);

    const optionForm = useSelector((state) => state.optionForm);
    const instansiTemplateWa = useSelector((state) => state.instansiTemplateWa);

    const dispatchFecthGetOptionForm = () => {
        if (optionForm.listRemainderOptionStatus === "idle") {
          dispatch(fetchRemainderOption());
        }
    
        if (optionForm.listOnetimeRemainderConditionStatus === "idle") {
          dispatch(fetchOnetimeRemainderCondition());
        }
    
        if (optionForm.listScheduleRemainderConditionStatus === "idle") {
          dispatch(fetchScheduleRemainderCondition());
        }
    
        if (optionForm.listRemainderConditionTriggerEventStatus === "idle") {
          dispatch(fetchRemainderConditionTriggerEvent());
        }
    
        if (optionForm.listDurationOptionStatus === "idle") {
          dispatch(fetchDurationOption());
        }
    }
    
    const dispatchFecthListTemplate = () => {
        if (instansiTemplateWa.listTemplateStatus === 'idle') {
              dispatch(fetchListTemplate());
        }
    }

    const dispatchFecthTemplateDetail = async (id) => {
      if (instansiTemplateWa.templateDetailStatus === 'idle') {
        await dispatch(fetchTemplateDetail(id));
        if (instansiTemplateWa.templateDetailError) {
          toast.error(instansiTemplateWa.templateDetailError, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
          });
        }
      }
      setShowDetail(true);
    };

    const dispatchFetchPatientProgram = async (id) => {
        const disp = await dispatch(fetchPatientProgram(id));
        if (!disp.payload.status) {
          toast.error(disp.payload.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
        }
        setLoadForm(false)
    };

    const dispatchDeletePatientProgram = async (patientId, detailId) => {
        const param = {
          patientId: patientId,
          detailId: detailId,
        };

        swal({
          title: "Peringatan!",
          text: "Apa program ini akan dihapus dari pasien?",
          icon: "warning",
          dangerMode: true,
          buttons: {
            cancel: "Batal",
            confirm: {
              text: "Hapus",
              value: true,
              visible: true,
              className: "btn-danger",
              closeModal: true,
            },
          },
        })
          .then(async (willDelete) => {
            if (willDelete) {
              const disp = await dispatch(fetchDeletePatientProgram(param));
              if (disp.payload.data.status !== true) {
                toast.error(disp.payload.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "light",
                  transition: Bounce,
                });
              } else {
                toast.success(disp.payload.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "light",
                  transition: Bounce,
                });
              }
              dispatchFetchPatientProgram(segment);
            }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
        });
    };

    const openModalPenggunaKlinis = (doctorData, nurseData, program_id) => {
      const selectedNurse = nurseData.map(item => (item.id));
      const selectedDoctor = doctorData.map(item => (item.id));
      setDataModalAssignProgPass((dataModalAssignProgPass) => ({
          ...dataModalAssignProgPass,
          nurseId: selectedNurse ? selectedNurse : [],
          doctorId: selectedDoctor ? selectedDoctor : [],
          program_id: program_id
      }));
      setShowAssignProgram(true);
  }

  const closeModalPenggunaKlinis = () => {
      setDataModalAssignProgPass({});
      setShowAssignProgram(false);
  };

  const handleChangeModalAssgnProg = (e, key) => {
      if(key == 'doctorId' || key == 'nurseId'){
        setDataModalAssignProgPass((dataModalAssignProgPass) => ({
          ...dataModalAssignProgPass,
          [key]: e ? e.map(option => option.value) : [],
        }));
      }else{
        setDataModalAssignProgPass((dataModalAssignProgPass) => ({
          ...dataModalAssignProgPass,
          [key]: e.value,
        }));
      }
  };
  
  const savePenggunaKlinis = async () => {
      const data = {
        nurse_ids: dataModalAssignProgPass.nurseId,
        doctor_ids: dataModalAssignProgPass.doctorId,
      };
      const disp = await dispatch(
        fetchUpdateAssignProgram({ data, id: pasienData.id, program_id: dataModalAssignProgPass.program_id  })
      );
      if (disp?.payload?.data?.status) {
        toast.success(disp.payload.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
        dispatch(resetUpdateAssignProgram());
        dispatchFetchPatientProgram(segment);
        closeModalPenggunaKlinis();
      }else{
        toast.error(disp.payload.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
        dispatch(resetUpdateAssignProgram());
      }
  };
    
    const handleRemainderChange = (field, value, thirdField = null, fourField = null, index = null) => {
      if (thirdField == null && fourField == null) {
        if(field == 'onetime'){
          setNewFrequency('onetime')
        }
        
        setRemainder({
          ...remainder,
          [field]: value
        });
      } else if (thirdField !== null && fourField == null) {
        if(thirdField == 'scheduled' && field == 'reminder_condition'){
          let newScheduled = {
            ...remainder.scheduled,
            reminder_condition: value,
          };
        
          if (value === 'day') {
            setNewFrequency('daily')
            newScheduled.daily_reminder = {
              times: [],
              frequently: 0
            };
            delete newScheduled.weekly_reminder;
            delete newScheduled.monthly_reminder;
            delete newScheduled.yearly_reminder;
          } else if (value === 'week') {
            setNewFrequency('weekly')
            newScheduled.weekly_reminder = {
              days: [],
              times: [],
              frequently: 0
            };
            delete newScheduled.daily_reminder;
            delete newScheduled.monthly_reminder;
            delete newScheduled.yearly_reminder;
          } else if (value === 'month') {
            setNewFrequency('monthly')
            newScheduled.monthly_reminder = {
              dates: [],
              times: [],
              frequently: 0
            };
            delete newScheduled.daily_reminder;
            delete newScheduled.weekly_reminder;
            delete newScheduled.yearly_reminder;
          } else if (value === 'year') {
            setNewFrequency('yearly')
            newScheduled.yearly_reminder = {
              dates: [],
              months: [],
              times: [],
              frequently: 0
            };
            delete newScheduled.daily_reminder;
            delete newScheduled.weekly_reminder;
            delete newScheduled.monthly_reminder;
          } else {
            delete newScheduled.daily_reminder;
            delete newScheduled.weekly_reminder;
            delete newScheduled.monthly_reminder;
            delete newScheduled.yearly_reminder;
          }
          setRemainder({
            ...remainder,
            scheduled: newScheduled
          });
        }else{
          setRemainder({
            ...remainder,
            [thirdField]: {
              ...remainder[thirdField],
              [field]: value
            }
          });
        }
      } else if (thirdField !== null && fourField !== null) {
        if(index !== null){
          setRemainder({
            ...remainder,
            [thirdField]: {
              ...remainder[thirdField],
              [fourField]: {
                ...remainder[thirdField][fourField],
                [field]: remainder[thirdField][fourField][field].map((time, i) => 
                  i === index ? value : time
                )
              }
            }
          });
        }else{
          setRemainder({
            ...remainder,
            [thirdField]: {
              ...remainder[thirdField],
              [fourField]: {
                ...remainder[thirdField][fourField],
                [field]: value
              }
            }
          });
        }
      }
    };

    const setFrekuensi = (data, program_id, tipereminder) => {
      if(data?.id){
        if(tipereminder == 'onetime'){
          var existReminder = {
            name: "",
            template_id: data.template_id,
            type: 1,
            onetime: {
                reminder_condition: data.start_reminder_condition,
                reminder_condition_trigger_event: data.start_reminder_condition_trigger_event,
                value: data.start_reminder_condition_value
            },
            durations: {
                start_trigger: {
                    reminder_condition: data.start_reminder_condition,
                    reminder_condition_trigger_event: data.start_reminder_condition_trigger_event,
                    value: data.start_reminder_condition_value
                },
                end_trigger: {
                    reminder_condition: "",
                    reminder_condition_trigger_event: "",
                    value: "0"
                }
            }
          }
        }else if(tipereminder == 'daily'){
          var existReminder = {
            name: "",
            template_id: data.template_id,
            type: 2,
            scheduled: {
              reminder_condition: "day",
              daily_reminder: { 
                  frequently: data.total_frequently,
                  times: [
                      data.reminder_time
                  ]
              },
            },
            durations: {
                start_trigger: {
                    reminder_condition: data.start_reminder_condition,
                    reminder_condition_trigger_event: data.start_reminder_condition_trigger_event,
                    value: data.start_reminder_condition_value
                },
                end_trigger: {
                    reminder_condition: data.end_reminder_condition,
                    reminder_condition_trigger_event: data.end_reminder_condition_trigger_event,
                    value: data.end_reminder_condition_value
                }
            }
          }
        }else if(tipereminder == 'weekly'){
          var existReminder = {
            name: "",
            template_id: data.template_id,
            type: 2,
            scheduled: {
              reminder_condition: "week",
              weekly_reminder: { 
                  frequently: data.total_frequently,
                  days: [
                    data.reminder_day
                  ],
                  times: [
                      data.reminder_time
                  ]
              },
            },
            durations: {
                start_trigger: {
                    reminder_condition: data.start_reminder_condition,
                    reminder_condition_trigger_event: data.start_reminder_condition_trigger_event,
                    value: data.start_reminder_condition_value
                },
                end_trigger: {
                    reminder_condition: data.end_reminder_condition,
                    reminder_condition_trigger_event: data.end_reminder_condition_trigger_event,
                    value: data.end_reminder_condition_value
                }
            }
          }
        }else if(tipereminder == 'monthly'){
          var existReminder = {
            name: "",
            template_id: data.template_id,
            type: 2,
            scheduled: {
              reminder_condition: "month",
              monthly_reminder: { 
                  frequently: data.total_frequently,
                  dates: [
                    data.reminder_date
                  ],
                  times: [
                      data.reminder_time
                  ]
              },
            },
            durations: {
                start_trigger: {
                    reminder_condition: data.start_reminder_condition,
                    reminder_condition_trigger_event: data.start_reminder_condition_trigger_event,
                    value: data.start_reminder_condition_value
                },
                end_trigger: {
                    reminder_condition: data.end_reminder_condition,
                    reminder_condition_trigger_event: data.end_reminder_condition_trigger_event,
                    value: data.end_reminder_condition_value
                }
            }
          }
        }else if(tipereminder == 'yearly'){
          var existReminder = {
            name: "",
            template_id: data.template_id,
            type: 2,
            scheduled: {
              reminder_condition: "year",
              yearly_reminder: { 
                  frequently: data.total_frequently,
                  months: [
                    data.reminder_month
                  ],
                  dates: [
                    data.reminder_date
                  ],
                  times: [
                      data.reminder_time
                  ]
              },
            },
            durations: {
                start_trigger: {
                    reminder_condition: data.start_reminder_condition,
                    reminder_condition_trigger_event: data.start_reminder_condition_trigger_event,
                    value: data.start_reminder_condition_value
                },
                end_trigger: {
                    reminder_condition: data.end_reminder_condition,
                    reminder_condition_trigger_event: data.end_reminder_condition_trigger_event,
                    value: data.end_reminder_condition_value
                }
            }
          }
        }
        setRemainder(existReminder)
        setOldFrequency(tipereminder)
        setNewFrequency(tipereminder)
        setReminderId(data.id)
      }else{
        setOldFrequency("")
        setNewFrequency("")
        setReminderId("")
        setRemainder(defaultRemainder)
      }
      setProgramId(program_id);
      setShowSetFrekuensi(true);
    };

    const callDeleteFrekuensi = async(reminder_id, program_id, tipereminder, index, deleteCreate) =>{
      let config = {
        method: "DELETE",
        maxBodyLength: Infinity,
        url:ApiUrl.patient.url +"/"+segment+"/programs/"+program_id +"/reminders/"+reminder_id+"?frequency_type="+tipereminder,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          'Content-Type': 'application/json', 
        },
      };

      await axios
      .request(config)
      .then((response) => {
          if (response.data.status) {
            if(!deleteCreate){
              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
              });
              
              const updatedReminders = dataClickReminder.map((program) => {
                if (program.program_id === program_id) {
                  const removeReminderAtIndex = (remindersList) => {
                    return remindersList.filter((_, idx) => idx !== index);
                  };
        
                  const updatedOnetime = tipereminder === 'onetime'
                    ? removeReminderAtIndex(program.onetime)
                    : program.onetime;
        
                  const updatedScheduled = {
                    daily_reminder: tipereminder === 'daily'
                      ? removeReminderAtIndex(program.scheduled.daily_reminder)
                      : program.scheduled.daily_reminder,
                    weekly_reminder: tipereminder === 'weekly'
                      ? removeReminderAtIndex(program.scheduled.weekly_reminder)
                      : program.scheduled.weekly_reminder,
                    monthly_reminder: tipereminder === 'monthly'
                      ? removeReminderAtIndex(program.scheduled.monthly_reminder)
                      : program.scheduled.monthly_reminder,
                    yearly_reminder: tipereminder === 'yearly'
                      ? removeReminderAtIndex(program.scheduled.yearly_reminder)
                      : program.scheduled.yearly_reminder,
                  };
        
                  return {
                    ...program,
                    onetime: updatedOnetime,
                    scheduled: updatedScheduled,
                  };
                }
                return program;
              });
          
              setDataClickReminder(updatedReminders);
            }
          } else {
              toast.error(response.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "light",
                  transition: Bounce,
              });
          }
      })
      .catch((error) => {
          toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
          });
      });
    }

    const deleteFrekuensi = async(reminder_id, program_id, tipereminder, index, deleteCreate) => {
      swal({
        title: "Peringatan!",
        text: "Apa anda yakin ingin menghapus pengingat ini?",
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: "Batal",
          confirm: {
            text: "Ya Hapus",
            value: true,
            visible: true,
            className: "btn-danger",
            closeModal: true,
          },
        },
      }).then(async(willDelete) => {
        if (willDelete) {
          return callDeleteFrekuensi(reminder_id, program_id, tipereminder, index, deleteCreate)
        }
      });
    }
    
    const closeSetFrekuensi = () => {
      setRemainder(defaultRemainder);
      setShowSetFrekuensi(false);
    };
    
    const addRemainderSchedule = () =>{
      var arr = RolesForm[remainder.scheduled.reminder_condition].label
  
      setRemainder(prevRemainder => {
        const updatedTimes = [
          ...prevRemainder.scheduled[arr].times,
          '00:00'
        ];
    
        return {
          ...prevRemainder,
          scheduled: {
            ...prevRemainder.scheduled,
            [arr]: {
              ...prevRemainder.scheduled[arr],
              times: updatedTimes
            }
          }
        };
      });
    }

    const deleteRemainderSchedule = (indexToRemove) => {
      var arr = '';
      if(remainder.scheduled.reminder_condition == 'day'){
        arr = "daily_reminder"
      }else if(remainder.scheduled.reminder_condition == 'week'){
        arr = "weekly_reminder"
      }else if(remainder.scheduled.reminder_condition == 'month'){
        arr = "monthly_reminder"
      }else if(remainder.scheduled.reminder_condition == 'year'){
        arr = "yearly_reminder"
      }
      
      setRemainder(prevRemainder => {
        const updatedTimes = prevRemainder.scheduled[arr].times.filter((time, index) => index !== indexToRemove);
    
        return {
          ...prevRemainder,
          scheduled: {
            ...prevRemainder.scheduled,
            [arr]: {
              ...prevRemainder.scheduled[arr],
              times: updatedTimes
            }
          }
        };
      });
    };

    const errorRemainder = () => {
      toast.error('Lengkapi Form Reminder Terlebih Dahulu!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }

    const saveRemainder = async () => {
      try {
        if (!remainder.name || !remainder.template_id || !remainder.type) {
          return errorRemainder();
        }
    
        if (remainder.type === 1) {
          if (!remainder.onetime.reminder_condition) {
            return errorRemainder();
          } else {
            if (
              remainder.onetime.reminder_condition === 'after_patient_enrollment' ||
              remainder.onetime.reminder_condition === 'after_patient_discharge'
            ) {
              if (!remainder.onetime.reminder_condition_trigger_event) {
                return errorRemainder();
              }
            }
          }
        } else if (remainder.type === 2) {
          if (remainder.scheduled.reminder_condition) {
            if (remainder.scheduled.reminder_condition === 'day') {
              if (!remainder.scheduled.daily_reminder.frequently || remainder.scheduled.daily_reminder.times.length < 1) {
                return errorRemainder();
              }
            } else if (remainder.scheduled.reminder_condition === 'week') {
              if (!remainder.scheduled.weekly_reminder.frequently || remainder.scheduled.weekly_reminder.times.length < 1 || remainder.scheduled.weekly_reminder.days.length < 1) {
                return errorRemainder();
              }
            } else if (remainder.scheduled.reminder_condition === 'month') {
              if (!remainder.scheduled.monthly_reminder.frequently || remainder.scheduled.monthly_reminder.times.length < 1 || remainder.scheduled.monthly_reminder.dates.length < 1) {
                return errorRemainder();
              }
            } else if (remainder.scheduled.reminder_condition === 'year') {
              if (!remainder.scheduled.yearly_reminder.frequently || remainder.scheduled.yearly_reminder.times.length < 1 || remainder.scheduled.yearly_reminder.dates.length < 1 || remainder.scheduled.yearly_reminder.months.length < 1) {
                return errorRemainder();
              }
            }
          } else {
            return errorRemainder();
          }
        }
        
        if (remainder.durations.start_trigger.reminder_condition) {
          if (
            remainder.durations.start_trigger.reminder_condition === 'after_patient_enrollment' ||
            remainder.durations.start_trigger.reminder_condition === 'after_patient_discharge'
          ) {
            if (!remainder.durations.start_trigger.reminder_condition_trigger_event) {
              return errorRemainder();
            }
          }
        } else {
          return errorRemainder();
        }
    
        if (remainder.durations.end_trigger.reminder_condition) {
          if (
            remainder.durations.end_trigger.reminder_condition === 'after_patient_enrollment' ||
            remainder.durations.end_trigger.reminder_condition === 'after_patient_discharge'
          ) {
            if (!remainder.durations.end_trigger.reminder_condition_trigger_event) {
              return errorRemainder();
            }
          }
          saveForm('edit');
        } else {
          return errorRemainder();
        }
        
      } catch (error) {
        return errorRemainder();
      }
    };

    const saveForm = async(edit = null) => {
        if(edit){
          await callDeleteFrekuensi(reminderId, programId, oldFrequency, 0, true)
        }
        setSaveFormLoad(true);   

        let config = {
          method: 'POST',
          maxBodyLength: Infinity,
          url: `${ApiUrl.patient.url}/${segment}/programs/${programId}/reminders`,
          headers: { 
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`, 
              'Content-Type': 'application/json'
          },
          data : JSON.stringify(remainder)
        };

        await axios.request(config)
        .then(async(response) => {
            setSaveFormLoad(false)
            if(response.data.status){
                toast.success(response.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "light",
                  transition: Bounce,
                });
                findReminder(programId, true);
                closeSetFrekuensi();
            }else{
              toast.error(response.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "light",
                  transition: Bounce,
              });
            }
        })
        .catch((error) => {
          setSaveFormLoad(false)
          toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
          });
      });
    }

    const findReminder = async(program_id, fetch_new) => {
      if(!fetch_new){
        if (dataClickReminder?.some(reminder => reminder.program_id === program_id) ?? false) {
            return true;
        }
      }

      let config = {
          method: ApiUrl.list_report.method,
          maxBodyLength: Infinity,
          url:ApiUrl.patient.url +"/"+segment+"/programs/"+program_id +"/reminders",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
      };

      await axios
      .request(config)
      .then((response) => {
          if (response.data.status) {
              const filteredDataClickReminder = dataClickReminder.filter(program => program.program_id !== program_id);
              const newReminderData = { program_id, ...response.data.data };
              const updatedDataClickReminder = [...filteredDataClickReminder, newReminderData];
              setDataClickReminder(updatedDataClickReminder);
          } else {
              toast.error(response.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "light",
                  transition: Bounce,
              });
          }
      })
      .catch((error) => {
          toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
          });
      });
    }

    const disableEnable = async(status, reminder_id, program_id, tipereminder, index) => {
      let data = JSON.stringify({
        "frequency_type": tipereminder,
        "active": status
      });

      let config = {
          method: "PUT",
          maxBodyLength: Infinity,
          url:ApiUrl.patient.url +"/"+segment+"/programs/"+program_id +"/reminders/"+reminder_id+"/status",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            'Content-Type': 'application/json', 
          },
          data: data
      };

      await axios
      .request(config)
      .then((response) => {
          if (response.data.status) {
              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
              });
              
              const updatedReminders = dataClickReminder.map((program) => {
                if (program.program_id === program_id) {
                  const updateReminderAtIndex = (remindersList) => {
                    return remindersList.map((reminder, indexlist) => {
                      if (indexlist === index) {
                        return { ...reminder, is_activated: status };
                      }
                      return reminder;
                    });
                  };
          
                  const updatedOnetime = tipereminder === 'onetime'
                    ? updateReminderAtIndex(program.onetime)
                    : program.onetime;
          
                  const updatedScheduled = {
                    daily_reminder: tipereminder === 'daily'
                      ? updateReminderAtIndex(program.scheduled.daily_reminder)
                      : program.scheduled.daily_reminder,
                    weekly_reminder: tipereminder === 'weekly'
                      ? updateReminderAtIndex(program.scheduled.weekly_reminder)
                      : program.scheduled.weekly_reminder,
                    monthly_reminder: tipereminder === 'monthly'
                      ? updateReminderAtIndex(program.scheduled.monthly_reminder)
                      : program.scheduled.monthly_reminder,
                    yearly_reminder: tipereminder === 'yearly'
                      ? updateReminderAtIndex(program.scheduled.yearly_reminder)
                      : program.scheduled.yearly_reminder,
                  };
          
                  return {
                    ...program,
                    onetime: updatedOnetime,
                    scheduled: updatedScheduled,
                  };
                }
                return program;
              });
          
              setDataClickReminder(updatedReminders);
          } else {
              toast.error(response.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "light",
                  transition: Bounce,
              });
          }
      })
      .catch((error) => {
          toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
          });
      });
    }

    const closeDetailData = () => {
      dispatch(resetStatusTemplateDetail());
      setShowDetail(false);
    };

    const doesProgramExist = (id) => {
        const program = dataClickReminder.find(item => item.program_id === id);

        if (!program) {
            return false;
        }

        const { scheduled } = program;

        return (
            program.onetime.length > 0 ||
            scheduled.daily_reminder.length > 0 ||
            scheduled.weekly_reminder.length > 0 ||
            scheduled.monthly_reminder.length > 0 ||
            scheduled.yearly_reminder.length > 0
        );
    };

    const doesProgramExistData = (id) => {
      return dataClickReminder.find(item => item.program_id === id)
    };

    const createLoopReminder = (tipe, data, index, tipereminder, program_id) => {
      return (
        <>
          <Card className="p-2 mb-2" key={index}>
            <div className="d-flex align-items-center justify-content-between border-bottom pb-2">
              <h6 className=" mb-0">Pengingat {tipe} {data.is_finished ? "(Selesai)" : ""}</h6> 
              {!data.is_finished ?
              <Dropdown className="btn-sm">
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-basic"
                  className="btn-sm"
                >
                <i className="ri-settings-3-line"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                <Dropdown.Item href="#" onClick={()=>setFrekuensi(data, program_id, tipereminder, index)}>Edit Pengingat</Dropdown.Item>
                <Dropdown.Item href="#" className="text-danger" onClick={()=>deleteFrekuensi(data.id, program_id, tipereminder, index)}>Hapus Pengingat</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> : <></>}
            </div>
            <div className={`d-flex justify-content-start align-items-center border-bottom`}>
              <h6 className="mt-2 fs-xs" style={{width:"250px"}}>Status Pengingat</h6>
              <h6 className="fs-xs text-secondary mb-0 d-flex align-items-center">
              <input
                  className="form-check-input mt-0"
                  type="checkbox"
                  checked={data.is_activated}
                  disabled={data.is_finished}
                  onChange={(e)=> disableEnable(!data.is_activated, data.id, program_id, tipereminder, index)}
              />&nbsp;
              <label className="form-check-label mb-0" >
                  Aktif
              </label>
              </h6>
            </div>
            <div className={`d-flex justify-content-start align-items-center border-bottom`}>
              <h6 className="mt-2 fs-xs" style={{width:"250px"}}>Waktu Pengingat</h6>
              <h6 className="fs-xs text-secondary mb-0">{tipe=='Bulanan' || tipe=='Tahunan' ? 'Tanggal ' + selectOptionsdates.find(option => option.value === data.reminder_date)?.label : ''} {tipe=='Tahunan' ? selectOptionsmonths.find(option => option.value === data.reminder_month)?.label : ''} {tipe=='Mingguan' ? 'Hari ' + selectOptionsdays.find(option => option.value === data.reminder_day)?.label : ''} {data.reminder_time}</h6>
            </div>
            {tipe !== 'Onetime'?
            <div className={`d-flex justify-content-start align-items-center border-bottom`}>
              <h6 className="mt-2 fs-xs" style={{width:"250px"}}>Frekuensi Pengingat</h6>
              <h6 className="fs-xs text-secondary mb-0">{data.total_frequently}</h6>
            </div> : <></>}
            <div className={`d-flex justify-content-start align-items-center border-bottom`}>
              <h6 className="mt-2 fs-xs" style={{width:"250px"}}>Start Reminder Condition</h6>
              <h6 className="fs-xs text-secondary mb-0">{oneTimeRemainderCondition.find(option => option.value === data.start_reminder_condition)?.label}</h6>
            </div>
            <div className={`d-flex justify-content-start align-items-center border-bottom`}>
              <h6 className="mt-2 fs-xs" style={{width:"250px"}}>Start Reminder Condition Trigger</h6>
              <h6 className="fs-xs text-secondary mb-0">{data.start_reminder_condition_value} {remainderConditionTriggerEvent.find(option => option.value === data.start_reminder_condition_trigger_event)?.label}</h6>
            </div>
            {tipe !== 'Onetime' ? 
            <>
              <div className={`d-flex justify-content-start align-items-center border-bottom`}>
                <h6 className="mt-2 fs-xs" style={{width:"250px"}}>End Reminder Condition</h6>
                <h6 className="fs-xs text-secondary mb-0">{remainderDurationOption.find(option => option.value === data.end_reminder_condition)?.label}</h6>
              </div>
              <div className={`d-flex justify-content-start align-items-center border-bottom`}>
                <h6 className="mt-2 fs-xs" style={{width:"250px"}}>End Reminder Condition Trigger</h6>
                <h6 className="fs-xs text-secondary mb-0">{data.end_reminder_condition_value} {remainderConditionTriggerEvent.find(option => option.value === data.end_reminder_condition_trigger_event)?.label}</h6>
              </div>
            </> : <></>}
            <div className={`d-flex justify-content-start align-items-center`}>
              <h6 className="mt-2 fs-xs" style={{width:"250px"}}>Template Message</h6>
              <h6 className="fs-xs text-secondary mb-0 text-success cursor-pointer" onClick={()=>dispatchFecthTemplateDetail(data.template_id)} >{templateId.find(option => option.value === data.template_id)?.label}</h6>
            </div>
          </Card>
        </>
      );
    }

    useEffect(()=>{
        dispatchFetchPatientProgram(segment);
        dispatchFecthGetOptionForm();
        dispatchFecthListTemplate();

        if(instansiTemplateWa.listTemplateStatus === "succeeded"){
        const transformedData = instansiTemplateWa.listTemplateResp.data.map(item => ({
            value: item.id,
            label: item.name
        }));
        setTemplateId(transformedData);
        }

        if(optionForm.listDurationOptionStatus === "succeeded"){
            const transformedData = optionForm.listDurationOptionResp.data.map(item => ({
              value: item.value,
              label: item.name
            }));
            setRemainderDurationOption(transformedData);
          }
      
          if(optionForm.listOnetimeRemainderConditionStatus === "succeeded"){
            const transformedData = optionForm.listOnetimeRemainderConditionResp.data.map(item => ({
              value: item.value,
              label: item.name
            }));
            setOneTimeRemainderCondition(transformedData);
          }
      
          if(optionForm.listRemainderConditionTriggerEventStatus === "succeeded"){
            const transformedData = optionForm.listRemainderConditionTriggerEventResp.data.map(item => ({
              value: item.value,
              label: item.name
            }));
            setRemainderConditionTriggerEvent(transformedData);
          }
      
          if(optionForm.listRemainderOptionStatus === "succeeded"){
            const transformedData = optionForm.listRemainderOptionResp.data.map(item => ({
              value: item.value,
              label: item.name
            }));
            setRemainderOption(transformedData);
          }
      
          if(optionForm.listScheduleRemainderConditionStatus === "succeeded"){
            const transformedData = optionForm.listScheduleRemainderConditionResp.data.map(item => ({
              value: item.value,
              label: item.name
            }));
            setScheduleRemainderCondition(transformedData);
          }
    }, [optionForm, remainder])

    return (
        <>
            <Row>
                {!loadForm ? 
                    dataProgramPasienModal ? dataProgramPasienModal.map((data, i) => (
                    <Col lg={6} key={i}>
                        <Card className="card mb-3">
                            <Card.Header className="pb-2 pt-2 d-block">
                                <Card.Title>
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                        <span>({data.form.type}) {data.form.name}</span>
                                        <Dropdown className="btn-sm">
                                            <Dropdown.Toggle
                                            variant="primary"
                                            id="dropdown-basic"
                                            className="btn-sm"
                                            >
                                            <i className="ri-more-2-fill"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                            <Dropdown.Item href="#" onClick= {()=> openModalPenggunaKlinis(data.doctor, data.nurse, data.id)}>Edit Pengguna Klinis</Dropdown.Item>
                                            <Dropdown.Item href="#" className="text-danger" onClick={()=> dispatchDeletePatientProgram( data.patient.id, data.id)}>Pulangkan Pasien</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className={`d-flex justify-content-start align-items-center border-bottom`}>
                                    <h6 className="mt-2 fs-xs" style={{width:"250px"}}>Tanggal Pendaftaran</h6>
                                    <h6 className="fs-xs text-secondary mb-0">{convertToGMT7(data.registered_at)}</h6>
                                </div>
                                <div className={`d-flex justify-content-start align-items-center border-bottom`}>
                                    <h6 className="mt-2 fs-xs" style={{width:"250px"}}>Program</h6>
                                    <h6 className="fs-xs text-secondary mb-0">{data.program.name}</h6>
                                </div>
                                <div className={`d-flex justify-content-start align-items-center border-bottom`}>
                                    <h6 className="mt-2 fs-xs" style={{width:"264px"}}>Pengguna Klinis Penanggung Jawab</h6>
                                    <h6 className="fs-xs text-secondary mb-0">
                                        <ul className="mb-0 mt-0 p-0">
                                            {data?.nurse ? data.nurse.map((val,index) =>(
                                                <li key={index}>{val.name}</li>
                                            )) : <li>-</li>}
                                        </ul>
                                    </h6>
                                </div>
                                <div className={`d-flex justify-content-start align-items-center`}>
                                    <h6 className="mt-2 fs-xs" style={{width:"264px"}}>Pengguna Klinis Penerima Peringatan</h6>
                                    <h6 className="fs-xs text-secondary mb-0">
                                        <ul className="mb-0 mt-0 p-0">
                                            {data?.doctor ? data.doctor.map((val,index) =>(
                                                <li key={index}>{val.name}</li>
                                            )) : <li>-</li>}
                                        </ul>
                                    </h6>
                                </div>
                                <Accordion defaultActiveKey="" className="mt-2">
                                  <Accordion.Item eventKey={i}>
                                    <Accordion.Header onClick={()=>findReminder(data.id)}>Customisasi Pengingat</Accordion.Header>
                                    <Accordion.Body key={i} className="p-1" style={{height: "250px", overflowY:"scroll"}}>
                                      {doesProgramExist(data.id) ? 
                                        <>
                                          {doesProgramExistData(data.id).onetime.map((onetimeval, onetimeindex) =>(
                                            <>
                                            {createLoopReminder('Onetime', onetimeval, onetimeindex, 'onetime', data.id)}
                                            </>
                                          ))}
                                        
                                          {doesProgramExistData(data.id).scheduled.daily_reminder.map((scheduleddailyval, scheduleddailyindex) =>(
                                            <>
                                            {createLoopReminder('Harian', scheduleddailyval, scheduleddailyindex, 'daily', data.id)}
                                            </>
                                          ))}

                                          {doesProgramExistData(data.id).scheduled.weekly_reminder.map((scheduledweeklyval, scheduledweeklyindex) =>(
                                            <>
                                            {createLoopReminder('Mingguan', scheduledweeklyval, scheduledweeklyindex, 'weekly', data.id)}
                                            </>
                                          ))}

                                          {doesProgramExistData(data.id).scheduled.monthly_reminder.map((scheduledmonthlyval, scheduledmonthlyindex) =>(
                                            <>
                                              {createLoopReminder('Bulanan', scheduledmonthlyval, scheduledmonthlyindex, 'monthly', data.id)}
                                            </>
                                          ))}

                                          {doesProgramExistData(data.id).scheduled.yearly_reminder.map((scheduledyearlyval, scheduledyearlyindex) =>(
                                            <>
                                              {createLoopReminder('Tahunan', scheduledyearlyval, scheduledyearlyindex, 'yearly', data.id)}
                                            </>
                                          ))}
                                          <div className="absolute-right-bottom"><Button variant="primary" onClick={()=>setFrekuensi([], data.id)}><i className="ri-add-line"></i></Button></div>
                                        </>
                                        : <div className="text-center"> <img src={"/favicon.png"} width="60px" alt={"Icon"}/>
                                          <h5>Data Not Found</h5>
                                          <p>You Can Create Custom Reminder!</p>
                                          <Button variant="light" className="btn btn-outline-primary" onClick={()=>setFrekuensi([], data.id)}>Custom Reminder</Button>
                                        </div>
                                      }
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                            </Card.Body>
                        </Card>
                    </Col> )) : 
                    <Col lg={12}>
                        <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/>
                        <h5>Data Not Found</h5>
                        <p>Assign Patient To Program!</p>
                        </div>
                    </Col>
                : 
                    <>
                        <Col lg={6}>
                            <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                                <Skeleton count={1} style={{ height: "200px" }} />
                            </SkeletonTheme>
                        </Col>
                        <Col lg={6}>
                            <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
                                <Skeleton count={1} style={{ height: "200px" }} />
                            </SkeletonTheme>
                        </Col>
                    </>
                }
            </Row>
            <Modal show={showAssignProgram} onHide={closeModalPenggunaKlinis} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Assign Program Pasien</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div>
                        <h6 className="mb-1">{pasienData.name}</h6>
                        <p className="fs-xs text-secondary d-flex align-items-center mb-0">
                            <i className="ri-whatsapp-line text-success"></i>{" "}
                            {pasienData.phone}
                        </p>
                        </div>
                    </div>
                    <Card className="mb-3">
                        <Card.Body>
                        <Row>
                            <Col lg={12}>
                                <Form.Group controlId="formFileSm" className="mb-3">
                                    <Form.Label>
                                    {`Pengguna Klinis Penanggung Jawab`} <span className="text-danger"></span>
                                    </Form.Label>
                                    <Select
                                    options={dokterPasien.listPerawatResp}
                                    isSearchable={true}
                                    isMulti={true}
                                    onChange={(e) => {
                                        handleChangeModalAssgnProg(e, "nurseId");
                                    }}
                                    value={dataModalAssignProgPass?.nurseId?.map(nurseId => {
                                        const nurseOption = dokterPasien.listPerawatResp.find(option => option.value === nurseId);
                                        return nurseOption ? nurseOption : { value: nurseId, label: nurseOption.label };
                                    })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group controlId="formFileSm" className="mb-3">
                                    <Form.Label>
                                    {`Pengguna Klinis Penerima Peringatan`} <span className="text-danger"></span>
                                    </Form.Label>
                                    <Select
                                    options={dokterPasien.listDokterResp}
                                    isSearchable={true}
                                    isMulti={true}
                                    onChange={(e) => {
                                        handleChangeModalAssgnProg(e, "doctorId");
                                    }}
                                    value={dataModalAssignProgPass?.doctorId?.map(doctorId => {
                                        const doctorOption = dokterPasien.listDokterResp.find(option => option.value === doctorId);
                                        return doctorOption ? doctorOption : { value: doctorId, label: doctorOption.label };
                                    })}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        </Card.Body>
                    </Card>
                    <div className="d-flex align-items-center justify-content-end">
                        <Button variant="danger" onClick={closeModalPenggunaKlinis}>
                        Batalkan
                        </Button>
                        &nbsp;
                        <Button
                        variant="primary"
                        onClick={savePenggunaKlinis}
                        disabled={!dokterPasien.updateAssignProgramPending}
                        >
                        {!dokterPasien.updateAssignProgramPending
                            ? "Silahkan Tunggu ..."
                            : "Assign Program"}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
              show={showSetFrekuensi}
              onHide={closeSetFrekuensi}
              centered
              size="lg"
            >
              <Modal.Header closeButton>
                  <Modal.Title>Frekuensi/Reminder</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {showSetFrekuensi ?
                <>
                  <>
                    <h6 className="card-title mb-1">Frekuensi</h6>
                    <span>
                      Pastikan Anda sudah mengatur Frekuensi atau Frekuensi akan diatur secara default.
                    </span>
                    <hr />
                    <Row>
                      <Col lg={6}>
                        <Form.Group controlId="formFileSm" className="mb-3">
                          <Form.Label>Pesan Template</Form.Label>
                          <Select options={templateId} isSearchable={true} onChange={(option) => handleRemainderChange('template_id', option.value)} value={{ value: remainder.template_id ? remainder.template_id : '', label: remainder.template_id ? templateId.find(option => option.value === remainder.template_id).label : 'Select Template'}}/>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group controlId="formFileSm" className="mb-3">
                          <Form.Label>Pilih Pengingat</Form.Label>
                          <Select options={remainderOption} isSearchable={true} onChange={(option) => handleRemainderChange('name', option.value)} value={{ value: remainder.name ? remainder.name : '', label: remainder.name ? remainderOption.find(option => option.value === remainder.name).label : 'Select Reminder'}}/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group controlId="formFileSm" className="mb-3">
                      <Form.Label>Jenis Pengingat</Form.Label>
                      <Row>
                        <Col lg="6">
                          <div className={remainder.type == 1 ? "card card-task mb-3 border-success" : "card card-task mb-3"} onClick={() => {const { scheduled, ...rest } = remainder; setRemainder({...rest,type: 1,onetime: resetOnetime})}}>
                            <div className="card-body p-3 pb-1">
                              <div className="d-flex align-items-center justify-content-between">
                                <span className="card-title">Satu Kali</span>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mt-2 mb-1">
                                <h6 className="card-date">
                                  Kirim pengingat ke pasien berdasarkan peristiwa pemicu seperti pendaftaran atau pemulangan pasien
                                </h6>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className={remainder.type == 2 ? "card card-task mb-3 border-success" : "card card-task mb-3"} onClick={() => {const { onetime, ...rest } = remainder;setRemainder({...rest, type: 2, scheduled: resetSchedule})}}>
                            <div className="card-body p-3 pb-1">
                              <div className="d-flex align-items-center justify-content-between">
                                <span className="card-title">Sedang Berlangsung</span>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mt-2 mb-1">
                                <h6 className="card-date">
                                  Kirim pengingat ke pasien berdasarkan jadwal yang telah ditentukan
                                </h6>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                    {remainder.type == 1 ?
                    <>
                      <h6 className="text-title">Buat Pengingat 1x</h6>
                      <Form.Group controlId="formFileSm" className="mb-3">
                        <Form.Label>Kondisi Yang Dipilih</Form.Label>
                        <Select options={oneTimeRemainderCondition} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition', option.value, 'onetime')} value={{ value: remainder.onetime.reminder_condition ? remainder.onetime.reminder_condition : '', label: remainder.onetime.reminder_condition ? oneTimeRemainderCondition.find(option => option.value === remainder.onetime.reminder_condition).label : 'Select Reminder'}} />
                      </Form.Group>
                      {remainder.onetime.reminder_condition == 'after_patient_enrollment' || remainder.onetime.reminder_condition == 'after_patient_discharge' ? <></> : <></>}
                      <Form.Group controlId="formFileSm" className="mb-3">
                        <Form.Label>Kirim Pengingat</Form.Label>
                        <Row>
                          <Col lg="3">
                            <Form.Control type="number" placeholder="Harus Lebih Dari 0" onChange={(e) => handleRemainderChange('value', e.target.value ? e.target.value.toString() : "0", 'onetime')} value={remainder.onetime.value ? parseInt(remainder.onetime.value) : 0} />
                          </Col>
                          <Col lg="9">
                            <Select options={remainderConditionTriggerEvent} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition_trigger_event', option.value, 'onetime')} value={{ value: remainder.onetime.reminder_condition_trigger_event ? remainder.onetime.reminder_condition_trigger_event : '', label: remainder.onetime.reminder_condition_trigger_event ? remainderConditionTriggerEvent.find(option => option.value === remainder.onetime.reminder_condition_trigger_event).label : 'Select Trigger Event'}}/>
                          </Col>
                        </Row>
                      </Form.Group>
                    </> : <></>}
                    {remainder.type == 2 ? <>
                      <h6 className="text-title">Buat Pengingat Sedang Berlangsung</h6>
                      <Row>
                        <Col lg={remainder.scheduled.reminder_condition ? 6 : 12 }>
                          <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Trigger Pengingat</Form.Label>
                            <Select options={scheduleRemainderCondition} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition', option.value, 'scheduled')} value={{ value: remainder.scheduled.reminder_condition ? remainder.scheduled.reminder_condition : '', label: remainder.scheduled.reminder_condition ? scheduleRemainderCondition.find(option => option.value === remainder.scheduled.reminder_condition).label : 'Select Remainder Condition'}}/>
                          </Form.Group>
                        </Col>
                        <Col lg={remainder.scheduled.reminder_condition ? 6 : 0} className={remainder.scheduled.reminder_condition ? '' : 'd-none'}>
                          <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Frekuensi</Form.Label>
                            <Form.Control type="number" placeholder="Harus Lebih Dari 0" onChange={(e) => handleRemainderChange('frequently', e.target.value ? parseInt(e.target.value) : 0, 'scheduled', RolesForm[remainder.scheduled.reminder_condition]?.label)} value={remainder.scheduled[RolesForm[remainder.scheduled?.reminder_condition]?.label]?.frequently ? parseInt(remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label]?.frequently) : 0} />
                          </Form.Group>
                        </Col>
                      </Row>
                      {remainder.scheduled.reminder_condition ? 
                      <> 
                        <Row>
                          {remainder.scheduled.reminder_condition === 'year' ? 
                          <Col>
                            <div className={"card card-task mb-3"}>
                              <div className="card-body p-3 pb-1">
                                <div className="d-flex align-items-center justify-content-between">
                                  <span className="card-title mb-2">Bulan</span>
                                </div>
                                <div className="">
                                  <Row>
                                    <Col lg={12}>
                                      <Form.Group controlId="formFileSm" className="mb-2">
                                      <Select
                                        options={selectOptionsmonths}
                                        isSearchable={true} 
                                        isMulti={true}
                                        onChange={(selectedOptions) => {
                                          const newRemainderMonths = selectedOptions.map(option => option.value);
                                          handleRemainderChange('months', newRemainderMonths ? newRemainderMonths : [], 'scheduled', 'yearly_reminder')
                                        }}
                                        value={remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label] ? remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label]?.months.map(month => {
                                          const option = selectOptionsmonths.find(option => option.value === month);
                                          return { value: month, label: option ? option.label : month };
                                        }) : { value: '', label: 'Pilih Bulan' }}
                                      />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </Col>:<></>}
                          {remainder.scheduled.reminder_condition === 'month' || remainder.scheduled.reminder_condition === 'year' ? 
                          <Col>
                            <div className={"card card-task mb-3"}>
                              <div className="card-body p-3 pb-1">
                                <div className="d-flex align-items-center justify-content-between">
                                  <span className="card-title mb-2">Tanggal</span>
                                </div>
                                <div className="">
                                  <Row>
                                    <Col lg={12}>
                                      <Form.Group controlId="formFileSm" className="mb-2">
                                      <Select
                                        options={selectOptionsdates}
                                        isSearchable={true} 
                                        isMulti={true}
                                        onChange={(selectedOptions) => {
                                          const newRemainderDates = selectedOptions.map(option => option.value);
                                          if(remainder.scheduled.reminder_condition === 'month'){
                                            handleRemainderChange('dates', newRemainderDates ? newRemainderDates : [], 'scheduled', 'monthly_reminder')
                                          }else{
                                            handleRemainderChange('dates', newRemainderDates ? newRemainderDates : [], 'scheduled', 'yearly_reminder')
                                          }
                                        }}
                                        value={remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label] ? remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label]?.dates.map(date => {
                                          const option = selectOptionsdates.find(option => option.value === date);
                                          return { value: date, label: option ? option.label : date };
                                        }) : { value: '', label: 'Pilih Tanggal' }}
                                      />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </Col>:<></>}
                          {remainder.scheduled.reminder_condition === 'week' ? 
                            <Col>
                              <div className={"card card-task mb-3"}>
                                <div className="card-body p-3 pb-1">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <span className="card-title mb-2">Hari</span>
                                  </div>
                                  <div className="">
                                    <Row>
                                      <Col lg={12}>
                                        <Form.Group controlId="formFileSm" className="mb-2">
                                        <Select
                                          options={selectOptionsdays}
                                          isSearchable={true} 
                                          isMulti={true}
                                          onChange={(selectedOptions) => {
                                            const newRemainderDays = selectedOptions.map(option => option.value);
                                            handleRemainderChange('days', newRemainderDays ? newRemainderDays : [], 'scheduled', 'weekly_reminder')
                                          }}
                                          value={remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label] ? remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition]?.label]?.days.map(day => {
                                            const option = selectOptionsdays.find(option => option.value === day);
                                            return { value: day, label: option ? option.label : day };
                                          }) : { value: '', label: 'Pilih Hari' }}
                                        />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          :<></>}
                          <Col>
                            <div className={"card card-task mb-3"}>
                              <div className="card-body p-3 pb-1">
                                <div className="d-flex align-items-center justify-content-between">
                                  <span className="card-title mb-2">Jam</span>
                                </div>
                                <div className="">
                                  {remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition].label]?.times && remainder.scheduled[RolesForm[remainder.scheduled.reminder_condition].label]?.times.map((val, index) => (
                                    <React.Fragment key={index}>
                                      <Row>
                                        <Col lg={8}>
                                          <Form.Group controlId="formFileSm" className="mb-2">
                                            <Form.Control
                                              type="time"
                                              placeholder="Masukan Waktu"
                                              value={val}
                                              onChange={(e) => {
                                                if(remainder.scheduled.reminder_condition === 'month'){
                                                  handleRemainderChange('times', e.target.value ? e.target.value : '00:00', 'scheduled', 'monthly_reminder', index)
                                                }else if(remainder.scheduled.reminder_condition === 'year'){
                                                  handleRemainderChange('times', e.target.value ? e.target.value : '00:00', 'scheduled', 'yearly_reminder', index)
                                                }else if(remainder.scheduled.reminder_condition === 'week'){
                                                  handleRemainderChange('times', e.target.value ? e.target.value : '00:00', 'scheduled', 'weekly_reminder', index)
                                                }else if(remainder.scheduled.reminder_condition === 'day'){
                                                  handleRemainderChange('times', e.target.value ? e.target.value : '00:00', 'scheduled', 'daily_reminder', index)
                                                }
                                              }}
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                          {index !== 0 ?
                                          <Button variant="danger" onClick={()=>deleteRemainderSchedule(index)}>
                                            <i className="ri-close-fill"></i>
                                          </Button> : <></>}
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  ))}
                                  <button className="text-primary btn" type="button" onClick={()=> addRemainderSchedule()}>
                                    <i className="ri-add-line"></i> Tambah Opsi Lainnya
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </> : <></>}
                    </> : <></> }
                  </>
                  <>
                    <h6 className="card-title mb-1">Durasi</h6>
                    <span>
                      Pastikan Anda sudah mengatur durasi atau durasi akan diatur secara default.
                    </span>
                    <hr />
                    <Row>
                      <Col lg={12} className="mb-3">
                        <Form.Group controlId="formFileSm" className="mb-3">
                          <Form.Label>Pengingat Mulai</Form.Label>
                          <Select options={remainderDurationOption} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition', option.value, 'durations', 'start_trigger')} value={{ value: remainder?.durations?.start_trigger?.reminder_condition ? remainder?.durations?.start_trigger?.reminder_condition : '', label: remainder?.durations?.start_trigger?.reminder_condition ? remainderDurationOption.find(option => option.value === remainder?.durations?.start_trigger?.reminder_condition)?.label : 'Select Reminder'}} />
                        </Form.Group>
                        {remainder.durations.start_trigger.reminder_condition == 'after_patient_enrollment' || remainder.durations.start_trigger.reminder_condition == 'after_patient_discharge' ? <></> : <></>}
                        <Form.Group controlId="formFileSm" className="mb-3">
                          <Row>
                            <Col lg="3">
                              <Form.Control type="number" placeholder="Harus Lebih Dari 0" onChange={(e) => handleRemainderChange('value', e.target.value ? e.target.value.toString() : "0", 'durations', 'start_trigger')} value={remainder?.durations?.start_trigger?.value ? parseInt(remainder?.durations?.start_trigger?.value) : 0} />
                            </Col>
                            <Col lg="9">
                              <Select options={remainderConditionTriggerEvent} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition_trigger_event', option.value, 'durations', 'start_trigger')} value={{ value: remainder?.durations?.start_trigger?.reminder_condition_trigger_event ? remainder?.durations?.start_trigger?.reminder_condition_trigger_event : '', label: remainder?.durations?.start_trigger?.reminder_condition_trigger_event ? remainderConditionTriggerEvent.find(option => option.value === remainder?.durations?.start_trigger?.reminder_condition_trigger_event)?.label : 'Select Trigger Event'}}/>
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                    
                      <Col lg={12} className="mb-3">
                        <Form.Group controlId="formFileSm" className="mb-3">
                          <Form.Label>Pengingat Selesai</Form.Label>
                          <Select options={remainderDurationOption} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition', option.value, 'durations', 'end_trigger')} value={{ value: remainder?.durations?.end_trigger?.reminder_condition ? remainder?.durations?.end_trigger?.reminder_condition : '', label: remainder?.durations?.end_trigger?.reminder_condition ? remainderDurationOption.find(option => option.value === remainder?.durations?.end_trigger?.reminder_condition)?.label : 'Select Reminder'}} />
                        </Form.Group>
                        {remainder.durations.end_trigger.reminder_condition == 'after_patient_enrollment' || remainder.durations.end_trigger.reminder_condition == 'after_patient_discharge' ? <></> : <></>}
                        <Form.Group controlId="formFileSm" className="mb-3">
                          <Row>
                            <Col lg="3">
                              <Form.Control type="number" placeholder="Harus Lebih Dari 0" onChange={(e) => handleRemainderChange('value', e.target.value ? e.target.value.toString() : "0", 'durations', 'end_trigger')} value={remainder?.durations?.end_trigger?.value ? parseInt(remainder?.durations?.end_trigger?.value) : 0} />
                            </Col>
                            <Col lg="9">
                              <Select options={remainderConditionTriggerEvent} isSearchable={true} onChange={(option) => handleRemainderChange('reminder_condition_trigger_event', option.value, 'durations', 'end_trigger')} value={{ value: remainder?.durations?.end_trigger?.reminder_condition_trigger_event ? remainder?.durations?.end_trigger?.reminder_condition_trigger_event : '', label: remainder?.durations?.end_trigger?.reminder_condition_trigger_event ? remainderConditionTriggerEvent.find(option => option.value === remainder?.durations?.end_trigger?.reminder_condition_trigger_event)?.label : 'Select Trigger Event'}}/>
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                </> : <></> }
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeSetFrekuensi}>
                  Batalkan
                </Button>
                <Button variant="primary" onClick={()=>saveRemainder()} disabled={saveFormLoad}>
                  {!saveFormLoad ? "Simpan" : "Silahkan Tunggu"}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showDetail} onHide={closeDetailData} centered size="md">
              <Modal.Header closeButton>
                  <Modal.Title>Detail Template</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="card card-task chat-whatsapp-bg" style={{ height: `calc(100vh - 325px)` }}>
                  <div className="card-body p-3 pb-3">
                    <div className="right-wa">
                      <div className="d-flex align-items-center mb-3">
                        <h6 className="card-title">{templateDetail.header ? templateDetail.header : ''}</h6>
                      </div>
                      <p className="fs-sm">{templateDetail.body ? templateDetail.body.text : ''}</p>
                      <small><i>{templateDetail.footer ? templateDetail.footer : ''}</i></small>
                    </div>
                    {templateDetail.actions?.map( (action, i) => (
                      <Button className="btn btn-light btn-sm w-100 mt-1" variant="light">
                          {action.text ? action.text : `Button ${i}`}
                      </Button>
                    ))}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={closeDetailData}>
                      Tutup
                  </Button>
              </Modal.Footer>
            </Modal>
        </>
    )   
}