import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';


import ApiUrl from "../../helpers/url";
import { generateConfig } from '../helper/axiosHelper';

export const fetchListTemplate = createAsyncThunk(
  'instansiTemplateWa/fetchListTemplate',
  async (_, { rejectWithValue }) => {
    const url = ApiUrl.get_template.url;
    const method = ApiUrl.get_template.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchListTemplateLog = createAsyncThunk(
  'instansiTemplateWa/fetchListTemplateLog',
  async (_, { rejectWithValue }) => {
    const url = ApiUrl.get_template_log.url;
    const method = ApiUrl.get_template_log.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchListTemplateBodyWa = createAsyncThunk(
  'instansiTemplateWa/fetchListTemplateBodyWa',
  async (_, { rejectWithValue }) => {
    const url = ApiUrl.option_template_body_field.url;
    const method = ApiUrl.option_template_body_field.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTemplateDetail = createAsyncThunk(
  'instansiTemplateWa/fetchTemplateDetail',
  async (id, { rejectWithValue }) => {
    const url = `${ApiUrl.detail_template.url}${id}`;
    const method = ApiUrl.detail_template.method;
    try {
      const response = await axios.request(generateConfig(url, method));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSaveDataTemplate = createAsyncThunk(
  'instansiTemplateWa/fetchSaveDataTemplate',
  async (param, { rejectWithValue }) => {
    const {data, id, action} = param;
    const url = action === 'Tambah' ? ApiUrl.create_template.url : `${ApiUrl.update_template.url}${id}`;
    const method = action === 'Tambah' ? ApiUrl.create_template.method : ApiUrl.update_template.method;
    try {
      const response = await axios.request(generateConfig(url, method, data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeleteTemplateWa = await createAsyncThunk(
  'instansiTemplateWa/fetchDeleteTemplateWa',
  async (id, { rejectWithValue }) => {
    const url_check = `${ApiUrl.detail_template.url}${id}/check-usage`;
    const method_check = ApiUrl.detail_template.method;
    try {
      const response = await axios.request(generateConfig(url_check, method_check));
      if(!response.data.data){
        const url = `${ApiUrl.delete_template.url}${id}`;
        const method = ApiUrl.delete_template.method;
        try {
          const response = await axios.request(generateConfig(url, method));
          return response.data;
        } catch (error) {
          return rejectWithValue(response.data.message);
        }
      }else{
        return rejectWithValue('Templates cannot be deleted because they are used for forms!');
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
    
  }
);

const instansiTemplateWaSlice = createSlice({
  name: 'instansiBanner',
  initialState: {
    listTemplateResp: [],
    listTemplateSelectOption: [],
    listTemplateStatus: 'idle',
    listTemplateError: null,
    listTemplatePending: false,

    listTemplateLogResp: [],
    listTemplateLogStatus: 'idle',
    listTemplateLogError: null,
    listTemplateLogPending: true,

    listTemplateBodyWaResp: [],
    listTemplateBodyWaStatus: 'idle',
    listTemplateBodyWaError: null,
    listTemplateBodyWaPending: true,

    templateDetailResp: {},
    templateDetailStatus: 'idle',
    templateDetailError: null,
    templateDetailPending: true,
  
    saveDataTemplateResp: [],
    saveDataTemplateStatus: 'idle',
    saveDataTemplateError: null,
    saveDataTemplatePending: false,

    deleteTemplateWaResp: [],
    deleteTemplateWaStatus: 'idle',
    deleteTemplateWaError: null,
    deleteTemplateWaPending: true,
  },
  reducers: {
    resetStatusListTemplate: (state) => {
      state.listTemplateStatus = 'idle';
      state.listTemplatePending = true;
      state.listTemplateResp = [];
    },
    resetStatusTemplateDetail:  (state) => {
      state.templateDetailResp = [];
      state.templateDetailStatus = 'idle';
      state.templateDetailError = null;
      state.templateDetailPending = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchListTemplate
      .addCase(fetchListTemplate.pending, (state) => {
        state.listTemplateStatus = 'loading';
        state.listTemplatePending = true;
      })
      .addCase(fetchListTemplate.fulfilled, (state, action) => {
        state.listTemplateStatus = 'succeeded';
        state.listTemplateResp = action.payload;
        state.listTemplatePending = false;
        const listTemplateSelectOption = action.payload.data?.map((val) => ({
          label: val.name,
          value: val.id
        }));
        state.listTemplateSelectOption = listTemplateSelectOption;
      })
      .addCase(fetchListTemplate.rejected, (state, action) => {
        state.listTemplateStatus = 'failed';
        state.listTemplateError = action.payload.message;
        state.listTemplatePending = false;
      })

      // fetchListTemplateLog
      .addCase(fetchListTemplateLog.pending, (state) => {
        state.listTemplateLogStatus = 'loading';
      })
      .addCase(fetchListTemplateLog.fulfilled, (state, action) => {
        state.listTemplateLogStatus = 'succeeded';
        state.listTemplateLogResp = action.payload;
        
      })
      .addCase(fetchListTemplateLog.rejected, (state, action) => {
        state.listTemplateLogStatus = 'failed';
        state.listTemplateLogError = action.payload?.message;
      })

      // fetchListTemplateBodyWa
      .addCase(fetchListTemplateBodyWa.pending, (state) => {
        state.listTemplateBodyWaStatus = 'loading';
      })
      .addCase(fetchListTemplateBodyWa.fulfilled, (state, action) => {
        state.listTemplateBodyWaStatus = 'succeeded';
        state.listTemplateBodyWaResp = action.payload;
        
      })
      .addCase(fetchListTemplateBodyWa.rejected, (state, action) => {
        state.listTemplateBodyWaStatus = 'failed';
        state.listTemplateBodyWaError = action.payload?.message;
      })

      // fetchTemplateDetail
      .addCase(fetchTemplateDetail.pending, (state) => {
        state.templateDetailStatus = 'loading';
      })
      .addCase(fetchTemplateDetail.fulfilled, (state, action) => {
        const dataResp = action.payload.data;
        const data = {
          name: dataResp.name,
          category: dataResp.category,
          lang: dataResp.language,
          header: dataResp.header,
          body: {
            text: JSON.parse(dataResp.content).text,
            bindings: JSON.parse(dataResp.content).bindings,
          },
          footer: dataResp.footer,
          actions: JSON.parse(dataResp.buttons).map(button => ({
            text: button.text,
            url: button.url,
          }))
        }

        state.templateDetailStatus = 'succeeded';
        state.templateDetailResp = data;

        
        
      })
      .addCase(fetchTemplateDetail.rejected, (state, action) => {
        state.templateDetailStatus = 'failed';
        state.templateDetailError = action.payload.message;
      })

      // fetchSaveDataTemplate
      .addCase(fetchSaveDataTemplate.pending, (state) => {
        state.saveDataTemplateStatus = 'loading';
        state.saveDataTemplatePending = true;
      })
      .addCase(fetchSaveDataTemplate.fulfilled, (state, action) => {
        state.saveDataTemplateStatus = 'succeeded';
        state.saveDataTemplateResp = action.payload;
        state.saveDataTemplatePending = false;
      })
      .addCase(fetchSaveDataTemplate.rejected, (state, action) => {
        state.saveDataTemplateStatus = 'failed';
        state.saveDataTemplatePending = false;
        if(action.payload?.message){
          state.saveDataTemplateError = action.payload.message;
        } else {
          state.saveDataTemplateError = "Fail - Silakan coba lagi"
        }
      })

      // fetchDeleteTemplateWa
      .addCase(fetchDeleteTemplateWa.pending, (state) => {
        state.deleteTemplateWaStatus = 'loading';
      })
      .addCase(fetchDeleteTemplateWa.fulfilled, (state, action) => {
        state.deleteTemplateWaStatus = 'succeeded';
        state.deleteTemplateWaResp = action.payload;
        state.deleteTemplateWaPending = false;
      })
      .addCase(fetchDeleteTemplateWa.rejected, (state, action) => {
        state.deleteTemplateWaStatus = 'failed';
        state.deleteTemplateWaError = action.payload.message;
      })
  },
})

export const {resetStatusListTemplate, resetStatusTemplateDetail } = instansiTemplateWaSlice.actions;
export default instansiTemplateWaSlice.reducer;