import React, { useEffect, useState } from "react";
import {} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import ApiUrl from "../../../helpers/url";
import { Row } from "react-bootstrap";

export default function Formulir() {
  const location = useLocation();
  const [pending, setPending] = useState(true);
  const [dataFormulir, setDataFormulir] = useState([]);
  const [load, setLoad] = useState(true);
  const columns = [
    {
      name: "Formulir",
      selector: (row) => row.name,
    },
    {
      name: "Type",
      selector: (row) => row.type,
    },
    {
      name: "Program",
      selector: (row) => row.program.name,
    },
    {
      name: "Opsi",
      selector: (row) => row.id,
      cell: (row) => (
        <>
          <Link
            to={"/admin/program/formulir/edit/" + row.id}
            className="btn btn-primary"
          >
            <i className="ri-edit-2-line"></i>
          </Link>
          &nbsp;
          <Link
            to="#"
            className="btn btn-danger"
            onClick={() => deleteFormulir(row.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Link>
        </>
      ),
    },
  ];

  const getSegment = (path) => {
    const segments = path.split("/").filter(Boolean);
    return segments[3];
  };
  const Segment = getSegment(location.pathname);

  const getdataformulir = async () => {
    setPending(true);
    let config = {
      method: ApiUrl.get_form.method,
      maxBodyLength: Infinity,
      url: ApiUrl.get_form.url + "?workspace_id=" + Segment,
      headers: {
        "PARTNER-TOKEN": "TXMKh?j9RTJutU5",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    await axios
      .request(config)
      .then((response) => {
        setPending(false);
        setLoad(false);
        if (response.data.status) {
          setDataFormulir(response.data.data);
        } else {
          setDataFormulir([]);
        }
      })
      .catch((error) => {
        setPending(false);
        setLoad(false);
        setDataFormulir([]);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
    getdataformulir();
    return () => clearTimeout(timeout);
  }, []);

  const deleteFormulir = async(id) => {
    swal({
      title: "Apa Data Ingin Dihapus?",
      text: "Jika data dihapus, data tidak dapat dikembalikan!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: "Batal",
        confirm: {
          text: "Hapus",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
    })
      .then(async(willDelete) => {
        if (willDelete) {
          let config = {
            method: ApiUrl.delete_form.method,
            maxBodyLength: Infinity,
            url: `${ApiUrl.delete_form.url}${id}/questions`,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          };

          await axios
            .request(config)
            .then(async(response) => {
              if (response.data.status) {
                let config2 = {
                  method: ApiUrl.delete_form.method,
                  maxBodyLength: Infinity,
                  url: `${ApiUrl.delete_form.url}${id}`,
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                  },
                };

                await axios
                .request(config2)
                .then((response2) => {
                  if (response2.data.status) {
                    toast.success("Data Berhasil Dihapus!", {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      theme: "light",
                      transition: Bounce,
                    });
                    getdataformulir();
                  } else {
                    toast.error(response2.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      theme: "light",
                      transition: Bounce,
                    });
                  }
                })
                .catch((error) => {
                  toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    transition: Bounce,
                  });
                });
              } else {
                toast.error(response.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "light",
                  transition: Bounce,
                });
              }
            })
            .catch((error) => {
              toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
              });
            });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      });
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Formulir</title>
      </Helmet>
      <Row>
        <h5 className="d-flex align-items-center justify-content-between">
          Formulir
          <Link
            to="/admin/program/formulir/add"
            className="btn btn-primary d-flex align-items-center"
          >
            <i className="ri-add-line"></i>Tambah Formulir
          </Link>
        </h5>
        <hr />
      </Row>
      {load ? (
        <SkeletonTheme baseColor="#ddd" highlightColor="#f2f2f2">
          <Skeleton count={1} style={{ height: "150px" }} />
        </SkeletonTheme>
      ) : dataFormulir ? (
        <DataTable
          columns={columns}
          data={dataFormulir}
          pagination
          progressPending={pending}
          defaultSortFieldId={1}
        />
      ) : (
        <div className="text-center">
          {" "}
          <img src={"/favicon.png"} width="100px" alt={"Icon"} />
          <h5>Data Not Found</h5>
          <p>You can create new form!</p>
        </div>
      )}
    </React.Fragment>
  );
}
