import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import pageSvg from "../assets/img/landing/img-right.svg";

export default function Landing() {
  return (
    <div className="landing-page">
        <object type="image/svg+xml" data={pageSvg} className="svg" aria-label="svg image"></object>
        <div className="landing-hero">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="sub-title"><i className="ri-hospital-line"></i> Auto Health Care</div>
                        <div className="title">Solusi <span>Pemantauan</span> Program Kesehatan Rumah Sakit</div>
                        <div className="description">Kami membantu rumah sakit memastikan bahwa setiap langkah dalam program kesehatan berjalan lancar dan sesuai dengan target yang ditetapkan.</div>
                        <div className="d-flex gap-2">
                            <Button variant="" className="btn-outline-light btn-w-175">Lihat Demo</Button>
                            <Link to="/sign-in" variant="" className="btn btn-success btn-w-175">Masuk</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}