import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Modal } from "react-bootstrap";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector, useDispatch } from 'react-redux';
import {
    resetStatusTemplateDetail, fetchTemplateDetail
} from "../../../../redux/slices/instansiTemplateWaSlice"
import ApiUrl from "../../../../helpers/url";
import { convertToGMT7 } from "../../../../helpers/timeConvert";
import axios from "axios";
export default function Pesan({selectedWorkspace, segment, pasienData}) {
    const [loadPesan, setLoadPesan] = useState(true);
    const [showDetail, setShowDetail] = useState(false);
    const dispatch = useDispatch();
    const instansiTemplateWa = useSelector((state) => state.instansiTemplateWa);
    const templateDetail = useSelector((state) => state.instansiTemplateWa.templateDetailResp);
    const [dataPesan, setDataPesan] = useState([]);
    const columns = [
        {
          name: "Tanggal",
          selector: (row) => convertToGMT7(row.created_at),
        },
        {
            name: "Direction",
            selector: (row) => row.direction,
        },
        {
            name: "Sender",
            selector: (row) => row.sender_id ? row.sender_id : '-',
        },
        {
          name: "Message Summary",
          selector: (row) => row.message,
          cell: (row) => (
            <>
              <div className="flex-direction-column">
                <h6 className="mt-2 text-success cursor-pointer" onClick={() => dispatchFecthTemplateDetail(row.template_id)}>Lihat Template</h6>
              </div>
            </>
          ),
        },
        {
          name: "Status",
          selector: (row) => row.status,
          cell: (row) => (
            <>
              <span className={`badge bg-${row.status == "SUCCESS" ? "success" : "danger"}`}>
                {row.status}
              </span>
            </>
          ),
        },
    ];


    const dispatchFecthTemplateDetail = async (id) => {
      if (instansiTemplateWa.templateDetailStatus === 'idle') {
        await dispatch(fetchTemplateDetail(id));
        if (instansiTemplateWa.templateDetailError) {
          toast.error(instansiTemplateWa.templateDetailError, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
          });
        }
      }
      setShowDetail(true);
    };

    const closeDetailData = () => {
      dispatch(resetStatusTemplateDetail());
      setShowDetail(false);
    };

    const getMessage = async() => {
      setLoadPesan(true)
      let config = {
        method: ApiUrl.patient.method,
        maxBodyLength: Infinity,
        url:ApiUrl.patient.url +"/" +segment+"/messages",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
  
      await axios
        .request(config)
        .then((response) => {
          setLoadPesan(false);
          if (response.data.status) {
            setDataPesan(response.data.data);
          } else {
            setDataPesan([]);
            toast.error(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
          }
        })
        .catch((error) => {
          setLoadPesan(false);
          setDataPesan([]);
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
        });
    }

    useEffect(()=>{
        getMessage();
    }, [])

    return (
        <>{!loadPesan ? 
            <>
                {dataPesan.length > 0 ?
                    <DataTable
                        columns={columns}
                        data={dataPesan}
                        pagination
                        progressPending={false}
                        defaultSortFieldId={1}
                    /> : 
                    <div className="text-center"> <img src={"/favicon.png"}width="95px" alt={"Icon"}/><h5>Message Not Found</h5></div>
                }
            </>
        : <SkeletonTheme><Skeleton count={1} style={{ height: "100px" }} className="mb-1"/> </SkeletonTheme>}
        
        <Modal show={showDetail} onHide={closeDetailData} centered size="md">
          <Modal.Header closeButton>
              <Modal.Title>Detail Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card card-task chat-whatsapp-bg" style={{ height: `calc(100vh - 325px)` }}>
              <div className="card-body p-3 pb-3">
                <div className="right-wa">
                  <div className="d-flex align-items-center mb-3">
                    <h6 className="card-title">{templateDetail.header ? templateDetail.header : ''}</h6>
                  </div>
                  <p className="fs-sm">{templateDetail.body ? templateDetail.body.text : ''}</p>
                  <small><i>{templateDetail.footer ? templateDetail.footer : ''}</i></small>
                </div>
                {templateDetail.actions?.map( (action, i) => (
                  <Button className="btn btn-light btn-sm w-100 mt-1" variant="light">
                      {action.text ? action.text : `Button ${i}`}
                  </Button>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={closeDetailData}>
                  Tutup
              </Button>
          </Modal.Footer>
        </Modal>
        </>
    )
}