const Role = {
    SuperAdminRole: {role: "SUPER_ADMIN", name: 'Super Admin', url: '/admin'},
    AdminRole: {role: "ADMIN", name: 'Admin Instansi', url: '/instansi'},
    DoctorRole: {role: "DOCTOR", name: 'Dokter', url: '/dokter'},
    NurseRole: {role: "NURSE", name: 'Perawat', url: '/dokter'},
    PatientRole: {role: "PATIENT", name: 'Pasien', url: '/'}
};
  
const RoleMapping = {
    1: Role.SuperAdminRole,
    2: Role.AdminRole,
    3: Role.DoctorRole,
    4: Role.NurseRole,
    5: Role.PatientRole
};

const CreateRoleAdmin = [
    {label: "SUPER_ADMIN", value: 1},
    {label: "ADMIN", value: 2},
    {label: "DOCTOR", value: 3},
    {label: "NURSE", value: 4},
    {label: "PATIENT", value: 5}
];

const CreateRoleInstitusi = [
    {label: "DOCTOR", value: 3},
    {label: "NURSE", value: 4},
];

const RolesForm = {
    day: {label: "daily_reminder", value: 'day'},
    week: {label: "weekly_reminder", value: 'week'},
    month: {label: "monthly_reminder", value: 'month'},
    year: {label: "yearly_reminder", value: 'year'},
};
  
export { Role, RoleMapping, CreateRoleAdmin, CreateRoleInstitusi, RolesForm };