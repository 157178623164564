import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import { Tabs } from "antd";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { RoleMapping } from "../../../helpers/roles";
import { toast, Bounce } from "react-toastify";
import swal from "sweetalert";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchDetailInstansi,
  fetchDataDokter,
  fetchDataWorkspace,
  fetchDataProgram,
  fetchDataProgramById,
  changeDataProgramById,
  fetchSaveDataModal,
  fetchDeleteProgramById,
  resetStatusDataProgram,
  resetDataDetail,
  resetDataProgramById,
} from "../../../redux/slices/adminInstansiDetailSlice";

export default function KlinikDetail() {
  const location = useLocation();
  const getFirstSegment = (path) => {
    const segments = path.split("/").filter(Boolean);
    return segments[2];
  };
  const thirdSegment = getFirstSegment(location.pathname);
  const [titleModal, setTitleModal] = useState("");
  const [show, setShow] = useState(false);
  const [mount, setMount] = useState(false);

  const dispatch = useDispatch();
  const adminInstansiDetail = useSelector((state) => state.adminInstansiDetail);
  const instansiDetail = useSelector(
    (state) => state.adminInstansiDetail.detailInstansiResp.data,
  );

  const handleClose = () => {
    setShow(false);
    dispatch(resetDataProgramById());
  };

  const addData = (title) => {
    setShow(true);
    setTitleModal(title);
  };

  const columns = [
    {
      name: "Identitas",
      selector: (row) => row.title,
      cell: (row) => (
        <>
          <div className="d-flex align-items-center gap-2">
            <div>
              <h6 className="mb-0">{row.name}</h6>
              <span className="fs-xs text-secondary">{row.email}</span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Institusi",
      cell: (row) => (
        <>
          <div className="d-flex align-items-center gap-2">
            <div>
              <h6 className="mb-0">{row.institution.name}</h6>
              <span className="fs-xs text-secondary">
                {row.institution.email}
              </span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Role",
      selector: (row) => RoleMapping[row.role].role,
    },
    {
      name: "Department",
      selector: (row) => (row.department ? row.department : "-"),
    },
  ];

  const columnsrk = [
    {
      name: "Program",
      selector: (row) => row.name,
    },
    {
      name: "Ruang Kerja",
      selector: (row) =>
        findWorkspaceByValue(
          adminInstansiDetail.dataWorkspaceResp.data,
          row.workspace_id,
        )?.label,
    },
    {
      name: "Opsi",
      selector: (row) => row.id,
      cell: (row) => (
        <>
          <Link
            to="#"
            className="btn btn-primary"
            onClick={() => dispatchFetchDataProgramById(row.id)}
          >
            <i className="ri-edit-2-line"></i>
          </Link>{" "}
          &nbsp;
          <Link
            to="#"
            className="btn btn-danger"
            onClick={() => deleteData(row.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Link>
        </>
      ),
    },
  ];

  const findWorkspaceByValue = (array, value) => {
    if (array) {
      return array.find((item) => item.value === value);
    }
    return "";
  };

  useEffect(() => {
    setMount(true);
    dispatGetDetailInstansi();
    dispatchGetDataDokter();
    dispatchGetDataWorkspace();
    dispatchGetDataProgram();
  }, [adminInstansiDetail]);

  // unmount
  useEffect(
    () => () => {
      if (mount) {
        dispatch(resetDataDetail());
      }
    },
    [mount],
  );

  const dispatGetDetailInstansi = async () => {
    if (adminInstansiDetail.detailInstansiStatus === "idle") {
      dispatch(fetchDetailInstansi(thirdSegment));
    }

    if (adminInstansiDetail.detailInstansiStatus === "failed") {
      toast.error(adminInstansiDetail.detailInstansiError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const dispatchGetDataDokter = async () => {
    if (adminInstansiDetail.dataDokterStatus === "idle") {
      dispatch(fetchDataDokter(thirdSegment));
    }

    if (adminInstansiDetail.dataDokterStatus === "failed") {
      toast.error(adminInstansiDetail.dataDokterError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const dispatchGetDataWorkspace = async () => {
    if (adminInstansiDetail.dataWorkspaceStatus === "idle") {
      dispatch(fetchDataWorkspace(thirdSegment));
    }

    if (adminInstansiDetail.dataWorkspaceStatus === "failed") {
      toast.error(adminInstansiDetail.dataWorkspaceError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const dispatchGetDataProgram = async () => {
    if (adminInstansiDetail.dataProgramStatus === "idle") {
      dispatch(fetchDataProgram(thirdSegment));
    }

    if (adminInstansiDetail.dataProgramStatus === "failed") {
      toast.error(adminInstansiDetail.dataProgramError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const saveData = async () => {
    const dataModal = adminInstansiDetail.dataProgramByIdResp.data;
    let data = JSON.stringify({
      name: dataModal.name,
    });

    if (titleModal == "Tambah") {
      data = JSON.stringify({
        names: [dataModal.name],
        workspace_id: dataModal.workspace_id,
      });
    }
    const param = {
      data: data,
      instansiId: dataModal.id,
      action: titleModal,
    };
    const disp = await dispatch(fetchSaveDataModal(param));
    if (disp.payload.status !== true) {
      toast.error(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    } else {
      toast.success(disp.payload.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
    await dispatch(resetStatusDataProgram());
    dispatchGetDataProgram();
    dispatch(resetDataProgramById());
    setShow(false);
  };

  const dispatchFetchDataProgramById = async (id) => {
    setTitleModal("Ubah");
    if (adminInstansiDetail.dataProgramByIdStatus === "idle") {
      await dispatch(fetchDataProgramById(id));
      if (adminInstansiDetail.dataProgramByIdError) {
        toast.error(adminInstansiDetail.dataProgramByIdError, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      }
    }
    setShow(true);
  };

  const handleChangeModal = (e) => {
    let value = e.target.value;
    let key = e.target.name;
    dispatch(changeDataProgramById({ key: key, value: value }));
  };

  const handleChangeModalOption = (e, key) => {
    dispatch(changeDataProgramById({ key: key, value: e.value }));
  };

  const deleteData = (id) => {
    swal({
      title: "Apa Data Ingin Dihapus?",
      text: "Jika data dihapus, data tidak dapat dikembalikan!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: "Batal",
        confirm: {
          text: "Hapus",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
    })
      .then(async (willDelete) => {
        if (willDelete) {
          const disp = await dispatch(fetchDeleteProgramById(id));
          if (disp.payload.status !== true) {
            toast.error(disp.payload.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
          } else {
            toast.success(disp.payload.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
            });
          }

          await dispatch(resetStatusDataProgram());
          dispatchGetDataProgram();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const onChange = (key) => {};

  const items = [
    {
      key: "1",
      label: "Profile Instansi",
      children: (
        <>
          <Row>
            <Col lg={6}>
              <div className="card card-task">
                <div className="card-body p-3 pb-1">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="card-title">Informasi Pribadi</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2 mb-1">
                    <h6 className="card-date">Nama Instansi</h6>
                    <h6 className="card-title">
                      {instansiDetail?.name ? instansiDetail?.name : "-"}
                    </h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2 mb-1">
                    <h6 className="card-date">Nama Penanggung Jawab</h6>
                    <h6 className="card-title">
                      {instansiDetail?.pic ? instansiDetail?.pic : "-"}
                    </h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2 mb-1">
                    <h6 className="card-date">Domisili</h6>
                    <h6 className="card-title">
                      {instansiDetail?.location
                        ? instansiDetail?.location
                        : "-"}
                    </h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
                    <h6 className="card-date">Alamat Lengkap</h6>
                    <h6 className="card-title w-50 text-end">
                      {instansiDetail?.address ? instansiDetail?.address : "-"}
                    </h6>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="card card-task">
                <div className="card-body p-3 pb-1">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="card-title">Data Akses</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2 mb-1">
                    <h6 className="card-date">No HP (Whatsapp)</h6>
                    <h6 className="card-title">
                      {instansiDetail?.phone ? instansiDetail?.phone : "-"}
                    </h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
                    <h6 className="card-date">Email</h6>
                    <h6 className="card-title">
                      {instansiDetail?.email ? instansiDetail?.email : "-"}
                    </h6>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: "Dokter/Perawat",
      children: (
        <>
          <h6>Daftar Dokter/Perawat</h6>
          { adminInstansiDetail.dataDokterResp.data ? <DataTable
            columns={columns}
            data={
              adminInstansiDetail.dataDokterResp.data
                ? adminInstansiDetail.dataDokterResp.data
                : []
            }
            pagination
            progressPending={adminInstansiDetail.detailInstansiPending}
            defaultSortFieldId={1}
          /> : <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/>
          <h5>Data Not Found</h5><p>You can create new Dokter/Perawat!</p></div>}
        </>
      ),
    },
    {
      key: "3",
      label: "Ruang Kerja/Program",
      children: (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <h6>Ruang Kerja/Program</h6>
            <Button
              variant="primary"
              className="d-flex align-items-center"
              onClick={() => addData("Tambah")}
            >
              <i className="ri-add-line"></i>Tambah Program
            </Button>
          </div>
          { adminInstansiDetail.dataProgramResp ? <DataTable
            columns={columnsrk}
            data={
              adminInstansiDetail.dataProgramResp?.data
                ? adminInstansiDetail.dataProgramResp?.data
                : []
            }
            pagination
            progressPending={adminInstansiDetail.dataProgramPending}
            defaultSortFieldId={1}
          /> : <div className="text-center"> <img src={"/favicon.png"} width="100px" alt={"Icon"}/>
          <h5>Data Not Found</h5><p>You can create new program!</p></div>}

          <Modal show={show} onHide={handleClose} centered>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                saveData();
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>{titleModal} Program</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {titleModal == "Tambah" ? (
                  <Form.Group controlId="formFileSm" className="mb-3">
                    <Form.Label>Ruang Kerja</Form.Label>
                    <Select
                      options={adminInstansiDetail.dataWorkspaceResp.data}
                      isSearchable={false}
                      required
                      onChange={(e) =>
                        handleChangeModalOption(e, "workspace_id")
                      }
                    />
                  </Form.Group>
                ) : (
                  ""
                )}

                <Form.Group controlId="formFileSm" className="mb-3">
                  <Form.Label>Program</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukan Program"
                    name="name"
                    onChange={handleChangeModal}
                    value={adminInstansiDetail.dataProgramByIdResp.data?.name}
                    required
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Batalkan
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={adminInstansiDetail.saveDataModalPending}
                >
                  {adminInstansiDetail.saveDataModalPending
                    ? "Silahkan Tunggu ..."
                    : "Simpan"}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>Auto Health Care | Detail Instansi</title>
      </Helmet>
      <div className="p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/admin">Admin</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/instansi/aktif">Instansi</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Detail
              </li>
            </ol>
            <h4 className="main-title mb-0">{instansiDetail?.name ? instansiDetail?.name : "-"}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-one w-100">
              <div className="card-body">
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}